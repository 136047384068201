import React from "react";
import RefillFormHeader from "./RefillFormHeader";
import RefillFormBody from "./RefillFormBody";
import RefillFormSKU from "./RefillFormSKU";
import RefillFormSignature from "./RefillFormSignature";

const RefillForm = ({ transaction }) => {
  const paymentMethod =
    transaction.paymentMethod === "COD"
      ? "Cash on Delivery"
      : transaction.paymentMethod;

  return (
    <div className="mt-3 mx-1">
      <RefillFormHeader
        zendeskId={transaction.zendeskId}
        molecule={
          transaction.details[0] && transaction.details[0].drugDetail.molecule
        }
      />
      <RefillFormBody transaction={transaction} />
      <RefillFormSKU
        medicines={transaction.details}
        paymentMethod={paymentMethod}
      />
      <RefillFormSignature />
    </div>
  );
};

export default RefillForm;
