import React, { Component } from "react";
import axios from "axios";
import { parse } from "query-string";
import { connect } from "react-redux";
import PageContent from "UIKit/components/PageContent";
import api from "Services/api";
import SalesInvoice from "./SalesInvoice";

class SalesInvoicePrintView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: [],
      isFetchingTransactions: false,
      hasCoupon: false,
    };
  }

  componentDidMount() {
    var transactionsToPrint = [this.props.match.params.id];
    if (!transactionsToPrint[0]) {
      const orders = parse(this.props.location.search).orders;
      if (Array.isArray(orders)) {
        transactionsToPrint = orders;
      } else {
        transactionsToPrint = [orders];
      }
    }
    let transactions = [];
    var calls = [];
    transactionsToPrint.forEach((transaction) => {
      const url = `transactions/${transaction}/`;
      calls.push(api.get(url));
    });
    axios
      .all(calls)
      .then((...args) => {
        const mergedTransactions = args[0].reduce(
          (accumulator, currentValue) => {
            accumulator.push(currentValue.data);
            return accumulator;
          },
          []
        );

        mergedTransactions.forEach((transaction) => {
          transactions.push(transaction);
        });

        this.setState({
          transactions,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  printSalesInvoice = () => {
    window.print();
  };

  handleBackToOrder = () => {
    this.props.history.goBack();
  };

  handleBackToDashboard = () => {
    this.props.history.push("/transactions");
  };

  render() {
    // Checks if any of the transactions has a coupon code and convenience fee
    // SI computations not yet accurately handled for transactions with coupons and convenience fee!
    const couponCheck = this.state.transactions.findIndex(
      (transaction) =>
        !!transaction.couponCode && !transaction.couponCode === "-"
    );
    const convenienceFeeCheck = this.state.transactions.findIndex(
      (transaction) => parseFloat(transaction.convenienceFee) > 0
    );
    return (
      <PageContent>
        <div
          className={`notification ${
            couponCheck !== -1 || convenienceFeeCheck !== -1
              ? "is-danger"
              : "is-warning"
          }`}
        >
          <div className="field is-grouped is-pulled-right">
            <p className="control">
              <button
                className="button is-primary"
                onClick={this.printSalesInvoice}
              >
                Print
              </button>
            </p>

            <p className="control">
              <button
                className="button is-pulled-right"
                onClick={this.handleBackToDashboard}
              >
                Back to Dashboard
              </button>
            </p>
          </div>
          <p className="print-message">
            {couponCheck !== -1 || convenienceFeeCheck !== -1
              ? "WARNING: You are printing SIs of transactions with a coupon code and/or convenience fee, of which computations the system isn't handling yet. Please report to IT's Admin System Bug Thread if this SI is urgent."
              : "You are printing sales invoices (choose A4 size when printing). Please verify information before printing."}
          </p>
        </div>
        {this.state.transactions.map((transaction) => (
          <SalesInvoice
            transaction={transaction}
            chargedTo="MAXICARE HEALTHCARE CORPORATION"
            customerName={`${transaction.customerFirstName} ${transaction.customerLastName}`}
            tin={"000-487-637-000"}
            address={`${transaction.billingStreetAddress} ${transaction.billingBarangay} ${transaction.billingCityMunicipality}, ${transaction.billingProvince}`}
          />
        ))}
      </PageContent>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.entities.products,
  transactions: state.entities.transactions,
  selectedTransactions: state.selectedTransactions,
});

export default connect(mapStateToProps)(SalesInvoicePrintView);
