import React from 'react';
import {FormInput, CheckBox, Dropdown, Tags} from 'UIKit/components/Forms/FormInput';
import { AddCouponContext } from 'Coupons/components/AddCoupon/AddCoupon';


const locations = [
  'PROVINCIAL', 'NCR',
  'Caloocan', 'Las Pinas', 'Makati', 'Mandaluyong', 'Malabon',
  'Manila', 'Marikina', 'Muntinlupa', 'Navotas', 'Paranaque',
  'Pasay', 'Pasig', 'Pateros', 'Quezon City', 'San Juan',
  'Taguig', 'Valenzuela'
]

const UserRestrictionsForm = () => {
  return (
    <AddCouponContext.Consumer>
    { context =>
      <div className='coupon-form'>
      <FormInput 
        id="company"
        type="text"
        label="Company"
        value={context.coupon.company}
        onChange={context.getData} 
      />
      <FormInput 
        id="userUseLimit"
        type="number" 
        label="Uses per User" 
        value={context.coupon.userUseLimit}
        onChange={context.getData}
      />
      <CheckBox 
        id="firstTime"
        onChange={context.getData}
        label="First-time users only"
      />
      <Dropdown 
        id="locations"
        label="Locations"
        options={locations}
        onClick={context.getData}
      />
      <Tags
        label=" "
        tags={context.coupon.locations}
        onClick={context.removeFromLocations}
        tagStyle="is-medium is-primary"
      />
    </div>
    }
    </AddCouponContext.Consumer>
  );
};

export default UserRestrictionsForm;