import React, { Fragment } from "react";
import classNames from "classnames";
import styles from "./refill.module.scss";

const RefillFormSignature = () => {
  return (
    <Fragment>
      <div className="mt-5 is-size-6 has-text-left">
        By signing, I understand that this order is still subject to
        verification and may be cancelled if found ineligible by the mechanics
        of the program.
      </div>
      <div className={classNames("is-size-6 mt-3 mb-5", styles["signature"])}>
        <div className="columns">
          <div className="column is-8"></div>
          <div className=" column is-4">
            <hr className="mb-0" />
            <div>Name and Signature</div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default RefillFormSignature;
