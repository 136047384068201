import React, { Fragment } from "react";
import PropTypes from "prop-types";
import provinces from "assets/data/philippineLocations.json";
import useAddressPicker from "Services/useAddressPicker";
import { PanelContent } from "UIKit/components/SplitContainer/SplitContainer";
import { Field } from "formik";

const AddressGenerator = ({
  type,
  values,
  title,
  withFields,
  handleChange
}) => {
  const [activeCityMunicipality, setProvince] = useAddressPicker(
    values[`${type}Province`]
  );

  if (!withFields) {
    return (
      <span>
        {values[`${type}StreetAddress`] && values[`${type}StreetAddress`]}
        &nbsp;
        {values[`${type}CityMunicipality`] && values[`${type}CityMunicipality`]}
        &nbsp;{values[`${type}Province`] && values[`${type}Province`]}
      </span>
    );
  } else {
    return (
      <PanelContent header={title}>
        <div className="field">
          <label>
            Street Address:
            <Field name={`${type}StreetAddress`} className="input" />
          </label>
        </div>
        <div className="columns">
          <div className="column">
            <label>
              Province:
              <div className="select">
                <Field
                  name={`${type}Province`}
                  component="select"
                  onChange={event => {
                    setProvince(event.target.value);
                    handleChange(event);
                  }}
                >
                  <option value="">--Select Province--</option>
                  {provinces.map(province => (
                    <option
                      key={province["Province"]}
                      value={province["Province"]}
                    >
                      {province["Province"]}
                    </option>
                  ))}
                </Field>
              </div>
            </label>
          </div>
          <div className="column">
            <label>
              City/Municipality:
              <div className="select">
                <Field
                  component="select"
                  name={`${type}CityMunicipality`}
                  onChange={event => handleChange(event)}
                >
                  {activeCityMunicipality.length ? (
                    <Fragment>
                      <option value="">-- Select City/Municipality --</option>
                      {activeCityMunicipality.map(city => (
                        <option key={city["City"]} value={city["City"]}>
                          {city["City"]}
                        </option>
                      ))}
                    </Fragment>
                  ) : (
                    <option value="">-- Select a Province First --</option>
                  )}
                </Field>
              </div>
            </label>
          </div>
        </div>
      </PanelContent>
    );
  }
};

AddressGenerator.propTypes = {
  type: PropTypes.string.isRequired,
  values: PropTypes.object
};

export default AddressGenerator;
