import React, { Fragment } from "react";
import { FormField, CheckBox } from "UIKit/components/Forms/FormInput";
import AddressPicker from "./AddressPicker";
import channels from "assets/data/channels.json";
import customerTypes from "assets/data/customerType.json";
import paymentMethods from "assets/data/paymentMethod.json";
import { Field, ErrorMessage } from "formik";
import FormSelect from "UIKit/components/Forms/FormSelect";

import CustomerVIP from "./VIPs/CustomerVIP.json";
import EmailVIP from "./VIPs/EmailVIP.json";
import CompanyVIP from "./VIPs/CompanyVIP.json";
import MobileNumberVIP from "./VIPs/MobileNumberVIP.json";

const CustomerDetails = ({
  values,
  handleDetailChange,
  isNewOrder,
  handleFieldChange,
  showError,
  isSameAddress,
  setFieldValue,
}) => {
  const handleFormSelectChange = (value, name) => {
    setFieldValue(name, value);
    handleDetailChange({ target: { value, name } });
    let channelType = "regular";
    if (!value.__isNew__ || value.type) channelType = "regular_vip";
    else return;
    handleDetailChange({ target: { value: channelType, name: "channel" } });
  };

  const handleNameAutofill = (fieldToChange) => (value, name) => {
    const { value: fullName, lastName, type, __isNew__ } = value;
    const firstName = fullName.replace(lastName, "");
    let newValue = { ...value };
    let fieldToChangeValue = {};
    if (!__isNew__) {
      if (name === "customerFirstName") {
        newValue = { value: firstName, label: firstName };
        fieldToChangeValue = { value: lastName, label: lastName, type };
      } else {
        newValue = { value: lastName, label: lastName };
        fieldToChangeValue = { value: firstName, label: firstName, type };
      }
      handleFormSelectChange(fieldToChangeValue, fieldToChange);
    }
    handleFormSelectChange(newValue, name);
  };

  const handleResetFormChange = (fieldToChange) => (value, name) => {
    if (!value.__isNew__) {
      handleDetailChange({
        target: { value: null, name: "customerFirstName" },
      });
      handleDetailChange({ target: { value: null, name: "customerLastName" } });
      handleDetailChange({ target: { value: null, name: fieldToChange } });
    }
    handleFormSelectChange(value, name);
  };

  const handleInputChange = (event) => {
    handleDetailChange(event);
    handleFieldChange(event);
  };

  return (
    <div>
      <div>
        {isNewOrder && (
          <Fragment>
            <FormSelect
              name="customerFirstName"
              value={values && values.customerFirstName}
              label="First Name"
              placeholder=""
              onChange={handleNameAutofill("customerLastName")}
              options={CustomerVIP}
              isRequired
            />

            <FormSelect
              name="customerLastName"
              value={values && values.customerLastName}
              label="Last Name"
              placeholder=""
              onChange={handleNameAutofill("customerFirstName")}
              options={CustomerVIP}
              isRequired
            />
          </Fragment>
        )}

        <FormSelect
          name="customerEmail"
          label="Email Address"
          type="email"
          placeholder=""
          value={values && values.customerEmail}
          onChange={handleResetFormChange("mobileNumber")}
          options={EmailVIP}
          isRequired
        />

        <FormSelect
          name="mobileNumber"
          label="Mobile Number"
          type="email"
          placeholder=""
          value={values && values.mobileNumber}
          isRequired
          onChange={handleResetFormChange("customerEmail")}
          options={MobileNumberVIP}
          staticText="+63"
        />

        <div className="mb-1">
          <label>Refill Code</label>
          <Field
            name="refillCode"
            className="input"
            value={values && values.refillCode}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-1">
          <label>Refill Dispatch Due Date</label>
          <Field
            type="date"
            name="refillDispatchDueDate"
            className="input"
            value={values && values.refillDispatchDueDate}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-1">
          <label>Zendesk Id</label>
          <Field
            name="zendeskId"
            className="input"
            value={values && values.zendeskId}
            onChange={handleInputChange}
          />
        </div>

        <FormSelect
          name="companyName"
          value={values && values.companyName}
          label="Company Name"
          placeholder=""
          onChange={handleFormSelectChange}
          options={CompanyVIP}
        />

        <div className="mb-1">
          <label>Company ID</label>
          <Field
            name="companyId"
            className="input"
            onChange={handleInputChange}
          />
        </div>

        <AddressPicker
          handleDetailChange={handleDetailChange}
          handleChange={handleFieldChange}
          transaction={values}
          isNewOrder={isNewOrder}
          type="shipping"
          showError={showError}
        />
        <br></br>

        <CheckBox
          name="isSameAddress"
          label="Same billing address"
          isChecked={isSameAddress}
          onChange={handleDetailChange}
        />

        {!isSameAddress && (
          <Fragment>
            <AddressPicker
              handleDetailChange={handleDetailChange}
              handleChange={handleFieldChange}
              transaction={values}
              isNewOrder={isNewOrder}
              type="billing"
              showError={showError}
            />
          </Fragment>
        )}
        <div className="field control">
          <label>Channel</label>
          <div className="select">
            <Field
              component="select"
              name="channel"
              onChange={handleInputChange}
              value={values.channel}
              required={true}
            >
              {channels.map((channel, index) => (
                <option key={index} value={channel.channel}>
                  {channel.label}
                </option>
              ))}
            </Field>
          </div>
        </div>
        <div className="field control">
          <label>Customer Type</label>
          <div className="select">
            <Field
              component="select"
              className="is-capitalized"
              name="customerType"
              label="Customer Type"
              onChange={handleInputChange}
              value={values.customerType}
              required={true}
            >
              <option value="">Select customer type</option>
              {customerTypes.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </Field>
          </div>
          <ErrorMessage
            name="customerType"
            render={(msg) => (
              <div className="has-text-danger is-size-8">{msg}</div>
            )}
          />
        </div>

        <div className="field control">
          <label>Payment Method</label>
          <div className="select">
            <Field
              component="select"
              className="is-capitalized"
              name="paymentMethod"
              label="Payment Method"
              onChange={handleInputChange}
              value={values.paymentMethod}
              required={true}
            >
              {paymentMethods.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </Field>
          </div>
          <ErrorMessage
            name="paymentMethod"
            render={(msg) => (
              <div className="has-text-danger is-size-8">{msg}</div>
            )}
          />
        </div>

        <div className="field control">
          <Field
            name="internalNotes"
            render={({ field, form }) => (
              <FormField
                {...field}
                className="input"
                label="Internal Notes"
                type="text"
                value={values.internalNotes}
                onChange={handleInputChange}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};
export default CustomerDetails;
