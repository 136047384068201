import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";

import styles from "./levelfilters.module.scss";

const LevelItem = ({ title, count, currentId, id, callback }) => {
  const isActive = currentId === id;
  const isLevelActive = { [styles["level-item--is-active"]]: isActive };
  const isTitleActive = { "has-text-primary": isActive };

  const triggerCallback = () => {
    if (callback) callback(id);
  };

  return (
    <div
      className={classNames(styles["level-item"], isLevelActive)}
      onClick={triggerCallback}
    >
      <div className={classNames(styles["level-item__title"], isTitleActive)}>
        {title}
      </div>
      <span
        className={classNames(
          "ml-1 tag is-rounded",
          styles["level-item__badge"],
          {
            "is-primary": isActive
          }
        )}
      >
        {count >= 0 ? count : <FontAwesomeIcon icon={faCircleNotch} spin />}
      </span>
    </div>
  );
};

export default LevelItem;
