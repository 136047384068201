import React from "react";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const CircleLoader = ({ type }) => {
  return (
    <div className={type}>
      <FontAwesomeIcon icon={faCircleNotch} spin={true} />
    </div>
  );
};

export default CircleLoader;
