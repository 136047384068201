import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faFastBackward,
  faStepBackward,
  faStepForward,
  faFastForward
} from "@fortawesome/free-solid-svg-icons";

import styles from "./pagination.module.scss";

const PageIcons = ({ onPageSkip, isDisabled, type = "backward" }) => {
  const backwardIcons = [faFastBackward, faStepBackward];
  const forwardIcons = [faStepForward, faFastForward];

  const icons = type === "forward" ? forwardIcons : backwardIcons;
  const iconsLength = type === "forward" ? -icons.length + 1 : icons.length;

  return (
    <p className="control">
      {icons.map((icon, index) => (
        <button
          onClick={() => onPageSkip(-iconsLength + index)}
          className={classNames("button is-size-6", styles["is-transparent"])}
          disabled={isDisabled}
        >
          <FontAwesomeIcon icon={icon} />
        </button>
      ))}
    </p>
  );
};

export default PageIcons;
