import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import Search from "Search/Search";
import {
  getFormScore,
  getBrandScore,
  getHighlightedParts
} from "Search/services/ProductSort";
import { AddCouponContext } from "Coupons/components/AddCoupon/AddCoupon";
import { Dropdown } from "UIKit/components/Forms/FormInput";

const categories = [
  "All",
  "Bone and Joint",
  "Cough and Colds",
  "Diabetes",
  "Endocrine",
  "Eye and Ear",
  "Hypertension",
  "Infection",
  "Kits and First Aid",
  "Mental Health",
  "Pain and Fever",
  "Urinary and Reproductive Health",
  "Respiratory Health",
  "Stomach Care",
  "Vitamins, Minerals, and Supplements",
  "Dermatologic",
  "Cardiovascular",
  "Blood Drugs",
  "Cancer",
  "Electrolytes",
  "Oral Care"
];

class SKURestrictionsForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hiddenForm: true,
      localProductList: []
    };
  }

  searchFunction = searchQuery => {
    let searchResults = this.props.products
      .filter(item => {
        let toReturn = true;
        item.indexOf = 100;
        item.toDisplay = item.title;
        searchQuery.map(query => {
          if (item.title.toLowerCase().indexOf(query) === -1) toReturn = false;
          else if (item.title.toLowerCase().indexOf(query) < item.indexOf)
            item.indexOf = item.title.toLowerCase().indexOf(query);
          return query;
        });
        return toReturn;
      })
      .sort((a, b) => {
        let indexOfA = a.indexOf;
        let indexOfB = b.indexOf;
        if (indexOfA - indexOfB === 0) {
          let checkMultipleA = a.title.split("+").length;
          let checkMultipleB = b.title.split("+").length;
          if (checkMultipleA - checkMultipleB === 0) {
            let formScoreA = getFormScore(a.title);
            let formScoreB = getFormScore(b.title);
            if (formScoreA - formScoreB === 0) {
              let brandScoreA = getBrandScore(a.title);
              let brandScoreB = getBrandScore(b.title);
              return brandScoreA - brandScoreB;
            }
            return formScoreA - formScoreB;
          }
          return checkMultipleA - checkMultipleB;
        }
        return indexOfA - indexOfB;
      })
      .filter((item, i) => {
        item.rowIndex = 0;
        return i < 100;
      })
      .map(item => {
        return getHighlightedParts(item, searchQuery);
      });

    return searchResults;
  };

  toggleForm = () => {
    this.setState(prevstate => {
      return { hiddenForm: !prevstate.hiddenForm };
    });
  };

  render() {
    return (
      <AddCouponContext.Consumer>
        {context => (
          <div>
            <article className="message">
              <div
                className="message-header is-orimary"
                onClick={this.toggleForm}
              >
                <p>Add Restrictions</p>
                {this.state.hiddenForm ? (
                  <FontAwesomeIcon icon={faPlusCircle} />
                ) : (
                  <FontAwesomeIcon icon={faMinusCircle} />
                )}
              </div>
              <div
                className={`message-body ${
                  this.state.hiddenForm ? "is-hidden" : ""
                }`}
              >
                <div className="control centered">
                  <label className="radio">
                    <input
                      id="INCLUDE"
                      type="radio"
                      name="INCLUDE_EXCLUDE"
                      onChange={context.clickRadioButton}
                    />
                    &nbsp;Include
                  </label>
                  <label className="radio">
                    <input
                      id="EXCLUDE"
                      type="radio"
                      name="INCLUDE_EXCLUDE"
                      onChange={context.clickRadioButton}
                    />
                    &nbsp;Exclude
                  </label>
                </div>
                <p className="help">
                  Before adding any item, click include/exclude first.
                </p>
                <div
                  class="is-divider"
                  data-content="Include or Exclude SKUs/Items"
                />
                <div className="columns">
                  <div className="column">
                    <h1 className="subtitle is-5">Add SKU Restrictions</h1>
                    <Dropdown
                      id="categories"
                      label="Categories"
                      options={categories}
                      onClick={context.getData}
                    />
                    <div className="field is-grouped">
                      <label className="label field-label">Classes</label>
                      <div className="field-body">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            id="Branded"
                            onClick={context.getData}
                          />
                          &nbsp;Branded&nbsp;
                        </label>
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            id="Generic"
                            onClick={context.getData}
                          />
                          &nbsp;Generic&nbsp;
                        </label>
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            id="Unibranded"
                            onClick={context.getData}
                          />
                          &nbsp;Unibranded&nbsp;
                        </label>
                      </div>
                    </div>
                    <div className="field is-grouped">
                      <label className="label field-label">
                        Other Restrictions
                      </label>
                      <div className="field-body">
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            id="Prescription"
                            onClick={context.getData}
                          />
                          &nbsp;Prescription&nbsp;
                        </label>
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            id="OTC"
                            onClick={context.getData}
                          />
                          &nbsp;OTC&nbsp;
                        </label>
                      </div>
                    </div>
                    <button
                      className="button is-primary"
                      onClick={context.addSKUToList}
                    >
                      Add SKU Restriction
                    </button>
                  </div>
                  <div className="is-divider-vertical" data-content="OR" />
                  <div className="column">
                    <h1 className="subtitle is-5">Search Items</h1>
                    <div className="box">
                      <p className="help">
                        No items added to the 'Included' list assumes <b>all</b>{" "}
                        items are to be included in the coupon, as long as they
                        are also part of the <i>Included SKU Restrictions</i>.
                      </p>
                    </div>
                    <Search
                      id="0"
                      searchFunction={this.searchFunction}
                      parentComponent="COUPONS"
                      onClick={context.addItemToList}
                      withIcon={true}
                    />
                  </div>
                </div>
              </div>
            </article>
            <div className="columns">
              <div className="column">
                <div className="message is-success">
                  <div className="message-header">
                    <p>Included Items</p>
                  </div>
                  <table
                    className={`${
                      context.coupon.includedItems.length > 0
                        ? "scrollable"
                        : null
                    } table is-fullwidth is-hoverable is-striped`}
                  >
                    <tbody>
                      {context.coupon.includedItems.map((item, i) => (
                        <tr key={i}>
                          <td key={i} style={{ width: "90%" }}>
                            {item.title}
                          </td>
                          <td style={{ width: "10%" }}>
                            <button
                              className="delete is-medium"
                              onClick={context.removeItemFromList}
                              id="includedItems"
                              name={item.title}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="message is-success">
                  <div className="message-header">
                    <p>Included SKUs</p>
                  </div>
                  <table className="scrollable table is-fullwidth is-hoverable is-striped">
                    <tbody>
                      {context.coupon.includedSKUs.map((item, i) => (
                        <tr key={i}>
                          <td key={i} style={{ width: "90%" }}>
                            <p>Category: {item.category}</p>
                            <p>Class: {item.classes.join(", ")}</p>
                            <p>Restrictions: {item.restrictions.join(", ")}</p>
                          </td>
                          <td style={{ width: "10%" }}>
                            <button
                              className="delete is-medium"
                              onClick={context.removeItemFromList}
                              id="includedSKUs"
                              name={item.category}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="column">
                <div className="message is-danger">
                  <div className="message-header">
                    <p>Excluded Items</p>
                  </div>
                  <table
                    className={`${
                      context.coupon.excludedItems.length > 0
                        ? "scrollable"
                        : null
                    } table is-fullwidth is-hoverable is-striped`}
                  >
                    <tbody>
                      {context.coupon.excludedItems.map((item, i) => (
                        <tr key={i}>
                          <td key={i} style={{ width: "90%" }}>
                            {item.title}
                          </td>
                          <td style={{ width: "10%" }}>
                            <button
                              className="delete is-medium"
                              onClick={context.removeItemFromList}
                              id="excludedItems"
                              name={item.title}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="message is-danger">
                  <div className="message-header">
                    <p>Excluded SKUs</p>
                  </div>
                  <table className="scrollable table is-fullwidth is-hoverable is-striped">
                    <tbody>
                      {context.coupon.excludedSKUs.map((item, i) => (
                        <tr key={i}>
                          <td key={i} style={{ width: "90%" }}>
                            <p>Category: {item.category}</p>
                            <p>Class: {item.classes.join(", ")}</p>
                            <p>Restrictions: {item.restrictions.join(", ")}</p>
                          </td>
                          <td style={{ width: "10%" }}>
                            <button
                              className="delete is-medium"
                              onClick={context.removeItemFromList}
                              id="excludedSKUs"
                              name={item.category}
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        )}
      </AddCouponContext.Consumer>
    );
  }
}

const mapStateToProps = state => ({
  searchResult: state.searchResult,
  products: state.products
});

export default connect(mapStateToProps)(SKURestrictionsForm);
