import React from "react";
import classNames from "classnames";
import styles from "./refill.module.scss";
import RefillFormSignature from "./RefillFormSignature";

const RefillFormSKU = ({ medicines, paymentMethod }) => {
  const total = medicines.reduce((a, b) => {
    let temp = a.vatIncTotal;
    if (typeof a === "number") temp = a;
    return parseFloat(temp) + parseFloat(b.vatIncTotal);
  });

  let medicineTotal = medicines.length === 1 ? medicines[0].vatIncTotal : total;

  return (
    <div className="is-size-4 mt-2">
      <div
        className={classNames(
          "has-text-grey has-text-weight-bold",
          styles["medicine__header"]
        )}
      >
        Medicines
      </div>

      {medicines.map((medicine) => {
        return (
          <div className={classNames("py-2", styles["medicine"])}>
            <div className="has-text-primary has-text-weight-bold">
              {medicine.productTitle}
            </div>
            <div
              className={classNames(
                "is-size-5 has-text-grey",
                styles["medicine__sub-details"]
              )}
            >
              <span>Quantity: {medicine.quantity}</span>
              <span>Php {parseFloat(medicine.vatIncTotal).toFixed(2)}</span>
            </div>
          </div>
        );
      })}

      <div
        className={classNames("mt-1 has-text-grey", styles["medicine__footer"])}
      >
        <div className="has-text-weight-bold">
          Amount due: Php {parseFloat(medicineTotal).toFixed(2)}{" "}
        </div>
        <div>Payment method: {paymentMethod}</div>
      </div>
    </div>
  );
};

export default RefillFormSKU;
