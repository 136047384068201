import React from "react";

import classNames from "classnames";

import { NPSScale } from "./DRCustomerFeedback";

import drBuilding from "assets/images/building.png";
import drPhone from "assets/images/phone.png";
import drQR from "assets/images/frame.png";
import { getTotal } from "Services/cart";
import { formatNumber, roundFloatToTwo } from "Services/util";
import { Fragment } from "react";

const ReceiptHeader = ({ transaction, lineItems, isChannelValidWithPrice }) => {
  // Fetch and manipulate data
  let fullName = `${transaction.customerFirstName} ${transaction.customerLastName}`;
  let mobile = transaction.mobileNumber.toString().padStart(11, "0");
  let notes = transaction.deliveryNotes ? transaction.deliveryNotes : "";
  let shippingAddress = `${transaction.shippingStreetAddress}, ${transaction.shippingCityMunicipality} ${transaction.shippingProvince}`;
  let paymentMethod = transaction.paymentMethod;
  let deliveryDate = new Date(Date.now()).toDateString();
  deliveryDate = deliveryDate.split(" ").slice(1).join(" ");

  let changeFor = "";
  let authorizedPerson = "";
  let authorizedPersonContact = "";

  if (transaction?.customFields) {
    changeFor = transaction?.customFields?.changeFor || 0;
    authorizedPerson = transaction?.customFields?.authorizedPerson;
    authorizedPersonContact =
      transaction?.customFields?.contactNumberOfAuthorizedPerson;
  } else {
    let authorizedSplit = notes.split("Authorized person to receive: ");
    if (authorizedSplit.length > 1)
      authorizedPerson = authorizedSplit[1].split("\n")[0];
    else authorizedPerson = "N/A";

    let changeSplit = notes.split("Change for: ");
    if (changeSplit.length > 1) changeFor = changeSplit[1].split("\n")[0] || 0;

    notes = notes.replace(
      `Authorized person to receive: ${authorizedPerson}`,
      ""
    );
    notes = notes.replace(`Change for: ${changeFor}`, "");
  }

  let total = 0;
  if (lineItems) {
    total = roundFloatToTwo(
      getTotal(
        lineItems,
        parseFloat(transaction.convenienceFee),
        transaction.couponCode,
        parseFloat(transaction.discountAmount)
      )
    );
  }

  return (
    <Fragment>
      <div className="dr-top-section mt-1">
        <div className="personal-delivery-section columns">
          <div className="column">
            <div className="personal-information">
              <h1 className="name-header is-size-3 has-text-weight-bold">
                {fullName}
              </h1>
              <p className="is-size-6 mt-1">
                <img
                  className="logo mr-1"
                  src={drBuilding}
                  alt="building"
                  width="20"
                />{" "}
                {shippingAddress}
              </p>
              <p className="mt-1 is-size-6">
                <img
                  className="logo mr-1"
                  src={drPhone}
                  alt="building"
                  width="20"
                />{" "}
                {mobile}
              </p>
            </div>
            <div className="billing-information-section mt-2 is-flex">
              <div className="information-section">
                <h1 className="has-text-weight-bold is-size-5">
                  Billing Information
                </h1>
                <div className="info-container ml-2 has-text-weight-bold">
                  {isChannelValidWithPrice && (
                    <Fragment>
                      <p className="mt-1 is-flex total-amount-container">
                        <span className="pl-2 billing-info-label">
                          TOTAL AMOUNT DUE
                        </span>
                        <span className="pr-2">P {formatNumber(total)}</span>
                      </p>
                      <p className="mt-1 is-flex">
                        <span className="pl-2 billing-info-label">
                          Payment Method
                        </span>
                        <span className="pr-2">{paymentMethod}</span>
                      </p>
                    </Fragment>
                  )}
                  <p className="mt-1 is-flex">
                    <span className="pl-2 billing-info-label">Order ID</span>
                    <span className="pr-2">
                      {transaction.zendeskId || transaction.id}
                    </span>
                  </p>
                  {paymentMethod === "COD" && changeFor > 0 && (
                    <p className="mt-1 is-flex">
                      <span className="pl-2 billing-info-label">
                        Change For
                      </span>
                      <span className="pr-2">P {formatNumber(changeFor)}</span>
                    </p>
                  )}
                  {authorizedPerson && (
                    <p className="mt-1 is-flex">
                      <span className="pl-2 billing-info-label">
                        Authorized Representative
                      </span>
                      <span className="pr-2">{authorizedPerson}</span>
                    </p>
                  )}
                  {authorizedPersonContact && (
                    <p className="mt-1 is-flex">
                      <span className="pl-2 billing-info-label">
                        Contact Number
                      </span>
                      <span className="pr-2">0{authorizedPersonContact}</span>
                    </p>
                  )}
                  {transaction.refillCode && (
                    <p className="mt-1 is-flex">
                      <span className="pl-2 billing-info-label">
                        Refill Code
                      </span>
                      <span className="pr-2">{transaction.refillCode}</span>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="delivery-information has-text-centered has-text-weight-bold is-size-5 mt-1">
            <div className="delivery-details">
              <p className="my-1">DELIVERY DATE</p>
              <div className="delivery-info px-3 has-text-primary">
                {deliveryDate}
              </div>
            </div>
            <div className="delivery-details mt-1">
              <p className="my-1">DELIVERY NOTES</p>
              <div
                className={classNames(
                  "delivery-info px-3 is-size-6 has-text-weight-normal has-text-left",
                  {
                    "is-size-7": notes.length > 300,
                  }
                )}
              >
                {notes}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default ReceiptHeader;
