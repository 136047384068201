import React from "react";
import classNames from "classnames";

import styles from "./loading.module.scss";

const Loading = ({ size, message, className }) => (
  <div className={classNames("has-text-centered", className)}>
    <section
      className={classNames([
        styles["loader"],
        styles[size || "loader--is-normal"]
      ])}
    >
      <section className="is-centered">
        <div className={classNames(styles["dot-1"])}></div>
        <div className={classNames(styles["dot-2"])}></div>
        <div></div>
      </section>
      <p>{message}</p>
    </section>
  </div>
);

export default Loading;
