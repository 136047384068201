import { fetchPriceLists } from "Products/actions"; //fetchProducts
import api from "Services/api";
export const actionTypes = {
  SET_AUTH_TOKEN: "SET_AUTH_TOKEN",
  REMOVE_AUTH_TOKEN: "REMOVE_AUTH_TOKEN",
  SET_USER: "SET_USER"
};

const receiveAuthToken = authToken => {
  return {
    type: actionTypes.SET_AUTH_TOKEN,
    payload: authToken
  };
};

const receiveUserData = user => dispatch => {
  delete user["access"];
  delete user["token"];
  delete user["refresh"];
  dispatch({
    type: actionTypes.SET_USER,
    payload: user
  });
  api
    .get(`users/${user.id}/`)
    .then(response => {
      dispatch({
        type: actionTypes.SET_USER,
        payload: response.data
      });
    })
    .catch(error => console.log(error));
};

export function removeAuthToken() {
  return {
    type: actionTypes.REMOVE_AUTH_TOKEN
  };
}

export const fetchAuthToken = (code, backend) => dispatch => {
  api
    .post("login/social/jwt-pair-user/", {
      provider: backend,
      code
    })
    .then(response => {
      dispatch(receiveAuthToken(response.data.token));
      dispatch(receiveUserData(response.data));
      // dispatch(fetchProducts());
      dispatch(fetchPriceLists());
    })
    .catch(error => console.log(error));
};

export const checkAuthToken = (token, errorCallback) => {
  return api
    .post("api/token/verify/", { token })
    .catch(error => errorCallback());
};
