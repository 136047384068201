import React, { Fragment } from "react";

import Dashboard from "DashboardV2/DashboardV2";
import SourcingSheetRow from "./SourcingSheetRow";
import FilterField from "Sourcing/components/FilterField";
import { Dropdown } from "UIKit/components/Forms/FormInput";

import { sourcingSheetHeaders } from "../jsonInitializers";

import "./sourcingsheet.scss";

const SourcingSheetBody = () => {
  return (
    <Fragment>
      <section className="columns">
        <div className="column is-6">
          <FilterField
            setSearchString={""}
            searchString={""}
            placeholder="Search Item, PO Code, or Supplier..."
          />
        </div>
        <div className="column is-1" />
        <div className="column is-3">
          <Dropdown options={[]}></Dropdown>
        </div>
        <div className="column is-2">
          <div className="field">
            <button className="button is-primary is-fullwidth">
              Generate Message
            </button>
          </div>
        </div>
      </section>
      <div className="is-sourcingsheet-dashboard">
        <Dashboard headers={sourcingSheetHeaders} isCheckboxEnabled>
          <SourcingSheetRow item="Menthol + Methyl salicylate + Eucalyptus 20mL Liniment (White Flower No1)" />
          <SourcingSheetRow item="Anthraquinone glycosides + Salicylic acid 10mL Oral Drops (Pyralvex)" />
          <SourcingSheetRow item="Sodium alginate + Sodium bicarbonate + Calcium carbonate 500mg/267mg/160mg/10mL 10mL Suspension (Gaviscon)" />
        </Dashboard>
      </div>
    </Fragment>
  );
};

export default SourcingSheetBody;
