import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import styles from "./utils/elements.module.scss";

/**
 * @param {string} title string
 * @param {} children
 */

const Section = ({ title, children, addOns, hideBodyOnPrint }) => (
  <Fragment>
    <div className={classNames(styles["no-print"])}>
      <div className={classNames(styles["section"])}>
        {addOns?.left}
        <h4 className="is-size-4 has-text-weight-bold has-text-primary mt-0">
          {title}
        </h4>
        {addOns?.right}
      </div>
    </div>
    <div
      className={classNames({
        [styles["no-print"]]: hideBodyOnPrint,
      })}
    >
      <div className="mt-1 mb-3">{children}</div>
    </div>
  </Fragment>
);

Section.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
};

export default Section;
