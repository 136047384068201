import { CLIENT_FETCH_TYPE, CLIENT_SITE_FETCH_TYPE } from "./actions";

export const inititalState = {
  fetchedData: {},
  type: ""
};
export const clientDetailReducer = (state = inititalState, action) => {
  switch (action.type) {
    case CLIENT_FETCH_TYPE.ON_FETCH_CLIENT_FAILED:
      return Object.assign({}, state, {
        fetchedData: action.payload,
        type: CLIENT_FETCH_TYPE.ON_FETCH_CLIENT_FAILED
      });
    case CLIENT_FETCH_TYPE.ON_FETCH_CLIENT_SUCCESS:
      return Object.assign({}, state, {
        fetchedData: action.payload,
        type: CLIENT_FETCH_TYPE.ON_FETCH_CLIENT_SUCCESS
      });
    default:
      return Object.assign({}, state, {
        type: CLIENT_FETCH_TYPE.ON_FETCH_CLIENT_PROCESS
      });
  }
};

export const clientSiteReducer = (state = inititalState, action) => {
  switch (action.type) {
    case CLIENT_SITE_FETCH_TYPE.ON_FETCH_SITE_FAILED:
      return Object.assign({}, state, {
        fetchedData: action.payload || [],
        type: CLIENT_SITE_FETCH_TYPE.ON_FETCH_SITE_FAILED
      });
    case CLIENT_SITE_FETCH_TYPE.ON_FETCH_SITE_SUCCESS:
      return Object.assign({}, state, {
        fetchedData: action.payload || [],
        type: CLIENT_SITE_FETCH_TYPE.ON_FETCH_SITE_SUCCESS
      });
    default:
      return Object.assign({}, state, {
        type: CLIENT_SITE_FETCH_TYPE.ON_FETCH_SITE_PROCESS
      });
  }
};
