import React from "react";
import Section from "../Section";

const SelectReceiptToPrint = ({
  printables,
  handleToggleDocument,
  printDrugCard,
  handleBackToDashboard,
}) => {
  return (
    <div className="mb-5">
      <Section
        title="Select pages to print"
        addOns={{
          right: (
            <div className="field is-grouped mb-1">
              <p className="control">
                <button className="button is-primary" onClick={printDrugCard}>
                  Print
                </button>
              </p>

              <p className="control">
                <button
                  className="button is-pulled-right"
                  onClick={handleBackToDashboard}
                >
                  Back to Dashboard
                </button>
              </p>
            </div>
          ),
        }}
        hideBodyOnPrint
      >
        <div className="mt-2">
          {printables.map((pageInfo, index) => (
            <div className="field">
              <input
                className="is-checkradio is-info"
                type="checkbox"
                id={pageInfo.page}
                checked={pageInfo.checked}
                onChange={(event) => handleToggleDocument(event, index)}
                documentKey={index}
              />
              <label for={pageInfo.page}>{pageInfo.page}</label>
            </div>
          ))}
        </div>
      </Section>
    </div>
  );
};

export default SelectReceiptToPrint;
