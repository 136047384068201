import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
const formattedDateTime = dateTime => {
  const dateFormat = {
    month: "short",
    day: "numeric"
  };

  const timeFormat = {
    hour: "numeric",
    minute: "numeric"
  };
  return (
    <Fragment>
      {new Date(dateTime).toLocaleString("en-US", dateFormat)} <br />
      <span className="help">
        {new Date(dateTime).toLocaleString("en-US", timeFormat)}
      </span>
    </Fragment>
  );
};

const convertInititalToFullName = data => {
  switch (data.accountManager) {
    case "jlu":
      data.accountManager = "Justine Lu";
      break;
    case "fdelacruz":
      data.accountManager = "Fatima dela Cruz";
      break;
    case "clalap":
      data.accountManager = "Celine Lalap";
      break;
    case "cbautista":
      data.accountManager = "Christine Bautista";
      break;
    case "slapus":
      data.accountManager = "Shaira Lapus";
      break;
    case "yque":
      data.accountManager = "Yano Que";
      break;
    default:
      data.accountManager = "Justine Lu";
  }

  return data;
};

const ClientListRow = ({ data }) => {
  data = convertInititalToFullName(data);
  const {
    id,
    name,
    lastModified,
    created,
    accountManager,
    companyLetters,
    entityNumber
  } = data;
  return (
    <Fragment>
      <td className="has-text-centered">
        <Link to={`/client/${id}`}>
          <div className="clickable">{formattedDateTime(lastModified)}</div>
        </Link>
      </td>
      <td className="has-text-centered has-text-weight-bold status link-row">
        <Link to={`/client/${id}`}>
          <div className="clickable flex emphasis">
            {companyLetters + entityNumber}
          </div>
        </Link>
      </td>
      <td className="has-text-centered has-text-weight-bold status link-row">
        <Link to={`/client/${id}`}>
          <div className="clickable flex emphasis">{name}</div>
        </Link>
      </td>
      <td className="has-text-centered">
        <Link to={`/client/${id}`}>
          <div className="clickable flex">{accountManager}</div>
        </Link>
      </td>
      <td className="has-text-centered">
        <Link to={`/client/${id}`}>
          <div className="clickable">{formattedDateTime(created)}</div>
        </Link>
      </td>
    </Fragment>
  );
};

const propTypes = {
  data: PropTypes.object.isRequired
};

ClientListRow.propTypes = propTypes;

export default ClientListRow;
