import React, { useEffect, Fragment, useState } from "react";
import PropTypes from "prop-types";
import TransactionDetailsRow from "./TransactionDetailsRow";
import CouponInput from "IndividualOrder/components/CouponInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { getVatExSubtotal, getTotalVat, getTotal } from "Services/cart";
import { formatNumber } from "Services/util";
import { Table, Cell, Row } from "UIKit/components/Table_v2/Table";
import { PanelContent } from "UIKit/components/SplitContainer/SplitContainer";
import MainPanelHeader from "./TransactionMainPanelHeader";
import TextMessageDropdown from "./TextMessageDropdown";
import { getDiscountAmount } from "Services/coupon";

const propTypes = {
  transaction: PropTypes.object,
  transactionItems: PropTypes.array,
  addRowOnClick: PropTypes.func,
  deleteRowOnClick: PropTypes.func,
  handleChange: PropTypes.func,
  onSelectItem: PropTypes.func,
  searchResultTitle: PropTypes.string,
  hideResults: PropTypes.bool,
  onKeyDown: PropTypes.func,
  handleDetailChange: PropTypes.func,
  discountAmount: PropTypes.number,
  total: PropTypes.number,
  coupon: PropTypes.object,
  onReceiveCoupon: PropTypes.func,
  onDeleteCoupon: PropTypes.func,
  textmessage: PropTypes.string,
};

const TransactionDetails = ({
  transaction,
  transactionItems,
  addRowOnClick,
  deleteRowOnClick,
  handleChange,
  onSelectItem,
  searchResultTitle,
  hideResults,
  onKeyDown,
  handleDetailChange,
  // discountAmount,
  coupon,
  onReceiveCoupon,
  onDeleteCoupon,
  textmessage,
  isSaving,
  saveOrder,
  transactionId,
  isNewOrder,
  sendMessage,
  setTextMessage,
  newTextMessage,
  isSendingMessage,
}) => {
  const subtotal = getVatExSubtotal(transactionItems, transaction.couponCode);
  const totalVat = getTotalVat(transactionItems, transaction.couponCode);
  const [discountAmount, setDiscountAmount] = useState(0.0);
  const [total, setTotal] = useState(0.0);

  /* eslint-disable */
  useEffect(() => {
    setDiscountAmount(
      getDiscountAmount(coupon, transactionItems, transaction.convenienceFee)
    );
  });

  useEffect(() => {
    setTotal(
      getTotal(
        transactionItems,
        parseFloat(transaction.convenienceFee),
        transaction.couponCode,
        parseFloat(discountAmount)
      )
    );
  });

  return (
    <Fragment>
      <PanelContent
        header={
          <MainPanelHeader
            saveOrder={saveOrder}
            isSaving={isSaving}
            transactionItems={transactionItems}
            transactionId={transactionId}
            isNewOrder={isNewOrder}
          />
        }
      >
        <Table name="itemTable">
          <Row isTableHeader name="table-header">
            <Cell priority>Medicine</Cell>
            <Cell>Quantity</Cell>
            <Cell>Unit Price</Cell>
            <Cell>Total</Cell>
            <Cell />
          </Row>
          {transactionItems.map((item, index) => {
            return (
              <TransactionDetailsRow
                key={index}
                index={index}
                item={item}
                productTitle={item.productTitle}
                quantity={item.quantity}
                vat={item.vat}
                vatexUnitPrice={item.vatexUnitPrice}
                deleteRowOnClick={deleteRowOnClick}
                handleChange={handleChange}
                handleDetailChange={handleDetailChange}
                handleTransactionItemChange={handleChange}
                onSelectItem={onSelectItem}
                searchResultTitle={
                  searchResultTitle !== item.productTitle
                    ? item.productTitle
                    : searchResultTitle
                }
                hideResults={hideResults}
                onKeyDown={onKeyDown}
                couponCode={transaction.couponCode}
              />
            );
          })}
        </Table>
        <table className="transaction-details table is-fullwidth">
          <tbody>
            <tr className="add-meds">
              <td colSpan="4">
                <div className="add-row" onClick={addRowOnClick}>
                  + Add product
                </div>
              </td>
            </tr>
          </tbody>

          <tfoot className="align-right transaction-total">
            <tr>
              <td
                className="transaction-total__right top"
                valign="top"
                colSpan="2"
                rowSpan={discountAmount > 0 ? "8" : "7"}
              >
                <div className="box text-field">
                  <div className="level">
                    {/* <div className='column is-3'>
                    <strong>Text message preview</strong>
                  </div> */}
                    <TextMessageDropdown
                      setTextMessage={setTextMessage}
                      textMessage={newTextMessage}
                    />
                    <button
                      className="button is-primary"
                      onClick={sendMessage}
                      disabled={isSendingMessage}
                    >
                      {isSendingMessage ? "Sending..." : "Send message"}
                    </button>
                  </div>
                  <textarea
                    className="textarea"
                    value={newTextMessage}
                    rows="7"
                    readOnly
                  />
                </div>
              </td>
              <td className="transaction-total__right top">
                Subtotal (VAT-Ex)
              </td>
              <td className="transaction-total__right top">
                {formatNumber(subtotal)}
              </td>
            </tr>
            <tr>
              <td className="transaction-total__right">VAT</td>
              <td className="transaction-total__right">
                {formatNumber(totalVat)}
              </td>
            </tr>
            <tr>
              <td className="transaction-total__right">Convenience Fee</td>
              <td className="transaction-total__right">
                <div className="select">
                  <select
                    className="select"
                    name="convenienceFee"
                    value={transaction.convenienceFee}
                    onChange={handleDetailChange}
                  >
                    <option value="45.00">45.00</option>
                    <option value="50.00">50.00</option>
                    <option value="95.00">95.00</option>
                    <option value="100.00">100.00</option>
                    <option value="180.00">180.00</option>
                    <option value="200.00">200.00</option>
                    <option value="300.00">300.00</option>
                    <option value="325.00">325.00</option>
                    <option value="500.00">500.00</option>
                    <option value="0.00">0.00</option>
                  </select>
                </div>
              </td>
            </tr>

            <tr>
              <td className="transaction-total__right">
                Coupon{" "}
                <span
                  className="tooltip is-tooltip-multiline has-text-centered"
                  data-tooltip='Coupon codes will be applied through text confirmation. For senior citizens or PWD, please input "SCPWD" as your coupon code.'
                >
                  <FontAwesomeIcon icon={faInfoCircle} />
                </span>
              </td>
              <td className="transaction-total__right">
                <CouponInput
                  coupon={coupon}
                  onReceiveCoupon={onReceiveCoupon}
                  onDeleteCoupon={onDeleteCoupon}
                />
              </td>
            </tr>

            {discountAmount > 0 && (
              <tr>
                <td className="transaction-total__right">Discount</td>
                <td className="transaction-total__right has-text-danger">
                  - {formatNumber(discountAmount)}
                </td>
              </tr>
            )}

            <tr>
              <td colSpan="2" className="transaction-total__right">
                <hr />
              </td>
            </tr>
            <tr>
              <td className="transaction-total__right">
                <span className="is-size-4 has-text-weight-bold">Total</span>
              </td>
              <td className="transaction-total__right">
                <span className="is-size-4 has-text-weight-bold">
                  {formatNumber(total)}
                </span>
              </td>
            </tr>
          </tfoot>
        </table>
      </PanelContent>
    </Fragment>
  );
};

TransactionDetails.propTypes = propTypes;

export default TransactionDetails;
