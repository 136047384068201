import React, { useContext, useState } from "react";
import { Formik, Form } from "formik";
import classNames from "classnames";
import * as Yup from "yup";

import { AuthContext } from "../authContext";
import AuthCard from "./AuthCard";
import Field from "UIKit/components/Forms/Field";

const ValidationSchema = Yup.object().shape({
  username: Yup.string()
    .email("Please enter valid email address")
    .required("Please enter email address."),
  password: Yup.string().required("Please enter password.")
});

const LoginForm = () => {
  const context = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const { authenticateUser } = context.authHelpers;
  const { error } = context.authState;
  const { pageActions, switchAuthForm } = context;

  const classes = "button is-primary is-fullwidth mt-4 mb-3 is-size-5";

  const switchForm = () => switchAuthForm(pageActions.TO_VERIFY_EMAIL);

  const authSuccess = async isUserAuthorized => {
    await setIsLoading(false);
    if (!isUserAuthorized) return switchAuthForm(pageActions.TO_UNAUTHORIZED);
    else if (isUserAuthorized.challengeName === "NEW_PASSWORD_REQUIRED")
      return switchAuthForm(pageActions.TO_NEW_PASSWORD);
    switchAuthForm(pageActions.TO_ADMIN);
  };

  const authFailed = () => setIsLoading(false);

  const initiateAuth = values => {
    setIsLoading(true);
    authenticateUser(values, authSuccess, authFailed);
  };

  return (
    <AuthCard errorMessage={error}>
      <Formik
        initialValues={{ username: "", password: "" }}
        validationSchema={ValidationSchema}
        onSubmit={initiateAuth}
      >
        <Form>
          <Field type="email" name="username" label="Email" required />
          <Field name="password" type="password" label="Password" required />
          <p className="has-text-centered is-size-7">
            Forgot your password? <a onClick={switchForm}>Reset password</a>
          </p>
          <button
            type="submit"
            className={classNames(classes, { "is-loading": isLoading })}
            disabled={isLoading}
          >
            Log in
          </button>
        </Form>
      </Formik>
    </AuthCard>
  );
};

export default LoginForm;
