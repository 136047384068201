import React from "react";
import "./SplitContainer.scss";

// THE PANEL
export const SideBarContainer = props => {
  return (
    <div className="sidebar-container" {...props}>
      <div className="sidebar-content">{props.children}</div>
    </div>
  );
};

export const MainContent = props => {
  return (
    <div className="main-content container">
      <div className="inner-content">{props.children}</div>
    </div>
  );
};

export const PanelContent = props => {
  return (
    <div className="panel panel-content">
      {props.header && (
        <div className="panel-heading panel-header">
          {props.header || props}
        </div>
      )}
      {props.headerComponent && (
        <div className="panel-heading panel-header">
          <props.headerComponent />
        </div>
      )}
      <div className="panel-body">
        {(props.render && props.render()) || props.children}
      </div>
    </div>
  );
};

const SplitContainer = props => {
  return <div className="content-container">{props.children}</div>;
};

export default SplitContainer;
