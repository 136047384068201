import React, { useState, useEffect } from "react";
import classNames from "classnames";
import styles from "./pagination.module.scss";

const PageInput = ({ currentPage, callback }) => {
  const [inputValue, setInputValue] = useState(currentPage);

  useEffect(() => {
    setInputValue(currentPage);
  }, [currentPage]);

  const onKeyEnter = event => {
    if (!inputValue) setInputValue(1);
    if (event.key === "Enter" && callback) callback(inputValue, true);
  };

  return (
    <p className="control">
      <input
        onChange={({ target }) => setInputValue(parseInt(target.value))}
        onKeyDown={onKeyEnter}
        className={classNames("input has-text-centered", styles["has-width-6"])}
        value={inputValue}
        type="number"
      />
    </p>
  );
};

export default PageInput;
