import React, { Component } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./PurchaseOrder.module.scss";
import POFooter from "./POFooter";
import qrCode from "assets/images/frame.png";
import mgLogo from "assets/images/mg-teal.png";

const renderQr = (
  <img
    className="logo is-pulled-right"
    src={qrCode}
    alt="MedGrocer.com QR code"
    width="100"
  />
);

class POBody extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { name, subName, poCode } = this.props;
    return (
      <div className={styles["po__body"]}>
        <div className={styles["po__header"]}>
          <div className="columns is-vcentered">
            <div className="column">
              <h1 className="is-size-4 has-text-primary has-text-weight-bold">
                <img src={mgLogo} width="250px" alt="mg-logo" />
                <div className="is-size-7 has-text-grey">
                  MG Health Solutions, Inc. <br></br>
                  24F Centuria Medical Makati, Kalayaan cor. Salamanca St.,
                  Makati City
                </div>
              </h1>
            </div>
            <div
              className={classNames(
                "column is-one-quarter",
                styles["po__header-column"]
              )}
            >
              {renderQr}
            </div>
          </div>
          <h1 className="is-size-4  has-text-grey has-text-weight-bold mb-2">
            {this.props.orderHeader}
            <div
              className={styles["po__header-customer"]}
            >{`${name} - ${subName}`}</div>
            <div className="is-size-7 has-text-grey">
              QUOTE CODE: <span className="has-text-weight-bold">{poCode}</span>
            </div>
          </h1>
          <hr className={styles["po-divider"]} />
        </div>
        {this.props.children}
        <footer className={styles["po-divider"]}>
          <POFooter />
        </footer>
      </div>
    );
  }
}

POBody.propTypes = {
  name: PropTypes.string,
  subName: PropTypes.string,
  poCode: PropTypes.string,
  children: PropTypes.array,
  orderHeader: PropTypes.string,
};

export default POBody;
