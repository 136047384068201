import React from "react";
import ReactDOM from "react-dom";
import { store } from "Services/store";
import { Provider } from "react-redux";
import App from "App/App";
import registerServiceWorker from "registerServiceWorker";
import { ApolloProvider } from "@apollo/react-hooks";
import { client } from "./Services/apollo";

import Amplify from "aws-amplify";
import config from "./awsExports";

Amplify.configure(config);

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={store}>
      <App />
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);
registerServiceWorker();
