import React from "react";

const DrugCardBody = ({
  icon,
  molecule,
  form,
  category,
  indication,
  administration
}) => (
  <article className="media drugcard-info">
    <figure className="media-left">
      <div className="image is-64x64">
        <div className="molecule-avatar is-size-3">{icon}</div>
      </div>
    </figure>

    <div className="media-content">
      <p className="molecule-title">{molecule}</p>
      <p className="indication">
        {category} {indication ? <em> • {indication}</em> : ""}
      </p>
      {administration && (
        <div className="content">
          <ul className="drugcard-regimen">
            <li>
              <b>{form}:</b> {administration}
            </li>
          </ul>
        </div>
      )}
    </div>
  </article>
);

export default DrugCardBody;
