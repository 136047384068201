import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { setBatchTransaction } from "BatchTransaction/actions";
import BatchTransactionForm from "BatchTransaction/components/BatchTransactionForm";
import BatchTransactionErrorView from "BatchTransaction/components/BatchTransactionErrorView";
import Footer from "UIKit/components/Footer";
import PageContent from "UIKit/components/PageContent";
import { pushNotification } from "UIKit/actions";
import api from "Services/api";

const propTypes = {
  batchTransaction: PropTypes.array.isRequired,
  products: PropTypes.object.isRequired,
  pushNotification: PropTypes.func.isRequired,
  priceLists: PropTypes.object.isRequired
};

class BatchTransaction extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1,
      notExistingProducts: [],
      invalidCoupons: [],
      duplicateProducts: [],
      file: null,
      isCheckingFile: false,
      isChecked: false,
      priceList: "retail",
      secondaryPricelist: null,
      channel: "b2c_website",
      coupons: [],
      hasErrors: true,
      isValidCSV: false,
      parsedData: null
    };
  }

  componentDidMount() {
    api
      .get(`coupons/?format=json`)
      .then(response =>
        this.setState({
          coupons: response.data.results
        })
      )
      .catch(error => console.log(error));
  }

  priceListsArray = Object.keys(this.props.priceLists).map(key => {
    return this.props.priceLists[key];
  });
  productsArray = Object.keys(this.props.products).map(key => {
    return this.props.products[key];
  });

  onCheck = event => {
    if (this.state.file) {
      this.setState(previousState => ({ isCheckingFile: true }));
      var formData = new FormData();
      formData.append("file", this.state.file);
      formData.append("pricelist", this.state.priceList);
      formData.append("secondary_pricelist", this.state.secondaryPriceList);
      api
        .post(`/upload/${this.state.file.name}`, formData)
        .then(response => {
          this.setState(
            previousState => ({
              isCheckingFile: false,
              parsedData: response.data.data,
              hasErrors:
                response.data.status === "error" ||
                response.data.status === "invalid"
            }),
            () => {
              const hasError = this.state.hasErrors;
              this.props.pushNotification(
                5000,
                !hasError
                  ? "No errors found in the CSV. "
                  : "Error on validating the uploaded data. Please check if the CSV file you uploaded matches the format of the template.",
                !hasError ? "is-success" : "is-danger"
              );
              if (!this.state.hasErrors) this.handleSetBatchtransaction();
            }
          );
        })
        .catch(error => {
          this.props.pushNotification(3000, "Invalid File.", "is-warning");
          this.setState(previousState => ({ isCheckingFile: false }));
        });
    }
  };

  handleSetBatchtransaction = () =>
    this.props.setBatchTransaction(this.state.parsedData);

  onFileChange = event => {
    const file = event.target.files[0] || null;
    if (file) {
      if (file.name.split(".").pop() === "csv") {
        this.setState({
          file: file,
          isValidCSV: true
        });
      } else {
        event.target.value = "";
        event.target.files = null;
        this.props.pushNotification(
          3000,
          file.name + " is not a valid csv.",
          "is-danger"
        );
        this.setState(previousState => ({
          isValidCSV: false,
          fileName: "",
          file: null
        }));
      }
    }
  };

  handleChange = event => {
    const target = event.target;
    const name = target.name;
    const value = target.value;

    this.setState({
      [name]: value
    });
  };

  goToNextStep = () => {
    this.setState(previousState => {
      return { step: previousState + 1 };
    });
  };

  renderErrors() {
    if (this.state.hasErrors && this.state.parsedData) {
      return <BatchTransactionErrorView errors={this.state.parsedData} />;
    }
  }

  render() {
    const {
      file,
      selectedPriceList,
      channels,
      selectedChannel,
      isChecked
    } = this.state;
    return (
      <Fragment>
        <PageContent hasTitle title="Batch Transaction">
          <BatchTransactionForm
            onFileChange={this.onFileChange}
            fileName={file ? file.name : "No file chosen"}
            priceLists={this.priceListsArray}
            selectedPriceList={selectedPriceList}
            handleChange={this.handleChange}
            channels={channels}
            selectedChannel={selectedChannel}
          />

          {this.renderErrors()}
        </PageContent>
        <Footer>
          <div className="buttons is-centered">
            <button
              className={`button ${isChecked ? "is-outlined" : "is-primary"} ${
                this.state.isCheckingFile ? "is-loading" : ""
              }`}
              onClick={this.onCheck}
              disabled={!this.state.isValidCSV}
            >
              Check file
            </button>

            <Link to="batch-transaction/preview">
              <button
                className="button is-link"
                disabled={this.state.hasErrors}
              >
                Next
              </button>
            </Link>
          </div>
        </Footer>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  batchTransaction: state.batchTransaction,
  products: state.entities.products,
  priceLists: state.entities.priceLists,
  notification: state.notification
});

BatchTransaction.propTypes = propTypes;

export default connect(
  mapStateToProps,
  {
    setBatchTransaction,
    pushNotification
  }
)(BatchTransaction);
