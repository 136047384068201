import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { postTransactions } from "BatchTransaction/actions";
import Table from "UIKit/components/Table/Table";
import Footer from "UIKit/components/Footer";
import PageContent from "UIKit/components/PageContent";
import { pushNotification } from "UIKit/actions";
import SingleTransactionPreview from "BatchTransaction/components/SingleTransactionPreview";
// import { pushNotification } from "UIKit/actions";

const OrderQuantityTotal = ({ total }) => (
  <tr>
    <td colSpan="4" className="has-text-right is-size-4 has-border-top">
      <b>Total No. of Transactions:</b>
    </td>
    <td className="has-text-right is-size-4">
      <b className="has-text-black">{total}</b>
    </td>
  </tr>
);
class BatchTransactionPreview extends Component {
  state = {
    isLoading: false,
    successfulTransactions: [],
  };
  static propTypes = {
    showNotification: PropTypes.func.isRequired,
    postTransactions: PropTypes.func.isRequired,
  };

  addTransaction = (event) => {
    this.setState({ isLoading: true });
    this.props.postTransactions(this.props.batchTransaction).then((data) => {
      this.setState({
        isLoading: false,
      });
      if (data.response && data.response.status === 400) {
        this.props.pushNotification(
          8000,
          "Failed adding transactions. Please contact IT",
          "is-danger"
        );
      } else {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
        this.setState({
          isLoading: false,
          successfulTransactions: data.payload,
        });
        this.props.pushNotification(
          8000,
          "Successfully added transactions.",
          "is-success"
        );
      }
      // this.props.history.push("/orders");
    });
  };

  printSuccessfulOrders = () => {
    let searchString = "";
    this.state.successfulTransactions &&
      this.state.successfulTransactions.forEach((transaction) => {
        searchString += `orders=${transaction.id}&`;
      });
    this.props.history.push(`/orders/print/?${searchString}`);
  };

  PageHeader = () => {
    return (
      <div className="columns">
        <div className="column">Batch Transactions Preview</div>
        <div className="column is-narrow">
          <button
            className="button is-primary"
            disabled={
              this.state.successfulTransactions &&
              !this.state.successfulTransactions.length
            }
            onClick={this.printSuccessfulOrders}
          >
            Print Orders
          </button>
        </div>
      </div>
    );
  };

  returnToOrdersDashboard = () => this.props.history.push("/orders?page=1");

  render() {
    const headerTitles = [
      "Order No.",
      "Customer Name",
      "Email",
      "Shipping Address",
      "Basket Size",
      "Total Value",
    ];

    const { successfulTransactions } = this.state;
    return (
      <Fragment>
        <PageContent hasTitle title={<this.PageHeader />}>
          <Table headerTitles={headerTitles}>
            <tbody>
              <SingleTransactionPreview
                transactions={this.props.batchTransaction}
              />
              <OrderQuantityTotal total={this.props.batchTransaction.length} />
            </tbody>
          </Table>
        </PageContent>
        <Footer>
          <div className="buttons is-centered">
            <Link className="button is-light" to="/batch-transaction">
              Back
            </Link>
            <button
              className={classNames("button is-primary", {
                "is-loading": this.state.isLoading,
              })}
              onClick={
                successfulTransactions && successfulTransactions.length
                  ? this.returnToOrdersDashboard
                  : this.addTransaction
              }
            >
              {successfulTransactions && successfulTransactions.length
                ? "Finish"
                : " Add transactions"}
            </button>
          </div>
        </Footer>
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  batchTransaction: state.batchTransaction,
  notification: state.notification,
});

export default connect(mapStateToProps, { postTransactions, pushNotification })(
  BatchTransactionPreview
);
