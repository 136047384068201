import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { connect } from "react-redux";
import "normalize.css";
import "App/styles/app.scss";

import Navbar from "Navigation/components/Navbar";
import Notification from "UIKit/components/Notification";
import TransactionDashboard from "TransactionDashboard/components/TransactionDashboard";
import BatchTransaction from "BatchTransaction/components/BatchTransaction";
import BatchTransactionPreview from "BatchTransaction/components/BatchTransactionPreview";
// import ProductsDashboard from "Products/components/ProductsDashboard";
import IndividualOrder from "IndividualOrder/components/IndividualOrder";
import Receipt from "../Printables/Receipt/Receipt";
import SalesInvoicePrintView from "Printables/SalesInvoice/components/SalesInvoicePrintView";
import Logout from "Auth/Logout";
import Coupons from "Coupons/Coupons";
import { AddCoupon } from "Coupons/components/AddCoupon/AddCoupon";
import ViewActive from "Coupons/components/ViewActiveCoupons/ViewActive";
import SummaryView from "SummaryView/components/SummaryView";
import ClientList from "../Client/components/ClientList";
import ClientDetail from "../Client/components/ClientDetail/ClientDetail";
import RequestedProductDashBoard from "../RequestedProducts/components/ProductRequestDashboard";
import RequestedProductDetail from "../RequestedProducts/components/RequestedProductDetail";
import PurchaseOrderDashboard from "PurchaseOrder/PurchaseOrderView/PurchaseOrderDashboard";
import QuoteOrderPrint from "Printables/PurchaseOrder/QuoteOrderPrint/QuoteOrderPrint";
import DraftPurchaseOrderDashboard from "PurchaseOrder/DraftPurchaseOrderView/DraftPurchaseOrderDashboard";
import PurchaseOrderDetails from "PurchaseOrder/components/PurchaseOrderDetails";
import { removeAuthToken } from "Auth/actions";
import Authenticator from "Auth/Authenticator";
import { fetchPriceLists } from "Products/actions";
import Products from "Products/components/Products";
import Sourcing from "Sourcing/Sourcing";
import SourcingPickListPrint from "Printables/SourcingPickList/SourcingPickList";
import PickList from "Sourcing/components/PickList/PickList";
import SourcingSheet from "Sourcing/components/SourcingSheet/SourcingSheet";
import PrivateRoute from "./PrivateRoute";

import Home from "Home/Home";

const propTypes = {
  isFetchingPriceLists: PropTypes.bool,
  authToken: PropTypes.string
};

class App extends Component {
  constructor() {
    super();
    this.state = {
      isAuthenticated: "LOADING"
    };
  }
  componentDidMount() {
    this.props.fetchPriceLists();
  }

  render() {
    const isDataLoaded = !this.props.isFetchingPriceLists;
    return (
      isDataLoaded && (
        <BrowserRouter>
          <div>
            <Fragment>
              {/* <Redirect to="/" /> */}

              <Route path="/" component={Navbar} />
              <Route exact path="/" component={Home} />
              <Notification />
              <PrivateRoute
                exact
                path="/orders"
                component={TransactionDashboard}
                authorizedGroups={["cons"]}
                fallbackRoute="/"
              />
              <Route exact path="/dashboard" component={SummaryView} />
              <PrivateRoute
                exact
                path="/batch-transaction"
                component={BatchTransaction}
                authorizedGroups={["cons"]}
                fallbackRoute="/"
              />
              <PrivateRoute
                exact
                path="/clients"
                component={ClientList}
                fallbackRoute="/"
                authorizedGroups={["fin", "entp"]}
              />
              <PrivateRoute
                exact
                path="/client/:id/"
                component={ClientDetail("EDIT_PAGE")}
                fallbackRoute="/"
                authorizedGroups={["fin", "entp"]}
              />
              <PrivateRoute
                exact
                path="/clients/add/"
                component={ClientDetail("ADD_PAGE")}
                fallbackRoute="/"
                authorizedGroups={["fin", "entp"]}
              />
              <PrivateRoute
                path="/batch-transaction/preview"
                component={BatchTransactionPreview}
                fallbackRoute="/"
                authorizedGroups={["cons"]}
              />
              <Route exact path="/products" component={Products} />

              <Route
                exact
                path="/products/requested"
                component={RequestedProductDashBoard}
              />
              <Route
                exact
                path="/products_request/new"
                component={RequestedProductDetail("ADD")}
              />
              <Route
                exact
                path="/products_requests/:id"
                component={RequestedProductDetail("EDIT")}
              />
              <PrivateRoute
                exact
                path="/purchase-orders/:id"
                component={PurchaseOrderDetails}
                baseUrl="/purchase-orders"
                subUrl="purchase_order"
                type="EDIT"
                orderTitle="Purchase Order"
                fallbackRoute="/"
                authorizedGroups={["entp"]}
              />

              <PrivateRoute
                exact
                path="/quotes/"
                component={DraftPurchaseOrderDashboard}
                fallbackRoute="/"
                authorizedGroups={["entp"]}
              />
              <PrivateRoute
                exact
                path="/purchase-orders/"
                component={PurchaseOrderDashboard}
                fallbackRoute="/"
                authorizedGroups={["entp"]}
              />
              <PrivateRoute
                exact
                path="/quotes/:id/print"
                component={QuoteOrderPrint}
                fallbackRoute="/"
                authorizedGroups={["entp"]}
              />
              {/* <Route exact path="/products/:id" component={ProductDetail} /> */}
              <Switch>
                <PrivateRoute
                  exact
                  path="/orders/print/"
                  component={Receipt}
                  fallbackRoute="/"
                  authorizedGroups={["cons"]}
                />
                <PrivateRoute
                  exact
                  path="/orders/print_si"
                  component={SalesInvoicePrintView}
                  fallbackRoute="/"
                  authorizedGroups={["cons"]}
                />
                <Route exact path="/orders/new" component={IndividualOrder} />
                <Route exact path="/orders/:id/" component={IndividualOrder} />
                <PrivateRoute
                  exact
                  path="/quotes/edit/:id"
                  component={PurchaseOrderDetails}
                  baseUrl="/draft-purchase-orders"
                  subUrl="draft_purchase_order"
                  type="EDIT"
                  fallbackRoute="/"
                  authorizedGroups={["entp"]}
                />
                <PrivateRoute
                  exact
                  path="/quotes/add"
                  component={PurchaseOrderDetails}
                  baseUrl="/draft-purchase-orders"
                  subUrl="draft_purchase_order"
                  fallbackRoute="/"
                  authorizedGroups={["entp"]}
                />
                <PrivateRoute
                  exact
                  path="/orders/:id/print"
                  component={Receipt}
                  fallbackRoute="/"
                  authorizedGroups={["cons"]}
                />
                <PrivateRoute
                  exact
                  path="/orders/:id/print_si"
                  component={SalesInvoicePrintView}
                  fallbackRoute="/"
                  authorizedGroups={["cons"]}
                />
              </Switch>

              <Route exact path="/coupons" component={Coupons} />
              <Route exact path="/coupons/add" component={AddCoupon} />
              <Route exact path="/coupons/view/active" component={ViewActive} />

              {/* Sourcing */}
              <Route exact path="/sourcing" component={Sourcing} />
              <Route
                exact
                path="/sourcing/:id/pick-list"
                component={PickList}
              />
              <Route
                exact
                path="/sourcing/:id:/pick-list/print"
                component={SourcingPickListPrint}
              />
              <Route
                exact
                path="/sourcing/:id/sheet"
                component={SourcingSheet}
              />

              <Route
                exact
                path="/logout"
                component={() => (
                  <Logout handleLogout={this.props.handleLogout} />
                )}
              />
            </Fragment>
          </div>
        </BrowserRouter>
      )
    );
  }
}

const mapStateToProps = state => ({
  authToken: state.authToken,
  showNotification: state.showNotification,
  notificationDetails: state.notificationDetails,
  isFetchingProductRequests: state.isFetchingProductRequests
});

App.propTypes = propTypes;

export default Authenticator(
  connect(mapStateToProps, { removeAuthToken, fetchPriceLists })(App)
);
