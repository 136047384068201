import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import classNames from "classnames";
import { Auth } from "aws-amplify";

import styles from "../utils/navigation.module.scss";

const NavbarLink = ({ link, label, target, beta, authorizedGroups }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);

  /* eslint-disable */
  useEffect(() => {
    validateAuthorization();
  }, []);

  const validateAuthorization = async () => {
    try {
      if (!authorizedGroups) return setIsAuthorized(true);
      const userDetail = await Auth.currentSession();
      const userGroups = await userDetail.getAccessToken().payload[
        "cognito:groups"
      ];
      authorizedGroups.forEach(group => {
        if (userGroups.includes(group) || userGroups.includes("admin"))
          setIsAuthorized(true);
      });
    } catch (error) {
      setIsAuthorized(false);
    }
  };

  if (!isAuthorized) return null;

  return (
    <NavLink
      className={classNames(
        "navbar-item has-text-white has-text-centered",
        styles["navbar-item"]
      )}
      activeClassName={styles["navbar-item__is-active"]}
      to={link}
      target={target}
    >
      {label}
      {beta && <sup>BETA</sup>}
    </NavLink>
  );
};

export default NavbarLink;
