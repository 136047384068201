import React, { Fragment } from "react";

const scores = ["Very Unlikely", 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, "Very Likely"];

const CustomerSign = ({ children, hasBorderTop }) => (
  <tr>
    <td
      colSpan="2"
      className={
        hasBorderTop ? "has-text-centered has-border-top" : "has-text-centered"
      }
    >
      {children}
    </td>
  </tr>
);

const DRCustomerFeedback = () => (
  <table className="table is-fullwidth dr-feedback">
    <tbody>
      <CustomerSign>
        <p className="has-text-centered">
          Thank you for letting MedGrocer serve you. Let us know how we can do
          better. <br /> On a scale of 1 to 10, how likely are you to recommend
          us to others?
        </p>
        <br />
      </CustomerSign>
      <NPSScale />
    </tbody>
  </table>
);

export const NPSScale = () => (
  <Fragment>
    <div className="pagination is-centered">
      <ul className="pagination-list" id="feedback">
        {scores.map((score, index) => (
          <li className="pagination-link">{score}</li>
        ))}
      </ul>
    </div>
  </Fragment>
);

export default DRCustomerFeedback;
