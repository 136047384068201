import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import Loading from "UIKit/components/Loading";

import styles from "./dashboard.module.scss";

const propTypes = {
  handleOnCheck: PropTypes.func,
  isCheckboxEnabled: PropTypes.bool,
  headers: PropTypes.array,
  children: PropTypes.node,
  isLoading: PropTypes.bool
};

export const CheckBox = ({ handleOnCheck, isHeader = true }) => {
  const ParentComponent = ({ children, className }) => {
    if (!isHeader) return <td className={styles["checkbox"]}>{children}</td>;
    return <th className={styles["checkbox"]}>{children}</th>;
  };

  return (
    <ParentComponent className={styles["checkbox"]}>
      <label className="checkbox-field pt-1">
        <input type="checkbox" onChange={handleOnCheck} />
        <span className="checkmark" />
      </label>
    </ParentComponent>
  );
};

const Dashboard = ({
  handleOnCheck,
  isCheckboxEnabled,
  headers,
  children,
  isLoading
}) => {
  if (isLoading) return <Loading />;
  return (
    <table
      className={classNames(
        "table is-hoverable is-fullwidth",
        styles.dashboard
      )}
    >
      <thead>
        <tr>
          {isCheckboxEnabled && <CheckBox handleOnCheck={handleOnCheck} />}
          {!!headers &&
            headers.map(header => (
              <th
                className="has-text-centered table-header"
                key={header.value}
                id={header.value}
              >
                {header.label}
              </th>
            ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
};

Dashboard.propTypes = propTypes;

export default Dashboard;
