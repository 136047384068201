import { useReducer } from "react";
import { FORM_STATE } from "./authStates";

const SWITCH_FORM_ACTIONS = {
  TO_LOGIN: "SWITCH_TO_LOGIN_PAGE",
  TO_VERIFY_EMAIL: "SWITCH_TO_VERIFY_EMAIL_PAGE",
  TO_FORGOT_PASSWORD: "SWITCH_TO_FORGOT_PASSWORD_PAGE",
  TO_NEW_PASSWORD: "SWITCH_TO_NEW_PASSWORD_PAGE",
  TO_ADMIN: "SWITCH_TO_ADMIN_PAGE",
  TO_UNAUTHORIZED: "SWITCH_TO_UNAUTHORIZED_PAGE",
  TO_NEW_PHONE_NUMBER: "SWITCH_TO_NEW_PHONE_NUMBER_PAGE"
};

const formReducer = (state, action) => {
  switch (action.type) {
    case SWITCH_FORM_ACTIONS.TO_LOGIN:
      return { pageType: FORM_STATE.LOGIN_PAGE };
    case SWITCH_FORM_ACTIONS.TO_VERIFY_EMAIL:
      return { pageType: FORM_STATE.VERIFY_EMAIL_PAGE };
    case SWITCH_FORM_ACTIONS.TO_FORGOT_PASSWORD:
      return { pageType: FORM_STATE.FORGOT_PASSWORD_PAGE };
    case SWITCH_FORM_ACTIONS.TO_NEW_PASSWORD:
      return { pageType: FORM_STATE.NEW_PASSWORD_PAGE };
    case SWITCH_FORM_ACTIONS.TO_ADMIN:
      return { pageType: FORM_STATE.AUTHORIZED_PAGE };
    case SWITCH_FORM_ACTIONS.TO_UNAUTHORIZED:
      return { pageType: FORM_STATE.UNAUTHORIZED_PAGE };
    case SWITCH_FORM_ACTIONS.TO_NEW_PHONE_NUMBER:
      return { pageType: FORM_STATE.NEW_PHONE_NUMBER_PAGE };
    default:
      return { ...state };
  }
};

const useFormReducer = () => {
  const [state, dispatch] = useReducer(formReducer, {
    pageType: FORM_STATE.LOGIN_PAGE
  });

  const switchAuthForm = actionType => {
    dispatch({ type: actionType });
  };

  return [state, switchAuthForm, SWITCH_FORM_ACTIONS];
};

export default useFormReducer;
