import React, { Component, Fragment } from "react";
import Dashboard from "../../Dashboard/components/Dashboard";
import api from "Services/api";
import axios from "axios";
import { Link } from "react-router-dom";
import SearchField from "UIKit/components/SearchInput/SearchBar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { parse } from "query-string";
import { stringify } from "querystring";
import { headers } from "./helpers";
import { without } from "lodash";

const formattedDateTime = dateTime => {
  const dateFormat = {
    month: "short",
    day: "numeric"
  };

  const timeFormat = {
    hour: "numeric",
    minute: "numeric"
  };
  return (
    <Fragment>
      {new Date(dateTime).toLocaleString("en-US", dateFormat)} <br />
      <span className="help">
        {new Date(dateTime).toLocaleString("en-US", timeFormat)}
      </span>
    </Fragment>
  );
};

const DeleteButton = ({ handleDeleteAction }) => (
  <div>
    <button className="button is-danger" onClick={handleDeleteAction}>
      Delete Products
    </button>
  </div>
);

const RequestedProductListRow = ({ data }) => {
  return (
    <Fragment>
      <td className="has-text-weight-bold">
        <Link to={`/products_requests/${data.id}`}>{data.title}</Link>
      </td>
      <td className="has-text-centered">
        {data.acquisitionRetailVatincUnitPrice || "--.--"}
      </td>
      <td className="has-text-centered">
        {data.acquisitionWholesaleVatincUnitPrice || "--.--"}
      </td>
      <td className="has-text-centered has-text-weight-bold">
        {data.productType}
      </td>
      <td className="has-text-centered">{formattedDateTime(data.created)}</td>
    </Fragment>
  );
};
class RequestedProductDashBoard extends Component {
  constructor(props) {
    super(props);
    this.productRequestDashboard = React.createRef();
    this.state = {
      selectedProductRequest: [],
      searchQuery: ""
    };
  }

  onSelectProductRequest = productRequest => {
    this.setState(previousState => {
      return {
        selectedProductRequest: [
          ...previousState.selectedProductRequest,
          ...productRequest
        ]
      };
    });
  };

  onDeselectProductRequest = productRequest => {
    this.setState(previousState => {
      return {
        selectedProductRequest: without(
          previousState.selectedProductRequest,
          ...productRequest
        )
      };
    });
  };

  handleChangeSearchResults = () => event => {
    this.props.history.push(
      `/products/requested/?search=${event.target.value}`
    );
    this.setState({
      searchQuery: event.target.value
    });
  };

  getDashboardFilters = () => {
    const urlParameters = parse(this.props.history.location.search, {
      decode: false
    });
    return Object.keys(urlParameters).reduce((accumulator, key) => {
      var newFilters;
      if (Array.isArray(urlParameters[key]))
        newFilters = urlParameters[key].map(value => ({
          field: key,
          value: value
        }));
      else newFilters = [{ field: key, value: urlParameters[key] }];
      return [...accumulator, ...newFilters];
    }, []);
  };

  onChangePage = currentPage => {
    const urlParameters = parse(this.props.history.location.search);
    urlParameters.page = currentPage;
    this.props.history.push(`?${stringify(urlParameters)}`);
  };

  handleSelectBatchDelete = event => {
    const { pushNotification } = this.props;
    let deleteProductRequestCalls = [];
    this.state.selectedProductRequest.forEach(productRequest => {
      deleteProductRequestCalls.push(
        api.delete(`product_requests/${productRequest}/`)
      );
    });
    axios
      .all(deleteProductRequestCalls)
      .then((...args) => {
        this.productRequestDashboard.current.fetchData();
        this.setState({ selectedProductRequest: [] });
        this.props.history.push("/products/requested");
        pushNotification(4000, `Product Requests deleted.`, "is-info");
      })
      .catch(error => console.log("error is: ", error));
  };

  render() {
    const filters = this.getDashboardFilters();
    const pageFilter = filters.find(filter => {
      return filter.field === "page";
    });
    const page = pageFilter ? pageFilter.value : 1;
    return (
      <div className="container">
        <div className="level">
          <div className="level-left">
            <Link to="/products_request/new" className="button is-primary ">
              Request Product
            </Link>
            &nbsp;
            {this.state.selectedProductRequest.length > 0 && (
              <DeleteButton handleDeleteAction={this.handleSelectBatchDelete} />
            )}
          </div>
          <div className="columns level-right">
            <div className="column">
              <div className="control select ">
                <select>
                  <option value="sample is-primary is-medium" selected>
                    All
                  </option>
                  <option value="sample is-primary is-medium">
                    Sample Value
                  </option>
                </select>
              </div>
            </div>
            &nbsp;
            <div className="control is-expanded has-icons-left search-field">
              <SearchField
                disableOption
                className="input"
                placeholder="Search"
                onKeyEnter={this.handleChangeSearchResults}
              />
              <span className="icon is-small is-left">
                <FontAwesomeIcon icon={faSearch} />
              </span>
            </div>
          </div>
        </div>
        <Dashboard
          baseUrl="product_requests/?"
          onSelectRows={this.onSelectProductRequest}
          onDeselectRows={this.onDeselectProductRequest}
          selectedData={this.state.selectedProductRequest}
          headers={headers}
          RowComponent={RequestedProductListRow}
          idAttribute="id"
          filters={filters}
          ref={this.productRequestDashboard}
          isCheckboxEnabled={true}
          dashboardClassname="is-product-request-dashboard"
          searchQuery={this.state.searchQuery}
          onChangePage={this.onChangePage}
          currentPage={page}
        />
      </div>
    );
  }
}

export default RequestedProductDashBoard;
