import actionTypes from "Search/actionTypes";

const DEFAULT_STATE = {
  setSearchQuery: {}
};

export const setSearchQueryReducer = (
  state = DEFAULT_STATE.setSearchQuery,
  action
) => {
  switch (action.type) {
    case actionTypes.SET_SEARCH_QUERY:
      return action.payload;
    default:
      return state;
  }
};
