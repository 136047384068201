import * as yup from "yup";

export const clientFormValidationSchema = yup.object().shape({
  name: yup.string().required("This field is required"),
  accountManager: yup.string().required("Please select an account manager"),
  companyLetters: yup.string().required("This field is required")
});

export const blankSiteFieldObject = {
  client_id: -1,
  name: "",
  contactPerson: "",
  emailAddress: "",
  contactNumber: "",
  taxRate: "",
  defaultBillingStreetBarangay: "",
  defaultBillingCityMunicipality: "",
  defaultBillingProvince: "",
  defaultBillingShippingBarangay: "",
  defaultShippingCityMunicipality: "",
  defaultShippingProvince: "",
  notes: "",
  vatInclusive: false,
  shouldDeliverWithBilling: false,
  hasCertificateOfRegistration: false,
  newSite: false,
  isPeza: false
};

export const siteValidationSchema = yup.object().shape({
  name: yup.string().required("This field is required."),
  siteNumber: yup.string().required("Site # must not be blank."),
  siteLetters: yup.string().required("This field is required."),
  taxRate: yup.string().required("TIhis field is required."),
  siteCode: yup.string().matches(/[A-Z]{2}\d+/, {
    excludeEmptyString: true,
    message: "Site code must be a combination of 2 letters and a number."
  })
});
