import React from "react";

const TableHeader = ({ headerTitles, customStyle }) => (
  <thead>
    <tr>
      {headerTitles.map((header, index) => (
        <th
          key={index}
          className={!customStyle ? "has-text-centered" : customStyle}
        >
          {header}
        </th>
      ))}
    </tr>
  </thead>
);

export default TableHeader;
