import React from "react";

const DrugCardFooter = ({ orderID, zendeskID, refillCode }) => (
  <div className="drugcard-footer">
    <p className="h2">Order your medicines at MedGrocer.com</p>
    <p>
      <small>
        This information is intended to supplement, not substitute, the
        expertise of your healthcare professional.
      </small>
    </p>
    <p className="has-text-grey-light has-text-weight-bold is-size-7">
      ORDER ID {orderID}
      {zendeskID ? ` (TICKET # ${zendeskID})` : ""}
    </p>
  </div>
);

export default DrugCardFooter;
