import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";

import DataTable from "Dashboard/components/DataTable/DataTable";
import Pagination from "UIKit/components/Pagination/Pagination";
import { isEqual } from "lodash";
import "Dashboard/dashboard.scss";

const propTypes = {
  baseUrl: PropTypes.string.isRequired,
  onSelectRows: PropTypes.func.isRequired,
  onDeselectRows: PropTypes.func.isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      attributeName: PropTypes.string.isRequired,
      canSortBy: PropTypes.bool.isRequired
    })
  ).isRequired,
  RowComponent: PropTypes.func.isRequired,
  selectedData: PropTypes.arrayOf(PropTypes.string).isRequired,
  idAttribute: PropTypes.string.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      field: PropTypes.string.isRequired,
      value: PropTypes.node
    })
  ).isRequired,
  isCheckboxEnabled: PropTypes.bool.isRequired,
  dashboardClassname: PropTypes.string,
  searchQuery: PropTypes.string
};

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortBy: "",
      isSortAscending: true,
      isFetchingData: true,
      visibleData: [],
      count: 0,
      hasError: false,
      currentPage: 1
    };
  }
  componentDidUpdate(previousProps) {
    if (
      this.props.searchQuery !== previousProps.searchQuery ||
      !isEqual(this.props.filters, previousProps.filters)
    )
      this.setState({ currentPage: 1, isFetchingData: true });
  }

  getEndpoint = () => {
    const { sortBy, isSortAscending } = this.state;
    const { searchQuery, filters, baseUrl } = this.props;
    var url = baseUrl;
    if (filters)
      filters.forEach((filter, index) => {
        if (index !== 0) url += "&";
        url += `${filter.field}=${filter.value}`;
      });
    if (searchQuery) {
      url += `&search=${searchQuery}`;
    }
    if (sortBy) url += `&sort_by=${isSortAscending ? "" : "-"}${sortBy}`;
    return url;
  };

  onChangePage = currentPage => {
    this.props.onDeselectRows(this.props.selectedData);
    if (this.props.onChangePage) this.props.onChangePage(currentPage);
  };

  onSort = attribute => {
    this.setState(previousState => {
      if (previousState.sortBy === attribute) {
        return { isSortAscending: !previousState.isSortAscending };
      } else return { sortBy: attribute, isSortAscending: true };
    });
  };

  renderDataTable = () => {
    return (
      <Fragment>
        <DataTable
          callbackRowFunction={this.props.callbackRowFunction}
          idAttribute={this.props.idAttribute}
          visibleData={this.state.visibleData}
          selectRows={this.props.onSelectRows}
          deselectRows={this.props.onDeselectRows}
          selectedData={this.props.selectedData}
          onSort={this.onSort}
          sortBy={this.state.sortBy}
          isSortAscending={this.state.isSortAscending}
          headers={this.props.headers}
          RowComponent={this.props.RowComponent}
          isCheckboxEnabled={this.props.isCheckboxEnabled}
          dashboardClassname={this.props.dashboardClassname}
          isFetchingData={this.state.isFetchingData}
          hasError={this.state.hasError}
        />
      </Fragment>
    );
  };

  renderData = (response, currentPage) => {
    const { results, count } = response ? response.data : {};
    this.setState(() => ({
      visibleData: results,
      isFetchingData: !results,
      count: Math.ceil(count / 30),
      hasError: false
    }));
    this.onChangePage(currentPage);
  };

  renderErrorCallback = () => {
    this.setState({ hasError: true, isFetchingData: false });
  };

  render() {
    const { count } = this.state;
    const { currentPage } = this.props;

    return (
      <Fragment>
        {this.renderDataTable()}
        <Pagination
          onChangePage={this.onChangePage}
          currentPage={currentPage}
          url={this.getEndpoint()}
          callback={this.renderData}
          errorCallback={this.renderErrorCallback}
        />
      </Fragment>
    );
  }
}

Dashboard.propTypes = propTypes;

export default Dashboard;
