import React from "react";
import { useSubscription } from "@apollo/react-hooks";
import { gql } from "apollo-boost";
import Loading from "UIKit/components/Loading";

const GET_PRODUCTS = gql`
  subscription {
    products_product {
      item_code
      title
    }
  }
`;

const Products = () => {
  const { loading, error, data } = useSubscription(GET_PRODUCTS);
  const products = data && data.products_product;

  if (loading) return <Loading />;
  if (error)
    return (
      <section className="container">
        <div className="notification is-danger">Error: {error.message}</div>
      </section>
    );

  return (
    <section className="container content">
      Products:
      <table>
        <thead>
          <th>Item Code</th>
          <th>Title</th>
        </thead>
        <tbody>
          {products.map(product => (
            <tr key={product.item_code}>
              <td>{product.item_code}</td>
              <td>{product.title}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
};

export default Products;
