import React from "react";
import PropTypes from "prop-types";
import "Printables/printables.scss";
import { getTotalVat, getVatExSubtotal } from "Services/cart";
import { formatNumber, roundFloatToTwo } from "Services/util";

const propTypes = {
  transaction: PropTypes.object.isRequired,
  chargedTo: PropTypes.string.isRequired,
  tin: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  customerName: PropTypes.string.isRequired,
};

const SalesInvoice = ({
  transaction,
  chargedTo,
  tin,
  address,
  customerName,
}) => {
  const {
    details,
    // couponCode,
    // discountAmount,
    // convenienceFee,
    id,
  } = transaction;
  var totalSales = getVatExSubtotal(details) + getTotalVat(details, null);
  const currentDate = new Date().toLocaleDateString("en-US", {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
  const totalVat = getTotalVat(details, null);
  const netOfVat = getVatExSubtotal(details);

  // const amountDue =
  //   netOfVat +
  //   totalVat +
  //   parseFloat(convenienceFee) -
  //   parseFloat(discountAmount);
  return (
    <div className="sales-invoice">
      <div className="sales-invoice__header">
        <p className="has-text-right date">{currentDate}</p>
        <p className="has-text-right comp-ref">{id}</p>
        <p className="charged-to">{customerName}</p>
        <p className="tin">{tin}</p>
        <p className="address">{address}</p>
      </div>

      <table className="sales-invoice__order-details">
        <thead>
          <tr>
            <th className="number-column">No.</th>
            <th className="description">Item Description</th>
            <th className="has-text-right qty">Qty</th>
            <th className="has-text-right unit-price">Unit Price</th>
            <th className="has-text-right vat-ex">
              VAT-Ex
              <br />
              Total
            </th>
            <th className="has-text-right amount vat-inc">
              VAT
              <br />
              Total
            </th>
            <th className="has-text-right amount vat-inc">Total</th>
          </tr>
        </thead>
        <tbody>
          {details.map((detail, index) => {
            const { quantity, productTitle, vatexUnitPrice, vat } = detail;
            // const vatIncUnitPrice = roundFloatToTwo(
            //   parseFloat(vatexUnitPrice) + parseFloat(vat)
            // );
            // const vatExUnitTotal = quantity * vatexUnitPrice;
            // const vatUnitTotal = quantity * parseFloat(vat);
            // const total = roundFloatToTwo(vatIncUnitPrice * quantity);
            const vatable = parseFloat(vat) !== 0.0;
            const vatIncUnitPrice = vatable
              ? roundFloatToTwo(parseFloat(vatexUnitPrice) * 1.12)
              : parseFloat(vatexUnitPrice);
            const vatIncTotal = roundFloatToTwo(vatIncUnitPrice * quantity);
            const vatExTotal = vatable
              ? roundFloatToTwo(parseFloat(vatIncTotal) / 1.12)
              : roundFloatToTwo(parseFloat(vatexUnitPrice) * quantity);
            const vatTotal = vatable
              ? roundFloatToTwo(vatIncTotal - vatExTotal)
              : 0;

            return (
              <tr key={index}>
                <td className="number-column">{index + 1}</td>
                <td className="description">{productTitle}</td>
                <td className="has-text-right qty">{quantity}</td>
                <td className="has-text-right unit-price">
                  {formatNumber(vatIncUnitPrice)}
                </td>
                <td className="has-text-right amount vat-ex">
                  {formatNumber(vatExTotal)}
                </td>
                <td className="has-text-right amount vat-ex">
                  {formatNumber(vatTotal)}
                </td>
                <td className="has-text-right amount vat-inc">
                  {formatNumber(vatIncTotal)}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className="sales-invoice__totals">
        <table>
          <tbody>
            <tr>
              <td width="100px">Total</td>
              <td className="has-text-right">{formatNumber(totalSales)}</td>
            </tr>

            <tr>
              <td>Less: VAT</td>
              <td className="has-text-right">{formatNumber(totalVat)}</td>
            </tr>
            <tr>
              <td>Net of VAT</td>
              <td className="has-text-right">{formatNumber(netOfVat)}</td>
            </tr>
            <tr>
              <td>Add: VAT</td>
              <td className="has-text-right">{formatNumber(totalVat)}</td>
            </tr>
            {/* <tr>
              <td>
                Add: <small>Convenience Fee</small>
              </td>
              <td className="has-text-right">{formatNumber(convenienceFee)}</td>
            </tr> */}
            <tr>
              <td>Amount Due</td>
              <td className="has-text-right">
                <strong>{formatNumber(totalSales)}</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

SalesInvoice.propTypes = propTypes;

export default SalesInvoice;
