import React, { Fragment } from "react";

const SingleTransactionPreview = ({ transactions }) => {
  return transactions ? (
    <Fragment>
      {transactions.map((transaction, index) => (
        <tr key={index}>
          <td>{transaction.orderId}</td>
          <td>
            {transaction.customerFirstName + " " + transaction.customerLastName}
          </td>
          <td>{transaction.customerEmail}</td>
          <td>
            {transaction.shippingStreetAddress +
              " " +
              transaction.shippingBarangay +
              " " +
              transaction.shippingCityMunicipality +
              " " +
              transaction.shippingProvince}
          </td>
          <td className="has-text-centered">
            {transaction.details.reduce(
              (accumulator, currentValue) =>
                accumulator + parseInt(currentValue.quantity, 10),
              0
            )}
          </td>
          <td className="has-text-right">
            {transaction.details
              .reduce(
                (accumulator, currentValue) =>
                  accumulator +
                  (currentValue.vatexUnitPrice + currentValue.vat) *
                    currentValue.quantity,
                0
              )
              .toFixed(2)}
          </td>
        </tr>
      ))}
    </Fragment>
  ) : (
    <h3 className="title">No uploaded transaction.</h3>
  );
};

export default SingleTransactionPreview;
