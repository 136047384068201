import React, { Fragment } from "react";
const ActionButtons = ({
  buttons,
  buttonColor,
  activeViewIndex,
  handleSelectSummary
}) => {
  return (
    <Fragment>
      <div className="field is-grouped">
        {buttons.map((button, index) => (
          <div className="control">
            <button
              className={`button is-medium is-primary `}
              style={{
                backgroundColor:
                  activeViewIndex === index ? `${buttonColor}` : "#EEEEEE",
                color: activeViewIndex === index ? "white" : "gray"
              }}
              onClick={() => handleSelectSummary(index)}
            >
              <span>{button}</span>
            </button>
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default ActionButtons;
