import {
  DRAFT_PURCHASE_ORDER_ACTIONS,
  DRAFT_PURCHASE_ORDER_ITEMS_ACTIONS,
  FETCH_STATUS_B2B_STATUS,
  ORDER_ITEM_MUTATION,
  ORDER_DETAIL_MUTATION
} from "./actions";

export const fetchOrderDetail = (state = {}, action) => {
  switch (action.type) {
    case DRAFT_PURCHASE_ORDER_ACTIONS.SUCCESS:
      return { payload: action.payload, isFetching: false };
    case DRAFT_PURCHASE_ORDER_ACTIONS.FETCHING:
      return { payload: action.payload, isFetching: true };
    case DRAFT_PURCHASE_ORDER_ACTIONS.FAILURE:
      return { payload: null, isFetching: false, error: action.error };
    default:
      return { payload: null, isFetching: true };
  }
};

export const fetchOrderItems = (state = {}, action) => {
  switch (action.type) {
    case DRAFT_PURCHASE_ORDER_ITEMS_ACTIONS.SUCCESS:
      return { payload: action.payload, isFetching: false };
    case DRAFT_PURCHASE_ORDER_ITEMS_ACTIONS.FETCHING:
      return { payload: action.payload, isFetching: true };
    case DRAFT_PURCHASE_ORDER_ITEMS_ACTIONS.FAILURE:
      return { payload: [], isFetching: false, error: action.error };
    default:
      return state;
  }
};

export const fetchStatusB2B = (state = {}, action) => {
  switch (action.type) {
    case FETCH_STATUS_B2B_STATUS.SUCCESS:
      return { payload: action.payload, isFetching: false };
    case FETCH_STATUS_B2B_STATUS.FAILURE:
      return { payload: action.payload, isFetching: false };
    default:
      return { payload: null, isFetching: true };
  }
};

export const orderItemMutationReducer = (state, action) => {
  switch (action.type) {
    case ORDER_ITEM_MUTATION.DELETED:
      return { payload: [], message: action.payload };
    default:
      return action;
  }
};

export const orderDetailMutationReducer = (state, action) => {
  switch (action.type) {
    case ORDER_DETAIL_MUTATION.DELETED:
      return { payload: [], message: action.payload };
    default:
      return action;
  }
};

export const orderItemFetchReducer = (state, action) => {
  return action;
};
