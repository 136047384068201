import React from "react";
import PropTypes from "prop-types";

const Submenu = ({ leftButtons, rightButtons }) => (
  <div className="columns">
    <div className="column">
      <div className="buttons">{leftButtons}</div>
    </div>

    <div className="column">
      <div className="buttons is-right">{rightButtons}</div>
    </div>
  </div>
);

Submenu.propTypes = {
  leftButtons: PropTypes.node.isRequired,
  rightButtons: PropTypes.node.isRequired
};

export default Submenu;
