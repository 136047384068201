export const sourcingHeaders = [
  {
    value: "status",
    label: "Status"
  },
  {
    value: "code",
    label: "Sourcing Code"
  },
  {
    value: "noOfPOs",
    label: "No. of POs"
  },
  {
    value: "completion",
    label: "Completion"
  },
  {
    value: "created",
    label: "Created"
  },
  {
    value: "lastUpdated",
    label: "Last Updated"
  }
];

export const sourcingSheetHeaders = [
  {
    value: "code",
    label: "PO Code"
  },
  {
    value: "sku",
    label: "Item"
  },
  {
    value: "qty",
    label: "Qty Needed"
  },
  {
    value: "on_hand",
    label: "On-hand Allocated"
  },
  {
    value: "supplier",
    label: "Supplier"
  },
  {
    value: "qty_to_source",
    label: "Qty to Source"
  },
  {
    value: "recieved",
    label: "Received?"
  }
];

export const TABS = [
  {
    label: "Sourcing",
    count: 0
  },
  {
    label: "Completed",
    count: 0
  },
  {
    label: "Cancelled",
    count: 0
  }
];

export const pickListHeaders = [
  {
    value: ""
  },
  {
    value: "items",
    label: "Items"
  },
  {
    value: "qtyNeeded",
    label: "Qty Needed"
  },
  {
    value: "expectedOnHand",
    label: "Expected On-hand"
  },
  {
    value: "actualOnHand",
    label: "Actual On-hand"
  },
  {
    value: "status",
    label: "Status"
  },
  {
    value: ""
  }
];

export const setQueryVariable = (status, code) => {
  let statusOFSourcing = status;
  if (!statusOFSourcing) statusOFSourcing = "SOURCING";
  return {
    status: statusOFSourcing,
    offset: 0,
    limit: 30,
    code: code ? `%${code}%` : null
  };
};
