import { actionTypes } from "BatchTransaction/actions";

const DEFAULT_STATE = {
  batchTransaction: []
};

export default (state = DEFAULT_STATE.batchTransaction, action) => {
  switch (action.type) {
    case actionTypes.SET_BATCH_TRANSACTION:
      return action.payload;
    default:
      return state;
  }
};
