import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import styles from "./PurchaseOrder.module.scss";
import { formatNumber } from "Services/util";

const SubTotalFooter = ({ totalObject, convenienceFee }) => {
  return (
    <div>
      <hr className={styles["po-divider"]} />
      <table className={classNames("table is-fullwidth", styles.po__subtotal)}>
        <tbody>
          <tr>
            <td>Subtotal (VAT-Ex)</td>
            <td>{formatNumber(totalObject.vatexTotal)}</td>
          </tr>
          <tr>
            <td>VAT</td>
            <td>{formatNumber(totalObject.vatincTotal)}</td>
          </tr>
          <tr>
            <td>Convenience Fee</td>
            <td>{convenienceFee}</td>
          </tr>
          <tr>
            <td>Total Amount</td>
            <td>{formatNumber(totalObject.totalAmount)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

SubTotalFooter.propTypes = {
  totalObject: PropTypes.object,
  convenienceFee: PropTypes.string,
};
export default SubTotalFooter;
