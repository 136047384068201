import React from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import { CheckBox } from "DashboardV2/DashboardV2";
import { OnHandAllocatedInput } from "./SourcingSheetRowComponents";

import styles from "Sourcing/sourcing.module.scss";

const SourcingSheetRow = ({ item }) => {
  return (
    <tr className={classNames("m-1", [styles.sourcingSheetBodyRow])}>
      <CheckBox handleOnCheck={() => {}} isHeader={false} />
      <td className="has-text-centered">1MG0HQ1WA08</td>
      <td>{item}</td>
      <td className="has-text-centered">50</td>
      <td>
        <OnHandAllocatedInput quantityNeeded={50} />
      </td>
      <td>
        <div className="field has-addons">
          <div className="select">
            <select>
              <option value="CoPharm">CoPharm</option>
            </select>
          </div>
          <div className="control">
            <button className="button is-primary">
              <FontAwesomeIcon icon={faPlus} />
            </button>
          </div>
        </div>
      </td>
      <td className="has-text-centered">
        <input type="number" className="input has-text-centered" />
      </td>
      <CheckBox handleOnCheck={() => {}} isHeader={false} />
    </tr>
  );
};

export default SourcingSheetRow;
