import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import {
  fetchOrderItems,
  fetchOrderDetail as fetchObject
} from "PurchaseOrder/actions";
import PurchaseOrderPrintHeader from "../PurchaseOrderParts/PurchaseOrderPrintHeader";
import PageContent from "UIKit/components/PageContent";
import Loading from "UIKit/components/Loading/Loading";
import POBody from "../PurchaseOrderParts/POBody";
import SubTotalFooter from "../PurchaseOrderParts/SubTotalFooter";
import PurchaseOrderTableContent from "../PurchaseOrderParts/PurchaseOrderTableContent";
import { requestedOrderHeaders, recommendedOrderHeaders } from "./Headers";
import { isEqual } from "lodash";

const listOfChoices = ["Requested", "Recommended", "Requested & Recommended"];
class QuoteOrderPrint extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "Requested",
      objectFetched: null,
      orderItems: {
        isFetching: true
      }
    };
  }
  componentDidMount() {
    const { id } = this.props.match.params;
    this.props.fetchObject(id, "/draft-purchase-orders");
    this.props.fetchOrderItems(
      id,
      `/draft-purchase-orders/items/?draft_purchase_order`
    );
  }

  componentDidUpdate(_, prevState) {
    const { objectFetched, orderItems } = this.props;
    if (
      !isEqual(objectFetched, prevState.objectFetched) &&
      !prevState.objectFetched
    )
      this.setState(() => ({ objectFetched: objectFetched }));

    if (!isEqual(orderItems, prevState.orderItems))
      this.setState(() => ({ orderItems: orderItems }));
  }

  handlePrint = () => {
    window.print();
  };

  subTotalAccumulator = (objectFetched, type) => {
    let totalObject = { vatexTotal: 0, vatincTotal: 0, totalAmount: 0 };
    objectFetched.forEach(item => {
      totalObject.vatexTotal +=
        parseFloat(item[type]["vatexUnitPrice"]) * parseFloat(item["quantity"]);
      totalObject.vatincTotal +=
        parseFloat(item[type]["unitPrice"]) * parseFloat(item["quantity"]) -
        parseFloat(item[type]["vatexUnitPrice"]) * parseFloat(item["quantity"]);
      totalObject.totalAmount +=
        parseFloat(item[type]["unitPrice"]) * parseFloat(item["quantity"]);
    });
    return totalObject;
  };

  componentToggle = selectedValue => {
    if (selectedValue === "Requested")
      this.setState(() => ({ type: "Requested" }));
    else if (selectedValue === "Recommended")
      this.setState(() => ({ type: "Recommended" }));
    else this.setState(() => ({ type: "Both" }));
  };

  render() {
    const { isFetching, payload } = this.state.orderItems;
    const { objectFetched, type } = this.state;
    if (!objectFetched || isFetching) return <Loading />;
    else {
      return (
        <PageContent>
          <PurchaseOrderPrintHeader
            componentToggle={this.componentToggle}
            listOfChoices={listOfChoices}
            typeOfOrder="quote form"
            handlePrint={this.handlePrint}
          />
          {(type === "Requested" || type === "Both") && (
            <POBody
              orderHeader="QUOTATION FOR"
              name={objectFetched.createdForName}
              subName={objectFetched.corporateClient.name || ""}
              poCode={objectFetched.code}
            >
              <PurchaseOrderTableContent
                headers={requestedOrderHeaders}
                items={payload}
              />
              <SubTotalFooter
                totalObject={this.subTotalAccumulator(
                  payload,
                  "requestedOrder"
                )}
                convenienceFee={objectFetched.convenienceFee}
              />
            </POBody>
          )}
          <br />
          {(type === "Recommended" || type === "Both") && (
            <POBody
              orderHeader="QUOTATION FOR"
              name={objectFetched.createdForName}
              subName={objectFetched.corporateClient.name || ""}
            >
              <PurchaseOrderTableContent
                headers={recommendedOrderHeaders}
                items={payload}
              />
              <SubTotalFooter
                totalObject={this.subTotalAccumulator(
                  payload,
                  "recommendedOrder"
                )}
                convenienceFee={objectFetched.convenienceFee}
              />
            </POBody>
          )}
        </PageContent>
      );
    }
  }
}

QuoteOrderPrint.propTypes = {
  fetchObject: PropTypes.func,
  fetchOrderItems: PropTypes.func,
  match: PropTypes.object,
  listOfChoices: PropTypes.array,
  objectFetched: PropTypes.object,
  orderItems: PropTypes.object
};

const mapStateToProps = state => {
  return {
    objectFetched: state.fetchOrderDetail.payload,
    orderItems: state.fetchOrderItems
  };
};
export default connect(mapStateToProps, { fetchObject, fetchOrderItems })(
  QuoteOrderPrint
);
