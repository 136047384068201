import React, { Component } from "react";
import PropTypes from "prop-types";

class Modal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isActive: false
    };
  }

  componentDidMount() {
    this.setState(() => ({ isActive: this.props.isActive }));
  }

  componentDidUpdate(previousProps, previousState) {
    if (this.props.isActive !== previousState.isActive)
      this.setState(previousState => ({ isActive: previousProps.isActive }));
  }

  render() {
    return (
      this.state.isActive && (
        <div className="modal is-active">
          <div className="modal-background" />
          <div className="modal-card">
            <header className="modal-card-head">
              <p className="modal-card-title">{this.props.title}</p>
              <button
                name="closeModalButton"
                type="button"
                className="delete"
                aria-label="close"
                onClick={this.props.handleCloseModal}
              />
            </header>
            {this.props.children}
          </div>
        </div>
      )
    );
  }
}

Modal.propTypes = {
  isActive: PropTypes.bool,
  title: PropTypes.string,
  handleCloseModal: PropTypes.func
};

export default Modal;
