import React, { Component } from "react";
import { Formik, Field, Form } from "formik";
import { connect } from "react-redux";
import { pushNotification } from "UIKit/actions";
import PropTypes from "prop-types";
import { PanelContent } from "UIKit/components/SplitContainer/SplitContainer";
import FieldContainer from "UIKit/components/SplitContainer/FieldContainer";
import { clientFormValidationSchema } from "../helpers";
import api from "Services/api";
import provinces from "../../../../assets/data/philippineLocations.json";
import "../../../Client.scss";

const EDIT_PAGE = "EDIT_PAGE";
const accountManagers = [
  {
    value: "jlu",
    name: "Justine Lu"
  },
  {
    value: "cbautista",
    name: "Christine Bautista"
  }
];

class ClientInformationForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasDuplicateError: false,
      duplicateErrorMessage: null
    };
  }

  handleDuplicateError = (message, boolean) =>
    this.setState(previousState => ({
      hasDuplicateError: boolean,
      duplicateErrorMessage: message
    }));

  handleVerifyCompanyLetters = handleChange => event => {
    event.target.value = event.target.value.toUpperCase();
    if (event.target.value) {
      api
        .post("check-company-letters/", { companyLetters: event.target.value })
        .then(response => {
          if (response.data.status === "Already Exists")
            this.handleDuplicateError(response.data.message, true);
          else this.handleDuplicateError(response.data.message, false);
        });
    }
    handleChange(event);
  };
  render() {
    return (
      <div className="is-client-sidebar">
        <div className="is-client-sidebar__header" />
        <Formik
          initialValues={this.props.clientDetail}
          onSubmit={this.props.onSubmit}
          validationSchema={clientFormValidationSchema}
          enableReinitialize
          render={({ values, handleChange, errors, touched }) => {
            const selectedProvince = provinces.find(
              province => province.Province === values.defaultBillingProvince
            );
            const availableCitiesMunicipalities = !!selectedProvince
              ? selectedProvince["Cities"]
              : [];
            return (
              <Form autoComplete="off" id="client-info-form">
                <PanelContent header="Client Information">
                  <FieldContainer label="Corporate Name">
                    <Field
                      name="name"
                      className="input"
                      disabled={this.props.pageType === EDIT_PAGE}
                    />
                    <div className="error-message">
                      {errors.name && touched.name ? (
                        <div className="has-text-danger is-size-7">
                          {errors.name}
                        </div>
                      ) : null}
                    </div>
                  </FieldContainer>
                  <FieldContainer label="Company Letters">
                    <Field
                      name="companyLetters"
                      className="input"
                      maxlength="2"
                      disabled={this.props.pageType === EDIT_PAGE}
                      onChange={this.handleVerifyCompanyLetters(handleChange)}
                    />
                    {this.state.hasDuplicateError && (
                      <div className="has-text-danger is-size-7">
                        {this.state.duplicateErrorMessage}
                      </div>
                    )}
                    {errors.companyLetters && touched.companyLetters ? (
                      <div className="has-text-danger is-size-7">
                        {errors.companyLetters}
                      </div>
                    ) : null}
                  </FieldContainer>
                  <FieldContainer label="Entity Number">
                    <Field
                      name="entityNumber"
                      className="input"
                      type="number"
                      min="0"
                      disabled={this.props.pageType === EDIT_PAGE}
                    />
                    <div className="error-message">
                      {errors.entityNumber && touched.entityNumber ? (
                        <div className="has-text-danger is-size-7">
                          {errors.entityNumber}
                        </div>
                      ) : null}
                    </div>
                  </FieldContainer>
                  <FieldContainer label="Account Manager">
                    <div className="select">
                      <Field component="select" name="accountManager">
                        <option selected disabled>
                          ---Select Account Manager---
                        </option>
                        {accountManagers.map((accountManager, index) => (
                          <option value={accountManager.value}>
                            {accountManager.name}
                          </option>
                        ))}
                      </Field>
                    </div>
                    <div className="has-text-danger is-size-7">
                      {errors.accountManager ? (
                        <div>{errors.accountManager}</div>
                      ) : null}
                    </div>
                  </FieldContainer>

                  <FieldContainer label="Price List">
                    <div className="select">
                      <Field name="defaultPriceList" component="select">
                        {Object.keys(this.props.priceList).map(price => {
                          price = this.props.priceList[price];
                          return <option value={price.id}>{price.code}</option>;
                        })}
                      </Field>
                    </div>
                  </FieldContainer>
                  <FieldContainer label="Billing Street Address">
                    <Field
                      name="defaultBillingStreetAddress"
                      className="input"
                    />
                  </FieldContainer>
                  <FieldContainer label="Billing Province">
                    <div className="select">
                      <Field name="defaultBillingProvince" component="select">
                        <option value="">-- Select Province -- </option>
                        {provinces.map(province => (
                          <option value={province["Province"]}>
                            {province["Province"]}
                          </option>
                        ))}
                      </Field>
                    </div>
                  </FieldContainer>
                  <FieldContainer label="Billing City / Municipality">
                    <div className="select">
                      <Field
                        name="defaultBillingCityMunicipality"
                        component="select"
                      >
                        {availableCitiesMunicipalities.length ? (
                          availableCitiesMunicipalities.map(city => (
                            <option value={city["City"]}>{city["City"]}</option>
                          ))
                        ) : (
                          <option value="">
                            -- Select a Province First --
                          </option>
                        )}
                      </Field>
                    </div>
                  </FieldContainer>
                </PanelContent>
              </Form>
            );
          }}
        />
      </div>
    );
  }
}

const propTypes = {
  clientDetail: PropTypes.object.isRequired
};

ClientInformationForm.propTypes = propTypes;

const mapStateToProps = state => {
  return {
    priceList: state.entities.priceLists || []
  };
};
export default connect(mapStateToProps, { pushNotification })(
  ClientInformationForm
);
