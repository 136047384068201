import React, { Fragment } from "react";
import { Field, ErrorMessage } from "formik";
import { PanelContent } from "UIKit/components/SplitContainer/SplitContainer";
import FieldContainer from "UIKit/components/SplitContainer/FieldContainer";

const SiteFinanceDetailSection = props => {
  return (
    <Fragment>
      <PanelContent header="Finance Information">
        <FieldContainer label="Contact Person">
          <Field
            name="financeContactPerson"
            className="input"
            placeholder="e.g Rozel Corpuz"
          />
        </FieldContainer>
        <div className="columns">
          <div className="column">
            <FieldContainer label="Contact Number">
              <Field
                name="financeContactNumber"
                className="input"
                placeholder="ex. 09123456789"
              />
            </FieldContainer>
          </div>
          <div className="column">
            <FieldContainer label="Email Address">
              <Field
                name="financeEmailAddress"
                className="input"
                placeholder="ex. finance@medgrocer.com"
              />
              <ErrorMessage
                name="financeEmailAddress"
                render={msg => (
                  <div className="has-text-danger is-size-7">{msg}</div>
                )}
              />
            </FieldContainer>
          </div>
        </div>
      </PanelContent>
    </Fragment>
  );
};

export default SiteFinanceDetailSection;
