import React, { Component } from "react";
import PropTypes from "prop-types";

import * as couponService from "Services/coupon";
import { LineTextInput } from "UIKit/components/Forms/FormInput";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
const defaultHelp = { text: "The coupon code to be used.", class: "" };
class CouponInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCheckingCoupon: false,
      couponHelp: defaultHelp,
      couponCodeInput: "",
      isEditingCoupon: false
    };
  }

  onClickCouponCodeButton = () => this.setState({ isEditingCoupon: true });

  onClickCouponCodeDelete = event => {
    this.props.onDeleteCoupon();
  };

  onCouponCodeCancel = () => this.setState({ isEditingCoupon: false });
  submitCoupon = event => {
    event.preventDefault();
    this.setState(
      {
        isCheckingCoupon: true
      },
      couponService.getCoupon(
        this.state.couponCodeInput,
        null,
        (coupon, message) => {
          if (coupon) {
            this.setState({
              couponHelp: defaultHelp,
              isEditingCoupon: false,
              coupon: coupon
            });
            this.props.onReceiveCoupon(coupon);
          } else {
            this.setState({
              couponHelp: { text: message, class: "is-danger" }
            });
          }
          this.setState({ isCheckingCoupon: false });
        }
      )
    );
  };

  onChangeCouponCodeInput = event => {
    this.setState({
      couponCodeInput: event.target.value.toUpperCase(),
      submittedCouponInvalid: false,
      submittedCouponUseLimitExceeded: false
    });
  };

  onCouponCodeDelete = () => {
    this.setState({
      couponCodeInput: ""
    });
  };

  render() {
    const couponForm = (
      <form onSubmit={this.submitCoupon}>
        <LineTextInput
          className="input is-narrow"
          type="text"
          // helpText=""
          placeholder="Coupon Code"
          disabled={this.state.isCheckingCoupon}
          help={this.state.couponHelp}
          value={this.state.couponCodeInput}
          onChange={this.onChangeCouponCodeInput}
          autoFocus
        />
        <div className="field is-grouped is-grouped-right">
          <div className="control">
            <button
              type="button"
              className={`button is-outlined has-text-left`}
              onClick={this.onCouponCodeCancel}
            >
              <span>Cancel</span>
            </button>
          </div>

          <div className="control">
            {
              <button
                type="submit"
                className={`button is-primary has-text-left ${
                  this.state.couponCodeInput ? "" : "disabled"
                } ${this.state.isCheckingCoupon ? "is-loading" : ""}`}
                disabled={this.state.isCheckingCoupon}
              >
                <span>Submit</span>
              </button>
            }
          </div>
        </div>
      </form>
    );

    const couponCodeButton = this.props.coupon ? (
      <span className="is-medium">
        <span className="has-weight-bold">
          {this.props.coupon.code}&nbsp;&nbsp;
        </span>

        <button className="button is-small">
          <span className="icon" onClick={this.onClickCouponCodeButton}>
            <FontAwesomeIcon icon={faEdit} />
          </span>
        </button>

        <button
          onClick={this.onClickCouponCodeDelete}
          className="button is-small"
        >
          <span className="icon">
            <FontAwesomeIcon icon={faTimes} />
          </span>
        </button>
      </span>
    ) : (
      <button className="button" onClick={this.onClickCouponCodeButton}>
        + Add Coupon
      </button>
    );

    if (this.state.isEditingCoupon) {
      return couponForm;
    } else return couponCodeButton;
  }
}

CouponInput.propTypes = {
  coupon: PropTypes.object,
  onDeleteCoupon: PropTypes.func.isRequired,
  onReceiveCoupon: PropTypes.func.isRequired
};

export default CouponInput;
