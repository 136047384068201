import React, { Component } from "react";
import { SearchBar } from "UIKit/components/Forms/FormInput";
import SearchResults from "Search/components/SearchResults";
import { setSearchQuery } from "Search/actions";
import { connect } from "react-redux";
import api from 'Services/api';
import { debounce } from 'lodash';

const ARROW_UP = 38;
const ARROW_DOWN = 40;
const ENTER = 13;
const ORDERS = "ORDERS";
const COUPONS = "COUPONS";

class Search extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: 0,
      hideResults: false,
      searchResults: [],
      displayValue: "",
      selectedItem: {}
    };
  }

  handleSearch = event => {
    let searchQuery = event.target.value.toLowerCase().split(" ");
    this.setState({
      displayValue: searchQuery.join(" "),
      hideResults: true
    }, async () => {
      if(searchQuery[0] !== ""){
        await this.debouncedSearch(searchQuery)
      }
    })  
  }

  debouncedSearch = debounce(searchQuery => this.search(searchQuery), 200)

  search = searchQuery => {
    api
      .get(
        process.env.REACT_APP_API_HOST +
          `products/?search=${
            searchQuery.join(" ")
          }`
      )
      .then(response => {
        const products = response.data.results
        let searchResults = this.props.searchFunction(searchQuery, products);
        if (searchQuery[0] === "") {
          searchResults = [];
        }
        this.setState({
          hideResults: false,
          searchResults: searchResults,
          activeItem: 0
        });
      })
  };

  handleKeyPress = event => {
    switch (event.keyCode) {
      default:
        break;
      case ARROW_UP:
        if (this.state.activeItem > 0) {
          this.setState(previousState => {
            return { activeItem: previousState.activeItem - 1 };
          });
        }
        break;
      case ARROW_DOWN:
        if (this.state.activeItem < this.state.searchResults.length - 1) {
          this.setState(previousState => {
            return { activeItem: previousState.activeItem + 1 };
          });
        }
        break;
      case ENTER:
        switch (this.props.parentComponent) {
          case ORDERS:
            this.setState(
              previousState => {
                return {
                  selectedItem:
                    previousState.searchResults[previousState.activeItem]
                };
              },
              () => {
                this.props.setSearchResult(this.state.selectedItem);
                this.setState(previousState => {
                  return {
                    displayValue: previousState.selectedItem.title,
                    hideResults: true
                  };
                });
              }
            );
            break;
          case COUPONS:
            this.props.onClick(this.state.searchResults[this.state.activeItem]);
            this.setState({
              displayValue: "",
              hideResults: true
            });
            break;
          default: {
          }
        }
        break;
    }
  };

  onClick = index => {
    switch (this.props.parentComponent) {
      case ORDERS:
        this.props.onClick(
          this.state.searchResults[index],
          this.state.searchResults[index].rowIndex
        );
        this.setState({
          hideResults: true
        });
        break;
      case COUPONS:
        this.props.onClick(this.state.searchResults[index]);
        this.setState({
          displayValue: "",
          hideResults: true
        });
        break;
      default:
        break;
    }
  };

  componentDidMount() {
    if (this.props.defaultValue) {
      this.setState({
        displayValue: this.props.defaultValue
      });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      displayValue: nextProps.searchResultTitle
    });
  }

  render() {
    return (
      <div>
        <SearchBar
          onChange={this.handleSearch}
          onKeyDown={this.handleKeyPress}
          value={this.state.displayValue}
          withIcon={this.props.withIcon}
        />
        <SearchResults
          searchResults={this.state.searchResults}
          hideResults={this.state.hideResults}
          active={this.state.activeItem}
          ROWID={this.props.id}
          onClick={this.onClick}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  setSearchResult: searchResults => dispatch(setSearchQuery(searchResults))
});

const mapStateToProps = state => ({
  searchResult: state.searchResult
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search);
