import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Formik, Field, Form } from "formik";
import FormikSearchField from "UIKit/components/SearchInput/FormikSearchField";
import { Cell, Row } from "UIKit/components/Table_v2/Table";

import { formatNumber, roundFloatToTwo } from "Services/util";
import { FormInput } from "UIKit/components/Forms/FormInput";

const TransactionDetailsRow = ({
  deleteRowOnClick,
  index,
  handleChange,
  handleTransactionItemChange,
  products,
  onSelectItem,
  hideResults,
  searchResultTitle,
  productTitle,
  item,
  couponCode,
  handleDetailChange
}) => {
  const { quantity } = item;
  const vatable = parseFloat(item.vat) !== 0.0 && couponCode !== "SCPWD";
  const unitPrice = vatable
    ? roundFloatToTwo(parseFloat(item.vatexUnitPrice) * 1.12)
    : roundFloatToTwo(parseFloat(item.vatexUnitPrice));
  const total = quantity * (parseFloat(unitPrice) || 0);
  return (
    <Formik
      initialValues={{
        productTitle: productTitle,
        quantity: "",
        unitPrice: ""
      }}
      render={({ values, errors, touched, handleChange, setFieldValue }) => (
        <Form autoComplete="off">
          <Row>
            <Cell priority>
              <FormikSearchField
                name="productTitle"
                id={index}
                individualOrderSelectItem={(item, event) => {
                  handleDetailChange(event);
                  onSelectItem(item, index, values);
                }}
                baseSearch="products?price_list=retail&"
                customOnChangeInput={event => {
                  handleChange(event);
                }}
                keyValue="itemCode"
                keyElement="title"
                defaultValue={productTitle}
              />
            </Cell>
            <Cell>
              <Field
                name="quantity"
                className="input"
                render={({ field, form }) => (
                  <FormInput
                    name="quantity"
                    type="number"
                    min="1"
                    id={index}
                    value={quantity}
                    onChange={event => {
                      handleChange(event);
                      handleTransactionItemChange(event);
                    }}
                  />
                )}
              />
            </Cell>
            <Cell>
              <Field
                name="unitPrice"
                className="input"
                render={({ field, form }) => (
                  <FormInput
                    name="unitPrice"
                    type="number"
                    id={index}
                    value={unitPrice || 0.0}
                    onChange={event => {
                      handleChange(event);
                      handleTransactionItemChange(event);
                    }}
                  />
                )}
              />
            </Cell>
            <Cell>
              <Field
                name="total"
                className="input"
                render={({ field, form }) => (
                  <FormInput
                    inputStyle="is-static"
                    value={formatNumber(total) || 0.0}
                    isReadOnly={true}
                  />
                )}
              />
            </Cell>
            <Cell className="is-narrow">
              <button
                className="delete is-medium"
                onClick={() => deleteRowOnClick(index)}
                index={index}
                type="button"
              />
            </Cell>
          </Row>
        </Form>
      )}
    />
  );
};

TransactionDetailsRow.propTypes = {
  deleteRowOnClick: PropTypes.func.isRequired,
  index: PropTypes.number,
  handleChange: PropTypes.func.isRequired,
  products: PropTypes.object,
  onSelectItem: PropTypes.func.isRequired,
  hideResults: PropTypes.bool.isRequired,
  searchResultTitle: PropTypes.string.isRequired,
  productTitle: PropTypes.string.isRequired,
  item: PropTypes.object.isRequired,
  couponCode: PropTypes.string
};

const mapStateToProps = state => ({
  products: state.entities.products
});

export default connect(mapStateToProps)(TransactionDetailsRow);
