import { useState, useEffect } from "react";
import provinces from "assets/data/philippineLocations.json";

const useAddressSelection = province => {
  const [recentProvince, setRecentProvince] = useState(province);
  const [activeCityMunicipality, setActiveCityMunicipality] = useState([]);
  useEffect(() => {
    function handleSetActiveCityMunicipality() {
      const selectedProvince = provinces.filter(
        provinceObject => provinceObject.Province === recentProvince
      );
      const availableCityMunicipalities = !!selectedProvince[0]
        ? selectedProvince[0]["Cities"]
        : [];
      setActiveCityMunicipality(availableCityMunicipalities);
    }
    handleSetActiveCityMunicipality();
  }, [recentProvince]);

  return [activeCityMunicipality, setRecentProvince];
};

export default useAddressSelection;
