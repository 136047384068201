import * as Yup from "yup";

export const DPO_MAP = {
  createdFor: {
    individual: null,
    site: null,
    requirements: null,
  },
  taxRate: null,
  corporateClient: null,
  businessLine: "1",
  couponCode: null,
  isPriority: false,
  convenienceFee: parseFloat(0.0),
  discountAmount: parseFloat(0.1),
  billingStreetAddress: null,
  billingCityMunicipality: null,
  billingProvince: null,
  billingZipCode: null,
  shippingStreetAddress: null,
  shippingCityMunicipality: null,
  shippingProvince: null,
  shippingZipCode: null,
  code: null,
  rating: 1,
  notes: "",
  status: "CREATED",
};

export const DPO_ITEM_MAP = {
  requestedOrder: {
    productTitle: "",
    unitPrice: 0.0,
    vatexUnitPrice: 0.0,
    product: null,
  },
  recommendedOrder: {
    productTitle: "",
    unitPrice: 0.0,
    vatexUnitPrice: 0.0,
    product: null,
  },
  clientRequest: "",
  quantity: 1,
  unitOfMeasure: "PC",
  draftPurchaseOrder: null,
  remarks: "",
  sequenceNumber: null,
};

export const PO_ITEM_MAP = {
  requestedOrder: {
    productTitle: "",
    unitPrice: 0.0,
    vatexUnitPrice: 0.0,
    product: null,
  },
  clientRequest: "",
  quantity: 1,
  unitOfMeasure: "PC",
  draftPurchaseOrder: null,
  sequenceNumber: null,
};

export const DPO_HEADER_TITLES = [
  { title: "#" },
  { title: "Client Request", priority: true },
  { title: "Requested Item", priority: true },
  { title: "Qty", className: "has-text-centered" },
  { title: "Unit Price", className: "has-text-right" },
  { title: "Total", className: "has-text-right" },
  { title: "Remarks" },
  { title: "" },
];
export const PO_HEADER_TITLES = [
  { title: "#" },
  { title: "Client Request", priority: true },
  { title: "Requested Item", priority: true },
  { title: "UoM" },
  { title: "Qty", className: "has-text-centered" },
  { title: "Price", className: "has-text-right" },
  { title: "Total", className: "has-text-right" },
  { title: "" },
];

export const objectMapper = (obj, objToMap) => {
  Object.keys(obj).map((key) => {
    if (objToMap[key]) return (obj[key] = objToMap[key]);
    return obj[key];
  });
  return obj;
};

export const generateHeaders = (subUrl) => {
  if (subUrl === "draft_purchase_order") return DPO_HEADER_TITLES;
  else return PO_HEADER_TITLES;
};

export const generateMap = (subUrl) => {
  if (subUrl === "draft_purchase_order") return DPO_ITEM_MAP;
  else {
    const PO_ITEM_MAP = { ...DPO_ITEM_MAP };
    delete PO_ITEM_MAP.recommendedOrder;
    return PO_ITEM_MAP;
  }
};

const PO_VALIDATION_SCHEMA = Yup.object().shape({
  requestedOrder: Yup.object().shape({
    productTitle: Yup.string().required("Requested product must not be empty."),
    vatexUnitPrice: Yup.string(),
  }),
  quantity: Yup.number(),
  unitOfMeasure: Yup.string(),
});

export const generateValidationSchema = (subUrl) => {
  if (subUrl === "purchase_order") {
    return PO_VALIDATION_SCHEMA;
  } else return null;
};
