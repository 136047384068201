export const formatNumber = (number) => {
  return Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(number);
};

export const roundFloatToTwo = (number) => {
  return Math.round(number * 1e2) / 1e2;
};
