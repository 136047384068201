import React, { Component } from "react";
import Dashboard from "../../Dashboard/components/Dashboard";
import ClientListRow from "./ClientListRow";
import { Link } from "react-router-dom";
import { stringify } from "querystring";
import { parse } from "query-string";
import { SearchBar } from "UIKit/components/Forms/FormInput";
import "../Client.scss";

const ENTER = 13;
const headers = [
  {
    displayName: "Last Modified",
    attributeName: "last_modified",
    canSortBy: true
  },
  {
    displayName: "Client Code",
    attributeName: "company_letters",
    canSortBy: true
  },
  { displayName: "Client Name", attributeName: "name", canSortBy: true },
  {
    displayName: "Account Manager",
    attributeName: "account_manager",
    canSortBy: true
  },
  {
    displayName: "Date Created",
    attributeName: "created",
    canSortBy: true
  }
];

class ClientList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: "",
      clients: []
    };
  }

  getDashboardFilters = () => {
    const urlParameters = parse(this.props.history.location.search, {
      decode: false
    });
    return Object.keys(urlParameters).reduce((accumulator, key) => {
      var newFilters;
      if (Array.isArray(urlParameters[key]))
        newFilters = urlParameters[key].map(value => ({
          field: key,
          value: value
        }));
      else newFilters = [{ field: key, value: urlParameters[key] }];
      return [...accumulator, ...newFilters];
    }, []);
  };

  onSelectClients = clients => {
    this.setState(previousState => {
      return {
        clients: [...previousState.clients, ...clients]
      };
    });
  };

  onChangePage = currentPage => {
    const urlParameters = parse(this.props.history.location.search);
    urlParameters.page = currentPage;
    this.props.history.push(`?${stringify(urlParameters)}`);
  };

  handleKeyPress = event => {
    switch (event.keyCode) {
      case ENTER:
        this.props.history.push(`/clients/?search=${event.target.value}`);
        break;
      default:
        break;
    }
  };

  render() {
    const filters = this.getDashboardFilters();
    const pageFilter = filters.find(filter => {
      return filter.field === "page";
    });
    const page = pageFilter ? pageFilter.value : 1;
    return (
      <div className="container">
        <div className="level">
          <Link to="/clients/add/" className="button is-primary">
            + Add Client
          </Link>
          <div className="level-right">
            <SearchBar
              id="search"
              withIcon={true}
              hideResults={false}
              onKeyDown={this.handleKeyPress}
              placeholder="Search Client"
            />
          </div>
        </div>
        <Dashboard
          baseUrl="clients/?"
          onSelectRows={() => {}}
          onDeselectRows={() => {}}
          selectedData={this.state.clients}
          headers={headers}
          RowComponent={ClientListRow}
          idAttribute="id"
          filters={filters}
          // ref={this.dashboard}
          isCheckboxEnabled={true}
          dashboardClassname="is-client-dashboard"
          searchQuery={this.state.searchQuery}
          onChangePage={this.onChangePage}
          currentPage={page}
        />
      </div>
    );
  }
}

export default ClientList;
