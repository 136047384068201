import React, { useState, useEffect } from "react";
import queryString from "query-string";

import PageContent from "UIKit/components/PageContent";
import Tabs from "UIKit/components/Tabs";

import SourcingSheetHeader from "Sourcing/components/SourcingSheetHeader";
import SourcingSheetBody from "./SourcingSheetBody";

const TABS = [
  { label: "Generic" },
  { label: "Branded" },
  { label: "Supplies" }
];

const SourcingSheet = ({ location, history, match: { params } }) => {
  const [query, setQuery] = useState(queryString.parse(location.search));
  const [activeTab, setActiveTab] = useState(query.category || "GENERIC");
  useEffect(() => {
    setQuery(queryString.parse(location.search));
  }, [activeTab, location]);

  const handleTabClick = event => {
    event.preventDefault();
    const target = event.currentTarget.getAttribute("name");
    setActiveTab(target.toUpperCase());
    history.push(
      `/sourcing/${params.id}/sheet?category=${target.toUpperCase()}`
    );
  };
  return (
    <PageContent>
      <SourcingSheetHeader
        buttonPosition="right"
        buttonLabel="Mark as Complete"
        title="SOURCING"
        tag={params.id}
        backButtonLabel="View Picklist"
      />
      <br />
      <Tabs tabs={TABS} activeTab={activeTab} onClick={handleTabClick} />
      <SourcingSheetBody />
    </PageContent>
  );
};

export default SourcingSheet;
