import React, { Fragment } from "react";
import { getLineItemTotals } from "Services/cart";
import ReceiptHeader from "Printables/Receipt/ReceiptHeader";
import DRBody from "Printables/Receipt/DR/DRBody";
import DRTopSection from "Printables/Receipt/DR/DRTopSection";

const DRClientCopy = ({ transaction, transactionItems, subtitle }) => {
  let lineItems = getLineItemTotals(
    transactionItems,
    transaction.couponCode,
    true
  );

  const isChannelValidWithPrice = transaction.channel !== "medensure";

  return (
    <Fragment>
      <div className="mx-2">
        <ReceiptHeader
          hasSubtitle
          subtitle={subtitle}
          header="DELIVERY RECEIPT"
        />
        <DRTopSection
          transaction={transaction}
          lineItems={lineItems}
          isChannelValidWithPrice={isChannelValidWithPrice}
        />
        <DRBody
          lineItems={lineItems}
          transaction={transaction}
          isChannelValidWithPrice={isChannelValidWithPrice}
        />
      </div>
    </Fragment>
  );
};

export default DRClientCopy;
