import React, { useState } from "react";
import * as Yup from "yup";
import classNames from "classnames";
import fileDownload from "js-file-download";
import { Form, Formik, Field, ErrorMessage } from "formik";
import channels from "../../assets/data/channels";
import api from "Services/api";
import transactionColumns from "../utils/transactionColumns.json";

const dateToday = new Date();
const dateLimitation = new Date(new Date().setMonth(dateToday.getMonth() - 6));
const ValidationSchema = Yup.object().shape({
  to: Yup.date().required("This field is required"),
  from: Yup.date()
    .max(Yup.ref("to"), "Value must be less than the date to.")
    .min(
      dateLimitation.toISOString().slice(0, 10),
      "Invalid date range: date must be less than 6 months from today."
    ),
});

const ExportTransactionModal = ({ isModalActive, setIsModalActive }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleExportSubmit = async (values) => {
    const buildColumns = values.columnHeaders.join(",");
    try {
      setIsLoading(true);
      setHasError(false);
      const response = await api.get(
        `/transactions/export/?from=${values.from}&to=${values.to}&channel=${values.channel}&columns=${buildColumns}`
      );
      fileDownload(response.data, `${values.from}-${values.to}.csv`);
    } catch (error) {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  const buildDefaultTransactionColumns = transactionColumns
    .map((column) => {
      if (column.default) return column.value;
    })
    .filter((item) => item);

  const handleOnCheck = (event, data, setFieldValue, columnHeaders) => {
    if (event.target.checked && !isNaN(data.priority)) {
      columnHeaders.splice(data.priority, 0, data.value);
      setFieldValue("columnHeaders", columnHeaders);
    } else if (event.target.checked) {
      columnHeaders.push(data.value);
      setFieldValue("columnHeaders", columnHeaders);
    } else {
      setFieldValue(
        "columnHeaders",
        columnHeaders.filter((item) => item !== data.value)
      );
    }
  };

  const transactionColumnsBreakdown = [
    { start: 0, end: Math.round(transactionColumns.length / 2) },
    {
      start: Math.round(transactionColumns.length / 2),
      end: transactionColumns.length,
    },
  ];

  return (
    <Formik
      initialValues={{
        from: dateToday.toISOString().slice(0, 10),
        to: dateToday.toISOString().slice(0, 10),
        columnHeaders: buildDefaultTransactionColumns,
        channel: "regular",
      }}
      onSubmit={handleExportSubmit}
      validationSchema={ValidationSchema}
    >
      {({ values, setFieldValue }) => (
        <Form>
          <div
            className={classNames("modal", {
              "is-active": isModalActive,
            })}
          >
            <div className="modal-background"></div>
            <div className="modal-card">
              <header className="modal-card-head">
                <p className="modal-card-title">
                  Export Transactions
                  <div className="is-size-7 mt-1">
                    Export transactions up to six months back
                  </div>
                </p>
                <button
                  type="button"
                  className="delete"
                  aria-label="close"
                  onClick={() => setIsModalActive(false)}
                ></button>
              </header>
              <section className="modal-card-body">
                <label className="label">Date Range:</label>
                <div className="columns">
                  <div className="column">
                    <label>From:</label>
                    <Field name="from" type="date" className="input" />
                    <div className="is-size-7 has-text-danger">
                      <ErrorMessage name="from" />
                    </div>
                  </div>
                  <div className="column">
                    <label>To:</label>
                    <Field name="to" type="date" className="input" />
                    <div className="is-size-7 has-text-danger">
                      <ErrorMessage name="to" />
                    </div>
                  </div>
                </div>

                <div className="mt-1">
                  <label>Channel:</label>
                  <div className="select">
                    <Field name="channel" component="select">
                      {channels.map((item) => (
                        <option value={item.channel}>{item.label}</option>
                      ))}
                    </Field>
                  </div>
                </div>

                <div className="mt-2 has-text-weight-bold is-size-5">
                  Select columns to include
                </div>

                <div className="columns">
                  {transactionColumnsBreakdown.map((breakdown) => (
                    <div className="column">
                      {transactionColumns
                        .slice(breakdown.start, breakdown.end)
                        .map((column) => (
                          <div>
                            <label>
                              <Field
                                type="checkbox"
                                name="columnHeaders"
                                checked={values.columnHeaders.includes(
                                  column.value
                                )}
                                onChange={(event) => {
                                  handleOnCheck(
                                    event,
                                    column,
                                    setFieldValue,
                                    values.columnHeaders
                                  );
                                }}
                              />{" "}
                              {column.alias || column.value}
                            </label>
                          </div>
                        ))}
                    </div>
                  ))}
                </div>
                {hasError && (
                  <div className="message is-danger p-2 has-text-centered">
                    Error in exporting transactions. Please contact IT for more
                    information.
                  </div>
                )}
              </section>
              <footer className="modal-card-foot">
                <button
                  className={classNames("button is-primary", {
                    "is-loading": isLoading,
                  })}
                >
                  Export
                </button>
                <button
                  type="button"
                  className="button"
                  onClick={() => setIsModalActive(false)}
                >
                  Cancel
                </button>
              </footer>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default ExportTransactionModal;
