import { PRODUCT_REQUEST_STATUS, FORM_REQUEST_STATUS } from "./actions";

export const fetchProductRequestDetailReducer = (
  state = { result: null },
  action
) => {
  switch (action.type) {
    case PRODUCT_REQUEST_STATUS.FETCH_PRODUCT_REQUEST_DETAIL:
      return { ...state, result: action.payload };
    default:
      return state;
  }
};

export const fetchFormReducer = (state = { result: [] }, action) => {
  switch (action.type) {
    case FORM_REQUEST_STATUS.FETCHED_FORM_LIST:
      return { ...state, result: action.payload };
    default:
      return state;
  }
};
