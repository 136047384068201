import React, { Fragment } from "react";
import { Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import FieldContainer from "UIKit/components/SplitContainer/FieldContainer";
import { actionStepChoices, aquisitionUnitPriceComputation } from "./helpers";
import "./productRequest.scss";

const SideBarContainerFields = ({ handleChange, values }) => (
  <Fragment>
    <FieldContainer label="Computed Retail Price (VAT-INC)" hasAddons>
      <div className="control is-expanded">
        <Field
          name="acquisitionRetailPriceFinal"
          className="input has-cursor-disabled "
          readOnly
          disabled
        />
      </div>
      <div className="control">
        <span class=" is-small is-right">
          <button type="button" className="button">
            <FontAwesomeIcon icon={faPencilAlt} />
          </button>
        </span>
      </div>
    </FieldContainer>
    <FieldContainer label="Computed Wholesale Price (VAT-INC)" hasAddons>
      <div className="control is-expanded">
        <Field
          name="acquisitionWholesalePriceFinal"
          className="input has-cursor-disabled "
          defaultValue={aquisitionUnitPriceComputation(
            values,
            "Wholesale",
            "Retail"
          )}
          readOnly
          disabled
        />
      </div>
      <div className="control">
        <span class=" is-small is-right">
          <button type="button" className="button">
            <FontAwesomeIcon icon={faPencilAlt} />
          </button>
        </span>
      </div>
    </FieldContainer>
    <FieldContainer label="Action steps">
      <div className="control select">
        <Field component="select" name="actionStep">
          {actionStepChoices.map(action => (
            <option value={action.value}>{action.text}</option>
          ))}
        </Field>
      </div>
    </FieldContainer>
  </Fragment>
);

export default SideBarContainerFields;
