import React, { Fragment, useState } from "react";
import { Form, Formik } from "formik";
import PropTypes from "prop-types";
import api from "Services/api";

import Modal from "UIKit/components/Modal";
import AddressGenerator from "./AddressGenerator";

const PurchaseOrderEditAddressModal = ({ objectFetched, handleOnSave }) => {
  const [isModalActive, setModalActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isGlobal, setIsGlobal] = useState(false);

  const handleOnSubmit = async values => {
    setIsLoading(true);
    try {
      if (isGlobal) {
        // deep copy of values
        const addresses = { ...values };
        // delete id so that it doesnt conflict on site's id
        delete addresses.id;
        // apply patch: only the addresses are changed
        await api.patch(`/sites/${addresses.createdFor.site}/`, addresses);
      }
      await handleOnSave(values);
      setModalActive(false);
    } finally {
      setIsLoading(false);
    }
  };
  return (
    <Formik
      initialValues={objectFetched}
      onSubmit={handleOnSubmit}
      enableReinitialize
    >
      {({ handleChange, values }) => (
        <Form autoComplete="off">
          <Fragment>
            <span
              name="editAddressLink"
              className="has-text-primary mt-2 mx-2 link"
              onClick={() => setModalActive(true)}
            >
              <u>Edit Addresses</u>
            </span>
            <Modal
              isActive={isModalActive}
              handleCloseModal={() => setModalActive(false)}
              title="Edit Addresses"
            >
              <section className="modal-card-body">
                <AddressGenerator
                  withFields
                  values={values}
                  title="Billing Address"
                  type="billing"
                  handleChange={handleChange}
                />
                <AddressGenerator
                  withFields
                  values={values}
                  title="Shipping Address"
                  type="shipping"
                  handleChange={handleChange}
                />
                <br />
                <div className="notification is-warning">
                  <label>
                    <input
                      name="checkboxGlobal"
                      type="checkbox"
                      checked={isGlobal}
                      onChange={() => setIsGlobal(!isGlobal)}
                    />{" "}
                    &nbsp; Please check this if you want to change the addresses
                    of all future orders of
                    <br />
                    <strong>
                      <u>{objectFetched.createdForName}</u>
                    </strong>{" "}
                    .
                  </label>
                </div>
              </section>
              <footer className="modal-card-foot">
                <button
                  className={`button is-primary ${isLoading && "is-loading"}`}
                  disabled={isLoading}
                >
                  Save changes
                </button>
                <button
                  name="cancelButton"
                  className="button"
                  type="button"
                  onClick={() => setModalActive(false)}
                >
                  Cancel
                </button>
              </footer>
            </Modal>
          </Fragment>
        </Form>
      )}
    </Formik>
  );
};

PurchaseOrderEditAddressModal.propTypes = {
  objectFetched: PropTypes.object,
  handleOnSave: PropTypes.func
};

export default PurchaseOrderEditAddressModal;
