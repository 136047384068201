export const blankProductRequestObject = {
  title: null,
  barcode: null,
  includeOnWebsite: true,
  productType: "MEDICINE-BRANDED",
  moleculeGenericName: null,
  strengthQuantityPerPackVolume: null,
  brand: null,
  form: null,
  acquisitionRetailVatincUnitPrice: null,
  acquisitionWholesaleVatincUnitPrice: null,
  retailSource: null,
  wholesaleSource: null,
  created: null,
  related_item: null,
  remarks: null,
  actionStep: "ADD PRODUCT"
};

export const headers = [
  { displayName: "Title", attributeName: "title", canSortBy: true },
  {
    displayName: "VAT-Inc Retail Price",
    attributeName: "acquisitionRetailVatincUnitPrice",
    canSortBy: true
  },
  {
    displayName: "VAT-Inc Wholesale Price ",
    attributeName: "acquisitionWholesaleVatincUnitPrice",
    canSortBy: true
  },
  {
    displayName: "Type",
    attributeName: "brand",
    canSortBy: true
  },
  {
    displayName: "Date Requested",
    attributeName: "date_requested",
    canSortBy: false
  }
];

export const actionStepChoices = [
  {
    value: "ADD PRODUCT",
    text: "Add to Product List"
  },
  {
    value: "UPDATE PRODUCT NAME",
    text: "Change Item Name"
  },
  {
    value: "UPDATE PRICE",
    text: "Update Price"
  },
  {
    value: "MARKET OOS",
    text: "Market OOS"
  },
  {
    value: "PHASED OUT",
    text: "Phased Out"
  },
  {
    value: "REMOVE DUPLICATES",
    text: "Remove Duplicates"
  },
  {
    value: "REMOVE ITEM",
    text: "Remove Item"
  }
];

export const productTypeChoices = [
  {
    value: "MEDICINE-BRANDED",
    text: "Branded Medicine"
  },
  {
    value: "MEDICINE-GENERIC",
    text: "Generic Medicine"
  },
  {
    value: "MEDSUPPLY-BRANDED",
    text: "Medical Supply - Branded"
  },
  {
    value: "MEDSUPPLY-BRANDED-BULK",
    text: "Medical Supply - Branded Bulk"
  },
  {
    value: "MEDSUPPLY-GENERIC",
    text: "Medical Supply - Generic"
  },
  {
    value: "MEDSUPPLY-GENERIC-BULK",
    text: "Medical Supply - Generic Bulk"
  }
];

export const titleBuilder = (productRequest, type) => {
  let productTitle = "";
  if (productRequest.moleculeGenericName)
    productTitle += " " + productRequest.moleculeGenericName.trim();
  if (productRequest.strengthQuantityPerPackVolume)
    productTitle += " " + productRequest.strengthQuantityPerPackVolume.trim();
  if (productRequest.form)
    productTitle += " " + productRequest.form || productRequest.form.trim();
  if (productRequest.brand)
    productTitle += " (" + productRequest.brand.trim() + ")";
  productRequest.title = productTitle;
  return productRequest;
};

export const aquisitionUnitPriceComputation = values => {
  if (!!values.title && !!values.productType) {
    const isMedSupply = values.productType.toLowerCase().includes("medsupply");
    const isBulk = values.productType.toLowerCase().includes("bulk");
    if (isMedSupply) {
      values.acquisitionRetailPriceFinal = "N/A";
      const supplyPrice = wholesaleSupplyCalculator(
        values.acquisitionRetailVatincUnitPrice
      );
      if (isBulk) {
        const bulkPrice = wholesaleBulkCalculator(supplyPrice);
        values.acquisitionWholesalePriceFinal = notNumberDefault(bulkPrice);
      } else
        values.acquisitionWholesalePriceFinal = notNumberDefault(supplyPrice);
    } else {
      const retailPrice = finalRetailPriceCalculator(
        values.retailSource,
        parseFloat(values.acquisitionRetailVatincUnitPrice)
      );
      const wholesaleMedicinePrice = wholesaleMedicineCalculator(
        parseFloat(values.acquisitionWholesaleVatincUnitPrice),
        retailPrice,
        values.productType
      );
      values.acquisitionRetailPriceFinal = retailPrice;
      values.acquisitionWholesalePriceFinal = notNumberDefault(
        wholesaleMedicinePrice
      );
    }
  }
};

export const wholesaleMedicineCalculator = (
  wholeSalePrice,
  finalRetailPrice,
  productType
) => {
  if (!!wholeSalePrice || wholeSalePrice < finalRetailPrice) {
    const isGeneric = productType.toLowerCase().includes("generic");
    const result =
      wholeSalePrice +
      (finalRetailPrice - wholeSalePrice) * (isGeneric ? 0.6 : 0.8);
    return roundByMultiple(result, 0.05).toFixed(2);
  } else return roundByMultiple(finalRetailPrice, 0.05).toFixed(2);
};

export const wholesaleSupplyCalculator = retailPrice => {
  if (!!retailPrice)
    return roundByMultiple(parseFloat(retailPrice) - 0.05, 0.05).toFixed(2);
  else return roundByMultiple(parseFloat(retailPrice) / 0.8, 0.05).toFixed(2);
};

export const wholesaleBulkCalculator = wholesaleSupply =>
  roundByMultiple(wholesaleSupply + 2000, 0.05).toFixed(2) || "N/A";

export const finalRetailPriceCalculator = (retailSource, retailPrice) => {
  if (!!retailSource)
    return roundByMultiple(retailPrice - 0.05, 0.05).toFixed(2);
  return "N/A";
};

//Special round
const roundByMultiple = (value, roundTo) =>
  roundTo * Math.round(value / roundTo);

const notNumberDefault = value => (value === "NaN" ? "N/A" : value);
