import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import classNames from "classnames";
import PropTypes from "prop-types";
const KEY_ENTER = 13;

const FilterField = ({ setSearchString, searchString, placeholder }) => {
  const handleKeyEnter = event => {
    if (event.keyCode === KEY_ENTER) setSearchString(event.target.value);
  };

  return (
    <div className={classNames("field columns")}>
      <div className={classNames("column is-two-thirds")}>
        <section className={classNames("control has-icons-left")}>
          <input
            placeholder={placeholder}
            className={classNames("input")}
            onKeyUp={handleKeyEnter}
          />
          <span className={classNames("icon is-small is-left")}>
            <FontAwesomeIcon icon={faSearch} />
          </span>
        </section>
      </div>
    </div>
  );
};

FilterField.propTypes = {
  setSearchString: PropTypes.func.isRequired,
  searchString: PropTypes.string
};

export default FilterField;
