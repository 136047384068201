import React, { useState, useReducer, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Table, Row } from "UIKit/components/Table_v2/Table";
import TableRowHeader from "./ItemRow/TableRowHeader";
import priceMatch, { getPriceType } from "../../helpers/matchPriceToVatType";
import PurchaseOrderRow from "./ItemRow/PurchaseOrderRow";
import onPasteAction from "Services/onPasteAction";
import { generateMap, generateHeaders } from "../../helpers/jsonInitializers";
import { camelize } from "humps";
import { orderItemFetchReducer } from "../../reducers";
import {
  orderItemFetch,
  DRAFT_PURCHASE_ORDER_ITEMS_ACTIONS,
} from "../../actions";
import "../PurchaseOrder.scss";
import Loading from "UIKit/components/Loading/Loading";

/**
 * The main functionality of this component
 * is to hold the order items and its corresponding mutation.
 *
 * This will return a loading component when the status of fetching is
 * "LOADING" and will return all of the order items when the fetching is done (zero or many).
 *
 * Note: The error component is not yet implemented.
 *        right now, the fallback of error is that it will not return any items.
 *        you can just use the network to debug things out.
 */
const PurchaseOrderMainContent = (props) => {
  // destructure the props.
  // I use this because eslint makes multiple lines for objects
  // hehehe... :D
  const { baseUrl, subUrl, paramsId, objectFetched } = props;

  // set the active row number.
  // much more cleaner that having this.setState
  const [activeRowNumber, setActiveRowNumber] = useState(null);

  const [isPermitted, setPermitted] = useState(false);

  // the key of fetching the order items.
  const [orderItems, dispatch] = useReducer(orderItemFetchReducer, {
    payload: null,
  });

  // the order items
  // I created this supporting state so that the reducer payload
  // will stay immutable.
  // also, changing directly to reducer causes some weird bugs
  // on rendering.
  const [items, setOrderItems] = useState(null);

  // destructuring the orderitems.
  // status of the order items (LOADING, SUCCESS and FAILURE)
  // payload of orderItems is a list of objects
  const { status, payload } = orderItems;

  useEffect(() => {
    async function isUserPermitted() {
      const {
        accessToken: { payload },
      } = await Auth.currentSession();
      if (payload["cognito:groups"])
        payload["cognito:groups"].forEach((group) => {
          if (group.includes("superuser") || group.includes("PO-Moderators"))
            return setPermitted(true);
        });
    }
    isUserPermitted();
  }, []);

  // the heart of this component.
  // only refetch the order items when the paramsId change
  // paramsId === to te order detail's primary key
  // reset the items
  useEffect(() => {
    orderItemFetch(baseUrl, subUrl, paramsId, dispatch);
    setOrderItems(null);
  }, [paramsId, baseUrl, subUrl]);

  // if the payload has the items of order,
  // and the order item state is null,
  // set the payload to orderItemState
  useEffect(() => {
    if (payload && !items) setOrderItems(payload);
  }, [payload, items]);

  // function for editing a value of an item
  // need to set the new value of payload to the orderItems
  // so we set it using dispatch.
  const handleEditRow = (rowNumber, values) => {
    items[rowNumber] = values;
    setOrderItems(items);
    if (rowNumber + 1 === items.length) setActiveRowNumber(null);
  };

  // function for removing an item in the payload
  // need to set the new value of payload to the orderItems
  // so we set it using dispatch.
  const handleRemoveRow = (rowNumber) => {
    if (items) {
      const state = [...items];
      state.splice(rowNumber, 1);
      setOrderItems(state);
      setActiveRowNumber(null);
    }
  };

  // One of my favorite feature! HEHEHE
  // function to integrate the column paste in
  // client request, unit of measure and quantity columns.
  const handleOnColumnPaste = (details, event) => {
    const MAP = generateMap(subUrl);
    details = { ...details, mapper: MAP, [camelize(subUrl)]: paramsId };
    if (items) {
      setOrderItems((prevValue) => {
        const newList = onPasteAction([...prevValue], details, event);
        const length = newList.length;
        setActiveRowNumber(activeRowNumber ? activeRowNumber + length : null);
        return newList;
      });
    }
  };

  // function for adding an item on the last position of the list.
  // need to set the new value of payload to the orderItems
  // so we set it using dispatch.
  const handleAddRow = (values, rowNumber) => {
    if (items) {
      const state = [...items];
      if (typeof rowNumber !== "number") state.push(values);
      else state.splice(rowNumber + 1, 0, values);
      setActiveRowNumber(rowNumber);
      setOrderItems(state);
      setActiveRowNumber(null);
    }
  };

  // supporting function for setActiveRowNumber
  // if the rowNumber is greater than the number of the order items
  // it will reset to null,
  // else it will set the number as the activeRowNumber
  const handleSetActiveRow = (rowNumber) => setActiveRowNumber(rowNumber);

  // function to setValues
  // this is pretty complicated to expalin. :(
  // this code needs refactoring.
  const setFormValues = (searchObj, setValues, values, rowNumber, type) => {
    // const { taxRate, corporateClient } = objectFetched || {};
    // const { priceListCode } = corporateClient || {};
    // const MAP = generateMap(subUrl);
    // const prevValue = payload[rowNumber] || MAP;
    // const recentTitle = values[`${type}Order`].productTitle;
    // const isEqualProductTitle =
    //   prevValue[`${type}Order`].productTitle === recentTitle;
    // if (searchObj && !isEqualProductTitle) {
    //   const unitPrice = priceMatch(taxRate, priceListCode, searchObj);
    //   let { vatexUnitPrice } = getPriceType(searchObj, priceListCode);
    //   vatexUnitPrice = vatexUnitPrice.toFixed(2);
    //   searchObj = { ...searchObj, unitPrice, vatexUnitPrice };
    //   setValues({ ...values, ...{ [`${type}Order`]: searchObj } });
    // } else if (!isEqualProductTitle) {
    //   const blankItem = { ...MAP[`${type}Order`], productTitle: recentTitle };
    //   setValues({ ...values, ...{ [`${type}Order`]: blankItem } });
    // }
  };

  // This will return if the status of the reducer is loading
  // this will also trigger if the payload is falsy
  //  === null or undefined
  if (status === DRAFT_PURCHASE_ORDER_ITEMS_ACTIONS.LOADING || !payload)
    return (
      <div className="container level-center">
        <Loading />
        Please wait. Fetching transaction items...
      </div>
    );

  // this will return if no site is chosen and the quote is not save.
  //
  // Note: users can only add items if the quote is save.
  if (!objectFetched.createdForName || !paramsId) {
    return (
      <div className="has-text-grey-light has-text-centered">
        Please choose and save a valid site before adding an item.
      </div>
    );
  }

  // the main return body.
  // a table of order items.
  // PurchaseOrderRow => the component row which holds the data of an item.
  // the last PurchaseOrderRow Component is for adding items only.
  // it will disappear if you have an active row.

  return (
    <Table>
      <TableRowHeader headerTitles={generateHeaders(subUrl)} />
      {items &&
        items.map((item, index) => {
          return (
            <PurchaseOrderRow
              paramsId={paramsId}
              item={item}
              key={index}
              sequence={index}
              activeRowNumber={activeRowNumber}
              setActiveRow={handleSetActiveRow}
              handleEditRow={handleEditRow}
              setFormValues={setFormValues}
              handleRemoveRow={handleRemoveRow}
              handleOnColumnPaste={handleOnColumnPaste}
              handleInsertRow={handleAddRow}
              subUrl={subUrl}
              baseUrl={baseUrl}
              isUserPermitted={isPermitted}
            />
          );
        })}
      {activeRowNumber === null ? (
        <PurchaseOrderRow
          item={generateMap(subUrl)}
          paramsId={paramsId}
          onSubmit={handleAddRow}
          setFormValues={setFormValues}
          handleOnColumnPaste={handleOnColumnPaste}
          type="ADD"
          subUrl={subUrl}
          baseUrl={baseUrl}
        />
      ) : (
        <Row></Row>
      )}
      <br />
    </Table>
  );
};

export default PurchaseOrderMainContent;
