import React from "react";
import TableHeader from "UIKit/components/Table/TableHeader";
import "../../styles/tables.scss";

const Table = ({ id, headerTitles, children, customStyle }) => (
  <table id={id} className="table is-fullwidth is-hoverable">
    <TableHeader headerTitles={headerTitles} customStyle={customStyle} />
    {children}
  </table>
);

export default Table;
