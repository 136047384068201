import React from "react";
import classNames from "classnames";
import { formatNumber } from "Services/util";
import DRTotalDetails from "Printables/Receipt/DR/DRTotalDetails";
import { Fragment } from "react";
import { NPSScale } from "./DRCustomerFeedback";

const DRBodyRow = ({ item, isChannelValidWithPrice, channel }) => {
  const drug = item.drugDetail || {};

  let productTitle = item.productTitle;
  if (channel === "medensure")
    productTitle = productTitle
      .replace(/[(]\w+\s?\w+[)]/g, "")
      .replace(/\s+/g, " ");

  return (
    <tr
      className={classNames("dr-header-row", {
        "has-text-secondary has-text-weight-bold":
          item.quantity > 0 && productTitle?.includes("Medicine Assistance"),
      })}
    >
      <td className="has-text-centered has-text-right is-size-6" width="3%">
        {item.quantity}
      </td>
      <td className="has-text-right is-size-6" width="15%" />
      <td className="is-size-6" width="35%">
        {productTitle}
      </td>
      <td className="has-text-centered has-text-right is-size-6" width="7%">
        {isChannelValidWithPrice && formatNumber(item.vatExTotal)}
      </td>
      <td className="has-text-centered has-text-right is-size-6" width="7%">
        {isChannelValidWithPrice && formatNumber(item.vatTotal)}
      </td>
    </tr>
  );
};

const DRBody = ({ lineItems, transaction, isChannelValidWithPrice }) => {
  return (
    <Fragment>
      <div className="dr-body-section mt-3">
        <h1 class="order-header has-text-weight-bold is-size-5 mb-1">
          Order Details
        </h1>
        <table className="table is-striped is-fullwidth dr-body is-size-6">
          <thead>
            <tr>
              <th
                className="is-size-6 has-text-centered has-text-primary"
                width="3%"
              >
                Qty
              </th>
              <th
                className="is-size-6 has-text-centered has-text-primary"
                width="15%"
              >
                Batch No./Expiry
              </th>
              <th
                className="is-size-6 has-text-centered has-text-primary"
                width="35%"
              >
                Item Description
              </th>
              <th
                className="is-size-6 has-text-centered has-text-primary"
                width="7%"
              >
                Vat-Ex Total
              </th>
              <th
                className="is-size-6 has-text-centered has-text-primary"
                width="7%"
              >
                VAT Total
              </th>
            </tr>
          </thead>
          <tbody>
            {lineItems.map((item) => (
              <DRBodyRow
                channel={transaction?.channel}
                item={item}
                isChannelValidWithPrice={isChannelValidWithPrice}
              />
            ))}
          </tbody>
        </table>
        <DRTotalDetails
          transaction={transaction}
          lineItems={lineItems}
          isChannelValidWithPrice={isChannelValidWithPrice}
        />
        <div className={classNames("columns customer-signature")}>
          <div className="column">
            <p className="mt-3 is-size-7 has-text-centered">
              I have checked and received these in good condition. I abide by
              MedGrocer’s terms and conditions on the website, along with other
              policies and terms.
            </p>
            <hr className="divider mt-5 mb-1" />
            <p className="is-size-7 has-text-centered">
              Customer Signature and Time Received
            </p>
          </div>
          <div className="column">
            <p className="is-size-7 has-text-centered mt-3 px-3">
              Thank you for letting MedGrocer serve you. Let us know how we can
              do better. On a scale of 1 to 10, how likely are you to recommend
              us to others?
            </p>
            <div className="nps-section mt-5">
              <NPSScale />
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default DRBody;
