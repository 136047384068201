import { roundFloatToTwo } from "Services/util";

// TOP REMINDER: Do not use vat for any computation, except when adding to vatEx to get vatInc
// All functions should return a roundFloatToTwo(result)

// DRBody computations
// const vatable = parseFloat(vat) !== 0.0;
// const vatIncUnitPrice = vatable
//   ? roundFloatToTwo(parseFloat(vatexUnitPrice) * 1.12)
//   : roundFloatToTwo(parseFloat(vatexUnitPrice));
// const vatIncTotal = roundFloatToTwo(vatIncUnitPrice * quantity);
// const vatExTotal = vatable
//   ? roundFloatToTwo(parseFloat(vatIncTotal) / 1.12)
//   : roundFloatToTwo(parseFloat(vatexUnitPrice) * quantity);
// const vatTotal = vatable ? roundFloatToTwo(vatIncTotal - vatExTotal) : 0;

export const getLineItemTotals = (
  transactionItems,
  couponCode,
  isForDRPrinting
) => {
  let lineItemTotals = transactionItems.map((item, index) => {
    let lineItemTotal = {};
    const vatable = parseFloat(item.vat) !== 0.0 && couponCode !== "SCPWD";
    const vatIncUnitPrice = vatable
      ? roundFloatToTwo(parseFloat(item.vatexUnitPrice) * 1.12)
      : roundFloatToTwo(parseFloat(item.vatexUnitPrice));
    const vatIncTotal = roundFloatToTwo(vatIncUnitPrice * item.quantity);
    const vatExTotal = vatable
      ? roundFloatToTwo(parseFloat(vatIncTotal) / 1.12)
      : roundFloatToTwo(parseFloat(item.vatexUnitPrice) * item.quantity);
    const vatTotal = vatable ? roundFloatToTwo(vatIncTotal - vatExTotal) : 0;

    lineItemTotal["vatable"] = vatable;
    lineItemTotal["vatIncUnitPrice"] = vatIncUnitPrice;
    lineItemTotal["vatIncTotal"] = vatIncTotal;
    lineItemTotal["vatExTotal"] = vatExTotal;
    lineItemTotal["vatTotal"] = vatTotal;

    // Accepts a boolean because for DR printing, it needs the original transactionItems object + the calculated totals object
    // Otherwise, we just need the numbers
    if (!!isForDRPrinting) {
      return (transactionItems[index] = Object.assign(
        {},
        transactionItems[index],
        lineItemTotal
      ));
    } else return lineItemTotal;
  });
  return lineItemTotals;
};

export const getVatExSubtotal = (transactionItems, couponCode) => {
  const subtotal = getLineItemTotals(transactionItems, couponCode).reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue.vatExTotal;
    },
    0
  );
  return roundFloatToTwo(subtotal);
};

export const getTotalVat = (transactionItems, couponCode) => {
  const totalVat = getLineItemTotals(transactionItems, couponCode).reduce(
    (accumulator, currentValue) => {
      return accumulator + currentValue.vatTotal;
    },
    0
  );
  return roundFloatToTwo(totalVat);
};

export const getTotal = (
  transactionItems,
  convenienceFee,
  couponCode,
  discountAmount
) => {
  let total =
    getVatExSubtotal(transactionItems, couponCode) +
    convenienceFee +
    getTotalVat(transactionItems, couponCode) -
    discountAmount;

  // Had to use Math.abs() because 0.00 becomes -0.00 when a coupon is used (?)
  return roundFloatToTwo(Math.abs(total));
};

export const getVatableSales = (transactionItems, couponCode) => {
  if (couponCode === "SCPWD") {
    return roundFloatToTwo(0.0);
  } else {
    const subtotal = getLineItemTotals(transactionItems, couponCode).reduce(
      (accumulator, currentValue) => {
        return accumulator + currentValue.vatExTotal;
      },
      0
    );
    return roundFloatToTwo(subtotal);
  }
};

export const getVatExemptSales = (
  transactionItems,
  convenienceFee,
  couponCode,
  discountAmount
) => {
  let subtotal = getLineItemTotals(transactionItems, couponCode).reduce(
    (accumulator, currentValue) => {
      // If line item is vatEx (vat = 0), it merely gets the vatEx subtotal
      // Otherwise, it means vat != 0 ~> line item is vatInc. It doesn't count as vatEx sales- it should go to vatable sales
      // UNLESS customer uses coupon SCPWD. In which case, vatInc line items also count as vatEx sales (hence dividing them by 1.12)
      // SCPWD discountAmount is subtracted because they also have a 20% discount on vatEx sales
      // Final step is adding convenience fee (also vatEx sales accdg to Fin)
      if (!!currentValue.vatable) {
        return accumulator;
      } else {
        return accumulator + currentValue.vatExTotal;
      }
    },
    0
  );
  if (couponCode === "SCPWD") subtotal -= parseFloat(discountAmount);
  return roundFloatToTwo(subtotal + parseFloat(convenienceFee));
};
