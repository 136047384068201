import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown, faCaretUp } from "@fortawesome/free-solid-svg-icons";

import Loading from "UIKit/components/Loading/Loading";
import DataRow from "Dashboard/components/DataTable/DataRow";

const propTypes = {
  onSort: PropTypes.func.isRequired,
  selectRows: PropTypes.func.isRequired,
  deselectRows: PropTypes.func.isRequired,
  visibleData: PropTypes.array.isRequired,
  idAttribute: PropTypes.string.isRequired,
  isFetchingData: PropTypes.bool.isRequired,
  RowComponent: PropTypes.func.isRequired,
  selectedData: PropTypes.arrayOf(PropTypes.string).isRequired,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      displayName: PropTypes.string.isRequired,
      attributeName: PropTypes.string.isRequired,
      canSortBy: PropTypes.bool.isRequired
    }).isRequired
  ).isRequired,
  sortBy: PropTypes.string.isRequired,
  isSortAscending: PropTypes.bool.isRequired,
  isCheckboxEnabled: PropTypes.bool.isRequired,
  dashboardClassname: PropTypes.string
};

class DataTable extends Component {
  handleHeaderClick = canSortBy => event => {
    if (canSortBy) {
      event.preventDefault();
      const headerId = event.target.id;
      this.props.onSort(headerId);
    }
  };

  onSelectRow = id => {
    this.props.selectRows([id]);
  };

  onDeselectRow = id => {
    this.props.deselectRows([id]);
  };

  handleSelectAll = event => {
    const { visibleData, idAttribute } = this.props;
    const checked = event.target.checked;
    const allIds = visibleData.map(data => {
      return data[idAttribute];
    });
    if (checked) {
      this.props.selectRows(allIds);
    } else {
      this.props.deselectRows(allIds);
    }
  };

  renderRows = () => {
    const {
      isFetchingData,
      visibleData,
      idAttribute,
      RowComponent,
      selectedData,
      hasError
    } = this.props;
    if (isFetchingData) {
      return (
        <div className="p-4">
          <Loading />
        </div>
      );
    } else if (hasError) {
      return (
        <div className="has-text-centered mt-2 has-text-danger">
          Error, page not found.
        </div>
      );
    } else if (visibleData) {
      return (
        <tbody className="dashboard">
          {visibleData.map(data => {
            return (
              <DataRow
                callbackRowFunction={this.props.callbackRowFunction}
                key={data[idAttribute]}
                id={data[idAttribute]}
                onSelectRow={this.onSelectRow}
                onDeselectRow={this.onDeselectRow}
                isSelected={selectedData.includes(data[idAttribute])}
                isCheckboxEnabled={this.props.isCheckboxEnabled}
              >
                <RowComponent data={data} />
              </DataRow>
            );
          })}
        </tbody>
      );
    } else {
      return (
        <tbody className="dashboard">
          <tr>
            <td
              colSpan={this.props.headers.length}
              className="has-text-centered"
            >
              Nothing to show here.
            </td>
          </tr>
        </tbody>
      );
    }
  };

  render() {
    return (
      <Fragment>
        <table
          className={`table is-hoverable is-fullwidth is-dashboard-table ${this.props.dashboardClassname}`}
        >
          <thead>
            <tr>
              {this.props.isCheckboxEnabled ? (
                <th>
                  <label className="checkbox-field">
                    <input type="checkbox" onChange={this.handleSelectAll} />
                    <span className="checkmark" />
                  </label>
                </th>
              ) : (
                ""
              )}
              {this.props.headers.map(header => (
                <th
                  className="has-text-centered table-header"
                  key={header.attributeName}
                  id={header.attributeName}
                  onClick={this.handleHeaderClick(header.canSortBy)}
                >
                  {header.displayName}{" "}
                  {header.canSortBy &&
                  header.attributeName === this.props.sortBy ? (
                    <FontAwesomeIcon
                      icon={
                        this.props.isSortAscending ? faCaretDown : faCaretUp
                      }
                      color="teal"
                    />
                  ) : (
                    ""
                  )}
                </th>
              ))}
            </tr>
          </thead>
          {this.renderRows()}
        </table>
      </Fragment>
    );
  }
}

DataTable.propTypes = propTypes;

export default DataTable;
