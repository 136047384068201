import React, { useState, useEffect } from "react";
import { useQuery } from "@apollo/react-hooks";
import queryString from "query-string";
import { getSourcingCounts } from "./Schema";

import SourcingBody from "Sourcing/components/SourcingBody";
import PageContent from "UIKit/components/PageContent";
import Tabs from "UIKit/components/Tabs";
import { TABS } from "./components/jsonInitializers";

const Sourcing = ({ location, history }) => {
  const { loading, data, error } = useQuery(getSourcingCounts);
  const [query, setQuery] = useState(queryString.parse(location.search));
  const [activeTab, setActiveTab] = useState(query.status || "SOURCING");

  useEffect(() => {
    setQuery(queryString.parse(location.search));
  }, [activeTab, location]);

  const handleTabClick = event => {
    event.preventDefault();
    const target = event.currentTarget.getAttribute("name");
    setActiveTab(target.toUpperCase());
    history.push(`/sourcing?status=${target.toUpperCase()}`);
  };

  const generateHeaders = () => {
    if (loading || error) return TABS;
    return Object.values(data).map(({ data }, i) =>
      ({ ...TABS[i], count: data.count }));
  };

  return (
    <PageContent>
      <Tabs
        tabs={generateHeaders()}
        activeTab={activeTab}
        onClick={handleTabClick}
      />
      <SourcingBody queryString={query} pushHistory={history.push} />
    </PageContent>
  );
};

export default Sourcing;
