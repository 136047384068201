import React, { Component } from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import { pushNotification } from "UIKit/actions";
import styles from "UIKit/styles/Notification.module.scss";

class Notification extends Component {
  render() {
    return this.props.notification ? (
      <div className={styles.notification}>
        <div
          className={classNames(
            `notification p-2 ${this.props.notification.type}`,
            styles.notification__toast
          )}
        >
          <div
            dangerouslySetInnerHTML={{
              __html: this.props.notification.message
            }}
          />
        </div>
      </div>
    ) : null;
  }
}

const mapStateToProps = state => ({
  notification: state.notification
});

export default connect(
  mapStateToProps,
  { pushNotification }
)(Notification);
