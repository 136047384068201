import React, { Fragment } from "react";

const drTermsData = [
  {
    id: 1,
    list:
      "All products sold on MedGrocer are subject to quality checks by our purchasing, storage, assembly, and delivery teams.",
  },
  {
    id: 2,
    list:
      "Please always inspect your medicines upon delivery. If your medicines are damaged upon delivery, you may refuse to accept the medicines.",
  },
  {
    id: 3,
    list:
      "MedGrocer will only replace damaged or wrongly dispensed medicines. Please let us know within seven days after delivery so that we can process the return. Any problems encountered seven days after the delivery may not be accommodated under our return policy.",
  },
  {
    id: 4,
    list: "All terms and conditions apply.",
  },
];

const DRTermsRow = ({ id, list }) => (
  <tr>
    <td className="is-narrow has-text-right is-size-7">{id}</td>
    <td className="is-size-7">{list}</td>
  </tr>
);

const DRTerms = () => (
  <Fragment>
    <div className="dr-term-section">
      <h1 className="terms-header is-size-7 has-text-weight-bold">
        Terms and Conditions
      </h1>
      <div>
        {drTermsData.map((term, index) => (
          <p className="help">
            {index + 1}. {term.list}
          </p>
        ))}
      </div>
    </div>
  </Fragment>
);

export default DRTerms;
