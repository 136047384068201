import React, { Fragment } from "react";
import Notifier from "UIKit/components/CircleNotifier/CircleNotifier";
import { Row, Cell as Td } from "UIKit/components/Table_v2/Table";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import PropTypes from "prop-types";
import Loading from "UIKit/components/Loading/Loading";

/**
 * @prop {object} objectFetched
 * @prop {string} status
 * @prop {number} sequence
 */
export const ColumnValidator = ({ objectFetched, status, sequence }) => {
  const { productTitle, product } = objectFetched.requestedOrder;

  if (status === "ORDER_ITEM_MUTATION_LOADING")
    return (
      <FontAwesomeIcon
        icon={faCircleNotch}
        spin={true}
        className="has-text-primary"
      />
    );
  else if (!productTitle)
    return (
      <Notifier type="has-text-danger" message="Requested product is n/a." />
    );
  else if (!product)
    return (
      <Notifier
        name="warning"
        type="has-text-warning"
        message="Requested Product is not in product list."
      />
    );
  else return sequence + 1;
};

const ReadOnlyRow = ({ item, sequence, status, onDelete }) => {
  const { recommendedOrder, requestedOrder, quantity } = item;
  const isLoading = status === "ORDER_ITEM_MUTATION_LOADING";

  return (
    <Row>
      <Td>
        <ColumnValidator
          objectFetched={item}
          status={status}
          sequence={sequence}
        />
      </Td>
      <Td priority>{item["clientRequest"]}</Td>
      <Td priority>{item["requestedOrder"]["productTitle"] || "N/A"}</Td>
      <Td className="has-text-centered">{item["quantity"]}</Td>
      <Td className="has-text-right">
        {isLoading ? (
          <Loading size="loader--is-small" />
        ) : (
          item["requestedOrder"]["unitPrice"] || (0).toFixed(2)
        )}
      </Td>
      <Td className="has-text-right">
        {isLoading ? (
          <Loading size="loader--is-small" />
        ) : (
          (requestedOrder.unitPrice * quantity).toFixed(2)
        )}
      </Td>
      <Td priority>{item["remarks"] || "N/A"}</Td>

      {/* {recommendedOrder && (
        <Fragment>
          <Td priority>
            {(recommendedOrder && recommendedOrder["productTitle"]) || "N/A"}
          </Td>
          <Td className="has-text-right">
            {(recommendedOrder && recommendedOrder["unitPrice"]) ||
              (0).toFixed(2)}
          </Td>
          <Td className="has-text-right">
            {(recommendedOrder.unitPrice * quantity).toFixed(2)}
          </Td>
        </Fragment>
      )} */}
      <Td>
        {!isLoading && (
          <button
            className="button hover-delete is-size-7"
            type="button"
            onClick={() => onDelete()}
          >
            <FontAwesomeIcon icon={faTrash} />
          </button>
        )}
      </Td>
    </Row>
  );
};

ReadOnlyRow.propTypes = {
  item: PropTypes.object,
  sequence: PropTypes.number,
  status: PropTypes.string,
  onDelete: PropTypes.func,
};

export default ReadOnlyRow;
