import React, { useReducer, useEffect } from "react";
import { Form, Formik } from "formik";
import { camelize } from "humps";
import PropTypes from "prop-types";
import { isEqual } from "lodash";
import { MenuProvider, Menu, Item } from "react-contexify";
import { orderItemMutation, ORDER_ITEM_MUTATION } from "../../../actions";
import { orderItemMutationReducer } from "../../../reducers";
import EditableRow from "./EditableRow";
import ReadOnlyRow from "./ReadOnlyRow";
import "../../PurchaseOrder.scss";
import "react-contexify/dist/ReactContexify.min.css";
import {
  DPO_ITEM_MAP,
  PO_ITEM_MAP,
} from "PurchaseOrder/helpers/jsonInitializers";

export function PurchaseOrderRow(props) {
  const isDraftPO = props.baseUrl === "/draft-purchase-orders";
  const { LOADING } = ORDER_ITEM_MUTATION;
  const [state, dispatch] = useReducer(orderItemMutationReducer, {
    payload: props.item,
  });

  /**
   * Triggers if props.item changes.
   * This will change the `state.payload` if the `props.item` is not equal to the
   * `previous state.payload`.
   */
  /* eslint-disable */

  useEffect(() => {
    if (!isEqual(state.payload, props.item) && state.status !== LOADING)
      orderItemMutation(props.baseUrl, props.item, "SET", dispatch);

    if (props.sequence === undefined && isDraftPO)
      orderItemMutation("", DPO_ITEM_MAP, "SET", dispatch);
    else if (props.sequence === undefined && !isDraftPO)
      orderItemMutation("", PO_ITEM_MAP, "SET", dispatch);
  }, [props.item]);

  /**
   * Triggers when state.payload changes.
   * case 1: when adding an item.
   *        Triggers when `state.payload.id` is `falsy (null or undefined)`
   * case 2: when inserting/duplicating and item.
   *        Triggers when update on `state.payload.id` (similar to case 1),
   *        but uses `I don't know cycle`. HEHEHE
   */
  /* eslint-disable */

  useEffect(() => {
    const { type, paramsId, subUrl, sequence } = props;
    const { payload, status } = state;

    if (payload.id) {
      props.handleEditRow(sequence, payload);
      if (payload.event === "PASTE")
        orderItemMutation(props.baseUrl, payload, "EDIT", dispatch);
    }

    const item = { ...payload, [camelize(subUrl)]: paramsId };
    item.sequenceNumber = state.payload.sequenceNumber;
    if (!payload.id && status !== LOADING && paramsId && type !== "ADD")
      orderItemMutation(props.baseUrl, item, "ADD", dispatch);
  }, [state.payload]);

  const handleEditNode = (values) => {
    if (values.id) orderItemMutation(props.baseUrl, values, "EDIT", dispatch);
    props.handleEditRow(props.sequence, values);
    props.setActiveRow(1 + props.sequence);
  };

  const handleDeleteNode = async () => {
    if (state.payload.id >= 0)
      await orderItemMutation(props.baseUrl, state.payload, "DELETE", dispatch);
    props.handleRemoveRow(props.sequence);
  };

  const handleOnPaste = (event) => {
    event.preventDefault();
    props.handleOnColumnPaste(
      { name: event.target.name, index: props.sequence },
      event
    );
  };

  const handleInsertRow = (values = DPO_ITEM_MAP) => {
    let item = { ...values };
    delete item.id;
    delete item.recommendedOrder.id;
    delete item.requestedOrder.id;
    item.sequenceNumber = state.payload.sequenceNumber;
    props.handleInsertRow(item, props.sequence);
  };

  return (
    <Formik
      initialValues={state.payload}
      onSubmit={props.onSubmit || handleEditNode}
      enableReinitialize
    >
      {({ values, setValues }) => (
        <MenuProvider id={`context${props.sequence}`} component="span">
          <Form
            onDoubleClick={() => props.setActiveRow(props.sequence)}
            autoComplete="off"
          >
            {props.activeRowNumber === props.sequence ? (
              <EditableRow
                sequence={props.sequence}
                values={values}
                setValues={setValues}
                setFormValues={props.setFormValues}
                onPaste={handleOnPaste}
                isUserPermitted={props.isUserPermitted}
              />
            ) : (
              <ReadOnlyRow
                item={values}
                sequence={props.sequence}
                status={state.status}
                onDelete={handleDeleteNode}
              />
            )}
            <input type="submit" style={{ display: "none" }} />
          </Form>
          <Menu id={`context${props.sequence}`}>
            <Item onClick={() => handleInsertRow()}>Insert Row</Item>
            <Item onClick={() => handleInsertRow(values)}>Duplicate Row</Item>
          </Menu>
        </MenuProvider>
      )}
    </Formik>
  );
}

PurchaseOrderRow.propTypes = {
  item: PropTypes.object,
  sequence: PropTypes.number,
  paramsId: PropTypes.number,
  subUrl: PropTypes.string,
  onSubmit: PropTypes.func,
  activeRowNumber: PropTypes.number,
  setFormValues: PropTypes.func,
  setActiveRow: PropTypes.func,
  handleEditRow: PropTypes.func,
  handleOnColumnPaste: PropTypes.func,
  handleRemoveRow: PropTypes.func,
  baseUrl: PropTypes.string,
  handleInsertRow: PropTypes.func,
};
export default PurchaseOrderRow;
