import React from 'react';
import PageContent from 'UIKit/components/PageContent';

/*
 *  To-do: 
 *  Refactor/Desconstruct code into components 
 * 
 */

const Coupons = () => {
  return(
    <PageContent>
      <p>Landing Page</p>
    </PageContent>
  );
};

export default Coupons;