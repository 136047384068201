import React from "react";
import FieldContainer from "UIKit/components/SplitContainer/FieldContainer";
import { Field } from "formik";
import { aquisitionUnitPriceComputation } from "./helpers";

const handleOnChangeCompute = (handleChange, values, type) => event => {
  aquisitionUnitPriceComputation(values, type);
  handleChange(event);
};

const SourcingBody = props => (
  <table className="table is-fullwidth is-hoverable">
    <thead>
      <th />
      <th className="has-text-centered">Source</th>
      <th className="has-text-centered">VAT-Inc Price</th>
    </thead>
    <tbody>
      <tr>
        <td className="has-text-weight-bold">Wholesale</td>
        <td className="has-text-centered has-text-weight-bold">
          <FieldContainer>
            <div className="control">
              <Field name="wholesaleSource" className="input" />
            </div>
          </FieldContainer>
        </td>
        <td>
          <FieldContainer>
            <div className="control">
              <Field
                name="acquisitionWholesaleVatincUnitPrice"
                className="input has-text-right"
                type="number"
                step=".01"
                required
                defaultValue={parseFloat(0.0)}
                onChange={handleOnChangeCompute(
                  props.handleChange,
                  props.values
                )}
              />
            </div>
          </FieldContainer>
        </td>
      </tr>
      <tr>
        <td className="has-text-weight-bold">Retail</td>
        <td className=" has-text-weight-bold">
          <FieldContainer>
            <div className="control">
              <Field name="retailSource" className="input" />
            </div>
          </FieldContainer>
        </td>
        <td>
          <FieldContainer>
            <div className="control">
              <Field
                name="acquisitionRetailVatincUnitPrice"
                className="input has-text-right"
                type="number"
                step=".01"
                required
                defaultValue={parseFloat(0.0)}
                onChange={handleOnChangeCompute(
                  props.handleChange,
                  props.values
                )}
              />
            </div>
          </FieldContainer>
        </td>
      </tr>
    </tbody>
  </table>
);

export default SourcingBody;
