import React from "react";
import DateTimePicker from "react-datetime-picker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import "../../styles/forms.scss";

const LineTextInput = ({
  id,
  name,
  type,
  placeholder,
  defaultValue,
  label,
  labelStyle,
  inputStyle,
  onChange,
  isReadOnly,
  value,
  disabled,
  helpClass,
  helpText,
  required
}) => (
  <div className="field is-grouped">
    {label ? (
      <div className="field-label is-normal">
        <label className={`label ${labelStyle || ""}`}>{label}</label>
      </div>
    ) : null}
    <div className="field-body">
      <div className="field">
        <div className="control">
          <input
            id={id}
            name={name}
            className={`input ${inputStyle}`}
            type={type}
            placeholder={placeholder}
            defaultValue={defaultValue}
            onChange={onChange}
            readOnly={isReadOnly ? true : false}
            value={value}
            disabled={disabled}
            required={required}
          />
        </div>
        {helpText ? <p className={`help ${helpClass}`}>{helpText}</p> : ""}
      </div>
    </div>
  </div>
);

const FormField = ({
  id,
  name,
  type,
  hasIcons,
  icon,
  label,
  labelStyle,
  placeholder,
  onChange,
  onBlur,
  value,
  isHidden,
  defaultValue,
  errorMessage,
  showError,
  pattern,
  required,
  field,
  form
}) => (
  <div className={`field is-fullwidth ${isHidden ? "is-hidden" : ""}`}>
    {label ? (
      <label className={`${required ? "label" : ""} ${labelStyle}`}>
        {label} {required ? <span className="is-danger">*</span> : ""}
      </label>
    ) : null}
    <p className={`control ${hasIcons ? "has-icons-left" : ""}`}>
      <input
        id={id}
        name={name}
        className="input"
        type={type}
        placeholder={placeholder}
        pattern={pattern}
        onChange={onChange}
        onBlur={onBlur}
        value={value}
        defaultValue={defaultValue}
        {...field}
      />
      {hasIcons ? (
        <span className="icon is-small is-left">
          <FontAwesomeIcon icon={icon} />
        </span>
      ) : (
        ""
      )}
      {showError ? <span className="help is-danger">{errorMessage}</span> : ""}
    </p>
  </div>
);

const FormInput = ({
  id,
  name,
  type,
  placeholder,
  defaultValue,
  label,
  labelStyle,
  inputStyle,
  onChange,
  onBlur,
  isReadOnly,
  value,
  disabled,
  help,
  field,
  form
}) => (
  <div className="field is-grouped">
    {label ? (
      <div className="field-label is-normal">
        <label className={`label ${labelStyle || ""}`}>{label}</label>
      </div>
    ) : null}
    <div className="field-body control">
      <input
        id={id}
        name={name}
        className={`input ${inputStyle}`}
        type={type}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={isReadOnly ? true : false}
        value={value}
        disabled={disabled}
        {...field}
      />
      {help ? <p className={`help ${help.class}`}>{help.text}</p> : ""}
    </div>
  </div>
);

const TextArea = ({
  label,
  labelStyle,
  id,
  name,
  onChange,
  isReadOnly,
  value
}) => (
  <div className="field is-grouped">
    {label ? (
      <div className="field-label is-normal">
        <label className={`label ${labelStyle}`}>{label}</label>
      </div>
    ) : null}
    <p className="field-body control is-expanded">
      <textarea
        id={id}
        name={name}
        className="textarea"
        onChange={onChange}
        readOnly={isReadOnly ? true : false}
        value={value}
      />
    </p>
  </div>
);

const DateField = ({ label, labelStyle, value, onChange }) => (
  <div className="field is-grouped">
    {label ? (
      <div className="field-label is-normal">
        <label className={`label ${labelStyle}`}>{label}</label>
      </div>
    ) : null}
    <div className="field-body control is-expanded">
      <DateTimePicker value={value} onChange={onChange} />
    </div>
  </div>
);

const Dropdown = ({
  label,
  labelStyle,
  onClick,
  options,
  id,
  selected,
  onChange
}) => {
  let onChangeObject = {};
  if (onChange) onChangeObject = { ...onChangeObject, onChange };
  return (
    <div className="field is-grouped">
      {label ? (
        <div className="field-label is-normal">
          <label className={`label ${labelStyle}`}>{label}</label>
        </div>
      ) : null}
      <div className="field-body control is-expanded">
        <div className="select">
          <select
            onClick={onClick}
            id={id}
            value={selected}
            {...onChangeObject}
          >
            {options.map((option, i) => (
              <option key={i} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      </div>
    </div>
  );
};

const CheckBox = ({ mainLabel, id, name, onChange, label, isChecked }) => (
  <div className="field is-grouped">
    {mainLabel ? <label className="label field-label">{mainLabel}</label> : ""}
    <div className="field-body control is-expanded">
      <label className="checkbox">
        <input
          type="checkbox"
          id={id}
          name={name}
          checked={isChecked}
          onChange={onChange}
        />
        &nbsp;
        {label}
      </label>
    </div>
  </div>
);

const Tags = ({ tags, label, labelStyle, onClick, tagStyle }) => (
  <div className="field is-grouped">
    {label ? (
      <div className="field-label is-normal">
        <label className={`label ${labelStyle}`}>{label}</label>
      </div>
    ) : null}
    <div className="field-body control is-expanded">
      <div className="tags">
        {tags.map((tag, i) => (
          <span key={i} className={`tag ${tagStyle}`}>
            {tag}
            <button id={i} className="delete" onClick={onClick} />
          </span>
        ))}
      </div>
    </div>
  </div>
);

const SearchBar = ({
  id,
  name,
  type,
  placeholder,
  defaultValue,
  label,
  labelStyle,
  inputStyle,
  onChange,
  onBlur,
  isReadOnly,
  value,
  onKeyDown,
  withIcon,
  field,
  form
}) => (
  <div className=" is-grouped">
    {label ? (
      <div className="field-label is-normal">
        <label className={`label ${labelStyle}`}>{label}</label>
      </div>
    ) : null}
    <p
      className={`field-body ${
        withIcon ? "has-icons-left" : null
      } control is-expanded`}
    >
      <input
        id={id}
        name={name}
        className={`input ${inputStyle}`}
        type={type}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChange}
        onBlur={onBlur}
        readOnly={isReadOnly ? true : false}
        value={value}
        onKeyDown={onKeyDown}
        {...field}
      />
      {withIcon ? (
        <span className="icon is-small is-left">
          <FontAwesomeIcon icon={faSearch} />
        </span>
      ) : (
        ""
      )}
    </p>
  </div>
);

export {
  LineTextInput,
  FormInput,
  FormField,
  TextArea,
  DateField,
  Dropdown,
  CheckBox,
  Tags,
  SearchBar
};
