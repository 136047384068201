import React from "react";
import { Formik, Form, Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
  faSitemap,
  faMapMarkerAlt,
  faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";
import AddressGenerator from "./AddressGenerator";
import SearchField from "UIKit/components/SearchInput/FormikSearchField";
import PurchaseOrderEditAddressModal from "./PurchaseOrderEditAddressModal";
import PropTypes from "prop-types";
import { objectMapper } from "../../helpers/jsonInitializers";
import "../PurchaseOrder.scss";

const ENTER = 13;

export const LabelField = ({ icon, label, children }) => (
  <div className="field mt-3">
    <label className="label has-text-weight-normal">
      <span className="icon mr-1">
        <FontAwesomeIcon icon={icon} />
      </span>
      <span>{label}</span>
    </label>
    <div className="control">{children}</div>
  </div>
);

export const getEmptyAddressFields = (type, objectFetched) => {
  const addressKeys = ["StreetAddress", "CityMunicipality", "Province"];
  const array = [];
  addressKeys.map(field => {
    if (!!!objectFetched[`${type}${field}`])
      array.push(
        field
          .replace(/StreetA/, "street a")
          .replace(/CityM/, "city/m")
          .toLowerCase()
      );
    return null;
  });
  return array;
};

export const renderTooltipMessage = array => {
  const fields = array.join(", ");
  return "Incomplete " + fields + " field(s)";
};

const PurchaseOrderSidebarForm = ({
  objectFetched,
  handleOnSave,
  handleChangeObjectFetched,
  statusList,
  editableSite,
  formId,
  handleOnChangeStatus = true,
  editableAddress = true
}) => {
  // TODO: Test the setFormValues function
  const setFormValues = (searchedObject, setter) => {
    if (searchedObject) {
      objectFetched.createdFor.site = searchedObject.id;
      objectFetched.createdForName = searchedObject.name;
      delete searchedObject.id;
      const result = { ...objectMapper(objectFetched, searchedObject) };
      setter(result);
      handleChangeObjectFetched(result);
    }
  };

  const emptyBillingFields = getEmptyAddressFields("billing", objectFetched);
  const emptyShippingFields = getEmptyAddressFields("shipping", objectFetched);
  return (
    <div>
      <Formik
        initialValues={objectFetched}
        onSubmit={handleOnSave}
        enableReinitialize
      >
        {({ values, setValues, handleChange }) => (
          <Form autoComplete="off" id={formId || "dpo-form"}>
            <div className="p-2">
              {objectFetched.createdForName && (
                <label>
                  Status:
                  <div className="field has-addons">
                    <div className="control is-expanded">
                      <div className="select is-fullwidth">
                        <Field
                          component="select"
                          name="status"
                          onChange={event => {
                            let { value } = event.target;
                            if (handleOnChangeStatus)
                              handleOnSave({ ...values, status: value });
                            handleChange(event);
                          }}
                        >
                          {statusList &&
                            statusList.map(status => {
                              return (
                                <option value={status.name} key={status.name}>
                                  {status.name}
                                </option>
                              );
                            })}
                        </Field>
                      </div>
                    </div>
                  </div>
                </label>
              )}

              <LabelField icon={faUsers} label="Site/Trade Name">
                {objectFetched.createdForName && !editableSite ? (
                  <span
                    id="site-name"
                    className="has-text-primary has-text-weight-bold"
                  >
                    {values.createdForName}
                  </span>
                ) : (
                  <SearchField
                    baseSearch="sites/search?"
                    keyElement="name"
                    keyValue="id"
                    functionToInvoke={searchedObject => event => {
                      if (event.keyCode === ENTER) event.preventDefault();
                      setFormValues(searchedObject, setValues);
                    }}
                    name="createdForName"
                    defaultValue={values.createdForName}
                  />
                )}
              </LabelField>
              <LabelField icon={faSitemap} label="Corporate Name">
                <span
                  id="corporate-name"
                  className="has-text-primary has-text-weight-bold"
                >
                  {values.corporateClient && values.corporateClient.name}
                </span>
              </LabelField>
              <LabelField icon={faMapMarkerAlt} label="Billing Address">
                <AddressGenerator type="billing" values={values} />
                {!!objectFetched.createdForName &&
                  emptyBillingFields.length > 0 && (
                    <span
                      className="dpo tooltip is-tooltip-top"
                      data-tooltip={renderTooltipMessage(emptyBillingFields)}
                    >
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="has-text-danger"
                      />
                    </span>
                  )}
              </LabelField>
              <LabelField icon={faMapMarkerAlt} label="Shipping Address">
                <AddressGenerator type="shipping" values={values} />
                {!!objectFetched.createdForName &&
                  emptyShippingFields.length > 0 && (
                    <span
                      className="dpo tooltip is-tooltip-top"
                      data-tooltip={renderTooltipMessage(emptyShippingFields)}
                    >
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="has-text-danger"
                      />
                    </span>
                  )}
              </LabelField>
            </div>
          </Form>
        )}
      </Formik>
      {editableAddress && (
        <PurchaseOrderEditAddressModal
          objectFetched={objectFetched}
          handleOnSave={handleOnSave}
        />
      )}
    </div>
  );
};

PurchaseOrderSidebarForm.propTypes = {
  objectFetched: PropTypes.object,
  handleOnSave: PropTypes.func,
  handleChangeObjectFetched: PropTypes.func
};

LabelField.propTypes = {
  icon: PropTypes.object,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  children: PropTypes.node
};

export default PurchaseOrderSidebarForm;
