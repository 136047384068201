export const requestedOrderHeaders = [
  { title: "No.", sequence: true, className: "has-text-centered" },
  {
    title: "Client Request",
    priority: true,
    key: "clientRequest",
  },
  {
    title: "Requested item",
    priority: true,
    key: "requestedOrder",
    subKey: "productTitle",
  },
  {
    title: "Remarks",
    priority: true,
    key: "remarks",
  },
  { title: "UoM", key: "unitOfMeasure", className: "has-text-centered" },
  { title: "Qty", key: "quantity", className: "has-text-centered" },
  {
    title: "Unit Price",
    key: "requestedOrder",
    subKey: "unitPrice",
    className: "has-text-right",
  },
  {
    title: "VAT Ex Unit Price",
    key: "requestedOrder",
    subKey: "vatexUnitPrice",
    className: "has-text-right",
  },
  {
    title: "VAT",
    className: "has-text-right",
    operation: "subtraction",
    listToOperate: [
      { key: "requestedOrder", subKey: "vatexUnitPrice" },
      { key: "requestedOrder", subKey: "unitPrice" },
    ],
  },
  {
    title: "Total",
    className: "has-text-right",
    listToOperate: [
      { key: "requestedOrder", subKey: "unitPrice" },
      { key: "quantity" },
    ],
  },
];

export const recommendedOrderHeaders = [
  { title: "No.", sequence: true },
  {
    title: "Client Request",
    priority: true,
    key: "clientRequest",
  },
  {
    title: "Recommended item",
    priority: true,
    key: "recommendedOrder",
    subKey: "productTitle",
  },
  { title: "UoM", key: "unitOfMeasure" },
  { title: "Qty", key: "quantity", className: "has-text-right" },
  {
    title: "Unit Price",
    key: "recommendedOrder",
    subKey: "unitPrice",
    className: "has-text-right",
  },
  {
    title: "VAT-Ex Total",
    key: "recommendedOrder",
    subKey: "vatexUnitPrice",
    className: "has-text-right",
  },
  {
    title: "VAT-Inc Total",
    className: "has-text-right",
    operation: "subtraction",
    listToOperate: [
      { key: "recommendedOrder", subKey: "vatexUnitPrice" },
      { key: "recommendedOrder", subKey: "unitPrice" },
    ],
  },
  {
    title: "Total",
    className: "has-text-right",
    listToOperate: [
      { key: "recommendedOrder", subKey: "unitPrice" },
      { key: "quantity" },
    ],
  },
];
