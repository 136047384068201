import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik, Form } from "formik";
import { PanelContent } from "UIKit/components/SplitContainer/SplitContainer";
import "../../../Client.scss";
import SiteDetailSection from "./SiteDetails";
import SiteAddressSection from "./SiteAddressDetails";
import SiteFinanceSection from "./SiteFinanceDetails";
import { blankSiteFieldObject, siteValidationSchema } from "../helpers";
import api from "Services/api";

class ClientSiteForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  handleHasError = (message, boolean) => {
    this.setState((previousState) => ({
      hasError: boolean,
      errorMessage: message,
    }));
  };

  handleVerifySiteLetters = (values, handleChange) => (event) => {
    const value = (event.target.value = event.target.value.toUpperCase());
    if (value.length >= 3) {
      const siteLetters = value.slice(0, 2);
      const siteNumber = value.slice(2);

      values.siteLetters = siteLetters;
      values.siteNumber = siteNumber;

      if (value && this.props.pageType === "EDIT_PAGE") {
        api
          .post("check-site-number/", {
            siteLetters: siteLetters,
            siteNumber: siteNumber,
            entityNumber: this.props.clientDetail.entityNumber || "",
            companyLetters: this.props.clientDetail.companyLetters || "",
            companyName: this.props.clientDetail.name || "",
          })
          .then((response) => {
            if (response.data.status === "Already Exists") {
              if (this.state.originalSiteLetters !== event.target.value) {
                this.handleHasError(response.data.message, true);
              }
            } else this.handleHasError(response.data.message, false);
          })
          .catch((error) => console.log(error));
      }
    }

    handleChange(event);
  };
  render() {
    const formInitialValues =
      this.props.clientId > -1
        ? {
            ...this.props.siteDetail,
            siteCode:
              this.props.siteDetail.siteLetters +
              this.props.siteDetail.siteNumber,
            shippingAsBilling: false,
          }
        : blankSiteFieldObject;

    return (
      <PanelContent>
        <Formik
          initialValues={formInitialValues}
          onSubmit={this.props.handleRedirection}
          validationSchema={siteValidationSchema}
          enableReinitialize
        >
          {({
            values,
            handleChange,
            setFieldError,
            setFieldTouched,
            errors,
          }) => {
            return (
              <Form id="form-client-info" autoComplete="off">
                <div className="columns">
                  <div className="column is-three-fifths">
                    <SiteDetailSection
                      values={values}
                      handleChange={handleChange}
                      setFieldError={setFieldError}
                      setFieldTouched={setFieldTouched}
                      hasDuplicateError={this.state.hasError}
                      duplicateErrorMessage={this.state.errorMessage}
                      handleVerifySiteLetters={this.handleVerifySiteLetters}
                      errors={errors}
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-three-fifths">
                    <SiteAddressSection values={values} />
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-three-fifths">
                    <SiteFinanceSection />
                  </div>
                </div>

                <div className="columns">
                  <div className="column is-three-fifths ">
                    <PanelContent>
                      <button
                        name="saveSiteInformation"
                        className="button is-primary"
                        type="submit"
                        disabled={this.state.hasError}
                      >
                        Save Information
                      </button>
                    </PanelContent>
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </PanelContent>
    );
  }
}

ClientSiteForm.propTypes = {
  handleRedirection: PropTypes.func.isRequired,
};

export default ClientSiteForm;
