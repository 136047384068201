import axios from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";
import { schema } from "normalizr";
import { Auth } from "aws-amplify";

const api = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}`
});

api.interceptors.request.use(
  async function(config) {
    if (config.data instanceof FormData) {
      config.method = "post";
      config.data.append("_method", "post");
    } else config.data = decamelizeKeys(config.data);

    const { accessToken } = await Auth.currentSession();
    const { attributes } = await Auth.currentUserInfo();

    config.headers["X-REMOTE-USER"] = attributes.email;
    config.headers.Authorization = accessToken.jwtToken;

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  function(response) {
    return camelizeKeys(response);
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

export const drugSchema = new schema.Entity("drugs");

export const productSchema = new schema.Entity(
  "products",
  { drug: drugSchema },
  {
    idAttribute: product => product.itemCode
  }
);

export const priceListSchema = new schema.Entity("priceLists");
export const productResponseSchema = new schema.Array(productSchema);

export const transactionSchema = new schema.Entity("transactions");

export const transactionResponseSchema = new schema.Array(transactionSchema);

export const priceListsResponseSchema = new schema.Array(priceListSchema);

export default api;

//this part is to create an api object that
