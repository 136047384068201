import React from "react";
import "../../../Client.scss";
import { Table, Row, Cell } from "UIKit/components/Table_v2/Table";
const ClientSiteTable = ({ children }) => {
  return (
    <Table>
      <Row isTableHeader>
        <Cell>Code</Cell>
        <Cell priority>Name</Cell>
        <Cell priority>Contact Person</Cell>
        <Cell className="has-text-centered" priority>
          Last Modified
        </Cell>
        <Cell className="has-text-centered" priority>
          Date Created
        </Cell>
      </Row>
      {children}
    </Table>
  );
};

export default ClientSiteTable;
