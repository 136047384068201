import React, { Component } from "react";
import { connect } from "react-redux";
import Loading from "UIKit/components/Loading/Loading";
import ClientInformationForm from "./ClientSidebar/ClientInformationForm";
import ClientSiteTable from "./ClientSiteTable/ClientSiteTable";
import ClientTableRow from "./ClientSiteTable/ClientTableRow";
import api from "Services/api";
import { pushNotification } from "UIKit/actions";
import { fetchClientDetail, fetchClientSites } from "../../actions";
import PropTypes from "prop-types";
import ClientSiteForm from "./ClientSiteForm/ClientSiteForm";
import SplitContainer, {
  SideBarContainer,
  MainContent,
  PanelContent
} from "UIKit/components/SplitContainer/SplitContainer";
import { clientFormValidationSchema } from "./helpers";

const EDIT_PAGE = "EDIT_PAGE";
const blankClientDetailObject = {
  name: "",
  accountManager: "",
  defaultBillingStreetAddress: "",
  defaultBillingCityMunicipality: "",
  defaultBillingProvince: "",
  notes: "",
  entityNumber: 0,
  sites: [],
  companyLetters: "",
  defaultPriceList: 2
};

const ClientDetail = pageType => {
  class ClientDetail extends Component {
    constructor() {
      super();
      this.state = {
        addSiteForm: false,
        saveButtonActive: false,
        siteIdTobeEdit: -1,
        blankClientDetail: blankClientDetailObject
      };
    }
    componentDidMount() {
      if (pageType === EDIT_PAGE) {
        this.props.fetchClientDetail(this.props.match.params.id);
        this.props.fetchClientSites(this.props.match.params.id);
      }
    }

    handleApiCall = data => {
      if (pageType === EDIT_PAGE) return api.put(`/clients/${data.id}/`, data);
      else return api.post(`/clients/`, data);
    };
    handleUpdateAndSaveClient = valuesToSave => {
      const data = valuesToSave;
      clientFormValidationSchema
        .validate(data)
        .then(() => {
          this.handleApiCall(data)
            .then(response => {
              this.props.fetchClientDetail(response.data.id);
              this.props.pushNotification(
                3000,
                "Client Successfully Saved.",
                "is-success"
              );
              this.props.history.push(`/client/${response.data.id}`);
              this.setState(previousState => ({
                saveButtonActive: !previousState.saveButtonActive,
                addSiteForm: !previousState.addSiteForm
              }));
            })
            .catch(error => {
              this.props.pushNotification(
                3500,
                `${error.message}: Please recheck all the fields and submit again.`,
                "is-danger"
              );
            });
        })
        .catch(error => {
          this.props.pushNotification(
            4000,
            `${error.path} ${error.message}`,
            "is-warning"
          );
        });
    };

    handleSaveSite = values => {
      values.corporate_client_id = this.props.clientDetail.id;
      if (values.id)
        api.put(`/sites/${values.id}/`, values).then(data => {
          this.props.pushNotification(
            3000,
            "Site Successfully Updated.",
            "is-success"
          );
          this.setState({ addSiteForm: false });
        });
      else {
        api.post(`/sites/`, values).then(response => {
          this.props.clientSites.push(response.data);
          this.props.pushNotification(
            3000,
            "Site Successfully Updated.",
            "is-success"
          );
          this.setState({ addSiteForm: false });
        });
      }
    };

    handleRedirection = values => {
      const dateInititialize = new Date().toISOString();
      values.lastModified = values.created = dateInititialize;
      if (values.id || values.temp_id) {
        this.props.clientSites.map(site => {
          if (site.id === values.id) {
            Object.keys(site).forEach(index => {
              site[index] = values[index];
            });
          }
          return null;
        });
      } else {
        values.temp_id = Date.now(); //temporary id for newly added. site not in database
        this.handleSaveSite(values);
      }
      if (pageType === EDIT_PAGE) this.handleSaveSite(values);
    };

    handleWithDataRedirection = values => {
      this.setState(previousState => ({
        saveButtonActive: true,
        addSiteForm: true,
        siteIdTobeEdit: values.temp_id || values.id
      }));
    };

    handleAddSiteForm = bool => event => {
      this.setState(previousState => ({
        addSiteForm: bool,
        saveButtonActive: bool,
        siteIdTobeEdit: -1
      }));
    };

    setSiteIdToBeEdit = id => {
      this.setState(previousState => ({
        siteIdTobeEdit: id
      }));
    };

    handleNoAccountManager = clientDetail => {
      clientDetail = { ...clientDetail };
      if (
        !!!clientDetail.accountManager ||
        clientDetail.accountManager.trim() === ""
      )
        clientDetail.accountManager = undefined;

      return clientDetail;
    };

    onSubmitClientDetail = values => {
      const tempClientDetail =
        pageType === EDIT_PAGE
          ? { ...this.props.clientDetail }
          : { ...this.state.blankClientDetail };
      Object.keys(tempClientDetail).forEach(key => {
        if (key !== "sites") {
          tempClientDetail[key] = values[key];
        }
      });
      tempClientDetail["companyLetters"] = values.companyLetters;
      this.handleUpdateAndSaveClient(tempClientDetail, true);
    };

    renderMainContentHeader = () => (
      <div className="level">
        <div className="level-left">Client Trade / Site List</div>
        <div className="level-right">
          <button
            type="submit"
            className="is-primary button"
            form="client-info-form"
            disabled={this.state.saveButtonActive}
          >
            Save Client
          </button>
          &nbsp;
          <button
            type="submit"
            className="is-link button"
            onClick={this.handleAddSiteForm(!this.state.addSiteForm)}
            disabled={!this.props.clientDetail.id}
          >
            {this.state.addSiteForm ? "Back to Site List" : "Add Site"}
          </button>
        </div>
      </div>
    );

    render() {
      let clientTobeProcessed =
        pageType === EDIT_PAGE
          ? this.props.clientDetail
          : { ...this.state.blankClientDetail };

      clientTobeProcessed = this.handleNoAccountManager(clientTobeProcessed);
      const clientSites = this.props.clientSites;

      if (
        this.props.clientDetail.id !== parseInt(this.props.match.params.id) &&
        pageType === EDIT_PAGE
      )
        return (
          <Loading message="Fetching client details..." className="pt-3" />
        );
      return (
        <SplitContainer>
          <SideBarContainer>
            <ClientInformationForm
              clientDetail={clientTobeProcessed}
              onSubmit={this.onSubmitClientDetail}
              pageType={pageType}
            />
          </SideBarContainer>
          <MainContent>
            <PanelContent header={this.renderMainContentHeader()}>
              {!clientTobeProcessed.id && (
                <p className="has-text-centered has-text-gray">
                  Please add a client first to add a site.
                </p>
              )}
              {!clientSites && <Loading />}
              {clientTobeProcessed.id ? (
                !this.state.addSiteForm ? (
                  <ClientSiteTable>
                    {clientSites &&
                      clientSites.map((site, index) => (
                        <ClientTableRow
                          key={index}
                          data={site}
                          redirectToSiteForm={this.handleWithDataRedirection}
                          setSiteIdToBeEdit={this.setSiteIdToBeEdit}
                        />
                      ))}
                  </ClientSiteTable>
                ) : (
                  <ClientSiteForm
                    clientDetail={clientTobeProcessed}
                    clientId={this.state.siteIdTobeEdit}
                    siteDetail={this.props.clientSites.find(site => {
                      return (
                        (site.temp_id || site.id) === this.state.siteIdTobeEdit
                      );
                    })}
                    handleRedirection={this.handleRedirection}
                    pageType={pageType}
                  />
                )
              ) : null}
            </PanelContent>
          </MainContent>
        </SplitContainer>
      );
    }
  }
  const propTypes = {
    fetchClientDetail: PropTypes.func.isRequired,
    match: PropTypes.object.isRequired,
    clientDetail: PropTypes.object.isRequired
  };

  ClientDetail.propTypes = propTypes;

  const mapStateToProps = state => {
    return {
      clientDetail:
        state.clientDetailReducer.fetchedData.data || blankClientDetailObject,
      clientSites: state.clientSiteReducer.fetchedData.data || []
    };
  };

  return connect(mapStateToProps, {
    fetchClientDetail,
    fetchClientSites,
    pushNotification
  })(ClientDetail);
};

export default ClientDetail;
