export const FORM_STATE = {
  LOGIN_PAGE: "LOGIN_PAGE",
  VERIFY_EMAIL_PAGE: "VERIFY_EMAIL_PAGE",
  FORGOT_PASSWORD_PAGE: "FORGOT_PASSWORD_PAGE",
  NEW_PASSWORD_PAGE: "NEW_PASSWORD_PAGE",
  AUTHORIZED_PAGE: "AUTHORIZED_PAGE",
  UNAUTHORIZED_PAGE: "UNAUTHORIZED_PAGE",
  NEW_PHONE_NUMBER_PAGE: "NEW_PHONE_NUMBER_PAGE"
};

export const AUTH_CHECK = {
  AUTH_SUCCESS: "AUTHENTICATION_SUCCESS",
  AUTH_FAILED: "AUTHENTICATION_FAILED",
  UNAUTHORIZED_ACCESS: "UNAUTHORIZED_ACCESS",
  NEW_PHONE_NUMBER_FAILED: "NEW_PHONE_NUMBER_FAILED"
};

export const NEW_PASSWORD_CHECK = {
  NEW_PASSWORD_REQUIRED: "NEW_PASSWORD_REQUIRED",
  NEW_PASSWORD_SUCCESS: "NEW_PASSWORD_SUCCESS",
  NEW_PASSWORD_FAILED: "NEW_PASSWORD_FAILED"
};

export const FORGOT_PASSWORD_CHECK = {
  FORGOT_PASSWORD_EMAIL_SUCCESS: "FORGOT_PASSWORD_VERIFY_EMAIL_SUCCESS",
  FORGOT_PASSWORD_EMAIL_FAILED: "FORGOT_PASSWORD_VERIFY_EMAIL_FAILED",
  FORGOT_PASSWORD_SUCCESS: "FORGOT_PASSWORD_SUCCESS",
  FORGOT_PASSWORD_FAILED: "FORGOT_PASSWORD_FAILED"
};
