import gql from "graphql-tag";

export const createSourcingSheet = gql`
  mutation sourcing_sheet(
    $created_by: String!
    $last_update_by: String!
    $selected_po: [sourcing_sourcingsheet_purchase_orders_insert_input!]!
    $created: timestamptz!
    $updated: timestamptz!
    $code: String!
  ) {
    insert_sourcing_sourcingsheet(
      objects: {
        created_by: $created_by
        created: $created
        last_update_by: $last_update_by
        last_updated: $updated
        status: "SOURCING"
        code: $code
        sourcing_sourcingsheet_purchase_orders: { data: $selected_po }
      }
    ) {
      returning {
        id
        code
        created_by
        sourcing_sourcingsheet_purchase_orders {
          sourcingsheet_id
          purchaseorderdetail_id
        }
      }
    }
  }
`;
