import React from "react";

const Card = ({ children, hasFooter, footerContent }) => (
  <div className="card">
    <div className="card-content">{children}</div>
    <footer className={hasFooter ? "card-footer" : "is-hidden"}>
      <p className="card-footer-item">{footerContent}</p>
    </footer>
  </div>
);

export default Card;
