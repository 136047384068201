import React from 'react';
import moment from 'moment';
import { AddCouponContext } from 'Coupons/components/AddCoupon/AddCoupon';

const ReviewData = () => (
  <AddCouponContext.Consumer>
  { context =>
    <div>
      <table className='table is-fullwidth is-striped is-hoverable'>
        <thead>
          <tr>
            <th style={{width: '30%'}}>BASIC COUPON INFO</th>
            <th style={{width: '70%'}}>Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Code</td>
            <td>{context.coupon.code}</td>
          </tr>
          <tr>
            <td>Description</td>
            <td>{context.coupon.description}</td>
          </tr>
          <tr>
            <td>Activation Date</td>
            <td>{moment(context.coupon.activation).format("ddd MMMM DD YYYY h:mm a")}</td>
          </tr>
          <tr>
            <td>Expiration Date</td>
            <td>{moment(context.coupon.expiration).format("ddd MMMM DD YYYY h:mm a")}</td>
          </tr>
          <tr>
            <td>Waive Service and Delivery Fees?</td>
            <td>{context.coupon.waiveFees ? 'Yes' : 'No'}</td>
          </tr>
          <tr>
            <td>Total Use Limit</td>
            <td>{context.coupon.totalUseLimit}</td>
          </tr>
          <tr>
            <td>Daily Use Limit</td>
            <td>{context.coupon.dailyUseLimit}</td>
          </tr>
        </tbody>
      </table>
      <table className='table is-fullwidth is-striped is-hoverable'>
        <thead>
          <tr>
            <th style={{width: '30%'}}>USER RESTRICTIONS</th>
            <th style={{width: '70%'}}>Details</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Locations</td>
            <td>{context.coupon.locations.join(', ')}</td>
          </tr>
          <tr>
            <td>Company</td>
            <td>{context.coupon.company}</td>
          </tr>
          <tr>
            <td>User Use Limit</td>
            <td>{context.coupon.userUseLimit} </td>
          </tr>
          <tr>
            <td>First-time Users Only?</td>
            <td>{context.coupon.firstTime ? 'Yes' : 'No'}</td>
          </tr>
        </tbody>
      </table>
      <table className='table is-fullwidth is-striped is-hoverable'>
        <thead>
          <tr>
            <th style={{width: '30%'}}>TRANSACTION RESTRICTIONS</th>
            <th style={{width: '70%'}}>Details</th>
          </tr>
        </thead>
        <tbody> 
          <tr>
            <td>Type</td>
            <td>{context.coupon.type}</td>
          </tr>
          {
            context.coupon.type === 'Fixed' &&
            <tr>
            <td>Amount</td>
            <td>{context.coupon.amount}</td>
            </tr>
          }
          {
            context.coupon.type === 'Percent' &&
            <tr>
            <td>Percent Off</td>
            <td>{context.coupon.percentOff}</td>
            </tr> 
          }
          {
            context.coupon.type === 'Percent' &&
            <tr>
            <td>Maximum Discount</td>
            <td>{context.coupon.maxDiscount}</td>
            </tr>
          }
          {
            context.coupon.type !== 'Senior-PWD' &&
            <tr>
            <td>Minimum Purchase</td>
            <td>{context.coupon.minPurchase}</td>
            </tr> 
          }
        </tbody>
      </table>
      <label className='label'>Included SKUs and Items</label>
      <table className='table is-fullwidth is-striped is-hoverable'>
        <thead>
          <tr>
            <th style={{width: '30%'}}>Type</th>
            <th style={{width: '70%'}}>Details</th>
          </tr> 
        </thead>
        <tbody>
          {context.coupon.includedItems.map((item, i) => 
            <tr key={i}>
              <td>Item</td>
              <td><b>{item.title}</b></td>
            </tr>
          )}
          {context.coupon.includedSKUs.map((item, i) => 
            <tr key={i+context.coupon.includedItems.length}>
              <td>SKU Restriction</td>
              <td>
                <p>Category: <b>{item.category}</b></p>
                <p>Class: <b>{item.classes}</b></p>
                <p>Restrictions: <b>{item.restrictions}</b></p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
      <label className='label'>Excluded SKUs and Items</label>
      <table className='table is-fullwidth is-striped is-hoverable'>
        <thead>
          <tr>
            <th style={{width: '30%'}}>Type</th>
            <th style={{width: '70%'}}>Details</th>
          </tr> 
        </thead>
        <tbody>
          {context.coupon.excludedItems.map((item, i) => 
            <tr key={i}>
              <td>Item</td>
              <td><b>{item.title}</b></td>
            </tr>
          )}
          {context.coupon.excludedSKUs.map((item, i) => 
            <tr key={i+context.coupon.excludedItems.length}>
              <td>SKU Restriction</td>
              <td>
                <p>Category: <b>{item.category}</b></p>
                <p>Class: <b>{item.classes}</b></p>
                <p>Restrictions: <b>{item.restrictions}</b></p>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  }
  </AddCouponContext.Consumer>
);

export default ReviewData;