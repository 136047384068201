import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

export const CSVErrorMessage = ({ errorType, message, acceptableValues }) => (
  <div className="mb-3" name="csv-error-message">
    <p className="mb-1">
      <FontAwesomeIcon
        icon={faExclamationCircle}
        className="has-text-danger mr-1"
      />
      <strong>{message}</strong>
    </p>

    {!!errorType &&
      errorType.map((warning, index) => (
        <div key={index}>
          <span>
            {!!warning.row
              ? `Row #${warning.row}`
              : `Transaction #${warning.transaction}`}
            :{" "}
          </span>
          <span className="has-text-weight-bold">{warning.error}</span>
        </div>
      ))}

    {!!acceptableValues && acceptableValues.length > 0 && (
      <Fragment>
        <div className="tags mt-1">
          <div className="help has-text-weight-bold mr-1">
            Accepted values:{" "}
          </div>
          {acceptableValues.map((value, index) => (
            <span className="tag is-light" key={index}>
              {value}
            </span>
          ))}
        </div>
      </Fragment>
    )}
  </div>
);

CSVErrorMessage.propTypes = {
  errorType: PropTypes.arrayOf(
    PropTypes.shape({
      row: PropTypes.number.isRequired,
      error: PropTypes.string.isRequired
    })
  ).isRequired,
  message: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.object.isRequired
  ]),
  acceptableValues: PropTypes.array
};
