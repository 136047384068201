import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { formatNumber } from "Services/util";
import styles from "./PurchaseOrder.module.scss";

export const getKeyValue = (item, key, subKey) => {
  if (key && subKey && item[key]) return item[key][subKey];
  else if (key) return item[key];
  else return "";
};

export const totalValue = (item, listToTotal, operation = "multiplication") => {
  let total = operation === "multiplication" ? 1.0 : 0.0;
  listToTotal.forEach(({ key, subKey }) => {
    if (operation === "multiplication")
      total = total * parseFloat(getKeyValue(item, key, subKey));
    if (operation === "subtraction")
      total = parseFloat(getKeyValue(item, key, subKey)) - total;
  });
  return formatNumber(total);
};

const PurchaseOrderTableContent = ({ headers, items }) => {
  return (
    <section>
      {/* The item Table in print */}
      <table className={classNames("table is-striped is-fullwidth", styles.po)}>
        <thead>
          <tr>
            {headers &&
              headers.map(({ title, className }, index) => (
                <th key={index} className={`has-text-weight-bold ${className}`}>
                  {title}
                </th>
              ))}
          </tr>
        </thead>
        <tbody className={styles["po__body-items"]}>
          {items &&
            items.map((item, index) => {
              return (
                <tr key={index}>
                  {headers.map((header, headerIndex) => {
                    index++;
                    return (
                      // You can change the CSS of the table here
                      <td
                        key={headerIndex}
                        className={classNames(header.className)}
                      >
                        {header.sequence && index}
                        {header.listToOperate &&
                          totalValue(
                            item,
                            header.listToOperate,
                            header.operation
                          )}
                        {getKeyValue(item, header.key, header.subKey)}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
        </tbody>
      </table>
      <br />
    </section>
  );
};

PurchaseOrderTableContent.propTypes = {
  headers: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired
};

export default PurchaseOrderTableContent;
