import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faStar,
  faHeart,
  faPrescriptionBottle,
  faExternalLinkAlt,
  faAsterisk,
  faPrescription,
  faUmbrella
} from "@fortawesome/free-solid-svg-icons";
import channelsList from "assets/data/channels.json";
import { getTotal } from "Services/cart";
import { roundFloatToTwo, formatNumber } from "Services/util";

const channelIcons = [
  faGlobe, // regular
  faStar, // regular VIP
  faHeart, // patient access
  faUmbrella, // medensure
  faPrescription, // flexmed
  faPrescriptionBottle, // chronic care
  faAsterisk // others
];

const formattedDateTime = dateTime => {
  const dateFormat = {
    month: "short",
    day: "numeric"
  };

  const timeFormat = {
    hour: "numeric",
    minute: "numeric"
  };
  return (
    <Fragment>
      {new Date(dateTime).toLocaleString("en-US", dateFormat)} <br />
      <span className="help">
        {new Date(dateTime).toLocaleString("en-US", timeFormat)}
      </span>
    </Fragment>
  );
};

const propTypes = {
  data: PropTypes.shape({
    id: PropTypes.string.is,
    currentStatus: PropTypes.string.isRequired,
    lastUpdate: PropTypes.string.isRequired,
    customerFirstName: PropTypes.string.isRequired,
    customerLastName: PropTypes.string.isRequired,
    shippingCityMunicipality: PropTypes.string.isRequired,
    internalNotes: PropTypes.string,
    zendeskId: PropTypes.number.isRequired,
    channel: PropTypes.string.isRequired,
    created: PropTypes.string.isRequired,
    details: PropTypes.array.isRequired
  }).isRequired
};

const TransactionRow = ({ data, color }) => {
  const {
    id,
    currentStatus,
    lastUpdate,
    customerFirstName,
    customerLastName,
    shippingCityMunicipality,
    internalNotes,
    zendeskId,
    channel,
    created,
    details
  } = data;
  const channelProperties = channelsList.find(item => item.channel === channel);
  const total = roundFloatToTwo(
    getTotal(
      details,
      parseFloat(data.convenienceFee),
      data.couponCode,
      parseFloat(data.discountAmount)
    )
  );
  const customerName =
    customerLastName + ", " + customerFirstName.match(/\b\w/g).join("");

  return (
    <Fragment>
      <td className="has-text-centered has-text-weight-bold status">
        <Link
          to={`orders/${id}/`}
          className="transaction-status"
          style={{ backgroundColor: color }}
        >
          {currentStatus}
        </Link>
      </td>
      <td className="has-text-centered">{formattedDateTime(created)}</td>
      <td>
        {zendeskId ? (
          <a
            href={`https://medgrocer.zendesk.com/agent/tickets/${zendeskId}`}
            target="_blank"
            rel="noopener noreferrer"
            title="Open Zendesk ticket"
          >
            {`Ticket #${zendeskId}`}{" "}
            <span className="icon is-pulled-right">
              <FontAwesomeIcon icon={faExternalLinkAlt} />
            </span>
          </a>
        ) : (
          id.substring(0, 7)
        )}
      </td>
      <td
        className="tooltip is-tooltip-left"
        data-tooltip={channelProperties.label}
      >
        <span className={classNames("icon", channelProperties.iconColor)}>
          <FontAwesomeIcon icon={channelIcons[channelProperties.iconIndex]} />
        </span>
        {customerName}
      </td>
      <td>{shippingCityMunicipality}</td>
      <td className="has-text-right">{formatNumber(total)}</td>
      <td className="td--is-notes">{internalNotes || ""}</td>
      <td className="has-text-centered">{formattedDateTime(lastUpdate)}</td>
    </Fragment>
  );
};

TransactionRow.propTypes = propTypes;

export default TransactionRow;
