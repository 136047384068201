const ESCAPE_WORD = /[^a-zA-Z0-9\u00C0-\u00FF, ]+/g;
class FuzzySearch {
  constructor(set, keyId) {
    this.set = set;
    this.groupWordSize = 3;
    this.keyId = keyId;
  }
  getMatchesFrom = (value, frequencyBoundary) => {
    const matchedObjects = [];
    Object.keys(this.set).map(key => {
      const ObjFrequencyBoundary = this._calculateMatchingFrequency(
        value,
        this.set[key][this.keyId] || this.set[key]
      );
      if (ObjFrequencyBoundary >= frequencyBoundary)
        matchedObjects.push({
          ...this.set[key],
          frequencyMatch: ObjFrequencyBoundary
        });
      return 1;
    });
    return matchedObjects;
  };
  _createGroupWord = (value, groupWordSize) => {
    groupWordSize = groupWordSize || this.groupWordSize;
    const normalizedWord = `-${value
      .toString()
      .toLowerCase()
      .replace(ESCAPE_WORD, "")}-`;
    const lengthDifference = groupWordSize - normalizedWord.length;
    const results = [];
    if (lengthDifference > 0)
      for (let i = 0; i < normalizedWord.length; i++) value += "-";
    for (let i = 0; i < normalizedWord.length - groupWordSize + 1; i++)
      results.push(normalizedWord.slice(i, i + groupWordSize));
    return results;
  };

  _groupWordCounter = (value, groupWordSize) => {
    groupWordSize = groupWordSize || this.groupWordSize;
    const result = {};
    const groupWordArray = this._createGroupWord(value, groupWordSize);
    let i = 0;
    for (i; i < groupWordArray.length; i++) {
      if (groupWordArray[i] in result) result[groupWordArray[i]] += 1;
      else result[groupWordArray[i]] = 1;
    }
    return result;
  };

  _calculateDotProduct = (groupWordToValidate, groupWordToMatch) => {
    const duplicateGroupWordToMatch = {};
    let dotProduct = 0;
    Object.keys(groupWordToValidate).forEach(key => {
      if (groupWordToValidate[key] && groupWordToMatch[key])
        duplicateGroupWordToMatch[key] =
          groupWordToValidate[key] * groupWordToMatch[key];
      else duplicateGroupWordToMatch[key] = 0;
    });
    Object.keys(duplicateGroupWordToMatch).forEach(
      key => (dotProduct += duplicateGroupWordToMatch[key])
    );
    return dotProduct;
  };

  _calculateVectorFrequency = groupWord => {
    let frequency = 0;
    Object.keys(groupWord).forEach(index => (frequency += groupWord[index]));
    return Math.sqrt(frequency);
  };

  _calculateMatchingFrequency = (valueToValidate, valueToMatch) => {
    const groupWordToValidate = this._groupWordCounter(valueToValidate);
    const groupWordToMatch = this._groupWordCounter(valueToMatch);
    const dotProduct = this._calculateDotProduct(
      groupWordToValidate,
      groupWordToMatch
    );
    const valueToValidateFrequency = this._calculateVectorFrequency(
      groupWordToValidate
    );
    const valueToMatchFrequency = this._calculateVectorFrequency(
      groupWordToMatch
    );
    return dotProduct / (valueToValidateFrequency * valueToMatchFrequency);
  };
}

export default FuzzySearch;
