// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsExports = {
  aws_project_region: process.env.REACT_APP_COGNITO_REGION,
  aws_cognito_region: process.env.REACT_APP_COGNITO_REGION,
  aws_user_pools_id: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  aws_user_pools_web_client_id: process.env.REACT_APP_COGNITO_CLIENT_ID,
  oauth: {
    domain: process.env.REACT_APP_COGNITO_USER_POOL_DOMAIN,
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin"
    ],
    redirectSignIn: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_IN,
    redirectSignOut: process.env.REACT_APP_COGNITO_REDIRECT_SIGN_OUT,
    responseType: "code"
  },
  federationTarget: "COGNITO_USER_POOLS"
};

export default awsExports;
