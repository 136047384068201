import React from 'react';
import {FormInput, Dropdown} from 'UIKit/components/Forms/FormInput';
import { AddCouponContext } from 'Coupons/components/AddCoupon/AddCoupon';

const options = ["Percent", "Fixed", "Senior-PWD", "Other"];

const TransactionRestrictionsForm = () => {
  return (
    <AddCouponContext.Consumer>
    { context =>
      <div className='coupon-form'>
        <Dropdown 
          id="type"
          label="Discount Type"
          options={options}
          onClick={context.getData}
        />
        {context.coupon.type === 'Percent' &&
          <FormInput 
            id="percentOff" 
            type="number" 
            label="Percent Off" 
            onChange={context.getData}
          />
        }
        {context.coupon.type === 'Fixed' &&
          <FormInput 
            id="amount"   
            type="number" 
            label="Amount"
            onChange={context.getData}
          />
        }
        {context.coupon.type !== 'Senior-PWD' && 
          <FormInput 
            id="minPurchase" 
            type="number" 
            label="Minimum Purchase" 
            onChange={context.getData}
          />
        }
        {context.coupon.type === 'Percent' &&
          <FormInput 
            id="maxDiscount" 
            type="number" 
            label="Maximum Discount" 
            onChange={context.getData}
          />
        }
      </div>
    }
    </AddCouponContext.Consumer>
  );
};

export default TransactionRestrictionsForm