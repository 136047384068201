import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetchOrderDetail, fetchStatusB2B } from "../actions";
import SplitContainer, {
  MainContent,
  PanelContent,
  SideBarContainer
} from "UIKit/components/SplitContainer/SplitContainer";
import MainContentHeader from "./MainContent/PurchaseOrderMainPanelHeader";
import PurchaseOrderNotification from "./MainContent/PurchaseOrderNotification";

import PurchaseOrderSidebarForm from "./Sidebar/PurchaseOrderSidebarForm";
import PurchaseOrderMainContent from "./MainContent/PurchaseOrderMainContent";
import { DPO_MAP } from "PurchaseOrder/helpers/jsonInitializers";
import { pushNotification } from "UIKit/actions";
import api from "Services/api";
import "./PurchaseOrder.scss";
import Loading from "UIKit/components/Loading/Loading";
import { isEqual } from "lodash";
import PurchaseOrderDuplicationModal from "./PurchaseOrderDuplicationModal";

const BASE_URL = "/draft-purchase-orders/";

class PurchaseOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isConfirmationButtonDisabled: true,
      isModalActive: false,
      isDuplicationModalActive: false,
      statusList: null,
      objectFetched: null
    };
  }

  componentDidMount() {
    this.props.fetchStatusB2B();
    const { type, baseUrl } = this.props;
    if (type === "EDIT")
      this.props.fetchOrderDetail(this.props.match.params.id, baseUrl);
    else this.setState(() => ({ objectFetched: { ...DPO_MAP } }));
  }

  componentDidUpdate() {
    const { payload } = this.props.orderDetail;
    const { type } = this.props;
    const { objectFetched, statusList } = this.state;
    if (!isEqual(payload, objectFetched) && type === "EDIT" && !objectFetched)
      this.setState(() => ({ objectFetched: payload }));
    if (!isEqual(this.props.orderStatus.payload, statusList) && !statusList)
      this.setState(() => ({ statusList: this.props.orderStatus.payload }));
  }

  handleOnEditOrderItem = (index, values) => {
    this.setState(previousState => {
      previousState.draftPurchaseOrderDetail.items[index] = values;
      return {
        draftPurchaseOrderDetail: previousState.draftPurchaseOrderDetail
      };
    });
  };

  handleOnSave = async values => {
    const ACTUAL_URI = values.id ? BASE_URL + `${values.id}/` : BASE_URL;
    try {
      const result = await api[values.id ? "put" : "post"](ACTUAL_URI, values);
      this.props.pushNotification(3500, "Successfully saved.", "is-success");
      this.setState(() => ({ objectFetched: result.data }));
      this.props.history.push(`/quotes/edit/${result.data.id}`);
    } catch (e) {
      this.props.pushNotification(3500, "Error saving DPO.", "is-danger");
    }
  };

  handleOnDuplicate = async values => {
    try {
      const { data } = await api.post(BASE_URL, values);
      this.props.pushNotification(
        3500,
        "Successfully duplicated.",
        "is-success"
      );
      this.props.history.push(`/draft-purchase-orders/edit/${data.id}`);
      this.setState(() => ({
        objectFetched: data,
        isDuplicationModalActive: false
      }));
    } catch (e) {
      this.props.pushNotification(3500, "Error saving DPO.", "is-danger");
    }
  };

  handleChangeObjectFetched = values =>
    this.setState(() => ({ objectFetched: values }));

  handleActiveDuplicationModal = () => {
    this.setState(({ isDuplicationModalActive }) => ({
      isDuplicationModalActive: !isDuplicationModalActive
    }));
  };

  render() {
    if (!this.state.objectFetched || !this.state.statusList)
      return (
        <div className="p-5">
          <Loading />
        </div>
      );

    return (
      <SplitContainer>
        <SideBarContainer>
          <PurchaseOrderSidebarForm
            objectFetched={this.state.objectFetched}
            handleOnSave={this.handleOnSave}
            handleChangeObjectFetched={this.handleChangeObjectFetched}
            statusList={this.state.statusList}
          />
        </SideBarContainer>
        <MainContent>
          <PurchaseOrderDuplicationModal
            isActive={this.state.isDuplicationModalActive}
            orderDetails={this.state.objectFetched}
            handleChangeObjectFetched={this.handleChangeObjectFetched}
            handleActiveDuplicationModal={this.handleActiveDuplicationModal}
            statusList={this.state.statusList}
            handleOnSave={this.handleOnDuplicate}
          />
          <PanelContent>
            <MainContentHeader
              enableEditPrivilege
              objectFetched={this.state.objectFetched}
              orderTitle={this.props.orderTitle}
              handleActiveDuplicationModal={this.handleActiveDuplicationModal}
            />
            {this.props.type === "EDIT" && (
              <PurchaseOrderNotification
                objectFetched={this.state.objectFetched}
              />
            )}
            <PurchaseOrderMainContent
              enableEditPrivilege
              paramsId={this.state.objectFetched.id}
              objectFetched={this.state.objectFetched}
              pushToRoute={this.props.history.push}
              baseUrl={`/draft-purchase-orders`}
              subUrl={this.props.subUrl}
            />
          </PanelContent>
        </MainContent>
      </SplitContainer>
    );
  }
}

PurchaseOrderDetails.propTypes = {
  pushNotification: PropTypes.func,
  objectFetched: PropTypes.object,
  handleSubmit: PropTypes.func,
  pushToRoute: PropTypes.func,
  fetchOrderDetail: PropTypes.func,
  fetchStatusB2B: PropTypes.func,
  match: PropTypes.object,
  history: PropTypes.object,
  baseUrl: PropTypes.string.isRequired,
  type: PropTypes.string,
  orderDetail: PropTypes.object,
  subUrl: PropTypes.string,
  orderTitle: PropTypes.string
};

const mapStateToProps = state => {
  return {
    orderDetail: state.fetchOrderDetail,
    orderStatus: state.fetchStatusB2B
  };
};

export default connect(mapStateToProps, {
  pushNotification,
  fetchOrderDetail,
  fetchStatusB2B
})(PurchaseOrderDetails);
