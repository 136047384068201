import api from "Services/api";

export const PRODUCT_REQUEST_STATUS = {
  FETCH_PRODUCT_REQUEST_DETAIL: "FETCH_PRODUCT_REQUEST_DETAIL"
};

export const FORM_REQUEST_STATUS = {
  FETCHED_FORM_LIST: "FETCHED_FORM_LIST_PRODUCT_REQUEST"
};

export const fetchProductRequestDetail = id => dispatch => {
  return api.get(`product_requests/${id}/`).then(response =>
    dispatch({
      type: PRODUCT_REQUEST_STATUS.FETCH_PRODUCT_REQUEST_DETAIL,
      payload: response.data
    })
  );
};

export const fetchFormList = () => dispatch => {
  return api.get(`forms/`).then(response => {
    return dispatch({
      type: FORM_REQUEST_STATUS.FETCHED_FORM_LIST,
      payload: response.data.results
    });
  });
};

/**
 *
 * @param {object} dataToSave
 * @param {function} callback
 * @param {string} pageType
 */
export const editProductRequestDetail = (
  dataToSave,
  callbackSuccess,
  pageType,
  callbackError
) => {
  const apiToHandle =
    pageType === "EDIT"
      ? api.put(`/product_requests/${dataToSave.id}/`, dataToSave)
      : api.post(`/product_requests/`, dataToSave);
  return apiToHandle
    .then(response => callbackSuccess(response))
    .catch(error => callbackError(error));
};
