import React, { useEffect, useState } from "react";
import api from "Services/api";
import PageIcons from "./PageIcons";
import PageInput from "./PageInput";

const Pagination = ({ url, callback, errorCallback, currentPage }) => {
  const [maxPage, setMaxPage] = useState(1);
  const [itemCount, setItemCount] = useState(0);
  const [itemPageCount, setItemPageCount] = useState(0);

  const firstItemNumber = 1 + (parseInt(currentPage) - 1) * 30;

  useEffect(() => {
    fetchData();
  }, [url]);

  const getPageResults = async (pageToSkip, isEntered) => {
    let skip = parseInt(pageToSkip) || 1;
    let nextPage = parseInt(currentPage) + skip;
    if (callback) callback(null, isEntered ? skip : nextPage);
  };

  const fetchData = async (pageUrl, nextPage) => {
    try {
      const response = await api.get(pageUrl || url);
      const { count, results } = response.data;
      setMaxPage(Math.ceil(count / 30));
      if (count != itemCount) setItemCount(count);
      if (callback) callback(response, nextPage || currentPage);
      if (results.length != itemPageCount) setItemPageCount(results.length);
      return response;
    } catch (error) {
      if (errorCallback) errorCallback(error);
    }
  };

  const skipToLast = () => {
    if (parseInt(maxPage) === parseInt(currentPage)) return;
    else getPageResults(parseInt(maxPage), true);
  };

  return (
    <footer className="footer">
      <section className="columns is-centered is-vcentered">
        <div className="column is-narrow">
          <div className="field is-grouped">
            <PageIcons
              onPageSkip={getPageResults}
              isDisabled={parseInt(currentPage) === 1}
            />
            <p className="control mt-1">Page</p>
            <PageInput currentPage={currentPage} callback={getPageResults} />
            <p className="control mt-1">of</p>
            <p className="control mt-1 has-text-primary" onClick={skipToLast}>
              {maxPage || 1}
            </p>
            <PageIcons
              onPageSkip={getPageResults}
              isDisabled={parseInt(currentPage) >= parseInt(maxPage)}
              type="forward"
            />
          </div>
        </div>
      </section>
      <section className="columns is-centered is-vcentered">
        <div className="column is-narrow ">
          Displaying <b>{firstItemNumber}</b> -{" "}
          <b>{firstItemNumber + itemPageCount - 1}</b> of <b>{itemCount}</b>
        </div>
      </section>
    </footer>
  );
};

export default Pagination;
