import React, { useContext, Suspense, useEffect } from "react";
import Loading from "UIKit/components/Loading/Loading";
import { AuthProvider, AuthContext } from "./authContext";

import { FORM_STATE } from "./reducers/authStates";

const Login = React.lazy(() => import("./Forms/LoginForm"));
const VerifyEmailForm = React.lazy(() => import("./Forms/VerifyEmailForm"));
const NewPasswordForm = React.lazy(() => import("./Forms/NewPasswordForm"));
const UnauthorizedPage = React.lazy(() => import("./Forms/UnauthorizedPage"));
const NewPhoneNumberForm = React.lazy(() =>
  import("./Forms/NewPhoneNumberForm")
);
const ForgotPasswordForm = React.lazy(() =>
  import("./Forms/ForgotPasswordForm")
);

const Authenticator = Component => {
  const AuthenticatorForm = props => {
    const { pageState, authHelpers, switchAuthForm, pageActions } = useContext(
      AuthContext
    );

    const authSuccess = (user, attributes) => {
      if (!user) return switchAuthForm(pageActions.TO_UNAUTHORIZED);
      if (!attributes.phone_number)
        return switchAuthForm(pageActions.TO_NEW_PHONE_NUMBER);

      switchAuthForm(pageActions.TO_ADMIN);
    };

    useEffect(() => {
      authHelpers.isUserAuthenticated(authSuccess);
    }, []);

    switch (pageState.pageType) {
      case FORM_STATE.LOGIN_PAGE:
        return <Login />;
      case FORM_STATE.VERIFY_EMAIL_PAGE:
        return <VerifyEmailForm />;
      case FORM_STATE.FORGOT_PASSWORD_PAGE:
        return <ForgotPasswordForm />;
      case FORM_STATE.NEW_PASSWORD_PAGE:
        return <NewPasswordForm />;
      case FORM_STATE.AUTHORIZED_PAGE:
        return <Component {...props} />;
      case FORM_STATE.UNAUTHORIZED_PAGE:
        return <UnauthorizedPage />;
      case FORM_STATE.NEW_PHONE_NUMBER_PAGE:
        return <NewPhoneNumberForm />;
      default:
        return <Login />;
    }
  };

  const AuthenticatorComponent = props => {
    return (
      <AuthProvider>
        <Suspense fallback={<Loading />}>
          <AuthenticatorForm {...props} />
        </Suspense>
      </AuthProvider>
    );
  };

  return AuthenticatorComponent;
};

export default Authenticator;
