import React from "react";
import moment from "moment";
// import { Link } from "react-router-dom";

const DropdownTextMessage = [
  {
    link: "#",
    label: "VERIFICATION",
    value: ""
  },
  {
    link: "#",
    label: "CANCEL",
    value: `Hi! We have not yet received your reply so we will be canceling your order for now. You can reorder on medgrocer.com any time. Thank you and we hope to serve you soon. This is an automated message. - MedGrocer`
  },
  {
    link: "#",
    label: "PAY",
    value: `You may deposit your payment to: \n\nWellbridge Health Inc (BDO Savings Account) \n0064-4801-2344 \nWellbridge Health Inc. (BPI Checking Account) 4161007221 \n\nFor GCash payment, kindly send it to 09176280383 and upload your proof of payment to order@medgrocer.com. Thank you and have a pleasant day ahead. \n\nKindly upload deposit slip to order@medgrocer.com with the subject "Proof of payment - [Name used for the order]". Thank you!`
  },
  {
    link: "#",
    label: "WEB",
    value: `Thank you for reaching out. To enhance your security and safety, we only accept orders through our website at www.medgrocer.com. Thank you and we look forward to serving you.`
  },
  {
    link: "#",
    label: "DELIVERY",
    value: `Thank you for confirming. Your order will be dispatched today, ${moment().format(
      "LL"
    )}. We expect you to receive it between 3 to 5pm. Please excuse delays due to bad weather or heavy traffic. Thank you.`
  },
  {
    link: "#",
    label: "CONFIRM",
    value: `Thank you for confirming. We will inform you on stock availability and delivery date within 24 hours. For urgent concerns or if you don’t receive a message from our team, please text us at 09159050555. Thank you and have a pleasant day ahead.`
  }
];

const TextMessageDropdown = ({ setTextMessage, textMessage }) => (
  <div className="field control has-addons">
    <div className="select">
      <select
        name="status"
        onChange={event => {
          const itemObject = JSON.parse(event.target.value);
          setTextMessage(itemObject);
        }}
      >
        {DropdownTextMessage.map((item, index) => (
          <option
            key={index}
            value={JSON.stringify(item)}
            selected={item.value === textMessage ? true : false}
          >
            {item.label}
          </option>
        ))}
      </select>
    </div>

    {/* <div className="control">
      <button
        type="submit"
        className="button is-primary"
        onClick={() => postStatusLog()}
      >
        Save
      </button>
    </div> */}
  </div>
);

export default TextMessageDropdown;
