import React from "react";
// import { Link } from "react-router-dom";

const DropdownStatus = [
  {
    link: "#",
    label: "CREATED",
    value: "CREATED"
  },
  {
    link: "#",
    label: "TEXTED",
    value: "TEXTED"
  },
  {
    link: "#",
    label: "CONFIRMED",
    value: "CONFIRMED"
  },
  {
    link: "#",
    label: "DR PRINTED",
    value: "DR PRINTED"
  },
  {
    link: "#",
    label: "PICKED & PACKED",
    value: "PICKED & PACKED"
  },
  {
    link: "#",
    label: "DISPATCHED",
    value: "DISPATCHED"
  },
  {
    link: "#",
    label: "DELIVERED",
    value: "DELIVERED"
  },
  {
    link: "#",
    label: "CANCELLED",
    value: "CANCELLED"
  }
];

const TransactionHeaderDropdown = ({
  statusLog,
  postStatusLog,
  setStatusLog
}) => (
  <div className="field control has-addons">
    <div className="select">
      <select
        name="status"
        onChange={event => setStatusLog(event.target.value)}
      >
        {DropdownStatus.map((item, index) => (
          <option
            key={index}
            value={item.value}
            selected={item.value === statusLog ? true : false}
          >
            {item.label}
          </option>
        ))}
      </select>
    </div>

    <div className="control">
      <button
        type="submit"
        className="button is-primary"
        onClick={() => postStatusLog()}
      >
        Save
      </button>
    </div>
  </div>
);

export default TransactionHeaderDropdown;
