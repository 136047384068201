import React, { Component, Fragment } from "react";
import axios from "axios";
import { parse } from "query-string";
import { connect } from "react-redux";
import PageContent from "UIKit/components/PageContent";
import DrugCard from "Printables/Receipt/DrugCard/DrugCard";
import DRClientCopy from "Printables/Receipt/DR/DRClientCopy";
import DRMedGrocerCopy from "Printables/Receipt/DR/DRMedGrocerCopy";
import SelectReceiptToPrint from "./SelectReceiptToPrint";
import Section from "../Section";
import api from "Services/api";
import printables from "./utils/printables.json";
import "Printables/printables.scss";
import RefillForm from "Printables/RefillForm";

class Receipt extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transactions: [],
      drugs: [],
      isFetchingTransactions: false,
      printables: printables,
    };
  }

  componentDidMount() {
    var transactionsToPrint = [this.props.match.params.id];
    if (!transactionsToPrint[0]) {
      const orders = parse(this.props.location.search).orders;
      if (Array.isArray(orders)) {
        transactionsToPrint = orders;
      } else {
        transactionsToPrint = [orders];
      }
    }
    var calls = [];
    transactionsToPrint.forEach((transaction) => {
      const url = `transactions/${transaction}/`;
      calls.push(api.get(url));
    });
    axios
      .all(calls)
      .then((...args) => {
        const mergedTransactions = args[0].reduce(
          (accumulator, currentValue) => {
            accumulator.push(currentValue.data);
            return accumulator;
          },
          []
        );

        this.setState((previousState) => ({
          transactions: mergedTransactions || [],
        }));
      })
      .catch((error) => {
        console.log(error);
      });
  }

  printDrugCard = () => {
    window.print();
  };

  handleBackToDashboard = () => {
    if (this.props.history.location.pathname === "/orders/print/") {
      this.props.history.goBack();
    } else {
      this.props.history.go(-2);
    }
  };

  isDocumentShowable = (pageType) => {
    const page = this.state.printables.find((info) => info.page === pageType);
    if (page) return page.checked;
    return page;
  };

  handleToggleDocument = (event, index) => {
    let { checked } = event.target;
    this.setState((previousState) => {
      let { printables } = previousState;
      printables[index].checked = checked;
      return { ...this.state, printables: [...printables] };
    });
  };

  render() {
    const multipleCopies = ["CUSTOMER'S COPY"];
    return (
      <PageContent>
        <div className="notification is-warning">
          <p className="print-message">
            You are printing{" "}
            <u>
              <strong>drug cards and delivery receipts</strong>
            </u>
            . Please verify information before printing.
          </p>
        </div>

        <SelectReceiptToPrint
          printables={printables}
          handleToggleDocument={this.handleToggleDocument}
          printDrugCard={this.printDrugCard}
          handleBackToDashboard={this.handleBackToDashboard}
        />

        <div className="receipt">
          <Section title="Page Summary">
            {this.state.transactions.map((transaction, index) => {
              return (
                <Fragment>
                  {transaction.details.length > 0 &&
                    this.isDocumentShowable("Drug Info Sheet") && (
                      <DrugCard
                        key={index}
                        transaction={transaction}
                        products={transaction.details}
                      />
                    )}

                  {this.isDocumentShowable("Delivery Receipt") && (
                    <div>
                      {transaction.channel === "flexmed" ? (
                        multipleCopies.map((copy, index) => (
                          <DRClientCopy
                            key={index}
                            transaction={transaction}
                            products={transaction.details}
                            transactionItems={transaction.details}
                            subtitle={copy}
                          />
                        ))
                      ) : (
                        <DRClientCopy
                          transaction={transaction}
                          drugs={this.state.drugs[index]}
                          transactionItems={transaction.details}
                          subtitle="CUSTOMER'S COPY"
                        />
                      )}
                      <DRMedGrocerCopy
                        transaction={transaction}
                        drugs={this.state.drugs[index]}
                        transactionItems={transaction.details}
                      />
                    </div>
                  )}
                  {this.isDocumentShowable("Refill Form") && (
                    <RefillForm transaction={transaction} />
                  )}
                </Fragment>
              );
            })}
          </Section>
        </div>
      </PageContent>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.entities.products,
  drugs: state.entities.drugs,
  transactions: state.entities.transactions,
  selectedTransactions: state.selectedTransactions,
});

export default connect(mapStateToProps)(Receipt);
