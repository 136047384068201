import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Auth } from "aws-amplify";
import classNames from "classnames";

import styles from "../utils/navigation.module.scss";

const NavbarDropdown = ({ label, children, links, authorizedGroups }) => {
  const [isAuthorized, setIsAuthorized] = useState(false);

  /* eslint-disable */
  useEffect(() => {
    validateAuthorization();
  }, []);

  const validateAuthorization = async () => {
    try {
      if (!authorizedGroups) {
        setIsAuthorized(true);
        return;
      }
      const userDetail = await Auth.currentSession();
      const userGroups = await userDetail.getAccessToken().payload[
        "cognito:groups"
      ];
      authorizedGroups.forEach(group => {
        if (userGroups.includes(group) || userGroups.includes("admin"))
          setIsAuthorized(true);
      });
    } catch (error) {
      setIsAuthorized(false);
    }
  };

  if (!isAuthorized) return null;

  return (
    <div
      className={classNames(
        "navbar-item has-dropdown is-hoverable has-text-centered",
        styles["navbar-item"]
      )}
    >
      <span
        className={classNames(
          "navbar-link has-text-white",
          styles["navbar-item"]
        )}
      >
        {label}
      </span>
      <div className="navbar-dropdown">
        {children.map((child, index) => (
          <Link
            key={index}
            className={classNames("navbar-item", styles["navbar-item"])}
            to={links[index]}
          >
            {child}
          </Link>
        ))}
      </div>
    </div>
  );
};

export default NavbarDropdown;
