import React, { Fragment, useState, useEffect, useContext } from "react";
import { AuthContext } from "Auth/authContext";
import classNames from "classnames";
import { Link } from "react-router-dom";
import ExportTransactionModal from "../ExportTransactionModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPrint,
  faPlus,
  faAngleDown,
} from "@fortawesome/free-solid-svg-icons";

const ActionButtons = ({ handleSelectBatchStatusUpdate, ordersToPrint }) => {
  const { authState } = useContext(AuthContext);
  const [isModalActive, setIsModalActive] = useState(false);
  const [isVerifiedToExport, setIsVerifiedToExport] = useState(false);
  let ordersQueryString = "?";

  ordersToPrint.map((order) => (ordersQueryString += `orders=${order}&`));

  useEffect(() => {
    const groups = authState.user.getAccessToken().payload["cognito:groups"];
    if (groups.includes("pbm-lead") || groups.includes("admin"))
      setIsVerifiedToExport(true);
  }, []);

  return (
    <Fragment>
      <div className="field is-grouped">
        <div className="control">
          <div className="dropdown is-hoverable">
            <div className="dropdown-trigger">
              <button
                className="button is-primary"
                aria-haspopup="true"
                aria-controls="dropdown-menu4"
              >
                <span className="icon is-small">
                  <FontAwesomeIcon icon={faPlus} />
                </span>
                <span>New Order</span>
                <span className="icon is-small">
                  <FontAwesomeIcon icon={faAngleDown} />
                </span>
              </button>
            </div>
            <div className="dropdown-menu" id="dropdown-neworder" role="menu">
              <div className="dropdown-content">
                <Link to={`/orders/new`}>
                  <div className="dropdown-item">Single</div>
                </Link>

                <Link to={`/batch-transaction`}>
                  <div className="dropdown-item">Batch</div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="control">
          <div className="dropdown is-hoverable">
            <div className={classNames({ "is-hidden": !ordersToPrint.length })}>
              <div className="dropdown-trigger">
                <button
                  className="button is-primary"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu4"
                >
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faPrint} />
                  </span>
                  <span>Print</span>
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faAngleDown} />
                  </span>
                </button>
              </div>
            </div>
            <div className="dropdown-menu" id="dropdown-neworder" role="menu">
              <div className="dropdown-content">
                <Link to={`orders/print/${ordersQueryString}`}>
                  <div className="dropdown-item">Delivery Receipt</div>
                </Link>

                <Link to={`/orders/print_si/${ordersQueryString}`}>
                  <div className="dropdown-item">Sales Invoice</div>
                </Link>
              </div>
            </div>
          </div>
        </div>

        <div className="control">
          <div className="select">
            <select
              className="select button"
              value=""
              onChange={handleSelectBatchStatusUpdate}
            >
              <option value="" hidden>
                Update status
              </option>
              <option value="TEXTED">TEXTED</option>
              <option value="CONFIRMED">CONFIRMED</option>
              <option value="DR PRINTED">DR PRINTED</option>
              <option value="PICKED & PACKED">PICKED & PACKED</option>
              <option value="FOR DELIVERY">FOR DELIVERY</option>
              <option value="DISPATCHED">DISPATCHED</option>
              <option value="DELIVERED">DELIVERED</option>
              <option value="CANCELLED">CANCELLED</option>
            </select>
          </div>
        </div>
        {isVerifiedToExport && (
          <button
            className="button is-light ml-1"
            onClick={() => setIsModalActive(true)}
          >
            Export transactions{" "}
          </button>
        )}
      </div>
      <ExportTransactionModal
        isModalActive={isModalActive}
        setIsModalActive={setIsModalActive}
      />
    </Fragment>
  );
};

export default ActionButtons;
