import React, { Component, Fragment, createRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { without } from "lodash";
import axios from "axios";
import { parse } from "query-string";

import TransactionRow from "./TransactionRow";
import PageContent from "UIKit/components/PageContent";
import Dashboard from "Dashboard/components/Dashboard";
import Submenu from "./Submenu/Submenu";
import ActionButtons from "./Submenu/ActionButtons";
import NewOrderButtons from "./Submenu/NewOrderButtons";
import api from "Services/api";
import { SearchBar } from "UIKit/components/Forms/FormInput";
import { setSearchQuery } from "Search/actions";
import { stringify } from "querystring";

import LevelFilters from "UIKit/components/Level/Level";
import levelfilters from "./levelfilters.json";

const headers = [
  { displayName: "Status", attributeName: "current_status", canSortBy: true },
  {
    displayName: "Created",
    attributeName: "created",
    canSortBy: true,
  },
  { displayName: "Order ID", attributeName: "id", canSortBy: true },
  {
    displayName: "Customer",
    attributeName: "customer_last_name",
    canSortBy: true,
  },
  {
    displayName: "City",
    attributeName: "shipping_city_municipality",
    canSortBy: true,
  },
  { displayName: "Value", attributeName: "total", canSortBy: false },
  // { displayName: "Created By", attributeName: "created_by", canSortBy: true },
  { displayName: "Last Update", attributeName: "last_update", canSortBy: true },
];

const propTypes = { pushNotification: PropTypes.func };
const ENTER = 13;
class TransactionsDashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTransactions: [],
      selectedLevel: "default",
      channelFilter: "ALL",
      searchQuery: "",
      searchString: "",
    };
    this.levelFilters = createRef();
    this.dashboard = createRef();
  }

  onLevelClick = (link) => {
    var urlParameters = parse(this.props.history.location.search);
    if (urlParameters.search) {
      delete urlParameters["search"];
      this.setState({
        searchQuery: "",
      });
    }
    delete urlParameters["date_updated"];
    this.props.history.push(
      `?${stringify({ ...urlParameters, ...parse(link) }, false)}`
    );
  };

  onSelectTransactions = (transactions) => {
    this.setState((previousState) => {
      return {
        selectedTransactions: [
          ...previousState.selectedTransactions,
          ...transactions,
        ],
      };
    });
  };

  onDeselectTransactions = (transactions) => {
    this.setState((previousState) => {
      return {
        selectedTransactions: without(
          previousState.selectedTransactions,
          ...transactions
        ),
      };
    });
  };

  onChangePage = (currentPage) => {
    const urlParameters = parse(this.props.history.location.search);
    urlParameters.page = currentPage;
    this.props.history.push(`?${stringify(urlParameters)}`);
  };

  getDashboardFilters = () => {
    const urlParameters = parse(this.props.history.location.search, {
      decode: false,
    });
    return Object.keys(urlParameters).reduce((accumulator, key) => {
      var newFilters;
      if (Array.isArray(urlParameters[key]))
        newFilters = urlParameters[key].map((value) => ({
          field: key,
          value: value,
        }));
      else newFilters = [{ field: key, value: urlParameters[key] }];
      return [...accumulator, ...newFilters];
    }, []);
  };

  handleChannelFilter = (event) => {
    const value = event.target.value;
    const urlParameters = parse(this.props.history.location.search);
    urlParameters.page = 1;
    urlParameters.channel = value;

    if (urlParameters.channel === "all" || urlParameters.channel === "")
      delete urlParameters.channel;
    this.props.history.push(`?${stringify(urlParameters)}`);
  };

  handleSelectBatchStatusUpdate = (event) => {
    const { pushNotification } = this.props;
    const newStatus = event.target.value;
    let updateTransactionsCalls = [];
    this.state.selectedTransactions.forEach((transaction) => {
      updateTransactionsCalls.push(
        api.patch(`transactions/${transaction}/`, {
          currentStatus: newStatus.toUpperCase(),
        })
      );
    });
    axios
      .all(updateTransactionsCalls)
      .then((...args) => {
        this.levelFilters.current.refresh();
        this.dashboard.current.fetchData();
        this.setState({ selectedTransactions: [] });
        pushNotification(4000, `Transactions updated.`, "is-info");
      })
      .catch((error) => console.log("error is: ", error));
  };

  handleSearchbarInputChange = (event) => {
    this.setState({ searchString: event.target.value });
  };

  handleChangeUrl = (filters) => {
    const urlParameters = parse(this.props.history.location.search);
    urlParameters.current_status = filters;
    this.props.history.push(`?${stringify(urlParameters)}`);
  };

  handleKeyPress = (event) => {
    switch (event.keyCode) {
      case ENTER:
        const urlParameters = parse(this.props.history.location.search);
        urlParameters.search = this.state.searchString;
        this.props.history.push(`?${stringify(urlParameters)}`);
        this.setState({
          searchQuery: this.state.searchString,
        });
        break;
      default:
        break;
    }
  };

  render() {
    const filters = this.getDashboardFilters();
    const { selectedTransactions, selectedLevel } = this.state;
    const pageFilter = filters.find((filter) => {
      return filter.field === "page";
    });
    const page = pageFilter ? pageFilter.value : 1;
    var channel = filters.find((filter) => {
      return filter.field === "channel";
    });
    if (!channel) channel = "";
    else channel = channel.value;
    return (
      <PageContent>
        <LevelFilters
          url="/transactions"
          levelFilters={levelfilters}
          callback={this.handleChangeUrl}
        />
        <Submenu
          leftButtons={
            <div>
              <ActionButtons
                handleSelectBatchStatusUpdate={
                  this.handleSelectBatchStatusUpdate
                }
                ordersToPrint={selectedTransactions}
              />
            </div>
          }
          rightButtons={
            <NewOrderButtons
              handleChannelFilter={this.handleChannelFilter}
              channelFilter={channel}
              search={
                <Fragment>
                  <SearchBar
                    id="search"
                    onChange={this.handleSearchbarInputChange}
                    value={this.state.searchString}
                    withIcon={true}
                    hideResults={false}
                    onKeyDown={this.handleKeyPress}
                  />
                </Fragment>
              }
            />
          }
        />
        <Dashboard
          baseUrl="transactions/?"
          onSelectRows={this.onSelectTransactions}
          onDeselectRows={this.onDeselectTransactions}
          selectedData={this.state.selectedTransactions}
          headers={headers}
          RowComponent={TransactionRow}
          idAttribute="id"
          filters={filters}
          ref={this.dashboard}
          isCheckboxEnabled={true}
          dashboardClassname="is-transaction-dashboard"
          searchQuery={this.state.searchQuery}
          onChangePage={this.onChangePage}
          currentPage={page}
        />
      </PageContent>
    );
  }
}

const mapStateToProps = (state) => ({
  searchResult: state.searchResult,
});

TransactionsDashboard.propTypes = propTypes;

export default withRouter(
  connect(mapStateToProps, { setSearchQuery })(TransactionsDashboard)
);
