import React from "react";

const Input = ({
  name,
  type,
  placeholder,
  defaultValue,
  modifier,
  onChange,
  isReadOnly,
  value
}) => (
  <div className="field is-grouped">
    <p className="control is-expanded">
      <input
        name={name}
        className={`input ${modifier}`}
        type={type}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChange}
        readOnly={isReadOnly ? true : false}
        value={value}
      />
    </p>
  </div>
);

export default Input;
