import React, { useContext } from "react";
import { AuthContext } from "Auth/authContext";
import NavbarLink from "Navigation/components/NavbarLink";
import NavbarDropdown from "Navigation/components/NavbarDropdown";
import "../utils/navigation.module.scss";
import PropTypes from "prop-types";

const propTypes = {
  isSearchBarActive: PropTypes.bool,
  searchQuery: PropTypes.string,
  searchResults: PropTypes.array
};

const Navbar = () => {
  return (
    <nav
      className={`navbar is-fixed-top ${
        process.env.NODE_ENV === "development"
          ? "has-background-dark"
          : "has-background-primary"
      }`}
    >
      <div className="navbar-brand">
        <a className="navbar-item mg-logo" href="/">
          <img src={require("assets/images/mg-white.png")} alt="MedGrocer" />{" "}
          {process.env.NODE_ENV === "development" ? (
            <sup className="has-text-light">BETA</sup>
          ) : null}
        </a>
      </div>

      <div className="navbar-menu">
        <div className="navbar-start">
          <NavbarLink
            label="Orders"
            link="/orders?page=1"
            authorizedGroups={["cons"]}
          />
          {/* <NavbarLink label="Dashboard" link="/dashboard" target="_blank" /> */}
          <NavbarDropdown
            label="Products"
            children={["Product List", "Requested Products"]}
            links={["/products", "/products/requested"]}
          />
          <NavbarDropdown
            label="Purchase Orders"
            children={["Quotes", "Purchase Orders"]}
            links={["/quotes?page=1", "/purchase-orders"]}
            authorizedGroups={["entp"]}
          />
          <NavbarLink
            label="Sourcing"
            link="/sourcing"
            authorizedGroups={["entp"]}
          />
          <NavbarLink
            label="Clients"
            link="/clients"
            authorizedGroups={["entp"]}
          />
        </div>

        <div className="navbar-end">
          <NavbarLink label="Log out" link="/logout" />
        </div>
      </div>
    </nav>
  );
};

Navbar.propTypes = propTypes;

export default Navbar;
