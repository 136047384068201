import React from "react";

const DataRow = ({
  id,
  onSelectRow,
  onDeselectRow,
  isSelected,
  children,
  isCheckboxEnabled,
  callbackRowFunction
}) => {
  const onChangeSelect = event => {
    if (event.target.checked) {
      onSelectRow(id);
    } else {
      onDeselectRow(id);
    }
  };
  return (
    <tr
      className="dashboard-row"
      onClick={event => {
        event.preventDefault()
        if (callbackRowFunction)
          callbackRowFunction(id);
      }}
    >
      {isCheckboxEnabled ? (
        <td onClick={(event) => event.stopPropagation()}>
          <label className="checkbox-field">
            <input
              type="checkbox"
              onChange={onChangeSelect}
              checked={isSelected}
            />
            <span className="checkmark" />
          </label>
        </td>
      ) : null}
      {children}
    </tr>
  );
};

export default DataRow;
