import React from "react";
import "./Table.scss";

export const Table = (props) => {
  return <div className={`div-table ${props.className}`}>{props.children}</div>;
};

export const Row = (props) => {
  return (
    <div
      className={`div-table-row ${
        props.isTableHeader ? "row-table-header" : ""
      } ${props.className}`}
      onClick={props.onClick || null}
    >
      {props.children}
    </div>
  );
};

export const TableBody = (props) => {
  return <div className="table-body">{props.children}</div>;
};

export const Cell = (props) => {
  return (
    <div
      className={`div-table-cell ${
        props.priority ? "cell-priority" : "cell-normal"
      } ${props.className}`}
    >
      {props.value}
      {props.children}
    </div>
  );
};
