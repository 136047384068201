import React, { Fragment, Component } from "react";
import { Field, ErrorMessage } from "formik";
import { PanelContent } from "UIKit/components/SplitContainer/SplitContainer";

class SiteDetailSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      originalSiteLetters: props.values ? props.values.siteLetters : ""
    };
  }

  render() {
    return (
      <Fragment>
        <PanelContent>
          <div className="columns">
            <div className="column is-half">
              <label>Trade Name</label>
              <Field
                placeholder="e.g Teleperformance Aura"
                name="name"
                className="input"
              />
              <ErrorMessage
                name="name"
                render={msg => (
                  <div className="has-text-danger is-size-7">{msg}</div>
                )}
              />
            </div>
            <div className="column ">
              <label>Site Code</label>
              <Field
                placeholder="e.g HQ1"
                name="siteCode"
                className="input"
                maxlength="4"
                onChange={this.props.handleVerifySiteLetters(
                  this.props.values,
                  this.props.handleChange
                )}
              />

              <ErrorMessage
                name="siteCode"
                render={msg => (
                  <div className="has-text-danger is-size-7">{msg}</div>
                )}
              />

              {!this.props.errors.siteCode && this.props.hasDuplicateError && (
                <div className="has-text-danger is-size-7">
                  {this.props.duplicateErrorMessage}
                </div>
              )}
            </div>
            {/* <div className="column "> */}
            {/* <label>Site Number</label>
              <Field
                type="number"
                placeholder="1"
                name="siteNumber"
                className="input"
                onChange={this.props.handleVerifySiteLetters(
                  this.props.values,
                  this.props.handleChange
                )}
              /> */}
            <ErrorMessage
              name="siteNumber"
              render={msg => (
                <div className="has-text-danger is-size-7">{msg}</div>
              )}
            />
            {/* </div> */}
          </div>
          <div className="columns">
            <div className="column ">
              <label>Contact Person</label>
              <Field
                placeholder="e.g Juan dela Cruz"
                name="contactPerson"
                className="input"
              />
              <ErrorMessage
                name="contactPerson"
                render={msg => (
                  <div className="has-text-danger is-size-7">{msg}</div>
                )}
              />
            </div>
            <div className="column ">
              <label>Contact Number</label>
              <Field
                placeholder="ex. 09123456789"
                name="contactNumber"
                className="input"
              />
            </div>
          </div>
          <div className="columns">
            <div className="column ">
              <label>Email Address</label>
              <Field
                placeholder="ex. solutions@medgrocer.com"
                name="emailAddress"
                className="input"
              />
              <ErrorMessage
                name="emailAddress"
                render={msg => (
                  <div className="has-text-danger is-size-7">{msg}</div>
                )}
              />
            </div>
            <div className="column ">
              <label>Vat Type</label>
              <div className="select">
                <Field component="select" name="taxRate">
                  <option value="">--- Please select VAT type ---</option>
                  <option value="tax_on_sales">Tax on Sales</option>
                  <option value="tax_exempt">Tax Exempt</option>
                  <option value="zero_rated">Zero Rated</option>
                </Field>
                <ErrorMessage
                  name="taxRate"
                  render={msg => (
                    <div className="has-text-danger is-size-7">{msg}</div>
                  )}
                />
              </div>
            </div>
          </div>
        </PanelContent>
      </Fragment>
    );
  }
}

export default SiteDetailSection;
