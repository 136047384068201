import React from "react";
import PropTypes from "prop-types";
import { CSVErrorMessage } from "./CSVErrorMessage";

const customerTypes = ["regular", "affiliate", "corporate"];
const paymentMethods = [
  "COD",
  "Bank Deposit - BPI",
  "Bank Deposit - Metrobank",
  "Bank Transfer - BPI",
  "Bank Transfer - Metrobank",
  "Credit",
  "Credit with Cash Out",
  "GCash"
];
const channels = [
  "regular",
  "regular_vip",
  "patient_access",
  "medensure",
  "flexmed",
  "chronic_care"
];

const BatchTransactionErrorView = ({ errors }) => (
  <div className="columns is-centered">
    <div className="column is-two-thirds">
      <article className="message is-primary">
        <div className="message-header">
          <p>Please address the following errors and try again</p>
        </div>
        <div className="message-body">
          {!!errors.fieldWarnings && (
            <CSVErrorMessage
              errorType={errors.fieldWarnings}
              message="Blank or invalid field/s:"
            />
          )}

          {!!errors.invalidChannel && (
            <CSVErrorMessage
              errorType={errors.invalidChannel}
              message={
                <>
                  Invalid <code>channel</code>
                </>
              }
              acceptableValues={channels}
            />
          )}

          {!!errors.invalidCustomerType && (
            <CSVErrorMessage
              errorType={errors.invalidCustomerType}
              message={
                <>
                  Invalid <code>customer_type</code>
                </>
              }
              acceptableValues={customerTypes}
            />
          )}

          {!!errors.invalidPaymentMethod && (
            <CSVErrorMessage
              errorType={errors.invalidPaymentMethod}
              message={
                <>
                  Invalid <code>payment_method</code>
                </>
              }
              acceptableValues={paymentMethods}
            />
          )}

          {!!errors.duplicateProduct && (
            <CSVErrorMessage
              errorType={errors.duplicateProduct}
              message="Duplicate Items:"
            />
          )}

          {!!errors.invalidProductItems && (
            <CSVErrorMessage
              errorType={errors.invalidProductItems}
              message="Item/s not in product list:"
            />
          )}

          {!!errors.invalidCouponCodes && (
            <CSVErrorMessage
              errorType={errors.invalidCouponCodes}
              message="Invalid coupon code/s:"
            />
          )}

          {!!errors.invalidPricelist && (
            <CSVErrorMessage
              errorType={errors.invalidPricelist}
              message="Blank product title/s or invalid price list/s:"
            />
          )}
        </div>
      </article>
    </div>
  </div>
);

BatchTransactionErrorView.propTypes = {
  errors: PropTypes.object
};

export default BatchTransactionErrorView;
