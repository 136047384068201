import React, { Fragment, Component } from "react";
import { PanelContent } from "UIKit/components/SplitContainer/SplitContainer";
import { Field } from "formik";
import FieldContainer from "UIKit/components/SplitContainer/FieldContainer";
import provinces from "../../../../assets/data/philippineLocations.json";

class SiteAddressDetailSection extends Component {
  constructor() {
    super();
    this.state = { isHidden: false };
  }

  handleToggleBillingInformation = event =>
    this.setState(previousState => ({ isHidden: !previousState.isHidden }));

  render() {
    const selectedBillingProvince = provinces.find(
      province => province.Province === this.props.values.billingProvince
    );
    const availableBillingCityMunicipalities = !!selectedBillingProvince
      ? selectedBillingProvince["Cities"]
      : [];

    const selectedShippingProvince = provinces.find(
      province => province.Province === this.props.values.shippingProvince
    );
    const availableShippingCityMunicipalities = !!selectedShippingProvince
      ? selectedShippingProvince["Cities"]
      : [];

    return (
      <Fragment>
        <PanelContent header="Shipping Address Information">
          <FieldContainer label="Street Address">
            <Field
              name="shippingStreetAddress"
              className="input"
              placeholder="Input complete address"
            />
          </FieldContainer>
          <div className="columns">
            <div className="column is-half">
              <FieldContainer label="Province">
                <div className="select">
                  <Field name="shippingProvince" component="select">
                    <option value="">--Select Province--</option>
                    {provinces.map(province => (
                      <option
                        key={province["Province"]}
                        value={province["Province"]}
                      >
                        {province["Province"]}
                      </option>
                    ))}
                  </Field>
                </div>
              </FieldContainer>
            </div>
            <div className="column is-half">
              <FieldContainer label="Municipality">
                <div className="select">
                  <Field name="shippingCityMunicipality" component="select">
                    {availableShippingCityMunicipalities.length ? (
                      availableShippingCityMunicipalities.map(city => (
                        <option key={city["City"]} value={city["City"]}>
                          {city["City"]}
                        </option>
                      ))
                    ) : (
                      <option value="">-- Select a Province First --</option>
                    )}
                  </Field>
                </div>
              </FieldContainer>
            </div>
          </div>
          <div className="field">
            <input
              id="toggleBillingInformation"
              type="checkbox"
              name="toggleBillingInformation"
              onClick={this.handleToggleBillingInformation}
            />
            <label htmlFor="toggleBillingInformation">
              Use this information for billing address
            </label>
          </div>
        </PanelContent>

        {!this.state.isHidden && (
          <PanelContent header="Billing Address Information">
            <FieldContainer label="Street Address">
              <Field
                name="billingStreetAddress"
                className="input"
                placeholder="Input complete address"
              />
            </FieldContainer>
            <div className="columns">
              <div className="column is-half">
                <FieldContainer label="Province">
                  <div className="select">
                    <Field name="billingProvince" component="select">
                      <option value="">--Select Province--</option>
                      {provinces.map(province => (
                        <option
                          key={province["Province"]}
                          value={province["Province"]}
                        >
                          {province["Province"]}
                        </option>
                      ))}
                    </Field>
                  </div>
                </FieldContainer>
              </div>
              <div className="column is-half">
                <FieldContainer label="Municipality">
                  <div className="select">
                    <Field name="billingCityMunicipality" component="select">
                      {availableBillingCityMunicipalities.length ? (
                        availableBillingCityMunicipalities.map(city => (
                          <option key={city["City"]} value={city["City"]}>
                            {city["City"]}
                          </option>
                        ))
                      ) : (
                        <option value="">-- Select a Province First --</option>
                      )}
                    </Field>
                  </div>
                </FieldContainer>
              </div>
            </div>
          </PanelContent>
        )}
      </Fragment>
    );
  }
}

export default SiteAddressDetailSection;
