import api from "Services/api";
import apiQueue from "Services/apiQueue";

export const ORDER_ITEM_MUTATION = {
  LOADING: "ORDER_ITEM_MUTATION_LOADING",
  EDITED: "ORDER_ITEM_MUTATION_EDITED",
  DELETED: "ORDER_ITEM_MUTATION_DELETED",
  SET: "ORDER_ITEM_MUTATION_SET",
  ADDED: "ORDER_ITEM_MUTATION_ADDED",
};

export const ORDER_DETAIL_MUTATION = {
  LOADING: "ORDER_DETAIL_MUTATION_LOADING",
  EDITED: "ORDER_DETAIL_MUTATION_EDITED",
  DELETED: "ORDER_DETAIL_MUTATION_DELETED",
  SET: "ORDER_DETAIL_MUTATION_SET",
  ADDED: "ORDER_DETAIL_MUTATION_ADDED",
};

export const DRAFT_PURCHASE_ORDER_ACTIONS = {
  SUCCESS: "FETCH_DRAFT_PURCHASE_ORDER_SUCCESS",
  FAILURE: "FETCH_DRAFT_PURCHASE_ORDER_FAILURE",
  FETCHING: "FETCH_DRAFT_PURCHASE_ORDER_FETCHING",
};

export const PURCHASE_ORDER_ACTIONS = {
  SUCCESS: "FETCH_PURCHASE_ORDER_SUCCESS",
  FAILURE: "FETCH_PURCHASE_ORDER_FAILURE",
};

export const DRAFT_PURCHASE_ORDER_ITEMS_ACTIONS = {
  LOADING: "FETCH_DRAFT_PURCHASE_ORDER_ITEMS_LOADING",
  SUCCESS: "FETCH_DRAFT_PURCHASE_ORDER_ITEMS_SUCCESS",
  FAILURE: "FETCH_DRAFT_PURCHASE_ORDER_ITEMS_FAILURE",
  FETCHING: "FETCH_DRAFT_PURCHASE_ORDER_ITEMS",
};

export const FETCH_STATUS_B2B_STATUS = {
  SUCCESS: "FETCH_STATUS_B2B_STATUS_SUCCESS",
  FAILURE: "FETCH_STATUS_B2B_STATUS_FAILURE",
  FETCHING: "FETCH_STATUS_B2B_STATUS_FETCHING",
};

export const fetchOrderDetail = (id, baseUrl) => async (dispatch) => {
  try {
    dispatch({
      type: DRAFT_PURCHASE_ORDER_ACTIONS.FETCHING,
      payload: null,
    });
    const objectFetched = await api.get(`${baseUrl}/${id}/`);
    return dispatch({
      type: DRAFT_PURCHASE_ORDER_ACTIONS.SUCCESS,
      payload: objectFetched.data,
    });
  } catch (error) {
    return dispatch({
      type: DRAFT_PURCHASE_ORDER_ACTIONS.FAILURE,
      payload: null,
      error: error,
    });
  }
};

export const fetchOrderItems = (id, baseUrl) => async (dispatch) => {
  try {
    dispatch({
      type: DRAFT_PURCHASE_ORDER_ITEMS_ACTIONS.FETCHING,
      payload: null,
    });
    const objectFetched = await api.get(`${baseUrl}=${id}&page_size=0`);
    return dispatch({
      type: DRAFT_PURCHASE_ORDER_ITEMS_ACTIONS.SUCCESS,
      payload: objectFetched.data,
    });
  } catch (e) {
    return dispatch({
      type: DRAFT_PURCHASE_ORDER_ITEMS_ACTIONS.FAILURE,
      payload: null,
      error: e,
    });
  }
};

export const fetchStatusB2B = () => async (dispatch) => {
  try {
    dispatch({
      type: FETCH_STATUS_B2B_STATUS.FETCHING,
      payload: null,
    });
    const status = await api.get(`/orders/status/?primary_business_line=1`);
    return dispatch({
      type: FETCH_STATUS_B2B_STATUS.SUCCESS,
      payload: status.data.results,
    });
  } catch (err) {
    return dispatch({
      type: FETCH_STATUS_B2B_STATUS.FAILURE,
      payload: err.response,
    });
  }
};

export const orderItemMutation = async (baseUrl, values, type, dispatch) => {
  dispatch({
    status: ORDER_ITEM_MUTATION.LOADING,
    payload: values,
  });
  if (type === "EDIT") {
    const { data } = await api.patch(`${baseUrl}/items/${values.id}/`, values);
    return dispatch({ status: ORDER_ITEM_MUTATION.EDITED, payload: data });
  } else if (type === "DELETE") {
    await api.delete(`${baseUrl}/items/${values.id}/`);
    return dispatch({ status: ORDER_ITEM_MUTATION.DELETED, payload: values });
  } else if (type === "SET") {
    dispatch({ status: ORDER_ITEM_MUTATION.SET, payload: values });
  } else if (type === "ADD") {
    console.log(`${baseUrl}/items/`);
    const { data } = await apiQueue.post(`${baseUrl}/items/`, values);
    return dispatch({ status: ORDER_ITEM_MUTATION.ADDED, payload: data });
  }
};

export const orderDetailMutation = async (baseUrl, values, type, dispatch) => {
  dispatch({ status: ORDER_DETAIL_MUTATION.LOADING, payload: values });
  if (type === "EDIT") {
    const { data } = await api.patch(`${baseUrl}/${values.id}/`, values);
    return dispatch({ status: ORDER_DETAIL_MUTATION.EDITED, payload: data });
  } else if (type === "DELETE") {
    await api.delete(`${baseUrl}/${values.id}/`);
    return dispatch({ status: ORDER_DETAIL_MUTATION.DELETED, payload: values });
  } else if (type === "SET") {
    dispatch({ status: ORDER_DETAIL_MUTATION.SET, payload: values });
  } else if (type === "ADD") {
    const { data } = await api.post(`${baseUrl}/`, values);
    return dispatch({ status: ORDER_DETAIL_MUTATION.ADDED, payload: data });
  }
};

export const orderItemFetch = async (baseUrl, subUrl, orderid, dispatch) => {
  dispatch({ status: ORDER_DETAIL_MUTATION.LOADING, payload: null });
  try {
    const queryString = `${baseUrl}/items/?${subUrl}=${orderid}&page_size=0`;
    const { data } = await api.get(queryString);
    dispatch({
      status: ORDER_DETAIL_MUTATION.SUCCESS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      status: ORDER_DETAIL_MUTATION.FAILURE,
      payload: [],
    });
  }
};
