import React, { Fragment } from "react";
import { Row, Cell } from "UIKit/components/Table_v2/Table";
const formattedDateTime = dateTime => {
  const dateFormat = {
    month: "short",
    day: "numeric"
  };

  const timeFormat = {
    hour: "numeric",
    minute: "numeric"
  };
  return (
    <Fragment>
      {new Date(dateTime).toLocaleString("en-US", dateFormat)}
      <br />
      <div className="help">
        {new Date(dateTime).toLocaleString("en-US", timeFormat)}
      </div>
    </Fragment>
  );
};

const ClientTableRow = ({ data, redirectToSiteForm, setSiteIdToBeEdit }) => {
  const {
    siteLetters,
    siteNumber,
    name,
    contactPerson,
    lastModified,
    created
  } = data;
  return (
    <Fragment>
      <Row>
        <Cell>{siteLetters + siteNumber}</Cell>
        <Cell className="has-text-weight-bold status link-row" priority>
          <div
            onClick={() => redirectToSiteForm(data)}
            className="clickable has-text-primary"
          >
            {name}
          </div>
        </Cell>
        <Cell priority>{contactPerson}</Cell>
        <Cell className="has-text-centered" priority>
          {formattedDateTime(lastModified)}
        </Cell>
        <Cell className="has-text-centered" priority>
          {formattedDateTime(created)}
        </Cell>
      </Row>
    </Fragment>
  );
};

export default ClientTableRow;
