import React, { Component } from "react";
import { Form, Formik } from "formik";
import SplitContainer, {
  PanelContent,
  SideBarContainer,
  MainContent
} from "UIKit/components/SplitContainer/SplitContainer";
import SideBarContainerFields from "./SideBarContainerFields";
import ProductDetailBody, { MainPanelHeader } from "./ProductDetailBody";
import SourcingBody from "./SourcingBody";
import { connect } from "react-redux";
import {
  fetchProductRequestDetail,
  editProductRequestDetail,
  fetchFormList
} from "../actions";
import Loading from "UIKit/components/Loading";
import { blankProductRequestObject } from "./helpers";
import { pushNotification } from "UIKit/actions";

const ProductDetailPage = pageType => {
  class RequestedProductDetail extends Component {
    constructor(props) {
      super(props);
      this.state = {
        isTitleFieldWritable: false
      };
    }
    componentDidMount() {
      this.props.fetchFormList();
      if (pageType === "EDIT")
        this.props.fetchProductRequestDetail(this.props.match.params.id);
    }

    handleCallbackIfSuccess = response => {
      this.props.pushNotification(4000, "Successfully Saved.", "is-success");
      this.props.history.push(`/products_requests/${response.data.id}`);
    };
    handleCallbackIfError = error =>
      this.props.pushNotification(
        4000,
        "Error Something went wrong. " + error.message,
        "is-warning"
      );

    onSubmit = values => {
      editProductRequestDetail(
        values,
        this.handleCallbackIfSuccess,
        pageType,
        this.handleCallbackIfError
      );
    };

    handleChangeState = (key, value) =>
      this.setState(previousState => ({
        [key]: value
      }));

    render() {
      let productRequestDetailToManipulate =
        pageType === "EDIT"
          ? this.props.productRequestDetail
          : blankProductRequestObject;
      if (pageType === "EDIT" && !this.props.productRequestDetail) {
        return <Loading />;
      } else if (
        pageType === "EDIT" &&
        this.props.productRequestDetail.id !==
          parseInt(this.props.match.params.id)
      )
        return <Loading />;
      else
        return (
          <Formik
            initialValues={{ ...productRequestDetailToManipulate }}
            onSubmit={this.onSubmit}
          >
            {({ values, handleChange }) => (
              <Form autoComplete="off">
                <SplitContainer>
                  <SideBarContainer>
                    <PanelContent header="PRODUCT LIST REQUEST">
                      <SideBarContainerFields
                        handleChange={handleChange}
                        values={values}
                      />
                    </PanelContent>
                  </SideBarContainer>
                  <MainContent>
                    <PanelContent
                      header={MainPanelHeader({
                        isTitleFieldWritable: this.state.isTitleFieldWritable,
                        handleChangeSingleState: this.handleChangeState,
                        values,
                        handleChange
                      })}
                    >
                      <ProductDetailBody
                        handleChange={handleChange}
                        values={values}
                        formList={this.props.formList}
                      />
                    </PanelContent>
                    <br />
                    <PanelContent header="SOURCING">
                      <SourcingBody
                        handleChange={handleChange}
                        values={values}
                      />
                    </PanelContent>
                  </MainContent>
                </SplitContainer>
              </Form>
            )}
          </Formik>
        );
    }
  }

  const mapStateToProps = state => ({
    productRequestDetail: state.fetchProductRequestDetailReducer.result,
    formList: state.fetchFormReducer.result
  });
  return connect(
    mapStateToProps,
    { fetchProductRequestDetail, pushNotification, fetchFormList }
  )(RequestedProductDetail);
};

export default ProductDetailPage;
