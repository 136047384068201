import React from "react";
import "./SearchBar.scss";
import FuzzySearch from "./FuzzySearch";
import OptionListRender from "./SearchOption";
import api from "Services/api";
import { debounce } from "lodash";

export const _ESCAPE_CHARACTERS_REGEX = /[-\\^$*+?.()|[\]{}]/g;
const NO_SELECTED = -1;
const KEY_DOWN = 40;
const KEY_UP = 38;
const KEY_TAB = 9;
const KEY_ENTER = 13;
class SearchBar extends React.Component {
  constructor(props) {
    super(props);
    this._selectRef = React.createRef();
    this._optionsRef = [];
    this._inputRef = React.createRef();
    this.fuzzySearch = new FuzzySearch(props.optionList, props.keyElement);
    this.state = {
      inputValue: props.defaultValue,
      _selectHidden: true,
      _optionList: [],
      _regex: props.regexString,
      _keyValue: props.keyValue,
      _keyElement: props.keyElement,
      _optionSelected: NO_SELECTED,
      _useLevenshtein: !!props.useLevenshtein,
      _limitToFind: props.limitToFind || 10,
      _objectSelected: {},
      _searchProducts: {}
    };
  }
  changeState = (key, value) => {
    this.setState({ [key]: value });
  };
  escapedRegex = string => {
    let completeRegexString = "^";
    string
      .replace(_ESCAPE_CHARACTERS_REGEX, "\\$&")
      .toLowerCase()
      .split(" ")
      .forEach(word => (completeRegexString += `(?=.*${word})`));
    completeRegexString += ".*$";
    return new RegExp(completeRegexString);
  };
  onFocusOption = bool => event => {
    this.changeState("_optionSelected", NO_SELECTED);
    this.changeState("_selectHidden", !bool);
  };
  onChangeSearch = onChange => async event => {
    this.changeState("inputValue", event.target.value);
    let searchQuery = event.target.value;
    this.onFocusOption(false)(event);
    if (this.props.customOnChangeInput) this.props.customOnChangeInput(event);
    if (onChange) onChange(event);
    if (searchQuery !== "") await this.debouncedSearch(searchQuery);
  };

  debouncedSearch = debounce(searchQuery => this.search(searchQuery), 200);

  search = searchQuery => {
    const baseSearch = this.props.baseSearch
      ? this.props.baseSearch
      : "products/?";
    api
      .get(
        `${process.env.REACT_APP_API_HOST}${baseSearch}search=${searchQuery}`
      )
      .then(response => {
        const products = response.data.results || [];
        this.changeState(
          "_optionList",
          products.splice(0, this.state._limitToFind)
        );
        this.changeState("_selectHidden", products.length >= 0 ? false : true);
      });
  };

  onBlurOption = event => {
    event.preventDefault();
    setTimeout(() => {
      this.onFocusOption(false)(event);
    }, 400);
  };
  handleChangeSelect = optionText => {
    this.changeState("inputValue", optionText[this.state._keyElement]);
  };

  handleSerializeKeyMapping = type => {
    const optionSelected = this.state._optionSelected;
    const optionsLength = Object.keys(this.state._optionList).length;
    let bool = optionSelected >= -1 && optionSelected < optionsLength - 1;
    if (type === KEY_UP) bool = optionSelected > 0;
    return bool;
  };
  handleScrollIntoView = event => {
    let scrollValue = this.state._optionSelected;
    if (event.keyCode === KEY_DOWN) scrollValue += 1;
    if (event.keyCode === KEY_UP) scrollValue -= 1;
    if (this._optionsRef[scrollValue])
      this._optionsRef[scrollValue].scrollIntoView({
        behavior: "auto",
        block: "nearest"
      });
  };

  handleDefaultKeyMappingSelect = event => {
    let selectedItem = this.state._optionList[this.state._optionSelected];
    let _optionSelectedvalue = this.state._optionSelected;
    let optionList = this.state._optionList[this.state._optionSelected];
    this.changeState("_selectHidden", false);
    if (event.keyCode === KEY_DOWN && this.handleSerializeKeyMapping(KEY_DOWN))
      this.changeState("_optionSelected", (_optionSelectedvalue += 1));
    if (event.keyCode === KEY_UP && this.handleSerializeKeyMapping(KEY_UP))
      this.changeState("_optionSelected", (_optionSelectedvalue -= 1));
    if (event.keyCode === KEY_TAB && this.props.onKeyTab) {
      this.props.individualOrderSelectItem
        ? this.props.individualOrderSelectItem(selectedItem)
        : this.props.onKeyTab(optionList)(event);
      if (optionList) this.handleChangeSelect(optionList);
    }
    if (event.keyCode === KEY_ENTER && this.props.onKeyEnter) {
      this.props.individualOrderSelectItem
        ? this.props.individualOrderSelectItem(selectedItem)
        : this.props.onKeyEnter(optionList)(event);
      if (optionList) this.handleChangeSelect(optionList);
    }
    this.handleScrollIntoView(event);
  };
  render() {
    return (
      <div className="search-bar">
        <div className="input-form">
          <input
            name={this.props.name}
            autoFocus={!!this.props.autoFocus}
            className={this.props.className}
            {...this.props.field}
            value={this.state.inputValue}
            onChange={this.onChangeSearch(
              this.props.field ? this.props.field.onChange : null
            )}
            onKeyDown={this.handleDefaultKeyMappingSelect}
            onBlur={this.onBlurOption}
            onFocus={this.onChangeSearch(
              this.props.field ? this.props.field.onChange : null
            )}
            placeholder={this.props.placeholder}
            onPaste={event => {
              if (this.props.onPaste) this.props.onPaste(event);
            }}
            ref={this._inputRef}
          />
        </div>
        <div ref={event => (this._selectRef = event)}>
          {!this.props.disableOption && (
            <div id="options" hidden={this.state._selectHidden}>
              <OptionListRender
                keyValue={this.state._keyValue}
                keyElement={this.state._keyElement}
                optionList={this.state._optionList}
                handleChangeSelect={result => event => {
                  this.props.individualOrderSelectItem
                    ? this.props.individualOrderSelectItem(result, event)
                    : this.props.customSelectClick(result)(event);
                  this.handleChangeSelect(result);
                }}
                optionSelected={this.state._optionSelected}
                ref={this._optionsRef}
              />
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default SearchBar;
