import React, { useEffect, useContext } from "react";

import Loading from "UIKit/components/Loading/Loading";

import { AuthContext } from "./authContext";
import { withRouter } from "react-router";

const Logout = props => {
  const { authHelpers, switchAuthForm, pageActions } = useContext(AuthContext);

  const signOutSuccess = async () => {
    await switchAuthForm(pageActions.TO_LOGIN);
    props.history.push("/");
  };

  useEffect(() => {
    authHelpers.signOutUser(signOutSuccess);
  }, []);

  return (
    <section className="columns is-centered pt-5">
      <div className="column is-narrow">
        <Loading message="Logging out..." className="mt-2" />
      </div>
    </section>
  );
};

export default withRouter(Logout);
