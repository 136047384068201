import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

// How to use:
// If more than one tag, use the ff classes (to render in single line)
// <div className="field is-grouped is-grouped-multiline">
//   <Tag>Simple Tag</Tag>
//   <Tag hasDeleteButton>Simple Tag with Delete Button</Tag>
//   <Tag label="Label" labelColor="is-dark" className="is-success">
//     Tag with Label
//   </Tag>
//   <Tag
//     label="Label"
//     labelColor="is-dark"
//     valueColor="is-success"
//     hasDeleteButton
//     onClick={onClick}
//   >
//     Tag with Label and Delete Button
//   </Tag>
// </div>

const Tag = ({
  label,
  labelColor,
  children,
  valueColor,
  hasDeleteButton,
  onClick,
  size
}) => {
  return (
    <>
      {!!label ? (
        <div className="control">
          <div className="tags has-addons">
            <span className={classNames("tag", labelColor, size)}>{label}</span>
            <span className={classNames("tag", valueColor, size)}>
              {children}
              {!!hasDeleteButton && (
                <button
                  className={classNames("delete", size)}
                  onClick={onClick}
                />
              )}
            </span>
          </div>
        </div>
      ) : (
        <span className={classNames("tag mb-0", valueColor, size)}>
          {children}
          {!!hasDeleteButton && (
            <button className={classNames("delete", size)} onClick={onClick} />
          )}
        </span>
      )}
    </>
  );
};

Tag.propTypes = {
  label: PropTypes.string,
  labelColor: PropTypes.string,
  children: PropTypes.node.isRequired,
  childrenColor: PropTypes.string,
  hasDeleteButton: PropTypes.bool,
  onClick: PropTypes.func,
  size: PropTypes.string
};

export default Tag;
