import React from "react";
import { Auth } from "aws-amplify";
import useAuthReducer from "./reducers/useAuthReducer";
import useFormReducer from "./reducers/useFormReducer";

Auth.configure({ storage: sessionStorage });

export const AuthContext = React.createContext({});

export const AuthProvider = ({ children }) => {
  const [authState, authHelpers] = useAuthReducer();
  const [pageState, switchAuthForm, pageActions] = useFormReducer();

  return (
    <AuthContext.Provider
      value={{
        authState,
        authHelpers,
        pageState,
        switchAuthForm,
        pageActions
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
