import React, { Fragment } from "react";
import classNames from "classnames";
import styles from "./refill.module.scss";
import months from "./utils/months.json";

const RefillFormHeader = ({ zendeskId, molecule }) => {
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    return `${day}-${months[date.getMonth()]}-${date.getFullYear()}`;
  };
  return (
    <Fragment>
      <div className={classNames(styles["header"])}>
        <span>
          <img
            src={require("assets/images/mg-teal.png")}
            alt="MedGrocer"
            width="300"
          />
          <div className="is-size-3 has-text-weight-bold has-text-primary">
            ORDER REFILL FORM
          </div>
        </span>
        <span className="is-size-4">
          <table className={classNames(styles["header__right-section"])}>
            <tr>
              <td>Delivery Date </td>
              <td className="has-text-right">{formatDate(new Date())}</td>
            </tr>
            <tr>
              <td>Order Number </td>
              <td className="has-text-right">{zendeskId}</td>
            </tr>
          </table>
        </span>
      </div>
      <div className="is-size-5 mt-3 is-italic">
        We want to make your next order easier. We can use your current details
        to process your refill. Fill the form below to schedule the next
        delivery of your{" "}
        <span className="has-text-weight-bold">{molecule}</span>:
      </div>
      <hr className="divider" />
    </Fragment>
  );
};

export default RefillFormHeader;
