import React from "react";
import { useMutation } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import moment from "moment";
import { createSourcingSheet } from "PurchaseOrder/Schema";
import api from "Services/api";

function createID(user) {
  const datetime = moment().format("YYYYMMDD-HHmm");
  const extractedUser = user.split("@")[0].toUpperCase();
  return `${datetime}-${extractedUser}`;
}

const CreateSourcingSheetButton = ({ selectedPurchaseOrder, redirect }) => {
  const [createSourcing, { loading }] = useMutation(createSourcingSheet, {
    onCompleted: async ({ insert_sourcing_sourcingsheet }) => {
      const code = insert_sourcing_sourcingsheet.returning[0].code;
      const id = insert_sourcing_sourcingsheet.returning[0].id;
      await api.post("sourcing/generate-picklist/", { sourcingsheet_id: id });
      await redirect(`/sourcing/${code}/pick-list`);
    }
  });

  const generateSelectedPurchaseOrder = () =>
    selectedPurchaseOrder.map(id => ({ purchaseorderdetail_id: id }));

  const handleCreateSourcingSheet = () => {
    const user = api.defaults.headers.common["X-REMOTE-USER"];
    const thisDate = new Date();
    createSourcing({
      variables: {
        created_by: user,
        created: thisDate.toISOString(),
        updated: thisDate.toISOString(),
        code: createID(user),
        last_update_by: user,
        selected_po: generateSelectedPurchaseOrder()
      }
    });
  };

  return (
    <button
      to="/purchase-orders/"
      className={`button is-primary ${loading ? "is-loading" : ""}`}
      disabled={loading || !selectedPurchaseOrder.length}
      onClick={handleCreateSourcingSheet}
    >
      + Add to Sourcing
    </button>
  );
};

CreateSourcingSheetButton.propTypes = {
  selectedPurchaseOrder: PropTypes.array,
  redirect: PropTypes.func.isRequired
};

export default CreateSourcingSheetButton;
