import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronCircleRight,
  faCaretLeft
} from "@fortawesome/free-solid-svg-icons";

import Tag from "UIKit/components/Tag";

const propTypes = {
  title: PropTypes.string.isRequired,
  buttonPosition: PropTypes.oneOf(["left", "right"]),
  tag: PropTypes.string.isRequired,
  buttonLabel: PropTypes.string,
  buttonLink: PropTypes.string,
  backButtonLabel: PropTypes.string,
  backkButtonLink: PropTypes.string
};

const SourcingSheetHeader = ({
  title,
  buttonPosition,
  buttonLabel,
  buttonLink,
  tag,
  backButtonLabel,
  backkButtonLink
}) => {
  return (
    <Fragment>
      <div className="tags are-medium mb-5">
        {backButtonLabel && (
          <span>
            <Tag size="is-large is-light has-text-weight-bold is-size-6 mr-2">
              <FontAwesomeIcon icon={faCaretLeft} className="is-size-4 mr-2" />
              {backButtonLabel}
            </Tag>
          </span>
        )}{" "}
        <span className="has-text-weight-bold is-size-4 mr-2">{title}</span>
        <Tag size="is-medium">{tag}</Tag>
        {buttonPosition === "right" && (
          <Link to={buttonLink} style={{ marginLeft: "auto" }}>
            <button className="button is-light">
              <span className="mr-2">{buttonLabel}</span>
              <FontAwesomeIcon icon={faChevronCircleRight} />
            </button>
          </Link>
        )}
      </div>
    </Fragment>
  );
};

SourcingSheetHeader.propTypes = propTypes;

export default SourcingSheetHeader;
