import React from "react";
import classNames from "classnames";
import { getTotal, getTotalVat, getVatExSubtotal } from "Services/cart";
import { formatNumber, roundFloatToTwo } from "Services/util";
import customLabels from "../utils/customFeeLabels";
import DRTerms from "./DRTerms";

const DRTotalDetails = ({
  transaction,
  lineItems,
  isChannelValidWithPrice,
}) => {
  // Fetch and manipulate data
  let subtotal = 0.0;
  let total = 0;
  let totalVat = 0;

  let discount = transaction.discountAmount;
  if (discount > 0) discount *= -1;

  let convenienceFee = transaction.convenienceFee;

  if (lineItems) {
    subtotal = roundFloatToTwo(
      getVatExSubtotal(lineItems, transaction.couponCode)
    );
    totalVat = roundFloatToTwo(getTotalVat(lineItems, transaction.couponCode));
    total = roundFloatToTwo(
      getTotal(
        lineItems,
        parseFloat(transaction.convenienceFee),
        transaction.couponCode,
        parseFloat(transaction.discountAmount)
      )
    );
  }

  let couponCode = transaction?.couponCode;

  let convenienceFeeVatex = parseFloat(convenienceFee / 1.12);
  let convenienceFeeVat = parseFloat(convenienceFeeVatex * 0.12);

  let labels = {};
  customLabels.map(
    (labelCompany) => (labels[labelCompany.company] = labelCompany.label)
  );

  return (
    <div className={classNames("is-flex computation-section mt-2")}>
      {isChannelValidWithPrice && (
        <div>
          <p className="is-size-7 computation is-flex">
            <span className="has-text-weight-bold">Total</span>
            <span>{formatNumber(total)}</span>
          </p>
          <p className="is-size-7 computation is-flex">
            <span className="has-text-weight-bold">Subtotal (VAT-Ex)</span>
            <span>{formatNumber(subtotal)}</span>
          </p>
          <p className="is-size-7 computation is-flex">
            <span className="has-text-weight-bold">Subtotal (VAT)</span>
            <span>{formatNumber(totalVat.toFixed(2))}</span>
          </p>
          <p className="is-size-7 computation is-flex">
            <span className="has-text-weight-bold">
              {`${
                transaction.companyName &&
                !!labels[transaction.companyName.toLowerCase()]
                  ? labels[transaction.companyName.toLowerCase()]
                  : "Convenience Fee"
              }`}{" "}
              (VAT-Ex)
            </span>
            <span>{formatNumber(convenienceFeeVatex.toFixed(2))}</span>
          </p>
          <p className="is-size-7 computation is-flex">
            <span className="has-text-weight-bold">
              {`${
                transaction.companyName &&
                !!labels[transaction.companyName.toLowerCase()]
                  ? labels[transaction.companyName.toLowerCase()]
                  : "Convenience Fee"
              }`}{" "}
              (VAT)
            </span>
            <span>{formatNumber(convenienceFeeVat.toFixed(2))}</span>
          </p>
          <p className="is-size-7 computation is-flex">
            <span className="has-text-weight-bold">
              Discount{" "}
              {couponCode && couponCode !== "-" && (
                <i className="has-text-weight-normal">({couponCode})</i>
              )}
            </span>
            <span>{formatNumber(discount)}</span>
          </p>
          <p className="computation total-amount is-flex mt-1 pt-1 is-size-7">
            <span className="has-text-weight-bold">TOTAL AMOUNT DUE</span>
            <span>P {formatNumber(total)}</span>
          </p>
        </div>
      )}
      <article class="message is-light mr-2 mt-1">
        <div class="message-body p-1">
          <DRTerms />
        </div>
      </article>
    </div>
  );
};

export default DRTotalDetails;
