import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/free-solid-svg-icons";
import { notifContainer } from "./CircleNotification.module.scss";

const CircleNotification = ({ message, type, name }) => {
  return (
    <div className={classNames([notifContainer])} name={name}>
      <div
        className={classNames(`tooltip is-tooltip-right`)}
        data-tooltip={message}
      >
        <FontAwesomeIcon className={type} icon={faCircle} />
      </div>
    </div>
  );
};

CircleNotification.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  name: PropTypes.string
};

export default CircleNotification;
