import axios from "axios";
import { normalize } from "normalizr";

import api, { priceListsResponseSchema } from "Services/api";

export const actionTypes = {
  FETCH_PRODUCTS_REQUEST: "FETCH_PRODUCTS_REQUEST",
  FETCH_PRODUCTS_FAILURE: "FETCH_PRODUCTS_FAILURE",
  FETCH_PRODUCTS_SUCCESS: "FETCH_PRODUCTS_SUCCESS",
  FETCH_PRICELISTS_REQUEST: "FETCH_PRICELISTS_REQUEST",
  FETCH_PRICELISTS_FAILURE: "FETCH_PRICELISTS_FAILURE",
  FETCH_PRICELISTS_SUCCESS: "FETCH_PRICELISTS_SUCCESS",
  FETCH_PRODUCT_REQUESTS_REQUEST: "FETCH_PRODUCT_REQUESTS_REQUEST",
  FETCH_PRODUCT_REQUESTS_FAILURE: "FETCH_PRODUCT_REQUESTS_FAILURE",
  FETCH_PRODUCT_REQUESTS_SUCCESS: "FETCH_PRODUCT_REQUESTS_SUCCESS"
};

function requestPriceLists() {
  return {
    type: actionTypes.FETCH_PRICELISTS_REQUEST
  };
}

function receivePriceLists(priceLists) {
  return {
    type: actionTypes.FETCH_PRICELISTS_SUCCESS,
    payload: normalize(priceLists, priceListsResponseSchema)
  };
}

function failurePriceLists(error) {
  return {
    type: actionTypes.FETCH_PRICELISTS_FAILURE,
    error
  };
}

export const fetchPriceLists = () => dispatch => {
  dispatch(requestPriceLists());
  api
    .get(`prices/lists/?page=1`)
    .then(response => {
      var calls = [];
      const { results, count } = response.data;
      for (var i = 1; i <= count / results.length; i++) {
        const url = `prices/lists/?page=${i}`;
        calls.push(api.get(url));
      }
      axios.all(calls).then(function(...args) {
        const priceLists = args[0].reduce((accumulator, currentValue) => {
          accumulator.push(...currentValue.data.results);
          return accumulator;
        }, []);
        return dispatch(receivePriceLists(priceLists));
      });
    })
    .catch(error => {
      console.log(error);
      return dispatch(failurePriceLists(error));
    });
};
