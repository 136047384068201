import React, { useState, useEffect } from "react";
import classNames from "classnames";

import styles from "../auth.module.scss";
import nuggets from "assets/data/nuggets.json";

const logo =
  "https://s3-ap-southeast-1.amazonaws.com/data.medgrocer.com/public/logo/medgrocer.png";

function AuthCard({ children, errorMessage, headerMessage }) {
  const [currentNugget, setCurrentNugget] = useState("");

  useEffect(() => {
    if (!headerMessage) setCurrentNugget(randomizeNugget());
    else setCurrentNugget(headerMessage);
  }, []);

  const randomizeNugget = () => {
    const randomIndex = Math.floor(Math.random() * nuggets.length);
    return nuggets[randomIndex];
  };

  return (
    <section className={classNames(styles["login"])}>
      <div className={classNames(styles["login__top-background"])}></div>
      <section className={classNames("columns is-centered")}>
        <div className="column card is-one-third-desktop is-one-quarter-fullhd is-mobile">
          <div className={classNames(styles["login__card-is-centered"])}>
            <img className="image" src={logo} width="70%" alt="medgrocer" />
          </div>
          <div className=" mt-3 has-text-centered mx-4">{currentNugget}</div>
          {errorMessage && (
            <div className="message is-danger mx-4 mt-3 p-2 has-text-centered">
              <span className="has-text-danger">{errorMessage}</span>
            </div>
          )}
          <div className="mx-5 mt-4">{children}</div>
        </div>
      </section>
    </section>
  );
}

export default AuthCard;
