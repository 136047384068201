import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

const propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      count: PropTypes.number
    })
  ),
  onClick: PropTypes.func.isRequired,
  activeTab: PropTypes.string
};

const Tabs = ({ tabs, onClick, activeTab }) => {
  return (
    <div className="tabs is-boxed mb-5">
      <ul>
        {tabs.map((tab, index) => (
          <li
            key={index}
            className={classNames(
              { "is-active": activeTab === tab.label.toUpperCase() },
              "px-1"
            )}
            name={tab.label}
            onClick={onClick}
          >
            {/* eslint-disable */}
            <a name={tab.label}>
              <span className="is-size-5 has-text-weight-bold px-3">
                {tab.label}
              </span>
              {tab.count >= 0 && <span className="count">{tab.count}</span>}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

Tabs.propTypes = propTypes;

export default Tabs;
