import React from "react";
import FieldContainer from "UIKit/components/SplitContainer/FieldContainer";
import { Field } from "formik";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencilAlt } from "@fortawesome/free-solid-svg-icons";
import { productTypeChoices, titleBuilder } from "./helpers";

export const MainPanelHeader = ({
  isTitleFieldWritable,
  handleChangeSingleState,
  values,
  handleChange
}) => {
  return (
    <div className="level">
      <div className="level-left">
        <FieldContainer hasAddons>
          <div className="control" style={{ width: "30.5rem" }}>
            {isTitleFieldWritable ? (
              <Field
                name="title"
                className={`input ${isTitleFieldWritable ? "" : "is-static"} `}
                readOnly={!isTitleFieldWritable}
              />
            ) : (
              <div className="has-text-primary has-text-weight-bold">
                {titleBuilder(values).title}
              </div>
            )}
          </div>
          <div className="control">
            <span class=" is-small is-right">
              <button
                type="button"
                className="button"
                onClick={() =>
                  handleChangeSingleState(
                    "isTitleFieldWritable",
                    !isTitleFieldWritable
                  )
                }
              >
                <FontAwesomeIcon icon={faPencilAlt} />
              </button>
            </span>
          </div>
        </FieldContainer>
      </div>
      <div className="level-right">
        <button type="input" className="button is-primary">
          Save Product
        </button>
      </div>
    </div>
  );
};

class MainContentBody extends React.Component {
  // const handleChangeTitle = (handleChange, values) => event => {
  //   titleBuilder(values);
  //   handleChange(event);
  // };
  constructor(props) {
    super(props);
    this.state = {
      isFormHidden: !props.values.productType
        .toLowerCase()
        .includes("medsupply")
    };
  }
  handleHideFormIfMedSupply = (handleChange, values) => event => {
    if (event.target.value) {
      const isFormHidden = event.target.value
        .toLowerCase()
        .includes("medsupply");
      values.form = "";
      this.setState({
        isFormHidden: !isFormHidden
      });
    }
    handleChange(event);
  };
  render() {
    return (
      <div className="columns">
        <div className="column">
          <FieldContainer label="Product Type">
            <div className="control select">
              <Field
                component="select"
                name="productType"
                onChange={this.handleHideFormIfMedSupply(
                  this.props.handleChange,
                  this.props.values
                )}
              >
                <option value="" selected disabled>
                  --Select Product Type ---
                </option>
                {productTypeChoices.map(product => (
                  <option value={product.value}>{product.text}</option>
                ))}
              </Field>
            </div>
          </FieldContainer>
          <FieldContainer label="Molecule / Generic Name">
            <div className="control">
              <Field name="moleculeGenericName" className="input" />
            </div>
          </FieldContainer>
          <FieldContainer label="Strength / Qty / Volume">
            <div className="control">
              <Field name="strengthQuantityPerPackVolume" className="input" />
            </div>
          </FieldContainer>
          {this.state.isFormHidden && (
            <FieldContainer label="Form">
              <div className="control select">
                <Field component="select" name="form">
                  <option value="" selected>
                    ---Select a Form---
                  </option>
                  {this.props.formList.map(form => (
                    <option value={form.title}> {form.title}</option>
                  ))}
                </Field>
              </div>
            </FieldContainer>
          )}
          <FieldContainer label="Brand">
            <div className="control">
              <Field name="brand" className="input" />
            </div>
          </FieldContainer>
        </div>
        <div className="column">
          <div className="field">
            <label>Remarks</label>
            <div className="control">
              <Field
                name="remarks"
                component="textarea"
                className="textarea textarea-custom"
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default MainContentBody;
