import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";

import Dashboard from "DashboardV2/DashboardV2";
import FilterField from "./FilterField";
import { getSourcingDashboardData as GET_DATA } from "../Schema";
import { sourcingHeaders, setQueryVariable } from "./jsonInitializers";

const RenderRows = ({ sourcingList, pushHistory }) => {
  const formatDate = date => {
    const dateTimeFormat = {
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric"
    };

    return (
      <span className="help has-text-primary">
        {new Date(date).toLocaleString("en-US", dateTimeFormat)}
      </span>
    );
  };

  return (
    sourcingList &&
    sourcingList.map(sourcing => {
      const splittedCode = sourcing.code.split("-", "3");
      return (
        <tr
          key={sourcing.code}
          onClick={() => {
            pushHistory(`/sourcing/${sourcing.code}/pick-list`);
          }}
        >
          <td>
            <button className="button is-primary is-fullwidth">
              {sourcing.status}
            </button>
          </td>
          <td className="has-text-centered">
            {splittedCode[0]}-{splittedCode[1]} <br />
            <span className="has-text-primary is-size-7">
              {splittedCode[2]}
            </span>
          </td>
          <td className="has-text-centered">
            {sourcing.purchase_order.data.count}
          </td>
          <td className="has-text-centered">99.9%</td>
          <td className="has-text-centered">
            {sourcing.created_by.split("@")[0]}
            {formatDate(sourcing.created)}
          </td>
          <td className="has-text-centered">
            {sourcing.last_update_by.split("@")[0]}
            {formatDate(sourcing.last_updated)}
          </td>
        </tr>
      );
    })
  );
};

const SourcingBody = ({ queryString, pushHistory }) => {
  const [searchString, setSearchString] = useState(null);

  const { loading, data } = useQuery(GET_DATA, {
    notifyOnNetworkStatusChange: true,
    variables: setQueryVariable(queryString.status, searchString),
    fetchPolicy: "network-only"
  });

  return (
    <section>
      <section className="columns">
        <div className="column is-5">
          <FilterField
            setSearchString={setSearchString}
            searchString={searchString}
          />
        </div>
      </section>
      <Dashboard headers={sourcingHeaders} isLoading={loading}>
        {data && (
          <RenderRows
            sourcingList={data.sourcingsheets}
            pushHistory={pushHistory}
          />
        )}
      </Dashboard>
    </section>
  );
};

SourcingBody.propTypes = {
  queryString: PropTypes.object.isRequired,
  pushHistory: PropTypes.func
};

export default SourcingBody;
