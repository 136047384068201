import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { isEqual } from "lodash";

import { getDiscountAmount, getCoupon } from "Services/coupon";
import Loading from "UIKit/components/Loading/Loading";
import { pushNotification } from "UIKit/actions";
import api from "Services/api";
import { setSearchQuery } from "Search/actions";
import { formatNumber, roundFloatToTwo } from "Services/util";
import { getTotal } from "Services/cart";

import SplitContainer, {
  MainContent,
} from "UIKit/components/SplitContainer/SplitContainer";
import TransactionSidebarForm from "./TransactionSidebar/TransactionSidebarForm";
import TransactionDetails from "./TransactionMainContent/TransactionDetails";

const propTypes = {
  showTransactionDetails: PropTypes.bool,
  transaction: PropTypes.object,
  transactionItems: PropTypes.shape({
    productTitle: PropTypes.string,
    quantity: PropTypes.number,
  }),
  statusLog: PropTypes.string,
  isSaving: PropTypes.bool,
  isNewOrder: PropTypes.bool,
  coupon: PropTypes.string,
  match: PropTypes.object,
  products: PropTypes.object,
  searchResult: PropTypes.object,
  history: PropTypes.object,
  pushNotification: PropTypes.func,
};

const SPACE_KEY = 13;

class IndividualOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showTransactionDetails: true,
      transaction: {
        convenienceFee: 100.0,
        discountAmount: 0,
        channel: "regular",
        shippingStreetAddress: "",
        shippingCityMunicipality: "",
        shippingProvince: "",
        shippingBarangay: "",
      },
      transactionItems: [],
      statusLog: "",
      rows: [],
      isSaving: false,
      isNewOrder: false,
      coupon: null,
      discount: 0,
      /*
       * Search component states
       */
      searchResultTitle: "",
      hideResults: false,
      isSameAddress: true,
      showError: {
        customerFirstName: false,
        customerLastName: false,
        customerEmail: false,
        shippingStreetAddress: false,
        mobileNumber: false,
      },
      textMessage: "",
      messageType: "VERIFICATION",
      isSendingMessage: false,
    };
  }

  transactionId = this.props.match.params.id;

  componentDidMount() {
    if (window.location.pathname === "/orders/new") {
      this.setState({
        transaction: {
          ...this.state.transaction,
          shippingProvince: "Metro Manila",
          billingProvince: "Metro Manila",
          convenienceFee: "100.00",
          discountAmount: 0,
        },
        transactionItems: [],
        statusLog: "CREATED",
        isNewOrder: true,
        showTransactionDetails: false,
        isSameAddress: true,
        discountAmount: 0,
      });
      this.addRow();
    } else {
      api.get(`transactions/${this.transactionId}/`).then((result) => {
        let transaction = result.data;
        const items = transaction.details.map((detail) => {
          return {
            ...detail,
            ...this.props.products[detail.productCode],
          };
        });
        getCoupon(transaction.couponCode, null, (couponData, error) => {
          const discountAmount = !error
            ? getDiscountAmount(
                couponData.code,
                items,
                transaction.convenienceFee
              )
            : 0;
          this.setState({
            transaction: { ...transaction, details: items },
            transactionItems: items,
            statusLog: transaction.currentStatus,
            discountAmount: discountAmount,
            coupon: couponData ? couponData : null,
          });
        });
      });
    }
  }

  componentWillUpdate(nextProps) {
    if (nextProps.searchResult.id !== this.props.searchResult.id) {
      this.onSelectItem(
        nextProps.searchResult,
        nextProps.searchResult.rowIndex
      );
    }
  }

  postStatusLog = () => {
    this.props.pushNotification(4000, "Status log updated.", "is-info");
    api
      .patch(`transactions/${this.transactionId}/`, {
        currentStatus: this.state.statusLog.toUpperCase(),
      })
      .then((response) => {})
      .catch((error) => console.log("error is: ", error));
  };

  setStatusLog = (statusLog) => {
    this.setState({ statusLog });
  };

  onKeyDown = (event, item, rowIndex) => {
    switch (event.keyCode) {
      default:
        break;
      case SPACE_KEY:
        this.onSelectItem(item, rowIndex);
        break;
    }
  };

  onSelectItem = (item, rowIndex, values) => {
    if (!item) {
      return;
    }
    const { title, price, classification, drug, category } = item;
    const retailPrice = price;
    const vatexUnitPrice = parseFloat(
      retailPrice ? retailPrice.vatexUnitPrice : 0
    );
    const vat = parseFloat(retailPrice ? retailPrice.vat : 0);
    const items = this.state.transactionItems;

    items[rowIndex].title = title;
    items[rowIndex].productTitle = title;
    items[rowIndex].vatexUnitPrice = vatexUnitPrice;
    items[rowIndex].vat = vat;
    items[rowIndex].unitPrice = roundFloatToTwo(
      vat !== 0 ? parseFloat(vatexUnitPrice * 1.12) : parseFloat(vatexUnitPrice)
    );
    items[rowIndex].isVatable = !!vat;
    values.productTitle = title;
    items[rowIndex].value =
      items[rowIndex].unitPrice * items[rowIndex].quantity;
    items[rowIndex].classification = classification;
    items[rowIndex].category = category;
    items[rowIndex].rxRequired = drug ? drug.rxRequired : null;
    items[rowIndex].rxLevel = drug ? drug.rxLevel : null;
    this.setState({
      transactionItems: items,
      searchResultTitle: title,
      hideResults: true,
    });
  };

  productsArray = Object.keys(this.props.products).map((key) => {
    return this.props.products[key];
  });

  handleChange = (event) => {
    const target = event.target;
    const itemIndex = target.id;
    const fieldName = target.name;
    const value = target.value;

    this.setState((previousState, props) => {
      const items = previousState.transactionItems;
      const currentItem = items[itemIndex];
      if (currentItem.productTitle === "" && currentItem.quantity === 1) {
        let correspondingProduct = this.productsArray.find(
          (product) => currentItem.productTitle === product.title
        );
        if (correspondingProduct) {
          const retailPrice = correspondingProduct.prices.find(
            (price) => price.priceList === "retail"
          );
          currentItem.unitPrice = retailPrice.vatexUnitPrice + retailPrice.vat;
          currentItem.value = currentItem.unitPrice * currentItem.quantity;
        }
      }

      switch (fieldName) {
        case "productTitle":
          currentItem.productTitle = value;
          break;
        case "quantity":
          currentItem.quantity = value;
          break;
        case "unitPrice":
          const price = parseFloat(value);

          if (!currentItem.isVatable) currentItem.vatexUnitPrice = price;
          else {
            currentItem.vatexUnitPrice = price / 1.12;
            currentItem.vat = roundFloatToTwo(
              price - currentItem.vatexUnitPrice
            );
          }
          break;
        default:
          return null;
      }

      return {
        transactionItems: items,
        discountAmount: getDiscountAmount(
          previousState.coupon,
          items,
          previousState.transaction.convenienceFee
        ),
      };
    });
  };

  handleDetailChange = (event) => {
    const target = event ? event.target : {};
    const value = target.type === "checkbox" ? target.checked : target.value;
    const fieldName = target.name;
    this.setState((previousState, props) => {
      let transaction = { ...previousState.transaction };
      const objectFields = [
        "customerFirstName",
        "customerLastName",
        "customerEmail",
        "mobileNumber",
        "companyName",
      ];

      transaction[`${fieldName}`] = value;

      if (objectFields.includes(fieldName))
        transaction[fieldName] = value && value.value;

      if (fieldName === "shippingProvince") {
        transaction.convenienceFee =
          value === "Metro Manila" ? "100.00" : "300.00";
        transaction.shippingCityMunicipality = "";
        transaction.shippingBarangay = "";
      }

      if (fieldName === "shippingCityMunicipality") {
        transaction.shippingBarangay = "";
      }

      var isSameAddress = previousState.isSameAddress;

      if (fieldName === "isSameAddress") isSameAddress = value;

      if ((fieldName === "isSameAddress" && value) || isSameAddress) {
        transaction.billingStreetAddress = transaction.shippingStreetAddress;
        transaction.billingBarangay = transaction.shippingBarangay;
        transaction.billingCityMunicipality =
          transaction.shippingCityMunicipality;
        transaction.billingProvince = transaction.shippingProvince;
        transaction.billingPostalCode = transaction.shippingPostalCode;
        transaction.billingLandmarks = transaction.shippingLandmarks;
      }
      const discountAmount = getDiscountAmount(
        previousState.coupon,
        previousState.transactionItems,
        parseFloat(transaction.convenienceFee)
      );
      return {
        transaction: { ...transaction, discountAmount: discountAmount },
        showError: {
          ...this.state.showError,
          [`${fieldName}`]: transaction[`${fieldName}`] ? false : true,
        },
        isSameAddress,
        discountAmount,
      };
    });
  };

  toggleShowTransactionDetails = () => {
    this.setState((previousState) => {
      return { showTransactionDetails: !previousState.showTransactionDetails };
    });
  };

  toggleIsSaving = () => {
    this.setState((previousState) => {
      return { isSaving: !previousState.isSaving };
    });
  };

  toggleShowErrors = () => {
    this.setState((previousState) => {
      let transaction = previousState.transaction;
      return {
        showError: {
          customerFirstName: transaction.customerFirstName ? false : true,
          customerLastName: transaction.customerLastName ? false : true,
          customerEmail: transaction.customerEmail ? false : true,
          shippingStreetAddress: transaction.shippingStreetAddress
            ? false
            : true,
          mobileNumber: transaction.mobileNumber ? false : true,
        },
      };
    });
  };

  saveOrder = (event, history) => {
    this.toggleIsSaving();
    if (this.state.isNewOrder) {
      let details = [];
      let transaction = this.state.transaction;

      this.state.transactionItems.forEach((transactionItem) => {
        details.push({
          productTitle: transactionItem.productTitle,
          quantity: transactionItem.quantity,
          vatexUnitPrice: transactionItem.vatexUnitPrice,
          vat: transactionItem.vat,
        });
      });

      transaction = {
        ...transaction,
        currentStatus: "CREATED",
        discountAmount: this.state.discount,
        details: details,
      };
      delete transaction["subtotal"];
      delete transaction["total"];

      api
        .post("/transactions/", transaction)
        .then((response) => {
          this.props.history.push(`/orders/${response.data.id}`);
          this.toggleIsSaving();
          this.props.pushNotification(4000, `Order added.`, "is-success");
        })
        .catch((error) => {
          this.toggleIsSaving();
          this.toggleShowErrors();
          this.props.pushNotification(
            4000,
            `Please fill in the required fields`,
            "is-danger"
          );
        });
    } else {
      let transaction = this.state.transaction;
      // let details = this.state.transactionItems.map(detail => {
      //   return {
      //     ...detail,
      //     vatexUnitPrice: detail.vatexUnitPrice
      //   };
      // });
      const newTransaction = {
        ...transaction,
        discountAmount: getDiscountAmount(
          this.state.coupon,
          transaction.details,
          transaction.convenienceFee
        ),
        // details: details
      };

      api
        .put(`transactions/${this.transactionId}/`, newTransaction)
        .then((response) => {
          this.props.pushNotification(
            4000,
            `Order ${this.transactionId} updated.`,
            "is-info"
          );
          this.toggleIsSaving();
        })
        .catch((error) => {
          this.toggleIsSaving();
          this.toggleShowErrors();
          this.props.pushNotification(
            4000,
            `Please fill in the required fields`,
            "is-danger"
          );
        });
    }
  };

  addRow = () => {
    let newProduct = {
      productTitle: "",
      quantity: 1,
      vatexUnitPrice: 0,
      vat: 0,
      total: "",
    };
    this.setState((previousState) => {
      let addRows = previousState.transactionItems;
      addRows.push(newProduct);
      return {
        transactionItems: addRows,
      };
    });
  };

  deleteRow = (index) => {
    this.setState((previousState, props) => {
      let deleteRows = previousState.transactionItems;
      deleteRows.splice(index, 1);
      return {
        transactionItems: deleteRows,
      };
    });
  };

  detailsButton = () => (
    <Fragment>
      <button
        className="button is-primary"
        onClick={this.toggleShowTransactionDetails}
      >
        {this.state.showTransactionDetails
          ? "See Details >"
          : "< Hide Details "}
      </button>
      <br />
    </Fragment>
  );

  onReceiveCoupon = (coupon) => {
    this.setState((previousState) => {
      let discount = getDiscountAmount(
        coupon,
        previousState.transactionItems,
        previousState.transaction.convenienceFee
      );
      return {
        coupon: coupon,
        discount: discount,
        transaction: {
          ...previousState.transaction,
          couponCode: coupon.code,
          discountAmount: discount,
        },
        discountAmount: discount,
      };
    });
  };

  onDeleteCoupon = () => {
    this.setState((previousState) => {
      return {
        coupon: null,
        transaction: {
          ...previousState.transaction,
          couponCode: null,
          discountAmount: 0,
        },
        discountAmount: 0,
      };
    });
  };

  setTextMessage = (values) => {
    this.setState(() => ({
      textMessage: values.value,
      messageType: values.label,
    }));
  };

  sendMessage = async () => {
    this.setState(() => ({ isSendingMessage: true }));
    try {
      const result = await api.post(`/telerivet/send-message/`, {
        messageContent: this.state.textMessage,
        contactNumber: this.state.transaction.mobileNumber,
      });
      this.props.pushNotification(6000, result.data.message, "is-success");
    } catch (error) {
      this.props.pushNotification(6000, "Error sending message.", "is-danger");
    } finally {
      this.setState(() => ({ isSendingMessage: false }));
    }
  };

  render() {
    if (!this.state.transaction.id && !this.state.isNewOrder) {
      return (
        <div className="has-text-centered mt-3">
          <Loading />
          <p>Fetching order...</p>
        </div>
      );
    }

    const { transactionItems, transaction, discountAmount } = this.state;

    const total = getTotal(
      transactionItems,
      parseFloat(transaction.convenienceFee),
      transaction.couponCode,
      parseFloat(discountAmount)
    );

    var textmessage = `ORDER VERIFICATION `;

    var fullname = "";
    fullname =
      transaction.customerFirstName + " " + transaction.customerLastName;
    textmessage += new Date().toLocaleDateString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "numeric",
    });
    textmessage += `: Hi ${fullname}! Please confirm your MedGrocer order by replying to this SMS with your full name and birthday. If your order requires prescription, please present your prescription to our rider upon delivery (if you haven’t uploaded it yet).\n\n`;
    textmessage += "Your order:\n";
    var meds = "";

    if (transactionItems.length === 0) {
      meds = "";
    } else {
      for (var i = 0; i < transactionItems.length; i++) {
        meds += `${transactionItems[i].productTitle} #${transactionItems[i].quantity}\n`;
      }
    }

    textmessage += meds + `\n`;

    textmessage += `Order total is Php ${formatNumber(
      total
    )}\nDelivery address is at ${transaction.shippingStreetAddress} ${
      transaction.shippingCityMunicipality
    }, ${transaction.shippingProvince}\n\n`;

    textmessage += `To ensure you get your medicines on time, please reply to this SMS within 12 hours so that your order will not be cancelled. (This is an automated message.)\n`;
    // textmessage += `NOTE TO OPS: Send to ${transaction.mobileNumber}`;

    if (
      !isEqual(this.state.textMessage, textmessage) &&
      this.state.messageType === "VERIFICATION"
    ) {
      this.setState(() => ({ textMessage: textmessage }));
    }
    return (
      <SplitContainer>
        <section>
          <TransactionSidebarForm
            {...this.props}
            isNewOrder={this.state.isNewOrder}
            transaction={this.state.transaction}
            statusLog={this.state.statusLog}
            postStatusLog={this.postStatusLog}
            setStatusLog={this.setStatusLog}
            handleDetailChange={this.handleDetailChange}
            isSameAddress={this.state.isSameAddress}
            showError={this.state.showError}
            saveOrder={this.saveOrder}
          />
        </section>
        <MainContent>
          <TransactionDetails
            addRowOnClick={this.addRow}
            deleteRowOnClick={this.deleteRow}
            transaction={this.state.transaction}
            transactionItems={this.state.transactionItems}
            handleChange={this.handleChange}
            handleDetailChange={this.handleDetailChange}
            onSelectItem={this.onSelectItem}
            searchResultTitle={this.state.searchResultTitle}
            hideResults={this.state.hideResults}
            onKeyDown={this.onKeyDown}
            coupon={this.state.coupon}
            onReceiveCoupon={this.onReceiveCoupon}
            onDeleteCoupon={this.onDeleteCoupon}
            textmessage={this.state.textMessage}
            discountAmount={parseFloat(discountAmount)}
            isSaving={this.state.isSaving}
            saveOrder={this.saveOrder}
            transactionId={this.transactionId}
            isNewOrder={this.state.isNewOrder}
            sendMessage={this.sendMessage}
            setTextMessage={this.setTextMessage}
            newTextMessage={this.state.textMessage}
            isSendingMessage={this.state.isSendingMessage}
          />
        </MainContent>
      </SplitContainer>
    );
  }
}

const mapStateToProps = (state) => ({
  products: state.entities.products,
  transactions: state.entities.transactions,
  searchResult: state.searchResult,
  notification: state.notification,
});

IndividualOrder.propTypes = propTypes;

export default connect(mapStateToProps, { pushNotification, setSearchQuery })(
  IndividualOrder
);
