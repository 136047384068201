import React, { useState, useEffect } from "react";
import classNames from "classnames";

import LevelItem from "./LevelItem";

import styles from "./levelfilters.module.scss";
import api from "Services/api";

const Level = ({ url, levelFilters, callback }) => {
  const [isActive, setIsActive] = useState(levelFilters[0].title);
  const [fetchedData, setFetchedData] = useState(levelFilters);

  const baseUrl = `${url}/dashboard/count/`;

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await api.post(baseUrl, { filters: levelFilters });
      setFetchedData(response.data);
    } catch (error) {
      console.log(error.message);
    }
  };

  const handleFilterClick = id => {
    const data = fetchedData.filter(item => item.title === id);
    if (callback) callback(data[0].currentStatus);
    setIsActive(id);
  };

  return (
    <div className={classNames("mb-5", styles["level"])}>
      {fetchedData.map(filter => (
        <LevelItem
          title={filter.title}
          currentId={isActive}
          id={filter.title}
          count={filter.count}
          callback={handleFilterClick}
        />
      ))}
    </div>
  );
};

export default Level;
