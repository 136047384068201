import React from "react";
import classNames from "classnames";
import styles from "./PurchaseOrder.module.scss";

const drTermsData = [
  "This quotation is valid  until the end of the following month from the date sent",
  "Products quoted are subject to availability",
  "For items requiring special handling, bulky and/or delivered to locations outside serviceable areas, additional delivery fees may apply",
  "Delivery of goods shall take place at the address specified by the Client, on or as close as possible to the date required. Soft copy of the Delivery report may be used for billing purposes in provincial delivery sites",
  "To confirm order, email Purchase Order to clinic@medgrocer.com with the following details: billing name/company, billing address, VAT-Ex, withholding tax (if applicable), delivery name/company/contact person/contact number, delivery address, expected date of delivery",
  "All terms and conditions apply unless otherwise agreed in writing",
  "All prices may change without prior notice in the case of natural disasters or national emergencies"
];

const POTermsRow = ({ id, list }) => (
  <tr>
    <td className="is-narrow has-text-right">{id}</td>
    <td>{list}</td>
  </tr>
);

const POTerms = () => (
  <div className={styles["po__footer"]}>
    <hr className={styles["po-divider"]} />
    <h3>TERMS AND CONDITIONS</h3>
    <table className={classNames("table is-fullwidth mb-1", styles.po__terms)}>
      <tbody>
        {drTermsData.map((term, index) => (
          <POTermsRow key={index} id={index + 1} list={term} />
        ))}
      </tbody>
    </table>

    <hr className={styles["po-divider"]} />
  </div>
);

export default POTerms;
