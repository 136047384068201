import React, { Component, Fragment } from "react";
import { without } from "lodash";
import { Link } from "react-router-dom";
import { parse } from "query-string";
import { stringify } from "querystring";
import Dashboard from "../../Dashboard/components/Dashboard";
import { SearchBar } from "UIKit/components/Forms/FormInput";

import LevelFilters from "UIKit/components/Level/Level";

import levelfilters from "./levelfilters.json";
import "../PurchaseOrder.scss";

const ENTER = 13;
export const headers = [
  {
    displayName: "",
    attributeName: "",
    canSortBy: false
  },
  {
    displayName: "Status",
    attributeName: "site",
    canSortBy: true
  },
  {
    displayName: "Customer Name",
    attributeName: "site",
    canSortBy: true
  },
  {
    displayName: "Order Code",
    attributeName: "code",
    canSortBy: true
  },
  {
    displayName: "Created By",
    attributeName: "created",
    canSortBy: true
  },
  {
    displayName: "Last Updated By",
    attributeName: "lastUpdateBy",
    canSortBy: true
  }
];

const formattedDateTime = dateTime => {
  const dateTimeFormat = {
    hour: "numeric",
    minute: "numeric",
    month: "short",
    day: "numeric"
  };
  return (
    <Fragment>
      {/* {new Date(dateTime).toLocaleString("en-US", dateFormat)} <br /> */}
      <span className="help has-text-primary">
        {new Date(dateTime).toLocaleString("en-US", dateTimeFormat)}
      </span>
    </Fragment>
  );
};

const FormattedOrderFor = ({ name }) => {
  const splittedName = name.split("(");
  return (
    <Fragment>
      <div>{splittedName[0]}</div>
      <div className="has-text-primary is-size-7">
        {splittedName[1].split(")")[0]}
      </div>
    </Fragment>
  );
};

const PurchaseOrderRow = ({ data }) => {
  return (
    <Fragment>
      <td />
      <td className="has-text-centered">
        <button className="button is-primary button-dashboard">
          {data.status}
        </button>
      </td>
      <td>
        <FormattedOrderFor name={data.createdFor} />
      </td>
      <td>{data.code}</td>
      <td className="has-text-centered">
        {data.createdBy.split("@")[0] || ""}
        <br />
        {formattedDateTime(data.created)}
      </td>
      <td className="has-text-centered">
        {(data.lastUpdateBy && data.lastUpdateBy.split("@")[0]) || ""}
        <br />
        {data.lastUpdate && formattedDateTime(data.lastUpdate)}
      </td>
    </Fragment>
  );
};
class DraftPurchaseOrderDashboard extends Component {
  constructor(props) {
    super(props);
    this.draftPurchaseOrderDashboard = React.createRef();
    this.state = {
      selectedDraftPurchaseOrder: [],
      searchQuery: ""
    };
  }

  onSelectDraftPurchaseOrder = purchaseOrder => {
    this.setState(previousState => {
      return {
        selectedDraftPurchaseOrder: [
          ...previousState.selectedDraftPurchaseOrder,
          ...purchaseOrder
        ]
      };
    });
  };

  onDeselectDraftPurchaseOrder = purchaseOrder => {
    this.setState(previousState => {
      return {
        selectedDraftPurchaseOrder: without(
          previousState.selectedDraftPurchaseOrder,
          ...purchaseOrder
        )
      };
    });
  };

  onChangePage = currentPage => {
    const urlParameters = parse(this.props.history.location.search);
    urlParameters.page = currentPage;
    this.props.history.push(`?${stringify(urlParameters)}`);
  };

  getDashboardFilters = () => {
    const urlParameters = parse(this.props.history.location.search, {
      decode: false
    });
    return Object.keys(urlParameters).reduce((accumulator, key) => {
      var newFilters;
      if (Array.isArray(urlParameters[key]))
        newFilters = urlParameters[key].map(value => ({
          field: key,
          value: value
        }));
      else newFilters = [{ field: key, value: urlParameters[key] }];
      return [...accumulator, ...newFilters];
    }, []);
  };

  handleKeyPress = event => {
    switch (event.keyCode) {
      case ENTER:
        this.props.history.push(`/quotes/?search=${event.target.value}`);
        break;
      default:
        break;
    }
  };

  onRowRedirect = id => {
    this.props.history.push(`/quotes/edit/${id}`);
  };

  onLevelClick = link => {
    var urlParameters = parse(this.props.history.location.search);
    urlParameters.page = 1;
    if (urlParameters.search) {
      delete urlParameters["search"];
      this.setState({
        searchQuery: ""
      });
    }
    delete urlParameters["date_updated"];
    this.props.history.push(
      `?${stringify({ ...urlParameters, ...parse(link) }, false)}`
    );
  };

  handleChangeUrl = filters => {
    const urlParameters = parse(this.props.history.location.search);
    urlParameters.status = filters;
    urlParameters.page = 1;
    this.props.history.push(`?${stringify(urlParameters)}`);
  };

  render() {
    const filters = this.getDashboardFilters();
    const pageFilter = filters.find(filter => {
      return filter.field === "page";
    });
    const page = pageFilter ? pageFilter.value : 1;
    return (
      <div className="container">
        <LevelFilters
          url="/draft-purchase-orders"
          levelFilters={levelfilters}
          callback={this.handleChangeUrl}
        />
        {/* <DraftPurchaseOrderLevelFilters onLevelClick={this.onLevelClick} /> */}
        <div className="level">
          <div className="level-left">
            <SearchBar
              id="search"
              withIcon={true}
              hideResults={false}
              onKeyDown={this.handleKeyPress}
              placeholder="Search Draft PO"
            />
          </div>
          <div>
            <div className="level-right">
              <Link to="/quotes/add" className="button is-primary">
                + Add Quote
              </Link>
            </div>
          </div>
        </div>
        <Dashboard
          baseUrl={`draft-purchase-orders/dashboard/?`}
          onSelectRows={this.onSelectDraftPurchaseOrder}
          onDeselectRows={this.onDeselectDraftPurchaseOrder}
          selectedData={this.state.selectedDraftPurchaseOrder}
          headers={headers}
          RowComponent={PurchaseOrderRow}
          idAttribute="id"
          filters={filters}
          ref={this.draftPurchaseOrderDashboard}
          isCheckboxEnabled={false}
          dashboardClassname="is-purchase-order-dashboard"
          searchQuery={this.state.searchQuery}
          onChangePage={this.onChangePage}
          currentPage={page}
          callbackRowFunction={this.onRowRedirect}
        />
      </div>
    );
  }
}
export default DraftPurchaseOrderDashboard;
