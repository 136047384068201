import React, { Component } from "react";
import PageContent from "UIKit/components/PageContent";
import Steps from "Coupons/components/AddCoupon/Steps";
import BasicInfoForm from "Coupons/components/AddCoupon/BasicInfoForm";
import UserRestrictionsForm from "Coupons/components/AddCoupon/UserRestrictionsForm";
import TransactionRestrictionsForm from "Coupons/components/AddCoupon/TransactionRestrictionsForm";
import SKURestrictionsForm from "Coupons/components/AddCoupon/SKURestrictionsForm";
import {
  faUserFriends,
  faFile,
  faMoneyBillWave,
  faCheck,
  faPrescriptionBottleAlt
} from "@fortawesome/free-solid-svg-icons";
import ReviewData from "Coupons/components/AddCoupon/ReviewData";
import { connect } from "react-redux";

const AddCouponContext = React.createContext();
const checkBoxIDs = ["waiveFees", "firstTime"];
const classIDs = ["Branded", "Generic", "Unibranded"];
const restrictionIDs = ["Prescription", "OTC"];
const INCLUDE_EXCLUDE = "INCLUDE_EXCLUDE";
const INCLUDE = "INCLUDE";
const EXCLUDE = "EXCLUDE";
const WILL_INCLUDE = 1;
const WILL_EXCLUDE = 0;
const INIT = -1;

class AddCoupon extends Component {
  stepsLabels = [
    "Basic Coupon Information",
    "User Restrictions",
    "Transaction Restrictions",
    "SKU Restrictions",
    "Review Data and Submit"
  ];
  stepsIcons = [
    faFile,
    faUserFriends,
    faMoneyBillWave,
    faPrescriptionBottleAlt,
    faCheck
  ];
  forms = [
    <BasicInfoForm />,
    <UserRestrictionsForm />,
    <TransactionRestrictionsForm />,
    <SKURestrictionsForm />,
    <ReviewData />
  ];
  ncrLocations = [
    "Caloocan",
    "Las Pinas",
    "Makati",
    "Mandaluyong",
    "Malabon",
    "Manila",
    "Marikina",
    "Muntinlupa",
    "Navotas",
    "Paranaque",
    "Pasay",
    "Pasig",
    "Pateros",
    "Quezon City",
    "San Juan",
    "Taguig",
    "Valenzuela"
  ];
  allCategories = [
    "Bone and Joint",
    "Cough and Colds",
    "Diabetes",
    "Endocrine",
    "Eye and Ear",
    "Hypertension",
    "Infection",
    "Kits and First Aid",
    "Mental Health",
    "Pain and Fever",
    "Urinary and Reproductive Health",
    "Respiratory Health",
    "Stomach Care",
    "Vitamins, Minerals, and Supplements",
    "Dermatologic",
    "Cardiovascular",
    "Blood Drugs",
    "Cancer",
    "Electrolytes",
    "Oral Care"
  ];

  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      coupon: {
        type: "Percent",
        locations: [],
        includedItems: [],
        excludedItems: [],
        includedSKUs: [],
        excludedSKUs: []
      },
      willInclude: INIT,
      selectedCategory: "All",
      selectedClasses: [],
      selectedRestrictions: [],
      /*
       * Functions 
       */
      getData: this.getData,
      getActivationDate: this.getActivationDate,
      getExpirationDate: this.getExpirationDate,
      removeFromLocations: this.removeFromLocations,
      addItemToList: this.addItemToList,
      clickRadioButton: this.clickRadioButton,
      removeItemFromList: this.removeItemFromList,
      addSKUToList: this.addSKUToList
    };
  }

  setActiveStep = event => {
    let toActive = event.currentTarget.id;
    this.setState({ activeStep: parseInt(toActive, 10) });
  };

  getData = event => {
    let fieldID = event.target.id;
    let fieldData = event.target.value;

    if (checkBoxIDs.includes(fieldID)) {
      let checked = event.target.checked;
      this.setState(previousState => {
        return { coupon: { ...previousState.coupon, [fieldID]: checked } };
      });
    } else if (fieldID === "locations") {
      if (fieldData === "NCR") {
        this.setState(previousState => {
          return {
            coupon: { ...previousState.coupon, locations: this.ncrLocations }
          };
        });
      } else if (!this.state.coupon.locations.includes(fieldData)) {
        this.setState(previousState => {
          return {
            coupon: {
              ...previousState.coupon,
              locations: [...previousState.coupon.locations, fieldData]
            }
          };
        });
      }
    } else if (fieldID === "categories") {
      this.setState({
        selectedCategory: fieldData
      });
    } else if (classIDs.includes(fieldID)) {
      let checked = event.target.checked;
      if (checked) {
        this.setState(previousState => {
          return {
            selectedClasses: [...previousState.selectedClasses, fieldID]
          };
        });
      } else {
        this.setState(previousState => {
          return {
            selectedClasses: previousState.selectedClasses.filter(item => {
              return item !== fieldID;
            })
          };
        });
      }
    } else if (restrictionIDs.includes(fieldID)) {
      let checked = event.target.checked;
      if (checked) {
        this.setState(previousState => {
          return {
            selectedRestrictions: [
              ...previousState.selectedRestrictions,
              fieldID
            ]
          };
        });
      } else {
        this.setState(previousState => {
          return {
            selectedRestrictions: previousState.selectedRestrictions.filter(
              item => {
                return item !== fieldID;
              }
            )
          };
        });
      }
    } else {
      this.setState(previousState => {
        return { coupon: { ...previousState.coupon, [fieldID]: fieldData } };
      });
    }
  };

  removeFromLocations = event => {
    let locationID = parseInt(event.target.id, 10);
    let locations = this.state.coupon.locations.filter((location, i) => {
      return locationID !== i;
    });
    this.setState(previousState => {
      return { coupon: { ...previousState.coupon, locations: locations } };
    });
    console.log(locationID);
  };

  getActivationDate = date => {
    this.setState(previousState => {
      return { coupon: { ...previousState.coupon, activation: date } };
    });
  };

  getExpirationDate = date => {
    this.setState(previousState => {
      return { coupon: { ...previousState.coupon, expiration: date } };
    });
  };

  addItemToList = item => {
    const notYetIncluded = !this.state.coupon.includedItems.includes(item);
    const notYetExcluded = !this.state.coupon.excludedItems.includes(item);
    const alreadyInitialized = this.state.willInclude !== INIT;
    const willInclude = this.state.willInclude === WILL_INCLUDE;
    const willExclude = this.state.willInclude === WILL_EXCLUDE;

    if (notYetIncluded && notYetExcluded && alreadyInitialized) {
      this.setState(previousState => {
        if (willInclude)
          return {
            coupon: {
              ...previousState.coupon,
              includedItems: [...previousState.coupon.includedItems, item]
            }
          };
        else if (willExclude)
          return {
            coupon: {
              ...previousState.coupon,
              excludedItems: [...previousState.coupon.excludedItems, item]
            }
          };
      });
    }
  };

  addSKUToList = () => {
    const selectedCategory = this.state.selectedCategory;
    let notYetIncluded = true;
    this.state.coupon.includedSKUs.map(item => {
      if (selectedCategory === item.category) notYetIncluded = false;
      return item;
    });
    let notYetExcluded = true;
    this.state.coupon.excludedSKUs.map(item => {
      if (selectedCategory === item.category) notYetExcluded = false;
      return item;
    });
    const alreadyInitialized = this.state.willInclude !== INIT;
    const willInclude = this.state.willInclude === WILL_INCLUDE;
    const willExclude = this.state.willInclude === WILL_EXCLUDE;

    if (notYetIncluded && notYetExcluded && alreadyInitialized) {
      switch (selectedCategory) {
        case "All":
          if (willInclude) {
            if (this.state.coupon.excludedSKUs.length === 0) {
              this.setState(previousState => {
                return {
                  coupon: {
                    ...previousState.coupon,
                    includedSKUs: this.allCategories.map(category => {
                      return {
                        category: category,
                        classes: previousState.selectedClasses,
                        restrictions: previousState.selectedRestrictions
                      };
                    })
                  }
                };
              });
            }
          } else if (willExclude) {
            if (this.state.coupon.includedSKUs.length === 0) {
              this.setState(previousState => {
                return {
                  coupon: {
                    ...previousState.coupon,
                    excludedSKUs: this.allCategories.map(category => {
                      return {
                        category: category,
                        classes: previousState.selectedClasses,
                        restrictions: previousState.selectedRestrictions
                      };
                    })
                  }
                };
              });
            }
          }
          break;
        default:
          let toInsert = {
            category: selectedCategory,
            classes: this.state.selectedClasses,
            restrictions: this.state.selectedRestrictions
          };
          this.setState(previousState => {
            if (willInclude) {
              return {
                coupon: {
                  ...previousState.coupon,
                  includedSKUs: [...previousState.coupon.includedSKUs, toInsert]
                }
              };
            } else if (willExclude) {
              return {
                coupon: {
                  ...previousState.coupon,
                  excludedSKUs: [...previousState.coupon.excludedSKUs, toInsert]
                }
              };
            }
          });
          break;
      }
    }
  };

  removeItemFromList = event => {
    const itemTitle = event.target.name;
    const tableName = event.target.id;

    switch (tableName) {
      case "includedItems":
        this.setState(previousState => {
          return {
            coupon: {
              ...previousState.coupon,
              includedItems: previousState.coupon.includedItems.filter(item => {
                return item.title !== itemTitle;
              })
            }
          };
        });
        break;
      case "excludedItems":
        this.setState(previousState => {
          return {
            coupon: {
              ...previousState.coupon,
              excludedItems: previousState.coupon.excludedItems.filter(item => {
                return item.title !== itemTitle;
              })
            }
          };
        });
        break;
      case "includedSKUs":
        this.setState(previousState => {
          return {
            coupon: {
              ...previousState.coupon,
              includedSKUs: previousState.coupon.includedSKUs.filter(item => {
                return item.category !== itemTitle;
              })
            }
          };
        });
        break;
      case "excludedSKUs":
        this.setState(previousState => {
          return {
            coupon: {
              ...previousState.coupon,
              excludedSKUs: previousState.coupon.excludedSKUs.filter(item => {
                return item.category !== itemTitle;
              })
            }
          };
        });
        break;
      default:
        break;
    }
  };

  clickRadioButton = event => {
    let radioGroup = event.target.name;
    let radioID = event.target.id;
    switch (radioGroup) {
      case INCLUDE_EXCLUDE:
        switch (radioID) {
          case INCLUDE:
            this.setState({
              willInclude: WILL_INCLUDE
            });
            break;
          case EXCLUDE:
            this.setState({
              willInclude: WILL_EXCLUDE
            });
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }
  };

  render() {
    return (
      <AddCouponContext.Provider value={this.state}>
        <PageContent>
          <h3 className="title is-4">Add Coupon</h3>
          <Steps
            labels={this.stepsLabels}
            active={this.state.activeStep}
            onClick={this.setActiveStep}
            icons={this.stepsIcons}
          />
          {this.forms.map(
            (form, i) =>
              i === this.state.activeStep && <span key={i}>{form}</span>
          )}
        </PageContent>
      </AddCouponContext.Provider>
    );
  }
}

const mapStateToProps = state => ({
  searchResult: state.searchResult
});

connect(mapStateToProps)(AddCoupon);

export { AddCoupon, AddCouponContext };
