import React from "react";
import classNames from "classnames";
import styles from "./refill.module.scss";

const TargetDeliveryDateInput = () => {
  return (
    <table className={classNames(styles["target-delivery-date"])}>
      <tr className="mb-1 is-size-7 is-italic has-text-left">
        <td colSpan="2">dd</td>
        <td />
        <td colSpan="3">mmm</td>
        <td />
        <td>yyyy</td>
      </tr>
      <tr>
        {Array(11)
          .fill(0)
          .map(() => (
            <td />
          ))}
      </tr>
    </table>
  );
};

export default TargetDeliveryDateInput;
