import React, { Component, Fragment } from "react";
import { without } from "lodash";
import { Link } from "react-router-dom";
import { parse } from "query-string";
import { stringify } from "querystring";
import Dashboard from "../../Dashboard/components/Dashboard";
import { SearchBar } from "UIKit/components/Forms/FormInput";
import "../PurchaseOrder.scss";
import CreateSourcingSheetButton from "./CreateSourcingSheetButton";

const ENTER = 13;
export const headers = [
  {
    displayName: "Status",
    attributeName: "status",
    canSortBy: true
  },
  {
    displayName: "Customer Name",
    attributeName: "createdFor",
    canSortBy: true
  },
  {
    displayName: "Order Code",
    attributeName: "code",
    canSortBy: true
  },
  {
    displayName: "Created By",
    attributeName: "createdBy",
    canSortBy: true
  },
  {
    displayName: "Last Updated By",
    attributeName: "lastUpdatedBy",
    canSortBy: true
  }
];

const formattedDateTime = dateTime => {
  const dateTimeFormat = {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric"
  };
  return (
    <Fragment>
      <span className="help has-text-primary">
        {new Date(dateTime).toLocaleString("en-US", dateTimeFormat)}
      </span>
    </Fragment>
  );
};

const FormattedOrderFor = ({ name }) => {
  const splittedName = name.split("(");
  return (
    <Fragment>
      <div>{splittedName[0]}</div>
      <div className="has-text-primary is-size-7">
        {splittedName[1].split(")")[0]}
      </div>
    </Fragment>
  );
};

const PurchaseOrderRow = ({ data }) => {
  return (
    <Fragment>
      <td className="has-text-centered has-text-weight-bold">
        <button className="button is-primary button-dashboard">
          {data.status}
        </button>
      </td>
      <td>
        <FormattedOrderFor name={data.createdFor} />
      </td>
      <td className="has-text-weight-bold">
        <Link to={`/purchase-orders/${data.id}`}>{data.code}</Link>
      </td>
      <td className="has-text-centered">
        {data.createdBy.split("@")[0] || ""}
        <br />
        {formattedDateTime(data.created)}
      </td>
      <td />
    </Fragment>
  );
};
class DraftPurchaseOrderDashboard extends Component {
  constructor(props) {
    super(props);
    this.purchaseOrderDashboard = React.createRef();
    this.state = {
      selectedPurchaseOrder: [],
      searchQuery: ""
    };
  }

  onSelectPurchaseOrder = purchaseOrder => {
    this.setState(previousState => {
      return {
        selectedPurchaseOrder: [
          ...previousState.selectedPurchaseOrder,
          ...purchaseOrder
        ]
      };
    });
  };

  onDeselectPurchaseOrder = purchaseOrder => {
    this.setState(previousState => {
      return {
        selectedPurchaseOrder: without(
          previousState.selectedPurchaseOrder,
          ...purchaseOrder
        )
      };
    });
  };

  onChangePage = currentPage => {
    const urlParameters = parse(this.props.history.location.search);
    urlParameters.page = currentPage;
    this.props.history.push(`?${stringify(urlParameters)}`);
  };

  onRowRedirect = id => {
    this.props.history.push(`/purchase-orders/${id}`);
  };

  getDashboardFilters = () => {
    const urlParameters = parse(this.props.history.location.search, {
      decode: false
    });
    return Object.keys(urlParameters).reduce((accumulator, key) => {
      var newFilters;
      if (Array.isArray(urlParameters[key]))
        newFilters = urlParameters[key].map(value => ({
          field: key,
          value: value
        }));
      else newFilters = [{ field: key, value: urlParameters[key] }];
      return [...accumulator, ...newFilters];
    }, []);
  };

  handleKeyPress = event => {
    switch (event.keyCode) {
      case ENTER:
        this.props.history.push(
          `/purchase-orders/?search=${event.target.value}`
        );
        break;
      default:
        break;
    }
  };

  render() {
    const filters = this.getDashboardFilters();
    const pageFilter = filters.find(filter => {
      return filter.field === "page";
    });
    const page = pageFilter ? pageFilter.value : 1;
    return (
      <div className="container">
        <h1 className="has-text-weight-bold is-size-4">Purchase Orders</h1>
        <br />
        <br />
        <div className="level">
          <div className="level-left">
            <SearchBar
              id="search"
              withIcon={true}
              hideResults={false}
              onKeyDown={this.handleKeyPress}
              placeholder="Search PO"
            />
          </div>

          <div className="level-right mb-3">
            <CreateSourcingSheetButton
              selectedPurchaseOrder={this.state.selectedPurchaseOrder}
              redirect={this.props.history.push} />
          </div>
        </div>
        <Dashboard
          baseUrl={`purchase-orders/dashboard/?`}
          onSelectRows={this.onSelectPurchaseOrder}
          onDeselectRows={this.onDeselectPurchaseOrder}
          selectedData={this.state.selectedPurchaseOrder}
          headers={headers}
          RowComponent={PurchaseOrderRow}
          idAttribute="id"
          filters={filters}
          ref={this.purchaseOrderDashboard}
          isCheckboxEnabled={true}
          dashboardClassname="is-purchase-order-dashboard"
          searchQuery={this.state.searchQuery}
          onChangePage={this.onChangePage}
          currentPage={page}
          callbackRowFunction={this.onRowRedirect}
        />
      </div>
    );
  }
}
export default DraftPurchaseOrderDashboard;
