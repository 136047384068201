import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "Auth/authContext";
import userScopeList from "./utils/scope.json";
import { Link } from "react-router-dom";
import facts from "./utils/facts.json";

const Home = () => {
  const { authState } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [userScope, setUserScope] = useState([]);
  const [fact, setFact] = useState(0);

  useEffect(() => {
    const userDetail = authState.user.getIdToken().payload;
    const email = userDetail["email"];
    const groups = authState.user.getAccessToken().payload["cognito:groups"];

    setEmail(email.split("@")[0]);
    setScope(groups);
    setFact(facts[Math.floor(Math.random() * Object.keys(facts).length)]);
  }, []);

  const setScope = (groups) => {
    let scopes = [];
    if (groups.includes("cons") || groups.includes("admin"))
      scopes = scopes.concat(userScopeList["cons"]);
    if (groups.includes("entp") || groups.includes("admin"))
      scopes = scopes.concat(userScopeList["entp"]);
    setUserScope(scopes);
  };

  return (
    <section>
      <section className=" columns is-centered is-vcentered pt-5 pb-2 mt-5">
        <div className="column is-narrow is-text-light pb-5">
          <h1 className="title is-3 has-text-centered">
            Hi{" "}
            <span className="has-text-primary has-text-weight-bold">
              {email}
            </span>{" "}
            👋🏼!
            <div className="subtitle is-3">What are you working on today?</div>
          </h1>
        </div>
      </section>
      <section className=" columns is-centered">
        <div className="buttons">
          {userScope.map((scope) => {
            return (
              <Link to={scope.link} className="button is-rounded">
                {scope.name}
              </Link>
            );
          })}
        </div>
      </section>
    </section>
  );
};

export default Home;
