import React from "react";
import { useQuery } from "@apollo/react-hooks";
import PropTypes from "prop-types";
import { getSourcingSheet } from "Sourcing/Schema";

import PageContent from "UIKit/components/PageContent";
import SourcingSheetHeader from "../SourcingSheetHeader";
import Dashboard from "DashboardV2/DashboardV2";
import PickListRow from "./PickListRow";
import PickListNotification from "./PickListNotification";
import Loading from "UIKit/components/Loading";
import { pickListHeaders } from "../jsonInitializers";

import "./PickList.scss";

const PickList = ({ match: { params } }) => {
  const { loading, data } = useQuery(getSourcingSheet, {
    variables: { id: params.id }
  });

  if (loading) return <Loading />;

  if (data.sheet && !data.sheet.length)
    return <div>No matching sourcing sheet with code {params.id} found.</div>;

  return (
    <PageContent>
      <SourcingSheetHeader
        title="Pick List Summary"
        tag={data.sheet[0].code}
        buttonPosition="right"
        buttonLabel="Proceed to Sourcing"
        buttonLink={`/sourcing/${params.id}/sheet`}
      />
      <PickListNotification />
      <div className="is-pick-list-dashboard">
        <Dashboard headers={pickListHeaders}>
          {data.sheet[0].picklist.map((row, index) => {
            return <PickListRow data={row} key={index} />;
          })}
        </Dashboard>
      </div>
    </PageContent>
  );
};

PickList.propTypes = {
  match: PropTypes.object.isRequired
};

export default PickList;
