import React from "react";

const OptionListRender = React.forwardRef(
  (
    { optionList, handleChangeSelect, optionSelected, keyValue, keyElement },
    ref
  ) => {
    const condition = !optionList || !!!Object.keys(optionList).length;
    return (
      <div className={`option ${condition && "no-value-found"}`}>
        {condition && "No value found"}
        {!condition &&
          Object.keys(optionList).map((option, index) => (
            <div
              className={`option option-el ${
                index === optionSelected ? "selected-option" : ""
              }`}
              name="optionList"
              key={index}
              onClick={handleChangeSelect(optionList[option])}
              ref={event => {
                ref[index] = event;
              }}
            >
              {optionList[option][keyElement]}
            </div>
          ))}
      </div>
    );
  }
);

export default OptionListRender;
