import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPrint } from "@fortawesome/free-solid-svg-icons";

const PickListNotification = () => {
  return (
    <div className="notification is-warning p-2">
      <div className="columns is-vcentered">
        <div className="column">
          All items here are aggregated from the chosen POs. Update on-hand
          quantities and OOS before proceeding to sourcing.
        </div>
        <div className="column is-narrow">
          <button className="button is-primary mr-0">
            <span className="icon is-small mr-1">
              <FontAwesomeIcon icon={faPrint} />
            </span>
            Print Pick List
          </button>
        </div>
      </div>
    </div>
  );
};

export default PickListNotification;
