import React, { Component } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import PurchaseOrderModal from "./PurchaseOrderModal";
import Tag from "UIKit/components/Tag";
import { connect } from "react-redux";
import { pushNotification } from "UIKit/actions";
export class MainPanelHeader extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isModalActive: false
    };
  }

  handleSetModalActive = () => this.setState(() => ({ isModalActive: true }));

  handleSetInActive = () => this.setState(() => ({ isModalActive: false }));

  render() {
    const { enableEditPrivilege, objectFetched, isButtonDisabled } = this.props;

    return (
      <div className="columns">
        <PurchaseOrderModal
          objectCode={objectFetched.code}
          objectFetched={objectFetched}
          isActive={this.state.isModalActive}
          handleSetInActive={this.handleSetInActive}
          pushNotification={this.props.pushNotification}
        />
        <div className="column">
          <div className="subtitle has-text-weight-bold">
            {this.props.orderTitle || "Quote"} &nbsp;
            {objectFetched.code && (
              <span id="dpo-code" className="has-text-primary">
                {objectFetched.code}
              </span>
            )}
          </div>
          <div className="field is-grouped is-grouped-multiline">
            {!!objectFetched.corporateClient &&
              !!objectFetched.corporateClient.priceListCode && (
                <Tag label="Price List" valueColor="is-primary">
                  {objectFetched.corporateClient.priceListCode}
                </Tag>
              )}
            {!!objectFetched.taxRate && (
              <Tag label="Tax Rate" valueColor="is-primary">
                {objectFetched.taxRate.replace(/_/g, " ")}
              </Tag>
            )}
          </div>
        </div>
        <div className="column">
          {enableEditPrivilege ? (
            <div className="buttons is-right">
              <button
                type="submit"
                form="dpo-form"
                name="handleSaveButton"
                className="button is-primary"
              >
                Save
              </button>
              {!!objectFetched.id && (
                <Link
                  name="printPurchaseOrder"
                  className="button"
                  to={`/quotes/${!!objectFetched && objectFetched.id}/print`}
                  tabIndex={-1}
                >
                  Print
                </Link>
              )}
              <button
                className="button"
                onClick={this.props.handleActiveDuplicationModal}
              >
                Duplicate
              </button>
              {!!objectFetched.id && (
                <button
                  name="openPurchaseOrder"
                  className="button"
                  disabled={isButtonDisabled}
                  onClick={this.handleSetModalActive}
                >
                  Open Purchase Order
                </button>
              )}
            </div>
          ) : (
            <div className="select is-right">
              <select name="status">
                <option value="CONFIRMED">CONFIRMED</option>
                <option value="DR PRINTED">DR PRINTED</option>
                <option value="PICKED & PACKED">PICKED & PACKED</option>
                <option value="FOR DELIVERY">FOR DELIVERY</option>
                <option value="DISPATCHED">DISPATCHED</option>
                <option value="DELIVERED">DELIVERED</option>
                <option value="CANCELLED">CANCELLED</option>
              </select>
            </div>
          )}
        </div>
      </div>
    );
  }
}
MainPanelHeader.propTypes = {
  // handleSubmit: PropTypes.func,
  enableEditPrivilege: PropTypes.bool,
  objectFetched: PropTypes.shape({
    corporateClient: PropTypes.shape({
      priceListCode: PropTypes.string
    }),
    taxRate: PropTypes.string
  }),
  handleOpenModal: PropTypes.func,
  isButtonDisabled: PropTypes.bool
};

MainPanelHeader.propTypes = {
  // handleSubmit: PropTypes.func,
  enableEditPrivilege: PropTypes.bool,
  objectFetched: PropTypes.shape({
    corporateClient: PropTypes.shape({
      priceListCode: PropTypes.string
    }),
    taxRate: PropTypes.string
  }),
  handleOpenModal: PropTypes.func,
  isButtonDisabled: PropTypes.bool
};

export default connect(null, { pushNotification })(MainPanelHeader);
