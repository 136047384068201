import { actionTypes } from "Auth/actions";

const DEFAULT_STATE = {
  authToken: null,
  user: null
};

export const authToken = (state = DEFAULT_STATE.authToken, action) => {
  switch (action.type) {
    case actionTypes.SET_AUTH_TOKEN:
      return action.payload;
    case actionTypes.REMOVE_AUTH_TOKEN:
      return null;
    default:
      return state;
  }
};

export const user = (state = DEFAULT_STATE.user, action) => {
  switch (action.type) {
    case actionTypes.SET_USER:
      return action.payload;
    default:
      return state;
  }
};
