import React from "react";
import classNames from "classnames";
import styles from "./refill.module.scss";
import TargetDeliveryDateInput from "./TargetDeliveryDateInput";

const RefillFormBody = ({ transaction }) => {
  return (
    <div className="is-size-4 has-text-weight-bold">
      <div>
        <table className={classNames(styles["body"])}>
          <tr>
            <td className="has-text-primary">
              <div className="mt-1">Target delivery date</div>
            </td>
            <td>
              <TargetDeliveryDateInput />
            </td>
          </tr>
        </table>
      </div>
      <div className="has-text-primary is-size-4 mt-1">Order Details</div>
      <hr className="divider" className="mt-0" />
      <div>
        <table className={classNames(styles["body"])}>
          <tr>
            <td>Full Name</td>
            <td colSpan="2">
              <input
                className="input"
                disabled
                value={`${transaction.customerFirstName || ""} ${
                  transaction.customerLastName || ""
                }`}
              />
            </td>
          </tr>
          <tr>
            <td>Address</td>
            <td colSpan="2">
              <input
                className="input"
                disabled
                value={`${transaction.shippingStreetAddress || ""}, ${
                  transaction.shippingBarangay || ""
                }`}
              />
              <br />
              <input
                className="input mt-1"
                disabled
                value={`${transaction.shippingCityMunicipality || ""}, ${
                  transaction.shippingProvince || ""
                }`}
              />
            </td>
          </tr>
          <tr>
            <td>Mobile Number</td>
            <td colSpan="2">
              <input
                className="input"
                disabled
                value={`0${transaction.mobileNumber}`}
              />
            </td>
          </tr>
        </table>
      </div>
    </div>
  );
};

export default RefillFormBody;
