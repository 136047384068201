import React, { useState, useEffect, useContext } from "react";
import { Auth } from "aws-amplify";
import { AuthContext } from "Auth/authContext";
import { Route, Redirect } from "react-router-dom";
import Login from "Auth/Forms/LoginForm";
import Loading from "UIKit/components/Loading/Loading";

function PrivateRoute({
  component: Component,
  authorizedGroups,
  fallbackRoute,
  ...props
}) {
  const [isAuthorized, setIsAuthorized] = useState("LOADING");
  const { authState } = useContext(AuthContext);
  const customProps = { ...props };
  useEffect(() => {
    validateAuthorization();
  }, []);

  const validateAuthorization = () => {
    try {
      if (!authorizedGroups) return setIsAuthorized(true);
      const { userGroups } = authState;

      // I use the normal for loop because I need to return immediately if the user is authorized.
      // forEach doesn't have the feature, map creates new object, and filter also creates new object.
      for (var index = 0; index < authorizedGroups.length; index++) {
        if (
          userGroups.includes(authorizedGroups[index]) ||
          userGroups.includes("admin")
        )
          return setIsAuthorized(true);
      }
      setIsAuthorized(false);
    } catch (error) {
      setIsAuthorized(false);
    }
  };

  return (
    <Route
      {...props}
      render={renderProps => {
        if (isAuthorized === "LOADING") return <Loading message="Loading..." />;
        else if (isAuthorized === true) {
          return <Component {...customProps} {...renderProps} />;
        } else if (fallbackRoute) return <Redirect to={fallbackRoute} />;
        else return <Login />;
      }}
    />
  );
}

export default PrivateRoute;
