import React from "react";
import classNames from "classnames";
import { Field, ErrorMessage } from "formik";
import Select from "react-select/creatable";

/**
 ** Select field with label and error message.
 ** Supported parameters:
 **
 ** fieldProps: {
 **               'name': String,
 **               'placeholder': String,
 **               'label': String,
 **               'option': Object [],
 **               'onChange': Function,
 **               'value': Object {},
 **             }
 **
 ** Note: You need a list of objects as options with label and value element.
 **/
const FormSelect = props => {
  //* destructure props
  const { name, label, onChange, isRequired } = props;

  //* Function to set the value of the react-select in
  //* formik values.
  //*
  //* Note: Curried Function.
  //*       Need to call handleChange(form) to return (selectedValue) => { ... }
  const handleChange = form => selectedValue => {
    form.setFieldValue(name, selectedValue);
    if (onChange) onChange(selectedValue, name);
  };

  const SelectComponent = ({ form }) => (
    <div className="field">
      <div className="control has-addons">
        <div className="columns px-0">
          {props.staticText && (
            <div className="column is-2">
              <p class="control">
                <button class="button is-static">{props.staticText}</button>
              </p>
            </div>
          )}
          <div className="column">
            <Select
              {...props}
              className="is-size-6  pb-0"
              onChange={handleChange(form)}
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="field">
      <label className={classNames({ label: isRequired })}>
        {label} {isRequired && <span className="is-danger">*</span>}
      </label>
      <Field>{({ form }) => <SelectComponent form={form} />}</Field>
      <p className="help is-danger mt-0">
        <ErrorMessage
          name={name}
          render={error => {
            return <span>{error.value}</span>;
          }}
        />
      </p>
    </div>
  );
};

export default FormSelect;
