import React, { Fragment } from "react";
import PropTypes from "prop-types";
import channels from "assets/data/channels.json";

const propTypes = {
  channelFilter: PropTypes.string,
  handleChannelFilter: PropTypes.func,
  search: PropTypes.object
};

const Submenu = ({ channelFilter, handleChannelFilter, search }) => (
  <Fragment>
    <div className="field is-grouped">
      <div className="control">
        <div className="select">
          <select
            className="select button"
            value={channelFilter}
            onChange={handleChannelFilter}
          >
            <option value="" hidden>
              Channel Filter
            </option>
            <option value="all">All</option>
            {channels.map((channel, index) => (
              <option key={index} value={channel.channel}>
                {channel.label}
              </option>
            ))}
          </select>
        </div>
      </div>
      <div className="control">{search}</div>
    </div>
  </Fragment>
);

Submenu.propTypes = propTypes;

export default Submenu;
