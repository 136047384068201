import { actionTypes } from "UIKit/actions";

const DEFAULT_STATE = {
  notificationDetails: null
};

export const notificationReducer = (
  state = DEFAULT_STATE.notificationDetails,
  action
) => {
  switch (action.type) {
    case actionTypes.HIDE_NOTIFICATION:
      return null;

    case actionTypes.SHOW_NOTIFICATION:
      return action.payload;

    default:
      return state;
  }
};
