const medsForms = [
  "tablet",
  "capsule",
  "syrup",
  "suspension",
  "drops",
  "gel",
  "lotion"
];

export function getFormScore(itemTitle) {
  let formScore = 100;
  itemTitle = itemTitle.toLowerCase().split(" ");
  medsForms.map((form, i) => {
    if (itemTitle.includes(form)) formScore = i;
    return i;
  });
  return formScore;
}

export function getBrandScore(itemTitle) {
  // issue with this since not all itemTitles have "("
  // let itemBrand = itemTitle.toLowerCase().split("(");
  // itemBrand = itemBrand[1].replace(")", "");
  let itemBrand = '';
  if(itemTitle.includes("(")){
    itemBrand = itemTitle.toLowerCase().split("(");
    itemBrand = itemBrand[1].replace(")", "");
  } else {
    itemBrand = "value brand";
  }
  switch (itemBrand) {
    case "value brand":
      return 0;
    default:
      return itemBrand.charCodeAt(0) - 96;
  }
}

export function getHighlightedParts(item, searchQuery) {
  item.toDisplay = item.title;
  searchQuery.map(query => {
    if (query !== "") {
      let index = item.toDisplay.toLowerCase().indexOf(query);
      switch (query) {
        case "b":
          if (item.toDisplay[index + 1] !== ">") {
            item.toDisplay =
              item.toDisplay.slice(0, index) +
              "<b>" +
              item.toDisplay.slice(index, index + query.length) +
              "</b>" +
              item.toDisplay.slice(index + query.length);
          }
          break;
        default:
          item.toDisplay =
            item.toDisplay.slice(0, index) +
            "<b>" +
            item.toDisplay.slice(index, index + query.length) +
            "</b>" +
            item.toDisplay.slice(index + query.length);
          break;
      }
    }
    return query;
  });
  return item;
}
