import React from "react";
import { Field } from "formik";
import SearchInput from "UIKit/components/SearchInput/SearchBar";

const SearchField = ({
  name,
  functionToInvoke,
  optionList,
  autoFocus,
  keyElement,
  keyValue,
  customOnChangeInput,
  baseSearch,
  defaultValue,
  individualOrderSelectItem,
  onPaste
}) => {
  return (
    <Field
      name={name}
      render={({ field, form }) => (
        <SearchInput
          limitToFind={20}
          customSelectClick={functionToInvoke}
          field={field}
          form={form}
          className="input"
          onKeyTab={
            individualOrderSelectItem
              ? individualOrderSelectItem
              : functionToInvoke
          }
          onKeyEnter={
            individualOrderSelectItem
              ? individualOrderSelectItem
              : functionToInvoke
          }
          keyValue={keyValue}
          keyElement={keyElement}
          optionList={optionList}
          autoFocus={autoFocus}
          customOnChangeInput={customOnChangeInput}
          baseSearch={baseSearch}
          individualOrderSelectItem={individualOrderSelectItem}
          defaultValue={defaultValue}
          onPaste={onPaste}
        />
      )}
    />
  );
};

export default SearchField;
