import React from "react";
import { Field as TextField, ErrorMessage } from "formik";
import classNames from "classnames";

function Field(props) {
  const { required } = props;

  const classObject = { "has-text-danger": required, "is-hidden": !required };
  return (
    <div className="mb-3">
      <label className="label mb-0">
        {props.label}
        <span className={classNames(classObject)}>&nbsp; *</span>
      </label>
      <TextField {...props} className="input" />
      <ErrorMessage name={props.name}>
        {message => <div className="has-text-danger is-size-7">{message}</div>}
      </ErrorMessage>
    </div>
  );
}

export default Field;
