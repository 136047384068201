import { useReducer } from "react";
import { Auth } from "aws-amplify";
import {
  AUTH_CHECK,
  NEW_PASSWORD_CHECK,
  FORGOT_PASSWORD_CHECK
} from "./authStates";

const authReducer = (_, action) => {
  return { ...action };
};

const useAuthReducer = () => {
  const [state, dispatch] = useReducer(authReducer, {});

  const verifyUserAccess = user => {
    const userGroups = user.getAccessToken().payload["cognito:groups"];
    if (userGroups && userGroups.includes("hq")) return userGroups;
    else return false;
  };

  const checkSession = async successCallback => {
    const user = await Auth.currentSession();
    const userGroups = verifyUserAccess(user);
    const { attributes } = await Auth.currentAuthenticatedUser();
    if (!userGroups) {
      if (successCallback) await successCallback(false);
      return dispatch({ type: AUTH_CHECK.UNAUTHORIZED_ACCESS });
    }
    dispatch({ type: AUTH_CHECK.AUTH_SUCCESS, user, userGroups });
    if (successCallback) await successCallback(user, attributes, userGroups);
    return { userGroups, user };
  };

  const authenticateUser = async (values, successCallback, errorCallback) => {
    try {
      const user = await Auth.signIn(values);
      if (user.challengeName) {
        dispatch({ type: user.challengeName, user });
        if (successCallback) successCallback(user);
      } else await checkSession(successCallback);
    } catch ({ message }) {
      dispatch({ type: AUTH_CHECK.AUTH_FAILED, error: message });
      if (errorCallback) errorCallback();
    }
  };

  const isUserAuthenticated = async (successCallback, errorCallback) => {
    try {
      await checkSession(successCallback);
    } catch ({ message }) {
      dispatch({ type: AUTH_CHECK.AUTH_FAILED, error: message });
      if (errorCallback) errorCallback();
    }
  };

  const signOutUser = async (successCallback, errorCallback) => {
    try {
      await Auth.signOut({ global: true });
      if (successCallback) successCallback();
    } catch (error) {
      if (errorCallback) errorCallback();
    } finally {
      dispatch({ type: AUTH_CHECK.AUTH_FAILED });
    }
  };

  // ===========================================================================

  const verifyUserEmail = async (
    { username },
    successCallback,
    errorCallback
  ) => {
    try {
      await Auth.forgotPassword(username);
      dispatch({
        type: FORGOT_PASSWORD_CHECK.FORGOT_PASSWORD_EMAIL_SUCCESS,
        username
      });
      if (successCallback) successCallback();
    } catch ({ message }) {
      dispatch({
        type: FORGOT_PASSWORD_CHECK.FORGOT_PASSWORD_EMAIL_FAILED,
        error: message,
        username
      });
      if (errorCallback) errorCallback();
    }
  };

  const initiateChangeForgottenPassword = async (
    values,
    successCallback,
    errorCallback
  ) => {
    try {
      const { username, code, password } = values;
      await Auth.forgotPasswordSubmit(username, code, password);
      dispatch({ type: FORGOT_PASSWORD_CHECK.FORGOT_PASSWORD_SUCCESS });
      if (successCallback) successCallback();
    } catch ({ message }) {
      dispatch({
        type: FORGOT_PASSWORD_CHECK.FORGOT_PASSWORD_FAILED,
        error: message
      });
      if (errorCallback) errorCallback();
    }
  };

  // ============================================================================

  const createNewPassword = async (
    { user, newPassword },
    successCallback,
    errorCallback
  ) => {
    try {
      await Auth.completeNewPassword(user, newPassword);
      await checkSession(successCallback);
    } catch ({ message }) {
      dispatch({
        user,
        type: NEW_PASSWORD_CHECK.NEW_PASSWORD_FAILED,
        error: message
      });
      if (errorCallback) errorCallback();
    }
  };

  // =============================================================================

  const saveNewPhoneNumber = async (
    phoneNumber,
    successCallback,
    errorCallback
  ) => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      await Auth.updateUserAttributes(user, {
        phone_number: "+63" + phoneNumber
      });
      await checkSession(successCallback);
    } catch ({ message }) {
      dispatch({ type: AUTH_CHECK.NEW_PHONE_NUMBER_FAILED, error: message });
      if (errorCallback) errorCallback();
    }
  };

  const authHelpers = {
    authenticateUser,
    isUserAuthenticated,
    signOutUser,
    verifyUserEmail,
    initiateChangeForgottenPassword,
    createNewPassword,
    saveNewPhoneNumber
  };

  return [state, authHelpers];
};

export default useAuthReducer;
