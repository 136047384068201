import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const Steps = ({labels, active, onClick, icons}) => {
  return(
    <ul className="steps has-gaps has-content-centered">
      {labels.map((label, i) => 
        <li key={i} className={i === active ? "steps-segment is-active" : "steps-segment"}>
          <span className="steps-marker" id={i} onClick={onClick}>
            <FontAwesomeIcon icon={icons[i]}/>
          </span>
          <div className="steps-content">
            <p className="heading is-size-7">{label}</p>
          </div>
        </li>
      )}
    </ul>
  );
};

export default Steps;