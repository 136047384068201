import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";

export const OnHandAllocatedInput = ({ onHand, quantityNeeded }) => {
  const [allocatedValue, setAllocatedValue] = useState(onHand || 0);
  const [isAllocatedValueValid, setAllocatedValueValid] = useState(
    onHand >= quantityNeeded
  );

  /* eslint-disable */
  useEffect(() => {
    setAllocatedValueValid(allocatedValue >= quantityNeeded);
  }, [onHand, allocatedValue]);

  const handleChange = event => setAllocatedValue(parseInt(event.target.value));

  const classNameValidator = () => ({
    "is-primary": isAllocatedValueValid,
    "is-danger": !isAllocatedValueValid
  });

  const classNameIconValidator = () => ({
    "has-text-primary": isAllocatedValueValid,
    "has-text-danger": !isAllocatedValueValid
  });

  return (
    <div
      className="field tooltip is-tooltip-right"
      data-tooltip="On-hand left: 0"
    >
      <p className="control has-icons-right">
        <input
          className={classNames(
            "input has-text-centered",
            classNameValidator()
          )}
          type="number"
          value={allocatedValue}
          onChange={handleChange}
        />
        <span className="icon is-small is-right">
          <FontAwesomeIcon
            className={classNames(classNameIconValidator())}
            icon={isAllocatedValueValid ? faCheck : faTimes}
          />
        </span>
      </p>
    </div>
  );
};
