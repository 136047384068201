import React from "react";
import mgLogo from "assets/images/mg-teal.png";

const ReceiptHeader = ({ header, hasSubtitle, subtitle }) => (
  <div className="dr-header">
    <div className="dr-header-contents is-flex mb-1">
      <img className="logo" src={mgLogo} alt="MedGrocer logo" width="200" />
      <div>
        <p className="has-text-weight-bold has-text-right">{header}</p>
        <p className={hasSubtitle ? "has-text-weight-bold" : "is-invisible"}>
          {subtitle}
        </p>
      </div>
    </div>
    <hr className="divider" />
  </div>
);

export default ReceiptHeader;
