import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import phrases from "assets/data/phrases.json";

const style = {
  color: "teal"
};

const randomIndex = Math.floor(Math.random() * Object.keys(phrases).length);

const Loading = ({ size }) => {
  if (size === "small") {
    return (
      <FontAwesomeIcon icon={faSpinner} className="fa" spin style={style} />
    );
  } else {
    return (
      <section className="hero is-medium">
        <div className="hero-body has-text-centered">
          <p>
            <FontAwesomeIcon
              icon={faSpinner}
              className="fa-5x"
              spin
              style={style}
            />
          </p>
          <br />
          <h3 className="title has-text-centered">Please wait</h3>
          <h5 className="subtitle has-text-centered">
            {phrases[randomIndex].quote}
          </h5>
        </div>
      </section>
    );
  }
};

export default Loading;
