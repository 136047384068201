import React, { useState, useEffect } from "react";

const PurchaseOrderNotification = ({ objectFetched, isNotificationActive }) => {
  const [isActive, setIsActive] = useState(isNotificationActive);

  useEffect(() => {
    function setIsActiveBoolean() {
      const { isBillingAddressEqual, isShippingAddressEqual } = objectFetched;
      const isAddressEqualToSite =
        isBillingAddressEqual && isShippingAddressEqual;

      setIsActive(!isAddressEqualToSite);
    }
    setIsActiveBoolean();
  }, [objectFetched]);

  if (isActive) {
    return (
      <div className="notification is-warning is-size-7">
        <button className="delete" onClick={event => setIsActive(false)} />
        <p>
          This order details' addresses doesn't match with the billing and/or
          shipping addresses of{" "}
          <strong>
            <u>{objectFetched.createdForName}</u>.
          </strong>{" "}
          {/* If you want to match this order addresses to the corresponding site,{" "}
          <u className="has-text-primary has-text-weight-bold">
            Please click here
          </u>
          . */}
        </p>
      </div>
    );
  } else return null;
};

export default PurchaseOrderNotification;
