import React from "react";
import PageContent from "UIKit/components/PageContent";

const SourcingPickList = () => {
  return (
    <PageContent>
      <div className="notification is-warning">
        <div className="field is-grouped is-pulled-right">
          <p className="control">
            <button
              className="button is-primary"
              // onClick={this.printDrugCard}
            >
              Print
            </button>
          </p>

          <p className="control">
            <button
              className="button is-pulled-right"
              // onClick={this.handleBackToDashboard}
            >
              Back to Dashboard
            </button>
          </p>
        </div>
      </div>
    </PageContent>
  );
};

export default SourcingPickList;
