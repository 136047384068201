import api from "Services/api";
export const actionTypes = {
  SET_BATCH_TRANSACTION: "SET_BATCH_TRANSACTION",
  POST_TRANSACTIONS_REQUEST: "POST_TRANSACTIONS_REQUEST",
  POST_TRANSACTIONS_FAILURE: "POST_TRANSACTIONS_FAILURE",
  POST_TRANSACTIONS_SUCCESS: "POST_TRANSACTIONS_SUCCESS"
};

export const setBatchTransaction = batchTransaction => dispatch => {
  return dispatch({
    type: actionTypes.SET_BATCH_TRANSACTION,
    payload: batchTransaction
  });
};

function postTransactionsRequest() {
  return {
    type: actionTypes.POST_TRANSACTIONS_REQUEST
  };
}

function postTransactionsSuccess(transactions) {
  return {
    type: actionTypes.POST_TRANSACTIONS_SUCCESS,
    payload: transactions
  };
}

function postTransactionsFailure(error) {
  return {
    type: actionTypes.POST_TRANSACTIONS_FAILURE,
    error
  };
}

export const postTransactions = transactions => dispatch => {
  dispatch(postTransactionsRequest());
  return api
    .post("transactions/", transactions)
    .then(response => {
      return dispatch(postTransactionsSuccess(response.data));
    })
    .catch(error => {
      return dispatch(postTransactionsFailure(error));
    });
};
