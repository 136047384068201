import React from "react";
import { Row, Cell } from "UIKit/components/Table_v2/Table";
import PropTypes from "prop-types";

const TableRowHeader = ({ headerTitles }) => (
  <Row isTableHeader>
    {headerTitles.map((titleObj, index) => (
      <Cell
        key={index}
        priority={titleObj.priority}
        className={titleObj.className}
      >
        {titleObj.title}
      </Cell>
    ))}
  </Row>
);

TableRowHeader.propTypes = {
  headerTitles: PropTypes.array.isRequired,
};

export default TableRowHeader;
