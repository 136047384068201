/**
 * Accepts string and if that string contains breakline(s),
 * split it and return the result.
 * =====================================================================================
 * @param {String} string
 * =====================================================================================
 */
export const dataSplitter = string => string.split("\n");

/**
 * Accepts Array/List to initialize the current data to manipulate,
 * an object/inputDetails that contains the name of the input and current index,
 * a function/callback to run a custom function with the modified originalList attached to it.
 * =====================================================================================
 * @param {Array} originalList
 * @param {Object} inputDetails
 * @param {Function} callback
 * =====================================================================================
 */
const onPasteAction = (originalList, inputDetails, event, callback) => {
  let { name, mapper, index } = inputDetails;

  // split the name to validate if it is a nested object
  // by using (.) as the splitter.
  const splittedName = name.split(".");

  // map into a new originalList so that the original will not be modified imperatively
  const newList = [...originalList];
  let listLength = 0;

  // validate if the event is not null and clipboardData is not undefined.
  // if true => proceed
  if (event && event.clipboardData) {
    // extract the captured pasted data and split it using dataSplitter()
    const data = event.clipboardData.getData("text");
    const splittedData = dataSplitter(data);
    listLength = splittedData.length;
    let i = 0;

    // run through each data from the splittedData and
    // manipulate newList values base on the inputDetails.
    // push the data if the index is falsy (add),
    // edit the data in the newList[index] if index > 0 (edit)
    splittedData.forEach(element => {
      if (!(index >= 0) || originalList.length === i) {
        let item = null;

        // this block of function is for nested event name
        // if the splittedName length > 1, it will
        // use all the string to find the object to manipulate.
        //
        // Note: for now it will only supports up until the second
        //       level object.
        if (splittedName.length > 1)
          item = {
            ...mapper,
            ...{ [splittedName[0]]: { [splittedName[1]]: element } }
          };

        // if the splittedName length is equal to one,
        // it means that the target is the main object.
        else item = { ...mapper, ...{ [name]: element } };

        // push the item to the newList
        newList.push(item);
      } else if (index >= 0) {
        if (newList[index] && newList[index].skip) {
          index++;
        }

        // this block of function is for nested event name
        // if the splittedName length > 1, it will
        // use all the string to find the object to manipulate.
        //
        // Note: for now it will only supports up until the second
        //       level object.
        if (splittedName.length > 1)
          newList[index] = {
            ...mapper,
            ...newList[index],
            ...{
              [splittedName[0]]: { [splittedName[1]]: element }, event: "PASTE"
            }
          };

        // if the splittedName length is equal to one,
        // it means that the target is the main object.
        else
          newList[index] = {
            ...mapper,
            ...newList[index],
            ...{ [name]: element, event: "PASTE" }
          };
        index++;
      }
    });
  }

  // call the custom function if you have one
  if (callback) callback(newList, originalList, listLength);

  return newList;
};

export default onPasteAction;
