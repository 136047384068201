import React, { Fragment } from "react";

const FieldContainer = ({ children, label, hasAddons }) => (
  <Fragment>
    <label>{label}</label>
    <div className={`field is-fullwidth ${hasAddons ? "has-addons" : ""}`}>
      {children}
    </div>
  </Fragment>
);

export default FieldContainer;
