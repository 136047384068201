import React, { Fragment } from "react";
import PropTypes from "prop-types";

import Card from "UIKit/components/Card";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

const BatchTransactionForm = ({
  onFileChange,
  fileName,
  priceLists,
  selectedPriceList,
  handleChange
  // channels,
  // selectedChannel
}) => {
  const UploadForm = (
    <Fragment>
      <div className="file has-name">
        <label className="file-label">
          <input
            className="file-input"
            type="file"
            name="batch-upload-template"
            accept=".csv"
            onChange={onFileChange}
          />
          <span className="file-cta has-background-primary has-text-white">
            <span className="file-icon">
              <FontAwesomeIcon icon={faUpload} />
            </span>
            <span className="file-label">Choose a file</span>
          </span>
          <span className="file-name">{fileName}</span>
        </label>
      </div>
      <br />
      <div className="form-field">
        <div className="control">
          <label className="label">Select Price List</label>
          <div className="select">
            <select
              onChange={handleChange}
              name="priceList"
              value={selectedPriceList}
            >
              {priceLists.map((list, index) => (
                <option key={index}>{list.code}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
      <br />
      <div className="form-field">
        <div className="control">
          <label className="label">Select Secondary Price List</label>
          <div className="select">
            <select
              onChange={handleChange}
              name="secondaryPriceList"
              value={selectedPriceList}
            >
              <option value="">None</option>
              {priceLists.map((list, index) => (
                <option key={index}>{list.code}</option>
              ))}
            </select>
          </div>
        </div>
      </div>
    </Fragment>
  );

  const template = (
    <a
      href="https://docs.google.com/spreadsheets/d/1pWkCzavoGJE_BPehQ3CEePGzSvpZGcH4JQjEvaF5vZ8/edit#gid=2049806120"
      target="_blank"
      rel="noopener noreferrer"
    >
      here
    </a>
  );

  return (
    <Fragment>
      <p className="has-text-centered">
        Handle multiple orders by uploading a csv file.
      </p>
      <div className="columns is-centered">
        <div className="column is-two-thirds">
          <Card hasFooter={false}>
            <div className="columns">
              <div className="column">
                <span className="subtitle">
                  <strong>Batch Transaction Upload</strong>
                </span>
                <p>Upload orders from a template</p>
                <p className="help has-text-left">
                  Download the template {template}.
                </p>
              </div>
              <div className="column">{UploadForm}</div>
            </div>
          </Card>
        </div>
      </div>
    </Fragment>
  );
};

BatchTransactionForm.propTypes = {
  priceLists: PropTypes.array.isRequired,
  onFileChange: PropTypes.func.isRequired,
  fileName: PropTypes.string.isRequired,
  selectedPriceList: PropTypes.string,
  handleChange: PropTypes.func.isRequired
};

export default BatchTransactionForm;
