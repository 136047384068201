import api from "Services/api";

function isProductIncluded(item, include, exclude) {
  if (!include) return false;
  var isIncluded = false;
  const findCategory = (singleCategory) => singleCategory === exclude[i].title;
  for (var i = 0; i < include.length; i++) {
    switch (include[i].type) {
      case "all":
        isIncluded = true;
        break;
      case "classification":
        if (item.classification === include[i].title) isIncluded = true;
        break;
      case "category":
        if (item.category.find(findCategory)) isIncluded = true;
        break;
      case "product":
        if (item.productTitle === include[i].title) isIncluded = true;
        break;
      case "rxLevel":
        if (include[i].value.indexOf(item.rxLevel) >= 0) isIncluded = true;
        break;
      case "rxRequired":
        if (item.rxRequired === include[i].value) isIncluded = true;
        break;
      default:
        isIncluded = false;
        break;
    }
  }
  if (exclude) {
    for (i = 0; i < exclude.length; i++) {
      switch (exclude[i].type) {
        case "all":
          isIncluded = false;
          break;
        case "classification":
          if (item.classification === exclude[i].title) isIncluded = false;
          break;
        case "category":
          if (item.category.find(findCategory)) isIncluded = false;
          break;
        case "product":
          if (item.id === exclude[i].productId) isIncluded = false;
          break;
        case "rxRequired":
          if (item.rxRequired === exclude[i].value) isIncluded = false;
          break;
        default:
          isIncluded = false;
          break;
      }
    }
  }

  return isIncluded;
}

export function getCoupon(couponString, user, callback) {
  api
    .get(`coupons/${couponString}/`)
    .then((response) => {
      callback(response.data);
    })
    .catch((error) => {
      callback(null, "Coupon is invalid.");
    });
}

export function getDiscountAmount(coupon, transactionDetails, convenienceFee) {
  if (!coupon) return 0;
  if (coupon.code === "SCPWD") {
    const amount = transactionDetails.reduce((accumulator, currentValue) => {
      return (
        accumulator +
        parseFloat(currentValue.vatexUnitPrice) * currentValue.quantity * 0.2
      );
    }, 0);
    return parseFloat(amount).toFixed(2);
  } else {
    const { productInclusions, productExclusions } = coupon;
    var sum = 0;
    var discountAmount = 0;
    if (coupon.includeConvenienceFee) sum += parseFloat(convenienceFee);
    transactionDetails.forEach((item) => {
      if (isProductIncluded(item, productInclusions, productExclusions)) {
        let hasProductDiscount = productInclusions.find(
          (productInclusion) =>
            productInclusion?.type === "product" &&
            productInclusion?.title === item.productTitle &&
            !!productInclusion?.percentOff
        );
        let currentDiscount =
          (parseFloat(item.vatexUnitPrice.toFixed(2)) + parseFloat(item.vat)) *
          parseFloat(item.quantity);
        if (hasProductDiscount) {
          let productPercentDiscount = hasProductDiscount?.percentOff;
          currentDiscount =
            (currentDiscount * parseFloat(productPercentDiscount)) / 100;
        }
        sum += currentDiscount;
      }
    });
    if (!!coupon.percentOff) {
      sum = (sum * coupon.percentOff) / 100;
    }
    if (!!coupon.maxAmount && sum >= parseFloat(coupon.maxAmount)) {
      discountAmount = parseFloat(coupon.maxAmount);
    } else discountAmount = sum;
    return parseFloat(discountAmount).toFixed(2);
  }
}
