import gql from "graphql-tag";

export const getSourcingDashboardData = gql`
  query Sourcing($status: String!, $offset: Int, $limit: Int, $code: String) {
    sourcingsheets: sourcing_sourcingsheet(
      offset: $offset
      limit: $limit
      where: { status: { _eq: $status }, code: { _ilike: $code } }
    ) {
      id
      code
      last_updated
      last_update_by
      created
      created_by
      status
      purchase_order: sourcing_sourcingsheet_purchase_orders_aggregate {
        data: aggregate {
          count
        }
      }
    }
  }
`;

export const getSourcingCounts = gql`
  query {
    Sourcing: sourcing_sourcingsheet_aggregate(
      where: { status: { _eq: "SOURCING" } }
    ) {
      data: aggregate {
        count
      }
    }
    Completed: sourcing_sourcingsheet_aggregate(
      where: { status: { _eq: "COMPLETED" } }
    ) {
      data: aggregate {
        count
      }
    }
    Cancelled: sourcing_sourcingsheet_aggregate(
      where: { status: { _eq: "CANCELLED" } }
    ) {
      data: aggregate {
        count
      }
    }
  }
`;

export const getSourcingSheet = gql`
  query SourcingSheet($id: String!) {
    sheet: sourcing_sourcingsheet(where: { code: { _eq: $id } }) {
      id
      code
      picklist: sourcing_picklists(order_by: { id: asc }) {
        id
        item: product_title
        qtyNeeded: quantity_needed
        expectedOnHand: quantity_on_hand
        status
        actualOnHand: actual_on_hand
      }
    }
  }
`;

export const updatePicklistItem = gql`
  mutation UpdatePicklistItem(
    $id: Int!
    $values: sourcing_picklist_set_input!
  ) {
    update_sourcing_picklist(where: { id: { _eq: $id } }, _set: $values) {
      returning {
        id
      }
    }
  }
`;
