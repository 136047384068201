import React from "react";
import { Link } from "react-router-dom";

const MainPanelHeader = ({
  saveOrder,
  isSaving,
  transactionItems,
  transactionId,
  isNewOrder
}) => {
  return (
    <div className="level">
      <div className="level-left">
        <h1>Order Items</h1>
      </div>
      <div className="level-right">
        <div className="buttons">
          <button
            className={`button ${isSaving ? "is-loading" : null} ${
              transactionItems ? "is-primary" : "is-disabled"
            }`}
            type="submit"
            form="sidebar-form"
            disabled={transactionItems ? false : true}
          >
            Save
          </button>
          <Link
            className={`button is-outlined ${
              isNewOrder ? "disabled-link" : ""
            }`}
            to={`/orders/${transactionId}/print`}
            disabled={isNewOrder ? true : false}
          >
            Print DR
          </Link>
          <Link
            className={`button is-outlined ${
              isNewOrder ? "disabled-link" : ""
            }`}
            to={`/orders/${transactionId}/print_si`}
            disabled={isNewOrder ? true : false}
          >
            Print SI
          </Link>
        </div>
      </div>
    </div>
  );
};

export default MainPanelHeader;
