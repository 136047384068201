import { combineReducers } from "redux";
import // isFetchingProductsReducer,
// quotableProduct?SsReducer
"Products/reducers";
import batchTransactionReducer from "BatchTransaction/reducers";
import { notificationReducer } from "UIKit/reducers";
import { authToken, user } from "Auth/reducers";
import { setSearchQueryReducer } from "Search/reducers";
import { merge } from "lodash";

import {
  fetchOrderDetail,
  fetchOrderItems,
  fetchStatusB2B
} from "./PurchaseOrder/reducers";

import { clientDetailReducer, clientSiteReducer } from "./Client/reducer";

import {
  fetchProductRequestDetailReducer,
  fetchFormReducer
} from "./RequestedProducts/reducers";

const entities = (
  state = {
    products: {},
    priceLists: {},
    drugs: {},
    coupons: {}
  },
  action
) => {
  if (action.payload && action.payload.entities) {
    return merge({}, state, action.payload.entities);
  }

  return state;
};

const rootReducer = combineReducers({
  entities,
  authToken,
  user,
  // isFetchingProducts: isFetchingProductsReducer,
  searchResult: setSearchQueryReducer,
  batchTransaction: batchTransactionReducer,
  notification: notificationReducer,

  clientDetailReducer,
  clientSiteReducer,

  //PRODUCT REQUEST
  fetchProductRequestDetailReducer,
  fetchFormReducer,

  fetchOrderDetail,
  fetchOrderItems,
  fetchStatusB2B
});
export default rootReducer;
