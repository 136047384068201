import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Submenu from "SummaryView/components/Submenu/Submenu";
import ActionButtons from "SummaryView/components/Submenu/ActionButtons";
import { pushNotification } from "UIKit/actions";
import PageContent from "UIKit/components/PageContent";
import Dashboard from "Dashboard/components/Dashboard";
import TransactionRow from "SummaryView/components/TransactionsTable/TransactionRow";

const SUMMARY_VIEWS = ["Due Soon", "Least Updated", "Unopened"];

const headers = [
  { displayName: "Status", attributeName: "current_status", canSortBy: true },
  {
    displayName: "Created",
    attributeName: "created",
    canSortBy: true
  },
  { displayName: "Order ID", attributeName: "id", canSortBy: true },
  {
    displayName: "Customer",
    attributeName: "customer_last_name",
    canSortBy: true
  },
  {
    displayName: "City",
    attributeName: "shipping_city_municipality",
    canSortBy: true
  },
  { displayName: "Value", attributeName: "total", canSortBy: false },
  { displayName: "Notes", attributeName: "internal_notes", canSortBy: true },
  { displayName: "Last Update", attributeName: "last_update", canSortBy: true }
];

class SummaryView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTransactions: [],
      filters: [{ field: "current_status", value: "CREATED" }],
      sortBy: "",
      isSortAscending: true,
      isFetchingTransactions: false,
      visibleTransactions: [],
      currentPage: 1,
      numberOfPages: 0,
      selectedLevel: "default",
      selectedSummary: "",
      activeViewIndex: 0,
      statusColor: "#fe385f"
    };
  }

  setFilter = viewFilter => {
    switch (viewFilter) {
      case "Due Soon":
        this.setState({
          filters: [{ field: "current_status", value: "FOR DELIVERY" }],
          statusColor: "#fe385f"
        });
        break;

      case "Least Updated":
        this.setState({
          filters: [{ field: "last_update" }],
          statusColor: "#f57f20"
        });
        break;

      case "Unopened":
        this.setState({
          filters: [{ field: "current_status", value: "CREATED" }],
          statusColor: "#ffe270"
        });
        break;

      default:
        this.setState({
          filters: [{ field: "current_status", value: "FOR DELIVERY" }]
        });
    }
  };

  componentDidMount() {
    window.setInterval(() => {
      if (this.state.activeViewIndex < SUMMARY_VIEWS.length - 1)
        this.setState(
          previousState => {
            return {
              activeViewIndex: previousState.activeViewIndex + 1
            };
          },
          () => this.setFilter(SUMMARY_VIEWS[this.state.activeViewIndex])
        );
      else
        this.setState({ activeViewIndex: 0 }, () =>
          this.setFilter(SUMMARY_VIEWS[this.state.activeViewIndex])
        );
    }, 30000);
  }

  componentWillUnmount() {
    clearInterval();
  }

  handleSelectSummary = index => {
    this.setState(
      {
        activeViewIndex: index
      },
      () => this.setFilter(SUMMARY_VIEWS[index])
    );
  };

  render() {
    const {
      selectedSummary,
      activeViewIndex,
      selectedTransactions,
      filters,
      statusColor
    } = this.state;

    return (
      <Fragment>
        <PageContent>
          <Submenu
            leftButtons={
              <ActionButtons
                buttonColor={statusColor}
                buttons={SUMMARY_VIEWS}
                activeViewIndex={activeViewIndex}
                handleSelectSummary={this.handleSelectSummary}
                selectedSummary={selectedSummary}
              />
            }
          />
          <Dashboard
            baseUrl="transactions/?"
            headers={headers}
            selectedData={selectedTransactions}
            filters={filters}
            idAttribute="id"
            RowComponent={props => (
              <TransactionRow color={statusColor} {...props} />
            )}
            showCheckbox={false}
          />
        </PageContent>
      </Fragment>
    );
  }
}

const mapStateToProps = state => ({
  isFetchingTransactions: state.isFetchingTransactions,
  selectedTransactions: state.selectedTransactions,
  notification: state.notification
});

export default connect(
  mapStateToProps,
  {
    pushNotification
  }
)(SummaryView);
