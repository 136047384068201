import React from "react";

const PageContent = ({ children, hasTitle, title }) => (
  <div className="container has-padding-bottom">
    <h3 className={hasTitle ? "title" : "is-hidden"}>{title}</h3>
    {children}
  </div>
);

export default PageContent;
