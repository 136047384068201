import api from "../Services/api";

export const CLIENT_FETCH_TYPE = {
  ON_FETCH_CLIENT_SUCCESS: "FETCH_CLIENTDETAIL_ON_CLIENTS_SUCCESS",
  ON_FETCH_CLIENT_FAILED: "FETCH_CLIENTDETAIL_ON_CLIENTS_FAILED",
  ON_FETCH_CLIENT_PROCESS: "FETCH_CLIENTDETAIL_ON_CLIENTS_ON_PROCESS"
};

export const CLIENT_SITE_FETCH_TYPE = {
  ON_FETCH_SITE_SUCCESS: "FETCH_CLIENT_SITES_SUCCESS",
  ON_FETCH_SITE_FAILED: "FETCH_CLIENT_SITES_FAIED",
  ON_FETCH_SITE_PROCESS: "FETCH_CLIENT_SITES_PROCESS"
};

export const fetchClientDetail = id => dispatch => {
  api
    .get(`/clients/${id}/`)
    .then(data => {
      dispatch({
        payload: data,
        type: CLIENT_FETCH_TYPE.ON_FETCH_CLIENT_SUCCESS
      });
    })
    .catch(error => {
      dispatch({
        type: CLIENT_FETCH_TYPE.ON_FETCH_CLIENT_FAILED,
        payload: []
      });
    });
};

export const fetchClientSites = id => async dispatch => {
  try {
    dispatch({
      type: CLIENT_SITE_FETCH_TYPE.ON_FETCH_SITE_PROCESS,
      payload: []
    });
    const response = await api.get(`/sites/?corporate_client=${id}`);
    dispatch({
      payload: response,
      type: CLIENT_SITE_FETCH_TYPE.ON_FETCH_SITE_SUCCESS
    });
  } catch (error) {
    dispatch({
      payload: [],
      type: CLIENT_SITE_FETCH_TYPE.ON_FETCH_SITE_FAILED
    });
  }
};
