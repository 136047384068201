import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

const PurchaseOrderPrintHeader = ({
  handlePrint,
  listOfChoices,
  componentToggle,
  typeOfOrder
}) => {
  return (
    <div className="quoteform-print notification is-warning">
      <div className="columns">
        <div className="column">
          <p className="print-message">
            You are printing &nbsp;
            <strong>
              <u>{typeOfOrder}</u> (choose A4 size when printing)
            </strong>
            . Please verify information before printing.
          </p>
        </div>

        <div className="column">
          <div className="field has-addons is-right">
            <p className="control mr-1">
              {listOfChoices && (
                <div className="select">
                  <select
                    onChange={event => {
                      componentToggle(event.target.value);
                    }}
                  >
                    {listOfChoices.map((item, index) => (
                      <option value={item} key={index}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
              )}
            </p>

            <p className="control">
              {/* <div className="buttons is-right mt-1"> */}
              <button onClick={handlePrint} className="button is-primary mr-1">
                PRINT
              </button>
              <Link to="/draft-purchase-orders">
                <Link className="button is-pulled-right" to="/quotes?page=1">
                  Back to Dashboard
                </Link>
              </Link>
              {/* </div> */}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

PurchaseOrderPrintHeader.propTypes = {
  handlePrint: PropTypes.func,
  listOfChoices: PropTypes.array,
  componentToggle: PropTypes.func,
  typeOfOrder: PropTypes.string.isRequired
};

export default PurchaseOrderPrintHeader;
