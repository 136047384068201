import axios from "axios";
import { camelizeKeys, decamelizeKeys } from "humps";
import { Auth } from "aws-amplify";

// import { schema } from "normalizr";
const { ConcurrencyManager } = require("axios-concurrency");

const MAX_CONCURRENT_REQUESTS = 1;

const apiQueue = axios.create({
  baseURL: `${process.env.REACT_APP_API_HOST}`
});

apiQueue.interceptors.request.use(
  async function(config) {
    if (config.data instanceof FormData) {
      config.method = "post";
      config.data.append("_method", "post");
    } else config.data = decamelizeKeys(config.data);

    const { accessToken } = await Auth.currentSession();
    const { attributes } = await Auth.currentUserInfo();

    config.headers["X-REMOTE-USER"] = attributes.email;
    config.headers.Authorization = accessToken.jwtToken;

    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

apiQueue.interceptors.response.use(
  function(response) {
    return camelizeKeys(response);
  },
  function(error) {
    // Do something with response error
    return Promise.reject(error);
  }
);

// this method is use to limit the requests sent to the server
// to 1.
//
// this method is the implementation of queue in adding items
ConcurrencyManager(apiQueue, MAX_CONCURRENT_REQUESTS);

export default apiQueue;
