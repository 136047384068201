import React, { Fragment } from "react";
import { FormField } from "UIKit/components/Forms/FormInput";
import locs from "assets/data/philippineLocations.json";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const AddressPicker = ({
  transaction,
  handleDetailChange,
  type,
  showError,
  handleChange
}) => {
  let filteredProvinces = [];
  filteredProvinces = locs.filter(
    (province, index, self) =>
      index === self.findIndex(p => p.Province === province.Province)
  );

  filteredProvinces.sort((x, y) => {
    if (x.Province.toLowerCase() < y.Province.toLowerCase()) return -1;
    if (x.Province.toLowerCase() > y.Province.toLowerCase()) return 1;
    return 0;
  });

  let selectedProvince =
    transaction[`${type}Province`] === undefined ||
    transaction[`${type}Province`] === null
      ? "Province"
      : transaction[`${type}Province`];

  let selectedCity =
    transaction[`${type}CityMunicipality`] === undefined ||
    transaction[`${type}CityMunicipality`] === null
      ? ""
      : transaction[`${type}CityMunicipality`];

  let selectedBarangay =
    transaction[`${type}Barangay`] === undefined ||
    transaction[`${type}Barangay`] === null
      ? "Barangay"
      : transaction[`${type}Barangay`];

  let filteredCities =
    // transaction[`${type}Province`]
    filteredProvinces.find(
      province => province.Province === selectedProvince
    ) === null ||
    filteredProvinces.find(
      province => province.Province === selectedProvince
    ) === undefined
      ? []
      : filteredProvinces.find(
          province => province.Province === selectedProvince
        ).Cities;

  let filteredBarangays =
    filteredCities.find(barangay => barangay.City === selectedCity) ===
      undefined ||
    filteredCities.find(barangay => barangay.City === selectedCity) === null
      ? []
      : filteredCities.find(barangay => barangay.City === selectedCity)
          .Barangays;

  const ValidationSchema = Yup.object().shape({
    [`${type}StreetAddress`]: Yup.string().required("This field is required.")
  });

  return (
    <Fragment>
      <Field
        name={`${type}StreetAddress`}
        render={({ field, form }) => (
          <FormField
            {...field}
            type="text"
            value={transaction[`${type}StreetAddress`]}
            placeholder="Street Address"
            label={"Shipping Address"}
            required={true}
            onChange={event => {
              handleDetailChange(event);
              handleChange(event);
            }}
          />
        )}
      />
      <ErrorMessage
        name={`${type}StreetAddress`}
        render={msg => (
          <div className="has-text-danger is-size-7 mt-0 mb-1">{msg}</div>
        )}
      />

      <div className="field control">
        <div className="select">
          <Field
            component="select"
            name={`${type}Province`}
            onChange={event => {
              handleDetailChange(event);
              handleChange(event);
            }}
          >
            <option value="" selected>
              Province
            </option>
            {filteredProvinces.map((province, index) => (
              <option value={province.Province} key={index}>
                {province.Province}
              </option>
            ))}
          </Field>
        </div>
        <ErrorMessage
          name={`${type}Province`}
          render={msg => (
            <div className="has-text-danger is-size-7 mt-0 mb-1">{msg}</div>
          )}
        />
      </div>

      <div className="field control">
        <div className="select">
          <Field
            component="select"
            name={`${type}CityMunicipality`}
            onChange={event => {
              handleDetailChange(event);
              handleChange(event);
            }}
            disabled={selectedProvince === "Province" ? true : false}
          >
            <option value="" selected>
              City / Municipality
            </option>
            {selectedProvince === undefined || selectedProvince === null
              ? null
              : filteredCities.map((city, index) => (
                  <option value={city.City} key={index}>
                    {city.City}
                  </option>
                ))}
          </Field>
        </div>
      </div>
      <ErrorMessage
        name={`${type}CityMunicipality`}
        render={msg => (
          <div className="has-text-danger is-size-7 mt-0 mb-1">{msg}</div>
        )}
      />

      <div className="field control">
        <div className="select">
          <Field
            component="select"
            name={`${type}Barangay`}
            onChange={event => {
              handleDetailChange(event);
              handleChange(event);
            }}
            disabled={!selectedCity}
          >
            <option value="" selected>
              Barangay
            </option>
            {selectedCity === undefined || selectedCity === null
              ? "Barangay"
              : filteredBarangays.map((barangay, index) => (
                  <option value={barangay} key={index}>
                    {barangay}
                  </option>
                ))}
          </Field>
        </div>
      </div>
      <ErrorMessage
        name={`${type}Barangay`}
        render={msg => (
          <div className="has-text-danger is-size-7 mb-3">{msg}</div>
        )}
      />
      <div className="field control">
        <Field
          name={`${type}PostalCode`}
          render={({ field, form }) => (
            <FormField
              {...field}
              type="text"
              value={transaction[`${type}PostalCode`]}
              onChange={event => {
                handleDetailChange(event);
                handleChange(event);
              }}
              placeholder="Postal Code"
            />
          )}
        />
      </div>

      <Field
        name={`${type}Landmarks`}
        render={({ field, form }) => (
          <FormField
            {...field}
            type="text"
            value={transaction[`${type}Landmarks`]}
            onChange={event => {
              handleDetailChange(event);
              handleChange(event);
            }}
            placeholder="Landmarks"
          />
        )}
      />
    </Fragment>
  );
};

export default AddressPicker;
