import React from 'react';
import {FormInput, TextArea, DateField, CheckBox} from 'UIKit/components/Forms/FormInput';
import { AddCouponContext } from 'Coupons/components/AddCoupon/AddCoupon';

const BasicInfoForm = () => {
  return(
    <AddCouponContext.Consumer>
      { context =>
      <div className='coupon-form'>
        <FormInput 
          type="text" 
          id="code" 
          value={context.coupon.code} 
          label="Coupon Code" 
          onChange={context.getData} 
        />
        <TextArea 
          id="description"
          label="Description"
          value={context.coupon.description}
          onChange={context.getData}
        />
        <DateField 
          label="Activation Date"
          value={context.coupon.activation}
          onChange={context.getActivationDate}
        />
        <DateField 
          label="Expiration Date"
          value={context.coupon.expiration}
          onChange={context.getExpirationDate}
        />
        <FormInput 
          type="number" 
          id="totalUseLimit" 
          value={context.coupon.totalUseLimit} 
          label="Total Use limit"
          onChange={context.getData}
        />
        <FormInput 
          type="number" 
          id="dailyUseLimit" 
          value={context.coupon.dailyUseLimit} 
          label="Daily Use limit" 
          onChange={context.getData}
        />
        <CheckBox 
          id="waiveFees"
          onChange={context.getData}
          label="Waive Service and Delivery Fees"
        />
      </div>}
    </AddCouponContext.Consumer>
  );
}; 

export default BasicInfoForm;