import React, { useState } from "react";

import Modal from "UIKit/components/Modal";
import PurchaseOrderSidebarForm from "./Sidebar/PurchaseOrderSidebarForm";

import "./PurchaseOrder.scss";

const PurchaseOrderDuplicationModal = ({
  orderDetails,
  isActive,
  handleOnSave,
  statusList,
  handleActiveDuplicationModal
}) => {
  const [updatePrice, setUpdatePrice] = useState(true);

  const handleOnSubmit = values => {
    values["previousOrderId"] = values.id;
    delete values.id;
    delete values.createdFor.id;
    values["updateItemPrices"] = updatePrice;
    handleOnSave(values);
  };

  return (
    <Modal isActive={isActive} handleCloseModal={handleActiveDuplicationModal}>
      <section className="modal-card-body">
        <PurchaseOrderSidebarForm
          objectFetched={{ ...orderDetails, status: "CREATED" }}
          statusList={statusList}
          editableAddress={false}
          editableSite={true}
          handleChangeObjectFetched={() => {}}
          handleOnSave={handleOnSubmit}
          handleOnChangeStatus={false}
          formId="duplicateFormOrderDetail"
        />
      </section>
      <footer className="modal-card-foot p-0">
        <div className="notification is-warning level mx-0 px-5 fullwidth">
          <label className="checkbox">
            <input
              type="checkbox"
              checked={!updatePrice}
              onClick={() => setUpdatePrice(!updatePrice)}
            />
            &nbsp;&nbsp;&nbsp;Do you want to retain the unit price of items?
          </label>
        </div>
      </footer>
      <footer className="modal-card-foot">
        <button className="button is-primary" form="duplicateFormOrderDetail">
          Continue
        </button>
        <button
          type="button"
          className="button"
          onClick={handleActiveDuplicationModal}
        >
          Cancel
        </button>
      </footer>
    </Modal>
  );
};

export default PurchaseOrderDuplicationModal;
