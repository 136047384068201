export const actionTypes = {
  SHOW_NOTIFICATION: "SHOW_NOTIFICATION",
  HIDE_NOTIFICATION: "HIDE_NOTIFICATION",
  PUSH_NOTIFICATION: "PUSH_NOTIFICATION"
};

function showNotification(message, type) {
  return {
    type: actionTypes.SHOW_NOTIFICATION,
    payload: { message, type }
  };
}

function hideNotification() {
  return {
    type: actionTypes.HIDE_NOTIFICATION
  };
}

export const pushNotification = (duration, message, type) => dispatch => {
  dispatch(showNotification(message, type));
  setTimeout(() => dispatch(hideNotification()), duration);
};
