import React from "react";

const SearchResults = ({
  searchResults,
  active,
  onClick,
  hideResults,
  ROWID
}) => {
  return (
    <div>
      {searchResults.length > 0 && hideResults === false && (
        <div className="bordered">
          <table className="table is-striped is-bordered is-fullwidth is-hoverable">
            <tbody>
              {searchResults.map(
                (item, i) =>
                  i <= active + 4 &&
                  i >= active && (
                    <tr
                      key={i}
                      className={
                        i === active ? "is-primary can-select" : "can-select"
                      }
                      onClick={() => {
                        onClick(i);
                      }}
                    >
                      <td>
                        <span
                          dangerouslySetInnerHTML={{ __html: item.toDisplay }}
                        />
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          </table>
        </div>
      )}
    </div>
  );
};

export default SearchResults;
