import React, { Fragment } from "react";
import { FastField as Field, ErrorMessage } from "formik";
import PropTypes from "prop-types";

import { Row, Cell as Td } from "UIKit/components/Table_v2/Table";
import FormikSearchField from "UIKit/components/SearchInput/FormikSearchField";

import "../../../PurchaseOrder.scss";

const EditableRow = ({
  sequence,
  values,
  setValues,
  setFormValues,
  onPaste,
  activeRowNumber,
  isUserPermitted,
}) => {
  const { recommendedOrder, requestedOrder } = values;

  const handleCaptureObject = (searchObj, type) => {
    // !WARNING Remove this: setFormValues(searchObj, setValues, values, sequence, type);
  };

  const isRowActive = () => {
    if (activeRowNumber === sequence && sequence) return "is-active-row";
    return "";
  };

  return (
    <Row className={isRowActive()}>
      <Td id="sequence">{(sequence || sequence === 0) && sequence + 1}</Td>
      <Td priority>
        <Field name="clientRequest" className="input" onPaste={onPaste} />
        <div className="has-text-danger is-size-7 mt-1">
          <ErrorMessage name="clientRequest" className="error " />
        </div>
      </Td>
      <Td priority>
        <FormikSearchField
          autoFocus
          className="input"
          keyValue="id"
          baseSearch="products/filtered?"
          keyElement="productTitle"
          functionToInvoke={(searchObj) => () =>
            handleCaptureObject(searchObj, "requested")}
          name="requestedOrder.productTitle"
          defaultValue={values.requestedOrder.productTitle}
          onPaste={onPaste}
        />
        <div className="has-text-danger is-size-7 mt-1">
          <ErrorMessage name="requestedOrder.productTitle" className="error" />
        </div>
      </Td>
      <Td>
        <Field
          name="quantity"
          className="input has-text-centered"
          type="number"
          onPaste={onPaste}
        />
      </Td>
      <Td id="productTitleCell" className="has-text-right">
        {isUserPermitted ? (
          <Field
            type="number"
            step="0.01"
            name="requestedOrder.unitPrice"
            className="input"
          />
        ) : (
          parseFloat(requestedOrder.unitPrice).toFixed(2)
        )}
      </Td>
      <Td className="has-text-right">
        {(requestedOrder.unitPrice * values.quantity).toFixed(2)}
      </Td>
      <Td priority>
        <Field name="remarks" className="input" onPaste={onPaste} />
        <div className="has-text-danger is-size-7 mt-1">
          <ErrorMessage name="remarks" className="error " />
        </div>
      </Td>
      {/* {recommendedOrder && (
        <Fragment>
          <Td priority>
            <FormikSearchField
              className="input "
              keyValue="id"
              baseSearch="products/filtered"
              keyElement="productTitle"
              functionToInvoke={(searchObj) => () =>
                handleCaptureObject(searchObj, "recommended")}
              name="recommendedOrder.productTitle"
              defaultValue={values.recommendedOrder.productTitle}
            />
          </Td>
          <Td className="has-text-right">
            {parseFloat(recommendedOrder.unitPrice).toFixed(2)}
          </Td>
          <Td className="has-text-right">
            {(recommendedOrder.unitPrice * values.quantity).toFixed(2)}
          </Td>
        </Fragment>
      )} */}
      <Td />
    </Row>
  );
};

EditableRow.propTypes = {
  values: PropTypes.object,
  sequence: PropTypes.number,
  setValues: PropTypes.func,
  setFormValues: PropTypes.func.isRequired,
  onPaste: PropTypes.func,
};

export default EditableRow;
