import React, { Fragment } from "react";
import ReceiptHeader from "Printables/Receipt/ReceiptHeader";
import DrugCardBody from "Printables/Receipt/DrugCard/DrugCardBody";
import DrugCardFooter from "Printables/Receipt/DrugCard/DrugCardFooter";

const DrugCard = ({ transaction, products }) => {
  let drugList = [];

  products.forEach((product) => {
    const drugDetail = {
      ...product.drugDetail,
      productCategory: product.productCategory,
    };
    const isAlreadyInserted = drugList.find(
      (element) =>
        element.administration === drugDetail.administration &&
        element.form === drugDetail.form &&
        element.molecule === drugDetail.molecule
    );

    if (isAlreadyInserted) return;
    drugList.push(drugDetail);
  });

  return (
    <Fragment>
      {/* DrugCard has three parts:
          ReceiptHeader   contains customer information + MG logo
          DrugCardBody    contains medicines purchased + drug information
          DrugCardFooter  QR code directing to MedGrocer.com + CTA + general disclaimer */}

      <ReceiptHeader
        header="DRUG INFO SHEET"
        isDrugCard
        customer={
          transaction.customerFirstName + " " + transaction.customerLastName
        }
        mobile={"0" + transaction.mobileNumber}
        date={new Date(Date.now()).toDateString()}
        notes={transaction.deliveryNotes ? transaction.deliveryNotes : "None"}
        companyName={transaction.companyName}
        shippingAddress={
          transaction.shippingStreetAddress +
          ", " +
          transaction.shippingCityMunicipality +
          ", " +
          transaction.shippingProvince
        }
        billingAddress={
          transaction.billingStreetAddress +
          ", " +
          transaction.billingCityMunicipality +
          ", " +
          transaction.billingProvince
        }
      />

      <hr className="divider" />

      <div className="drugcard">
        {drugList.map((drugDetail, index) => {
          const drug = drugDetail;
          if (!!!drug.molecule) return "";
          return (
            <DrugCardBody
              key={index}
              icon={drug.molecule ? drug.molecule[0] + drug.molecule[1] : ""}
              form={drug.form}
              molecule={drug.molecule}
              category={drug.productCategory[0]}
              indication={drug.indication}
              administration={drug.administration}
            />
          );
        })}
      </div>

      <hr className="divider" />

      <DrugCardFooter
        refillCode={transaction.refillCode}
        orderID={transaction.id}
        zendeskID={transaction.zendeskId}
      />
    </Fragment>
  );
};

export default DrugCard;
