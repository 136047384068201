const VAT_MULTIPLIER = (1.12).toFixed(2);
const FALLBACK = "wholesale";

export const getPriceType = (value, priceList) => {
  if (value && value.prices && value.prices.length) {
    const price =
      value.prices.find(price => price.priceList === priceList) ||
      value.prices.find(price => price.priceList === FALLBACK);

    return price || { vatexUnitPrice: 0.0, vat: "0.0", priceList: "unknown" };
  } else return { vatexUnitPrice: 0.0, vat: "0.0", priceList: "unknown" };
};

export default (taxRate, clientPriceList, productBase) => {
  const priceType = getPriceType(productBase, clientPriceList);
  if (
    taxRate === "tax_on_sales" &&
    parseFloat(priceType.vat).toFixed(2) !== (0.0).toFixed(2)
  )
    return parseFloat((priceType.vatexUnitPrice * VAT_MULTIPLIER)).toFixed(2);
  else return parseFloat(priceType.vatexUnitPrice).toFixed(2);
};
