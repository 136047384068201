import React, {Component} from 'react';
import PageContent from 'UIKit/components/PageContent';

class ViewActive extends Component {
  render() {
    return(
      <PageContent>
        <h3 className="title is-4">View Active Coupons</h3>
      </PageContent>
    );
  }
}

export default ViewActive;