import React, { Component } from "react";
import { Formik, Form, Field } from "formik";
import PropTypes from "prop-types";
import Modal from "UIKit/components/Modal";
import api from "Services/api";

const successMessage = "Successfully converted Draft Order to Purchase Order.";
const errorMessage =
  "Error converting to PO. Please check the billing and shipping addresses of the site, and must not have N/A on items.";
class PurchaseOrderModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      isDisabled: true
    };
  }

  handleConvertToPO = values => {
    const { pushNotification, handleSetInActive } = this.props;
    this.setState(() => ({ isLoading: true }));
    api
      .post(`/purchase-orders/`, this.props.objectFetched)
      .then(() => {
        this.setState(() => ({ isLoading: false }));
        pushNotification(5000, successMessage, "is-success");
        handleSetInActive();
      })
      .catch(err => {
        this.setState(() => ({ isLoading: false }));
        handleSetInActive();
        pushNotification(8000, errorMessage, "is-danger");
      });
  };

  handleValidateInput = handleChange => event => {
    this.setState(() => ({
      isDisabled: !(event.target.value === this.props.objectCode)
    }));
    handleChange(event);
  };
  render() {
    const { objectCode, isActive, handleSetInActive } = this.props;
    return (
      <Modal
        isActive={isActive}
        title="Confirm PO Creation: "
        handleCloseModal={handleSetInActive}
      >
        <Formik onSubmit={this.handleConvertToPO}>
          {({ handleChange }) => {
            return (
              <Form className="modal-card-body">
                <div className="notification is-warning is-marginless">
                  <div>
                    Read first or you might regret pressing that button!
                  </div>
                </div>
                <div className="p-2">
                  <span className="has-text-weight-bold">{`Code: ${objectCode ||
                    ""}`}</span>
                  <br />
                  <br />
                  <div>
                    This will convert
                    <span className="has-text-weight-bold">
                      &nbsp; into an offical purchase order.
                    </span>
                    <br />
                    Please type the Draft PO code to confirm.
                  </div>
                  <br />
                  <Field
                    id="codeVerifier"
                    name="codeVerifier"
                    className="input is-fullwidth"
                    placeholder="ex. 1ZZ0QZ0A01W"
                    onChange={this.handleValidateInput(handleChange)}
                  />
                </div>
                <footer className="modal-card-foot">
                  <button
                    name="onConvertPurchaseOrder"
                    type="submit"
                    className={`button is-primary ${this.state.isLoading &&
                      "is-loading"}`}
                    disabled={this.state.isDisabled}
                  >
                    Continue
                  </button>
                  <button
                    name="cancelButton"
                    className="button"
                    onClick={handleSetInActive}
                  >
                    Cancel
                  </button>
                </footer>
              </Form>
            );
          }}
        </Formik>
      </Modal>
    );
  }
}

PurchaseOrderModal.propTypes = {
  paramsId: PropTypes.string,
  objectCode: PropTypes.string,
  isActive: PropTypes.bool,
  handleSetInActive: PropTypes.func,
  pushNotification: PropTypes.func
};

export default PurchaseOrderModal;
