import React, { useState } from "react";
import { useMutation } from "@apollo/react-hooks";
import Input from "UIKit/components/Input";
import { Dropdown } from "UIKit/components/Forms/FormInput";
import { updatePicklistItem as UPDATE_ITEM } from "Sourcing/Schema";
import CircleLoader from "UIKit/components/Loaders/CircleLoader";

const options = ["SOURCE", "OK", "OUT OF STOCK"];

const PickListRow = ({ data }) => {
  const [actualOnHand, setActualOnHand] = useState(data.actualOnHand);
  const [actualStatus, setStatus] = useState(data.status);
  const [debounceQueue, debounce] = useState(null);
  const [updateItem, { loading: update }] = useMutation(UPDATE_ITEM);

  const GENERATE_MUTATION_DATA = (value, suggestedStatus) => {
    let status = calculateStatus(value, suggestedStatus);
    return {
      variables: { id: data.id, values: { actual_on_hand: value, status } }
    };
  };

  const calculateStatus = (value, suggestedStatus) => {
    if (suggestedStatus === "OUT OF STOCK") return "OUT OF STOCK";
    setStatus(value >= data.expectedOnHand ? "OK" : "SOURCE");
    return value >= data.expectedOnHand ? "OK" : "SOURCE";
  };

  const handleChange = event => {
    const value = event.target.value;
    if (value > 9999) return false;
    if (debounceQueue) clearTimeout(debounceQueue);
    setActualOnHand(value);
    debounce(setTimeout(() => updateItem(GENERATE_MUTATION_DATA(value)), 600));
  };

  const handleChangeSelected = ({ target: { value } }) => {
    if (value === "OUT OF STOCK") {
      setStatus(value);
      setActualOnHand(0);
      setTimeout(() => updateItem(GENERATE_MUTATION_DATA(0, value)), 600);
    }
  };

  return (
    <tr>
      <td>{update && <CircleLoader type="has-text-primary" />}</td>
      <td>{data.item}</td>
      <td className="has-text-right">{data.qtyNeeded}</td>
      <td className="has-text-right">{data.expectedOnHand}</td>
      <td className="has-text-right px-3">
        <Input
          type="number"
          modifier="has-text-right is-narrow"
          onchange={handleChange}
          value={actualOnHand}
        />
      </td>
      <td>
        <Dropdown
          options={options}
          selected={actualStatus}
          onChange={handleChangeSelected}
        />
      </td>
      <td></td>
    </tr>
  );
};

export default PickListRow;
