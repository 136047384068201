import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import {
  SideBarContainer,
  PanelContent
} from "UIKit/components/SplitContainer/SplitContainer";

import "../IndividualOrder.scss";

import TransactionHeaderDropdown from "./TransactionHeaderDropdown.js";
import CustomerDetails from "./CustomerDetails.js";

const handleMutateTransactionObject = value => {
  const obj = { ...value };
  const { customerFirstName, customerLastName } = obj;
  const { customerEmail, mobileNumber, companyName } = obj;
  obj.customerFirstName = {
    value: customerFirstName,
    label: customerFirstName
  };
  obj.customerLastName = { value: customerLastName, label: customerLastName };
  obj.customerEmail = { value: customerEmail, label: customerEmail };
  obj.mobileNumber = { value: mobileNumber, label: mobileNumber };
  obj.companyName = { value: companyName, label: companyName };
  return obj;
};

const ValidationSchema = Yup.object().shape({
  customerFirstName: Yup.object().shape({
    value: Yup.string().required("This Field is required")
  }),
  customerLastName: Yup.object().shape({
    value: Yup.string().required("This Field is required")
  }),
  customerEmail: Yup.object().shape({
    value: Yup.string().required("This Field is required")
  }),
  mobileNumber: Yup.object().shape({
    value: Yup.string().required("This Field is required")
  }),
  shippingStreetAddress: Yup.string().required("This field is required"),
  shippingCityMunicipality: Yup.string().required("This field is required"),
  shippingProvince: Yup.string().required("This field is required"),
  shippingBarangay: Yup.string().required("This field is required")
});

const TransactionSidebarForm = props => {
  const formValues = handleMutateTransactionObject(props.transaction);

  const handleOnSubmit = values => {
    props.saveOrder();
  };
  return (
    <SideBarContainer>
      <Formik
        onSubmit={handleOnSubmit}
        validationSchema={ValidationSchema}
        initialValues={formValues || {}}
        enableReinitialize
        render={({ handleChange, setFieldValue }) => (
          <Form name="sidebar-form" autoComplete="off" id="sidebar-form">
            <PanelContent header="Order Status">
              <TransactionHeaderDropdown
                statusLog={props.statusLog}
                postStatusLog={props.postStatusLog}
                setStatusLog={props.setStatusLog}
              />
            </PanelContent>
            <PanelContent
              headerComponent={() => (
                <div>
                  Enter Customer Details{" "}
                  {formValues.channel === "regular_vip" && (
                    <FontAwesomeIcon
                      icon={faStar}
                      className="has-text-yellow-orange"
                    />
                  )}
                </div>
              )}
            >
              <CustomerDetails
                values={formValues}
                isSameAddress={props.isSameAddress}
                hideBillingAddress={props.hideBillingAddress}
                handleDetailChange={props.handleDetailChange}
                isNewOrder={props.isNewOrder}
                showError={props.showError}
                handleFieldChange={handleChange}
                setFieldValue={setFieldValue}
              />
            </PanelContent>
          </Form>
        )}
      />
    </SideBarContainer>
  );
};

export default TransactionSidebarForm;
